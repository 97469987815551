import React, { useEffect } from 'react';
import { getCommaSeparatedNumber } from '../../../utils/common';

/**
 * 그룹 박스(Input + Button + unit)를 출력한다.
 * @param title: 제목을 가져온다
 * @param value: state
 * @param setValue: setState
 * @param unit: 단뤼를 지정한다
 * @param maxLength: 자릿수 제한 없으면 기본값(4자릿수)
 * @returns
 */
const InputGroup5 = ({ title, value, setValue, unit, maxLength, disabled }) => {
    /**
     * 입력되는 값을 숫자로 변환 한다.
        1. 첫 글자는 0일 될 수 없게
        2. 숫자로 변환(정규식)
     */
    const _handleOnChange = ({ target }) => {
        let result = target.value;

        result = result.replace(/^0/g, ''); // 1. 첫 글자가 0이면 변경
        result = result.replace(/[^0-9]/g, ''); // 2. 숫자로 변환

        setValue(result);
    };

    return (
        <div className="styled-group">
            <div className="styled-left2">
                <span className="title">{title}</span>
            </div>
            <div className="styled-middle">
                <input
                    className="middle"
                    type="text"
                    // pattern="[0-9,]*"
                    maxLength={maxLength ? maxLength : 6} // 기본값은 6자리
                    value={getCommaSeparatedNumber(value)}
                    onChange={_handleOnChange}
                    disabled={disabled}
                />
            </div>
            <div className="styled-right2">
                <span className="unit">{unit}</span>
            </div>
        </div>
    );
};

export default InputGroup5;
