import React from 'react';

const SelectOption = ({ value, setValue, options }) => {
    return (
        <select
            className="styled-select"
            value={value}
            onChange={(e) => {
                setValue(parseInt(e.target.value));
            }}
        >
            {options.map((option) => (
                <option key={option.id} value={option.id}>
                    {option.name}
                </option>
            ))}
        </select>
    );
};

export default SelectOption;
