import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    position: fixed;
    z-index: 999;
    opacity: 0.3;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: black;
`;

const ScreenBlock = () => {
    return (
        <Container />
    );
};

export default ScreenBlock;