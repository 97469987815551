import React from 'react';
import { confirmAlert } from 'react-confirm-alert';

/**
 * '정말 삭제하시겠습니까?' 확인(Confirm)창을 띄운다.
 * : 콜백함수에 대한 이해가 부족하다!! 돌아가서 짜긴 했는데.. 조금 더 공부할 것
 *   2022.01.05.(목) @ 부산서부교회 전도사사택
 * @param deleteCallBack 실행시킬 콜백함수
 */
export const showDeleteConfirmBox = async ( deleteCallBack ) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className='custom-ui'>
                    <h1>정말 삭제 하시겠습니까?</h1>
                    <p>You want to delete this file?</p>

                    <button onClick={ onClose }>취소</button>
                    <button className='delete-button'
                            onClick={() => {
                                deleteCallBack();
                                onClose();
                            }}
                    >
                    네, 삭제합니다.
                    </button>
                </div>
            );
        }
    });
}