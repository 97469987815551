/* eslint-disable no-useless-escape */
import React from 'react';
import { startOfMonth } from 'date-fns';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

/**
 * 이메일 오류를 체크한다.
 *
 * @format
 * @param {email} email
 * @returns
 */
export const vaildateEmail = (email) => {
  // eslint-disable-next-line
  const regex = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
  return regex.test(email);
};

/**
 * 비밀번호 오류를 체크한다.
 * @param {password} password
 * @returns
 */
export const vaildatePassword = (password) => {
  var num = password.search(/[0-9]/g);
  var eng = password.search(/[a-z]/gi);
  var spe = password.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);

  if (password.length < 6) {
    return false;
  } else if (num < 0 || eng < 0 || spe < 0) {
    return false;
  } else {
    return true;
  }
};

/**
 * 생일 오류를 체크한다.
 * @param {birthDay} birthDay
 * @returns
 */
export const validateBirthDay = (birthDay) => {
  const regex = /^(19|20)[0-9]{2}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
  return regex.test(birthDay);
};

/**
 * 전화번호 오류를 체크한다.
 * @param {*} phoneNumber
 * @returns
 */
export const validatePhoneNumber = (phoneNumber) => {
  const regex = /^01[0179]-[0-9]{3,4}-[0-9]{4}$/;
  return regex.test(phoneNumber);
};

/**
 * 받은 값이 숫자인지 확인한다.
 * @param {*} value
 * @returns
 */
export const validateNumber = (value) => {
  const regex = /^[0-9]+$/;
  return regex.test(value);
};
/**
 * 받은 값에 특수문자가 포함되어 있는지 확인한다.
 * @param {*} value
 * @returns
 */
export const validateText = (value) => {
  const regex = /[`~!@#$%^&*|\\\'\";:\/?]/gi;
  return regex.test(value);
};

/**
 * 공백을 제거한 문자열이 반환된다.
 * @param {text} text
 * @returns
 */
export const removeWhitespace = (text) => {
  const regex = /\s/g;
  return text.replace(regex, '');
};

/**
 * Unix Timestamp를 2021-12-14 18:44 의 형태로 보여준다.
 * @param {*} timestamp
 * @returns
 */
export const getFormattedTimeWithSeconds = (timestamp) => {
  const newDate = new Date(timestamp * 1000);
  const year = String(newDate.getFullYear());
  const month = String(newDate.getMonth() + 1).padStart(2, '0');
  const date = String(newDate.getDate()).padStart(2, '0');
  const hours = String(newDate.getHours()).padStart(2, '0');
  const minutes = String(newDate.getMinutes()).padStart(2, '0');
  return `${hours}시 ${minutes}분 ${newDate.getSeconds()}초`;
};

/**
 * Unix Timestamp를 2021-12-14 18:44 의 형태로 보여준다.
 * @param {*} timestamp
 * @returns
 */
export const getFormattedTime = (timestamp) => {
  const newDate = new Date(timestamp * 1000);
  const year = String(newDate.getFullYear());
  const month = String(newDate.getMonth() + 1).padStart(2, '0');
  const date = String(newDate.getDate()).padStart(2, '0');
  const hours = String(newDate.getHours()).padStart(2, '0');
  const minutes = String(newDate.getMinutes()).padStart(2, '0');
  return `${year}-${month}-${date} ${hours}:${minutes}`;
};

/**
 * Unix Timestamp를 2021-12-14 의 형태로 보여준다.
 * @param {*} timestamp
 * @returns
 */
export const getFormattedDate = (timestamp) => {
  const newDate = new Date(timestamp * 1000);
  const year = String(newDate.getFullYear());
  const month = String(newDate.getMonth() + 1).padStart(2, '0');
  const date = String(newDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${date}`;
};

/**
 * 글 작성시간을 기준으로 시간을 구한다
 * : 방금 -> 60분 -> 어제 -> 24시간 -> 00월 00일
 *
 * @param {*} writingTime
 * @returns
 */
export const getTimeByWritingTime = (writingTime) => {
  const today = new Date();
  const targetTime = new Date(writingTime * 1000);
  const timeDiff = Math.floor((today.getTime() - targetTime.getTime()) / 1000 / 60);

  if (timeDiff < 1) return '방금 전';
  if (timeDiff < 60) {
    return `${timeDiff}분 전`;
  }

  const oneHourDiff = Math.floor(timeDiff / 60);
  if (oneHourDiff < 24) {
    return `${oneHourDiff}시간 전`;
  }

  const oneDayDiff = Math.floor(timeDiff / 60 / 24);

  if (oneDayDiff === 1) {
    return '어제';
  }

  if (oneDayDiff < 7) {
    return `${oneDayDiff}일 전`;
  }

  if (today.getFullYear() === targetTime.getFullYear()) {
    // 쓰여진 글이 같은 년도에 쓰여졌다면, 년도는 생략한다.
    return `${targetTime.getMonth() + 1}월 ${targetTime.getDate()}일`;
  }

  return `${targetTime.getFullYear()}년 ${targetTime.getMonth() + 1}월 ${targetTime.getDate()}일`;
};
/**
 * 글 작성 시간을 기준으로 몇 일이 지났는지를 그 차이(difference)를 얻어온다.
 * @param {*} writingTime
 * @returns
 */
export const getTimeDiff = (writingTime) => {
  const today = new Date();
  const targetTime = new Date(writingTime * 1000);

  return Math.floor((today.getTime() - targetTime.getTime()) / 1000 / 60 / 60 / 24);
};

/**
 * 해당 장치(PC, Phone, Pad, etc..)에 로그인 정보를 저장한다.
 * @param email 사용자 이메일
 */
export const saveUserInfoOnDevice = ({ email }) => {
  localStorage.setItem('email', email);
};

/**
 * 해당 장치(PC, Phone, Pad, etc..)에 로그인 정보를 가져온다.
 * @returns
 */
export const getUserInfoOnDevice = () => {
  return { email: localStorage.getItem('email') };
};

/**
 * Bytes를 'KB', 'MB', 'GB', 'TB'로 변환한다.
 * @param {*} bytes
 * @returns
 */
export const getConvertedByteSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  if (bytes === 0) return 'O Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

/**
 * 1,000 단위의 숫자에서 콤마(comma를 찍어준다)
 * @param {*} number
 * @returns
 */
export const getCommaSeparatedNumber = (number) => {
  let result = 0;

  if (number) {
    result = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return result;
};
/**
 * 1,000 단위의 숫자에서 콤마(comma를 찍어준다, 0일 경우 '' 빈문자열 리턴)
 * @param {*} number
 * @returns
 */
export const getCommaSeparatedNumber2 = (number) => {
  let result = 0;

  if (number) {
    result = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return result ? result : '';
};

/**
 * 글 내용에 있는 링크들을 클릭하면 해당 링크로 갈 수 있도록 바꿔준다.
 * @param {*} content : ;
 * @returns
 */
export const changeURLinContent = (content) => {
  if (!content) return '';

  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // 링크를 감지하여 a 태그로 감싸기
  const replace = (content) => {
    const convertContent = content.replace(urlRegex, function (url) {
      return '<a href="' + url + '" target="_blank" style="color:#d5a10e">' + url + '</a>';
    });

    const htmlArr = [];
    convertContent.split('\n').forEach(function (text) {
      const textHtml = '<p>' + text + '</p>';
      htmlArr.push(textHtml);
    });

    return { __html: htmlArr.join('') };
  };

  return (
    <div>
      <div dangerouslySetInnerHTML={replace(content)}></div>
    </div>
  );
};

/**
 * 만 나이를 계산하여 온다.
 * @param birthDay : 2022-05-05
 */
export const getInternationalAge = ({ birthDay }) => {
  if (!birthDay) return 0;

  const arrBirth = birthDay.split('-');

  const today = new Date();
  const birthDate = new Date(arrBirth[0], arrBirth[1], arrBirth[2]); // 2022년 5월 5일

  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

/**
 * 한국에서 통용되는 나이를 계산하여 온다.
 * @param birthDay : 2022-05-05
 */
export const getKoreanAge = ({ birthDay }) => {
  if (!birthDay) return 0;

  const arrBirth = birthDay.split('-');

  const today = new Date();
  const birthDate = new Date(arrBirth[0], arrBirth[1], arrBirth[2]); // 2022년 5월 5일

  let age = today.getFullYear() - birthDate.getFullYear() + 1;

  return age;
};

/**
 * 현재의 날짜와 해당 월과 비교를 해서 값을 보내준다.
 * @param {*} param0
 * @returns
 */
export const getDifferentMonth = (month) => {
  return Math.floor(startOfMonth(month).getTime() / 1000) - Math.floor(startOfMonth(Date.now()).getTime() / 1000);
};

/**
 * 새 탭을 띄운다.
 * @param {string} url
 */
export const openNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

/**
 * 레포트를 PDF 파일로 저장한다.
 * @param {string} tableId
 * @param {string} fileName
 */
export const saveReportPDF = async ({ tableId, fileName }) => {
  const canvas = await html2canvas(document.getElementById(tableId), {
    // logging: true, // 디버그 목적 로그
    //proxy: "html2canvasproxy.php",
    allowTaint: true, // cross-origin allow
    useCORS: true, // CORS 사용한 서버로부터 이미지 로드할 것인지 여부
    scale: 2, // 기본 96dpi에서 해상도를 두 배로 증가
  });

  if (canvas) {
    const imgData = canvas.toDataURL('image/png');

    const imgWidth = 210; // 이미지 가로 길이(mm) / A4 기준 210mm
    const pageHeight = imgWidth * 1.414; // 출력 페이지 세로 길이 계산 A4 기준
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;

    const margin = 0; // 출력 페이지 여백설정
    const doc = new jsPDF('p', 'mm', 'a4');
    let position = 0;

    // 첫 페이지 출력
    doc.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    // 한 페이지 이상일 경우 루프 돌면서 출력
    while (heightLeft >= 20) {
      // 35
      position = heightLeft - imgHeight;
      position = position - 20; // -25

      doc.addPage();
      doc.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    // 파일 저장
    doc.save(fileName);
  }
};

/**
 *
 * @param {string} baseUrl
 * @param {object} params
 * @returns URL
 */
export const makeUrl = (baseUrl, params) => {
  const queryString = Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
  return `${baseUrl}?${queryString}`;
};
