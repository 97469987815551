import React, { useContext } from 'react';

import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { CommonContext, UserContext } from '../contexts';
import { DataTypes } from '../data/Types';
import { isLogin } from '../data/firebase';

const AdminAuth = () => {
    const { categories } = useContext(CommonContext);
    const { user } = useContext(UserContext);
    const location = useLocation();

    let result = '';

    try {
        if (!user) {
            return false;
        }

        if (!isLogin()) {
            // 로그인 X
            result = <Navigate to={`/member/unauthorized/${categories?.find((c) => c.categoryCode === 'login')?.id}/login`} state={{ from: location }} />;
        } else {
            // 로그인 O

            console.log(user.level);

            if (user.level >= DataTypes.USER_LEVEL_MANAGER) {
                // 매니저 이상만 접근 가능
                result = <Outlet />; // 접근 허가
            } else {
                result = <Navigate to="/" />; // 페이지가 존재한다는 사실도 알리면 안된다.
            }
        }
    } catch (e) {
        console.log(e.code, e.message);
    }

    return result;
};

export default AdminAuth;
