import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { CommonContext } from '../../contexts';

const InfoPresident = () => {
    const { categories } = useContext(CommonContext);
    const { pathname } = useLocation();

    return (
        // <!--s:#primary -->
                <div id='primary' className='content-area'>
                    {/* <!--s:#content --> */}
                    <div id='content' className='site-content'>
                        <article>
                            <div>
                                <div className='title-inner'>
                                    <img src='/common/images/title.png' alt='' />
                                    <h1>{ categories.find( category => category.categoryCode === pathname.split('/')[4])?.categoryName}</h1>
                                </div>
                                <div style={{ height: 20 }}></div>
                                <div style={{ clear: 'both' }}></div>
                                <div className='title_subpage_solid_1'></div>
                                <div style={{ height:30  }}></div>

                                <div className='greetings_wrap'>
                                    <div className=''>
                                        <h3>하나님과 교통하고 성도간 소통의 공간이 되기를...</h3>
                                        <div className='line_L7'></div>
                                        <p className='clr_both'>영남합회 홈페이지를 찾아주신 모든 분들을 진심으로 환영합니다.</p>
                                    </div>
                                    <div className='x-column x-sm vc x-1-2' ></div>
                                    <div className='single_image mb20'>
                                        
                                    </div>
                                    <div>
                                        <p>
                                            <img src='/common/images/insa_nsc.jpg' className='president' alt='' />
                                            영남합회 홈페이지를 통해 하나님의 은혜와 인도하심 그리고 성령의 역사를 함께 나누며 함께 기뻐하고 감사하기를 바랍니다. 그리고 어려움과 고난도 함께 나누며 기도하고 서로 용기와 격려를 하기를 바랍니다. 그래서 홈페이지를 통해 함께 기뻐하고 함께 기도하고 서로 격려하는 장소가 되기를 기원합니다.<br/><br/>
                                            그리고 홈페이지를 통해 영남합회 교회들 간에 더 가까워지고 또한 여러 정보들을 공유함으로 도움이 되고 또한 영적인 내용들을 통해 신앙생활에 힘이 되는 그러한 곳이 되기를 소망합니다.<br/><br/>
                                            아울러 영남합회 홈페이지를 잘 운영하여 많은 분들이 이용하도록 하겠습니다. 홈페이지를 이용하는 모든 분들에게 하나님의 은혜와 평강이 있기를 기원합니다. 감사합니다.<br/><br/>
                                            <span style={{ float: 'right' }}>영남합회장 <img src='/common/images/greetings-bg.jpg' alt='' /></span>
                                        </p>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- .entry-content --> */}
                        </article>
                    </div>
                    {/* <!--//e: #content --> */}
                </div>     
    );
};

export default InfoPresident;