import React, { useEffect, useState } from 'react';
import InputGroup from '../components/InputGroup';

const SeekerCount = ({ contactCnt, studyCnt, baptismStudents, baptistCnt, setReportMxData, reportMxData, disabled }) => {
    // const [disabled, setDisabled] = useState(false);

    // useEffect(() => {
    //     reportMxData.is_submitted === 'Y' ? setDisabled(true) : setDisabled(false);
    // }, [reportMxData.is_submitted]);

    return (
        <div className="row">
            <div className="panel">
                <div className="panel-heading">
                    <h3>구도자</h3>
                </div>
                <div className="panel-body">
                    <div className="col-xs-6 col-sm-3 col-md-3">
                        <InputGroup
                            title={'접촉중'} //
                            value={contactCnt}
                            setReportMxData={setReportMxData}
                            reportColumnName={'seekers_contact'}
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-xs-6 col-sm-3 col-md-3">
                        <InputGroup
                            title={'공부자'} //
                            value={studyCnt}
                            setReportMxData={setReportMxData}
                            reportColumnName={'seekers_study'}
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-xs-6 col-sm-3 col-md-3">
                        <InputGroup
                            title={'침례반'} //
                            value={baptismStudents}
                            setReportMxData={setReportMxData}
                            reportColumnName={'seekers_baptism'}
                            disabled={disabled}
                        />
                    </div>
                    <div className="col-xs-6 col-sm-3 col-md-3">
                        <InputGroup
                            title={'수침자'} //
                            value={baptistCnt}
                            setReportMxData={setReportMxData}
                            reportColumnName={'baptism'}
                            disabled={disabled}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SeekerCount;
