import React, { useEffect } from 'react';

import { getFormattedTime, openNewTab } from '../../../utils/common';
import { showConfirmBox } from '../../../components/ConfirmBox';

const TermReportBaseInfo = ({ errorMassage, termReportInfo, isSendable, setIsSendable, onSendButtonClick, onResetButtonClick, onSaveButtonClick }) => {
    const defaultSubmitTime = Math.floor(new Date().getTime() / 1000);

    useEffect(() => {
        termReportInfo?.is_submitted === 'Y' ? setIsSendable(false) : setIsSendable(true);
    }, [termReportInfo?.is_submitted]);

    return (
        <div className="row" style={{ marginBottom: 10 }}>
            <div className="panel">
                <div className="panel-heading">
                    <h3>{termReportInfo?.church_name ? termReportInfo.church_name : errorMassage.head}</h3>
                </div>
                {termReportInfo?.c_id ? (
                    <div className="panel-body">
                        {isSendable ? (
                            <>
                                <div className="col-xs-12 col-sm-4 col-md-4">
                                    <button
                                        className="styled-button send" //
                                        onClick={() =>
                                            showConfirmBox(
                                                //
                                                '보고서를 제출할까요?',
                                                '보고서가 제출되면 다시 수정하실 수 없습니다.',
                                                onSendButtonClick,
                                                'styled-button send',
                                            )
                                        }
                                    >
                                        보고서 제출
                                    </button>
                                </div>
                                <div className="col-xs-6 col-sm-4 col-md-4">
                                    <button //
                                        className="styled-button reset"
                                        onClick={() =>
                                            showConfirmBox(
                                                //
                                                '다시 작성할까요?',
                                                '등록된 데이터가 삭제됩니다. 그래도 다시 작성하시겠습니까?',
                                                onResetButtonClick,
                                                'styled-button reset',
                                            )
                                        }
                                    >
                                        다시 작성
                                    </button>
                                </div>
                                <div className="col-xs-6 col-sm-4 col-md-4">
                                    <button //
                                        className="styled-button save"
                                        onClick={onSaveButtonClick}
                                    >
                                        저장
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="col-xs-12 col-sm-8 col-md-8">
                                    <span className="message">
                                        해당 보고서는 {getFormattedTime(termReportInfo?.submitted_at === 0 ? defaultSubmitTime : termReportInfo?.submitted_at)}에 제출 되었습니다.
                                    </span>
                                </div>
                                <div className="col-xs-6 col-sm-2 col-md-2">
                                    <button //
                                        className="styled-button download"
                                        onClick={() => openNewTab(`/printTermReport/${termReportInfo?.id}/download`)}
                                    >
                                        다운로드
                                    </button>
                                </div>
                                <div className="col-xs-6 col-sm-2 col-md-2">
                                    <button //
                                        className="styled-button print"
                                        onClick={() => openNewTab(`/printTermReport/${termReportInfo?.id}/print`)}
                                    >
                                        인쇄
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                ) : (
                    <div className="panel-body">
                        <div style={{ color: 'red', padding: '0 10px 5px 10px' }}>
                            <div>{errorMassage.body}</div>
                            <div>{errorMassage.tail}</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TermReportBaseInfo;
