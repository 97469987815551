import React from 'react';

const BoardPagesHeader = () => {
    return (
        <>
            <caption>
                <strong>공지사항 목록</strong>
                <details>
                    <summary>공지사항 게시판입니다. 순번, 제목, 등록일, 조회, 첨부의 내용을 제공하며, 제목 클릭시 해당 게시물 상세페이지로 이동합니다.</summary>
                </details>
            </caption>
            <colgroup>
                <col className="title" />
                <col style={{ width: '10%' }} className="write" />
                <col style={{ width: '15%' }} className="date" />
                <col style={{ width: '10%' }} className="views" />
                <col style={{ width: '5%' }} className="file" />
                <col style={{ width: '10%' }} className="views" />
            </colgroup>
            <thead>
                <tr>
                    <th scope="col">제목</th>
                    <th scope="col" className="write">
                        작성자
                    </th>
                    <th scope="col" className="date">
                        등록일
                    </th>
                    <th scope="col" className="views">
                        조회
                    </th>
                    <th scope="col" className="file">
                        파일
                    </th>
                    <th scope="col" className="views">
                        공유
                    </th>
                </tr>
            </thead>
        </>
    );
};

export default BoardPagesHeader;
