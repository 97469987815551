import React from 'react';
import { Outlet } from 'react-router-dom';

import Header from './common/Header';
import Footer from './common/Footer';
import MobileMenu from './common/MobileMenu';
import SubHeaderBar from './common/SubHeaderBar';
import SubSideMenu from './common/SubSideMenu';

const AdminTemplate = () => {
    

    return (
        <div id='body'>
            <div id='wrap'>
                <Header />            {/* 탑(로그인 관련), 메인 메뉴(합회소개, 합회보고, 자료실, 게시판) */}
                <MobileMenu />        {/* 사이드 메뉴(Mobile Menu): 사이즈를 줄였을 때, 왼쪽 사이드에 붙는 메뉴 */}
                <SubHeaderBar />      {/* 홈 > 합회소개 > 합회소개 */}

                {/* <!--s:content-wrap--> */}
                <div id='content-wrap' className='container'>
                    <SubSideMenu />
                    <Outlet />

                    <div className='clr'></div>
                </div>
                {/* <!--//e:#container-wrap--> */}

                <Footer />            {/* 푸터(Footer): 패밀리 링크 사이트, Copyright */}
            </div>
        </div>
    );
};

export default AdminTemplate;