import { useState } from 'react';
import { Icon } from '@iconify/react';
import styled from 'styled-components';
import { showConfirmBox } from '../../../components/ConfirmBox';

const StyledContainer = styled.div`
  margin: 30px 5px 0 5px;
  flex: 1;
`;

const StyledTh = styled.th`
  font-family: '기본체' !important;
  padding: 5px 5px;
  text-align: center;
`;

const StyledTd = styled.td`
  font-family: '기본체' !important;
  text-align: center;
`;
const StyledButton = styled.button`
  margin-right: 5px;
  padding: 0 3px;
  border-radius: 5px;
  font-size: 1.1em;
`;

const StyledSortButton = styled.button`
  color: #000;
  font-weight: bold;
  font-family: '기본체' !important;
  cursor: pointer;
  background-color: transparent;
`;

const StyledDirection = styled.span`
  font-size: 0.8em;
  color: #ddd;
  font-weight: bold;
  background-color: transparent;
`;

const ReportTable = ({
  reports, //
  conditionFunc,
  stage,
  rejectMonthlyReport,
}) => {
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortField, setSortField] = useState('church_name');

  // Step 2: Create a function to toggle the sort order and set the field to sort
  const toggleSortOrder = (field) => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
    setSortField(field);

    reports.sort((a, b) => {
      const valueA = a[field].toUpperCase();
      const valueB = b[field].toUpperCase();

      if (valueA < valueB) {
        return newSortOrder === 'asc' ? -1 : 1;
      }
      if (valueA > valueB) {
        return newSortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };
  return (
    <StyledContainer>
      <table className="table table-hover">
        <thead>
          <tr>
            <StyledTh scope="col" width="12%">
              #
            </StyledTh>
            <StyledTh scope="col" width="23%">
              <StyledSortButton onClick={() => toggleSortOrder('church_name')}>교회명</StyledSortButton>
              &nbsp;
              {sortField === 'church_name' &&
                (sortOrder === 'asc' ? ( //
                  <StyledDirection>▲</StyledDirection>
                ) : (
                  <StyledDirection>▼</StyledDirection>
                ))}
            </StyledTh>
            <StyledTh scope="col" width="15%">
              <StyledSortButton onClick={() => toggleSortOrder('name')}>이름</StyledSortButton>
              &nbsp;
              {sortField === 'name' &&
                (sortOrder === 'asc' ? ( //
                  <StyledDirection>▲</StyledDirection>
                ) : (
                  <StyledDirection>▼</StyledDirection>
                ))}
            </StyledTh>
            <StyledTh scope="col" width="30%">
              전화번호
            </StyledTh>
            <StyledTh scope="col" width="10%">
              보고서
            </StyledTh>
            {stage === 1 ? (
              <StyledTh scope="col" width="10%">
                취소
              </StyledTh>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {reports?.filter(conditionFunc).map((item, index) => {
            return (
              <tr key={index}>
                <StyledTh scope="row">{index + 1}</StyledTh>
                <StyledTd>{item.church_name}</StyledTd>
                <StyledTd>{item.name}</StyledTd>
                <StyledTd>{item.phone}</StyledTd>

                <StyledTd>
                  {item.report_id && (
                    <>
                      <StyledButton
                        onClick={() => {
                          window.open(`/printReport/${item.report_id}/download`, '_blank');
                        }}
                      >
                        <Icon icon="bx:save" />
                      </StyledButton>
                      <StyledButton
                        onClick={() => {
                          window.open(`/printReport/${item.report_id}/print`, '_blank');
                        }}
                      >
                        <Icon icon="fa:print" />
                      </StyledButton>
                    </>
                  )}
                </StyledTd>
                {stage === 1 ? (
                  <StyledTh scope="col" width="10%">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <button //
                        style={{ backgroundColor: 'white' }}
                        onClick={() =>
                          showConfirmBox(
                            `[${item.name}]의 보고서 제출을 취소하시겠습니까?`,
                            `\n`,
                            () => {
                              rejectMonthlyReport({ reportId: item.report_id, name: item.name });
                            },
                            'delete-button',
                          )
                        }
                      >
                        <Icon
                          icon="bi:x-circle-fill" //
                          color="rgba(153, 153, 153, 0.6)"
                        />
                      </button>
                    </div>
                  </StyledTh>
                ) : null}
              </tr>
            );
          })}
        </tbody>
      </table>
    </StyledContainer>
  );
};

export default ReportTable;
