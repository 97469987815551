import React from 'react';

const InputGroup4 = ({ title, value, setValue, label, disabled }) => {
    return (
        <div className="styled-group">
            <span className="styled-left">{title}</span>
            <select //
                className="styled-right"
                aria-label={label}
                onChange={({ target }) => setValue(target.value)}
                value={value}
                disabled={disabled}
            >
                {Array.from(Array(31).keys(), (_, i) => {
                    return <option key={i}>{_}</option>;
                })}
            </select>
        </div>
    );
};

export default InputGroup4;
