import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import InputNum from '../components/InputNum';
import { getCommaSeparatedNumber } from '../../../utils/common';

const TermTable = styled.table`
    border: 1px solid silver;
    border-radius: 3px;
    border-collapse: separate;
    background-color: rgb(236, 236, 236);
    margin-bottom: 10px;
    width: 100%;
    /* height: 605px; */
`;

const TermTr = styled.tr`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 0.95em;
    text-align: center;
    vertical-align: middle;
    height: 34px;
`;

const TermTd = styled.td`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 0.95em;
    text-align: center;
    vertical-align: middle;
`;

const TermTh = styled.th`
    font-family: '기본체' !important;
    height: 35px;
    font-size: 1.2em;
    text-align: center;
    vertical-align: middle;
    background-color: rgb(212, 212, 212);
`;

const SchoolInfo = ({ disabled, toBeDeletedTermReportData, setToBeDeletedTermReportData, termReportPrevData }) => {
    const [sumSabbathSchoolMember, setSumSabbathSchoolMember] = useState(0);
    const [sumPrevSabbathSchoolMember, setSumPrevSabbathSchoolMember] = useState(0);

    useEffect(() => {
        setSumSabbathSchoolMember(
            getCommaSeparatedNumber(
                toBeDeletedTermReportData?.sabbath_school_member_more_then_71 +
                    toBeDeletedTermReportData?.sabbath_school_member_61_to_70 +
                    toBeDeletedTermReportData?.sabbath_school_member_51_to_60 +
                    toBeDeletedTermReportData?.sabbath_school_member_41_to_50 +
                    toBeDeletedTermReportData?.sabbath_school_member_31_to_40 +
                    toBeDeletedTermReportData?.sabbath_school_member_20_to_30 +
                    toBeDeletedTermReportData?.sabbath_school_member_17_to_19 +
                    toBeDeletedTermReportData?.sabbath_school_member_14_to_16 +
                    toBeDeletedTermReportData?.sabbath_school_member_11_to_13 +
                    toBeDeletedTermReportData?.sabbath_school_member_8_to_10 +
                    toBeDeletedTermReportData?.sabbath_school_member_4_to_7 +
                    toBeDeletedTermReportData?.sabbath_school_member_1_to_3 +
                    toBeDeletedTermReportData?.sabbath_school_member_baptism_class +
                    toBeDeletedTermReportData?.sabbath_school_member_long_term_absentee +
                    toBeDeletedTermReportData?.sabbath_school_member_lost,
            ),
        );
    }, [toBeDeletedTermReportData]);

    useEffect(() => {
        setSumPrevSabbathSchoolMember(
            getCommaSeparatedNumber(
                termReportPrevData?.sabbath_school_member_more_then_71 +
                    termReportPrevData?.sabbath_school_member_61_to_70 +
                    termReportPrevData?.sabbath_school_member_51_to_60 +
                    termReportPrevData?.sabbath_school_member_41_to_50 +
                    termReportPrevData?.sabbath_school_member_31_to_40 +
                    termReportPrevData?.sabbath_school_member_20_to_30 +
                    termReportPrevData?.sabbath_school_member_17_to_19 +
                    termReportPrevData?.sabbath_school_member_14_to_16 +
                    termReportPrevData?.sabbath_school_member_11_to_13 +
                    termReportPrevData?.sabbath_school_member_8_to_10 +
                    termReportPrevData?.sabbath_school_member_4_to_7 +
                    termReportPrevData?.sabbath_school_member_1_to_3 +
                    termReportPrevData?.sabbath_school_member_baptism_class +
                    termReportPrevData?.sabbath_school_member_long_term_absentee +
                    termReportPrevData?.sabbath_school_member_lost,
            ),
        );
    }, [termReportPrevData]);

    return (
        <div>
            <TermTable>
                <thead style={{ height: '38px' }}>
                    <TermTr>
                        <TermTh colSpan={5}>안 식 일 학 교</TermTh>
                    </TermTr>
                </thead>
                <tbody>
                    <TermTr>
                        <TermTd colSpan={2} rowSpan={2}>
                            구분
                        </TermTd>

                        <TermTd rowSpan={2}>연령</TermTd>
                        <TermTd colSpan={2}>안교생수</TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd>지난기</TermTd>
                        <TermTd>이번기</TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd rowSpan={5}>장년</TermTd>
                        <TermTd rowSpan={2}>노년</TermTd>
                        <TermTd>71세 이상</TermTd>
                        <TermTd>{getCommaSeparatedNumber(termReportPrevData?.sabbath_school_member_more_then_71)}</TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.sabbath_school_member_more_then_71}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'sabbath_school_member_more_then_71'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd>61-70</TermTd>
                        <TermTd>{getCommaSeparatedNumber(termReportPrevData?.sabbath_school_member_61_to_70)}</TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.sabbath_school_member_61_to_70}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'sabbath_school_member_61_to_70'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd rowSpan={3}>장년</TermTd>
                        <TermTd>51-60</TermTd>
                        <TermTd>{getCommaSeparatedNumber(termReportPrevData?.sabbath_school_member_51_to_60)}</TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.sabbath_school_member_51_to_60}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'sabbath_school_member_51_to_60'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd>41-50</TermTd>
                        <TermTd>{getCommaSeparatedNumber(termReportPrevData?.sabbath_school_member_41_to_50)}</TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.sabbath_school_member_41_to_50}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'sabbath_school_member_41_to_50'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd>31-40</TermTd>
                        <TermTd>{getCommaSeparatedNumber(termReportPrevData?.sabbath_school_member_31_to_40)}</TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.sabbath_school_member_31_to_40}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'sabbath_school_member_31_to_40'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={2}>청년</TermTd>

                        <TermTd>20-30</TermTd>
                        <TermTd>{getCommaSeparatedNumber(termReportPrevData?.sabbath_school_member_20_to_30)}</TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.sabbath_school_member_20_to_30}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'sabbath_school_member_20_to_30'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd rowSpan={2}>중고생</TermTd>
                        <TermTd>고등학생</TermTd>
                        <TermTd>17-19</TermTd>
                        <TermTd>{getCommaSeparatedNumber(termReportPrevData?.sabbath_school_member_17_to_19)}</TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.sabbath_school_member_17_to_19}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'sabbath_school_member_17_to_19'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd>중학생</TermTd>
                        <TermTd>14-16</TermTd>
                        <TermTd>{getCommaSeparatedNumber(termReportPrevData?.sabbath_school_member_14_to_16)}</TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.sabbath_school_member_14_to_16}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'sabbath_school_member_14_to_16'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd rowSpan={4}>어린이</TermTd>
                        <TermTd>소년</TermTd>
                        <TermTd>11-13</TermTd>
                        <TermTd>{getCommaSeparatedNumber(termReportPrevData?.sabbath_school_member_11_to_13)}</TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.sabbath_school_member_11_to_13}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'sabbath_school_member_11_to_13'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd>유년</TermTd>
                        <TermTd>8-10</TermTd>
                        <TermTd>{getCommaSeparatedNumber(termReportPrevData?.sabbath_school_member_8_to_10)}</TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.sabbath_school_member_8_to_10}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'sabbath_school_member_8_to_10'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd>유치</TermTd>
                        <TermTd>4-7</TermTd>
                        <TermTd>{getCommaSeparatedNumber(termReportPrevData?.sabbath_school_member_4_to_7)}</TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.sabbath_school_member_4_to_7}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'sabbath_school_member_4_to_7'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd>영아</TermTd>
                        <TermTd>1-3</TermTd>
                        <TermTd>{getCommaSeparatedNumber(termReportPrevData?.sabbath_school_member_1_to_3)}</TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.sabbath_school_member_1_to_3}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'sabbath_school_member_1_to_3'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={3}>침례반</TermTd>

                        <TermTd>{getCommaSeparatedNumber(termReportPrevData?.sabbath_school_member_baptism_class)}</TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.sabbath_school_member_baptism_class}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'sabbath_school_member_baptism_class'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={3}>장기결석자</TermTd>

                        <TermTd>{getCommaSeparatedNumber(termReportPrevData?.sabbath_school_member_long_term_absentee)}</TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.sabbath_school_member_long_term_absentee}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'sabbath_school_member_long_term_absentee'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={3}>잃은양</TermTd>

                        <TermTd>{getCommaSeparatedNumber(termReportPrevData?.sabbath_school_member_lost)}</TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.sabbath_school_member_lost}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'sabbath_school_member_lost'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={3}>계</TermTd>

                        <TermTd>{sumPrevSabbathSchoolMember || 0}</TermTd>
                        <TermTd>{sumSabbathSchoolMember || 0}</TermTd>
                    </TermTr>
                </tbody>
            </TermTable>
        </div>
    );
};

export default SchoolInfo;
