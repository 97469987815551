import React from 'react';

/**
 * 그룹 박스(Input + Button + unit)를 출력한다.
 * @param label: 제목을 가져온다
 * @param checked: 1 || 0
 * @param disable: 단뤼를 지정한다
 * @returns
 */
const CheckboxGroup = ({ label, checked, disabled, reportColumnName, setReportMxData }) => {
    const _handleOnChange = ({ target }) => {
        setReportMxData((report) => ({
            ...report, //
            [reportColumnName]: target.checked ? 1 : 0,
        }));
    };

    return (
        <div className="styled-checkbox-group">
            <input
                type="checkbox" //
                checked={checked}
                onChange={_handleOnChange}
                disabled={disabled}
            />
            <span className="checkbox-label">{label}</span>
        </div>
    );
};

export default CheckboxGroup;
