import React, { useContext, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CommonContext, ProgressContext, UserContext } from '../../contexts';
import { getUserInfoOnDevice, saveUserInfoOnDevice } from '../../utils/common';
import { login } from '../../data/firebase';
import { getUserInfo } from '../../data/mysql';

const Login = () => {
    const { categories } = useContext(CommonContext);
    const { spinner } = useContext(ProgressContext);
    const { dispatch } = useContext(UserContext);

    const emailRef = useRef();
    const passwordRef = useRef();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoginable, setIsLoginable] = useState(false);

    const { state } = useLocation(); // 로그인 페이지로 유입된 경로(로그인 이전 경로)를 가지고 온다.

    /* 시작할 때(마운트 시) */
    useEffect(() => {
        const user = getUserInfoOnDevice();

        if (!user.email) {
            emailRef.current.focus();
        } else {
            setEmail(user.email);
            passwordRef.current.focus();
        }
    }, []);

    /* [로그인] 버튼을 활성화 시킨다 */
    useEffect(() => {
        setIsLoginable(email && password);
    }, [email, password]);

    /* [로그인] 처리 */
    const _handleLoginButtonPress = async () => {
        try {
            spinner.start();
            const authUser = await login({ email, password });

            if (authUser) {
                const userInfo = await getUserInfo({ userId: authUser.uid });

                dispatch(userInfo);

                /* 
                    사용자가 새로고침(F5, refresh)를 누를 경우, 로그인 정보가 사라진다. 
                    그래서 firebase에서 제공하는 함수(onAuthStateChanged(), getCurrentUser())를 사용하려고 했으나.. 
                    Delay가 발생하면서 코드가 정상적으로 동작하지 않으므로 ..  sesstionStorage에 따로 저장한다.
                    2021.12.28.(화) @부산서부교회 전도사 사택
                 */
                sessionStorage.setItem('userId', authUser.uid);
                sessionStorage.setItem('token', authUser.token);
            }

            saveUserInfoOnDevice({ email: authUser.email }); // 클라이언트에 로그인 정보 저장

            navigate(state ? state.from.pathname + state.from.search : '/'); // 로그인을 호출한 이전 경로로 보낸다. 없으면 첫 화면으로('/') 보낸다.
            toast.success('로그인 되었습니다');
        } catch (e) {
            console.log(e.code, e.message);
            toast.error('로그인에 실패 하였습니다');
        } finally {
            spinner.stop();
        }
    };

    return (
        <div id="primary" className="content-area">
            {/* <!--s:#content --> */}
            <div id="content" className="site-content">
                <article>
                    <div className="login_wrap">
                        <p className="login">
                            LOG<span>I</span>N
                        </p>
                        <p>'영남합회 홈페이지에 오신 것을 환영합니다.'</p>
                        <fieldset>
                            <legend>로그인</legend>
                            <div className="login_insert">
                                <div>
                                    <div className="inser_a">
                                        <div className="inser_c">
                                            <label>이메일</label>
                                        </div>
                                        <div className="inser_b">
                                            <input
                                                tabIndex={1}
                                                type="text"
                                                ref={emailRef}
                                                value={email}
                                                className="form-control frm_input required"
                                                placeholder="이메일"
                                                onChange={(e) => setEmail(e.target.value.trim())}
                                            />
                                        </div>
                                    </div>
                                    <div className="inser_a">
                                        <div className="inser_c">
                                            <label>비밀번호</label>
                                        </div>
                                        <div className="inser_b">
                                            <input
                                                tabIndex={2}
                                                type="password"
                                                ref={passwordRef}
                                                value={password}
                                                className="form-control frm_input required"
                                                placeholder="비밀번호"
                                                onChange={(e) => setPassword(e.target.value.trim())}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        if (isLoginable) _handleLoginButtonPress();
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="id_check">
                                        <Link to={`/member/unauthorized/${categories?.find((c) => c.categoryCode === 'resetPassword')?.id}/resetPassword`}>비밀번호 재설정</Link>
                                    </div>
                                </div>
                                <span className="login_btn">
                                    <input tabIndex={3} type="button" value="로그인" disabled={!isLoginable} onClick={_handleLoginButtonPress} />
                                </span>
                            </div>
                            <div className="join_link">
                                <div>
                                    <p>아직 회원가입을 하지 않으셨나요?</p>
                                    <p>영남합회 회원으로 가입하시면 합회보고, 자료실 등 다양한 서비스를 누리실 수 있습니다.</p>
                                </div>
                                <Link to={`/member/unauthorized/${categories?.find((c) => c.categoryCode === 'signup')?.id}/signup`}>회원가입</Link>
                            </div>
                        </fieldset>
                    </div>
                </article>
            </div>
            {/* <!--//e: #content --> */}
        </div>
    );
};

export default Login;
