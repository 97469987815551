import React from 'react';
import { confirmAlert } from 'react-confirm-alert';

/**
 * @param deleteCallBack 실행시킬 콜백함수
 */
export const showConfirmBox = async (message, detail, deleteCallBack, className) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className="custom-ui">
                    <h1>{message}</h1>
                    <p style={{ whiteSpace: 'pre-wrap' }}>{detail}</p>

                    <button onClick={onClose}>취소</button>
                    <button
                        className={className}
                        onClick={() => {
                            deleteCallBack();
                            onClose();
                        }}
                    >
                        네, 실행합니다.
                    </button>
                </div>
            );
        },
    });
};
