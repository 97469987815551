import React, { useEffect, useState } from 'react';
import InputNum from '../components/InputNum';
import styled from 'styled-components';
import { getCommaSeparatedNumber } from '../../../utils/common';

const TermTable = styled.table`
    border: 1px solid silver;
    border-radius: 3px;
    border-collapse: separate;
    background-color: rgb(236, 236, 236);
    margin-bottom: 10px;
    width: 100%;
`;

const TermTr = styled.tr`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 0.95em;
    text-align: center;
    vertical-align: middle;
    height: 36px;
`;

const TermTd = styled.td`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 0.95em;
    text-align: center;
    vertical-align: middle;
`;

const TermTh = styled.th`
    font-family: '기본체' !important;
    height: 35px;
    font-size: 1.2em;
    text-align: center;
    vertical-align: middle;
    background-color: rgb(212, 212, 212);
`;

const AttendanceInfo = ({ disabled, toBeDeletedTermReportData, setToBeDeletedTermReportData, termReportPrevData }) => {
    const [sumTue, setSumTue] = useState(0); // 화요일 출석생 합
    const [sumFri, setSumFri] = useState(0); // 금요일 출석생 합
    const [sumSat, setSumSat] = useState(0); // 안식일예배 출석생 합
    const [sumSat2, setSumSat2] = useState(0); // 2부 예배 출석생 합

    const [sumSenior, setSumSenior] = useState(0); // 청장년 출석생 합
    const [sumStudent, setSumStudent] = useState(0); // 중고생 출석생 합
    const [sumChild, setSumChild] = useState(0); // 어린이 출석생 합

    const [sumSabbath, setSumSabbath] = useState(0); // 안식일 평균 출석생 (이번기)

    const [sumBaptized, setSumBaptized] = useState(0); // 수침자 합

    useEffect(() => {
        setSumTue(getCommaSeparatedNumber(toBeDeletedTermReportData?.average_senior_tue + toBeDeletedTermReportData?.average_student_tue + toBeDeletedTermReportData?.average_child_tue));
        setSumFri(getCommaSeparatedNumber(toBeDeletedTermReportData?.average_senior_fri + toBeDeletedTermReportData?.average_student_fri + toBeDeletedTermReportData?.average_child_fri));
        setSumSat(getCommaSeparatedNumber(toBeDeletedTermReportData?.average_senior_sat + toBeDeletedTermReportData?.average_student_sat + toBeDeletedTermReportData?.average_child_sat));
        setSumSat2(getCommaSeparatedNumber(toBeDeletedTermReportData?.average_senior_sat_2 + toBeDeletedTermReportData?.average_student_sat_2 + toBeDeletedTermReportData?.average_child_sat_2));

        setSumSenior(getCommaSeparatedNumber(toBeDeletedTermReportData?.average_senior_sat + toBeDeletedTermReportData?.average_senior_sat_2));
        setSumStudent(getCommaSeparatedNumber(toBeDeletedTermReportData?.average_student_sat + toBeDeletedTermReportData?.average_student_sat_2));
        setSumChild(getCommaSeparatedNumber(toBeDeletedTermReportData?.average_child_sat + toBeDeletedTermReportData?.average_child_sat_2));

        setSumBaptized(
            toBeDeletedTermReportData?.baptized_senior + //
                toBeDeletedTermReportData?.baptized_youth +
                toBeDeletedTermReportData?.baptized_student +
                toBeDeletedTermReportData?.baptized_child,
        );
    }, [toBeDeletedTermReportData]);

    useEffect(() => {
        setSumSabbath(
            getCommaSeparatedNumber(
                toBeDeletedTermReportData?.average_senior_sat + //
                    toBeDeletedTermReportData?.average_senior_sat_2 +
                    toBeDeletedTermReportData?.average_student_sat +
                    toBeDeletedTermReportData?.average_student_sat_2 +
                    toBeDeletedTermReportData?.average_child_sat +
                    toBeDeletedTermReportData?.average_child_sat_2,
            ),
        ); // 안식일 평균 출석생 (이번기)
    }, [
        toBeDeletedTermReportData?.average_senior_sat, //
        toBeDeletedTermReportData?.average_senior_sat_2,
        toBeDeletedTermReportData?.average_student_sat,
        toBeDeletedTermReportData?.average_student_sat_2,
        toBeDeletedTermReportData?.average_child_sat,
        toBeDeletedTermReportData?.average_child_sat_2,
    ]);

    return (
        <div>
            <TermTable>
                <thead style={{ height: '38px' }}>
                    <TermTr>
                        <TermTh colSpan={60}>출 석 현 황</TermTh>
                    </TermTr>
                </thead>
                <tbody>
                    <TermTr>
                        <TermTd colSpan={60}>평균출석생 수</TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd rowSpan={2} colSpan={10}>
                            구분
                        </TermTd>
                        <TermTd rowSpan={2} colSpan={10}>
                            화
                        </TermTd>
                        <TermTd rowSpan={2} colSpan={10}>
                            금
                        </TermTd>
                        <TermTd colSpan={30}>안식일</TermTd>
                        {/* <TermTd rowSpan={2}>합계</TermTd> */}
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={10}>설교예배</TermTd>

                        <TermTd colSpan={10}>2부예배</TermTd>
                        <TermTd colSpan={10}>합계</TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={10}>청장년</TermTd>
                        <TermTd colSpan={10} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.average_senior_tue}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'average_senior_tue'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd colSpan={10} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.average_senior_fri}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'average_senior_fri'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd colSpan={10} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.average_senior_sat}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'average_senior_sat'}
                                disabled={disabled}
                            />
                        </TermTd>

                        <TermTd colSpan={10} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.average_senior_sat_2}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'average_senior_sat_2'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd colSpan={10}>{sumSenior || 0}</TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={10}>중고생</TermTd>
                        <TermTd colSpan={10} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.average_student_tue}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'average_student_tue'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd colSpan={10} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.average_student_fri}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'average_student_fri'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd colSpan={10} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.average_student_sat}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'average_student_sat'}
                                disabled={disabled}
                            />
                        </TermTd>

                        <TermTd colSpan={10} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.average_student_sat_2}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'average_student_sat_2'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd colSpan={10}>{sumStudent || 0}</TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={10}>어린이</TermTd>
                        <TermTd colSpan={10} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.average_child_tue}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'average_child_tue'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd colSpan={10} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.average_child_fri}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'average_child_fri'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd colSpan={10} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.average_child_sat}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'average_child_sat'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd colSpan={10} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.average_child_sat_2}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'average_child_sat_2'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd colSpan={10}>{sumChild || 0}</TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={10}>합계</TermTd>
                        <TermTd colSpan={10}>{sumTue || 0}</TermTd>
                        <TermTd colSpan={10}>{sumFri || 0}</TermTd>
                        <TermTd colSpan={10}>{sumSat || 0}</TermTd>
                        <TermTd colSpan={10}>{sumSat2 || 0}</TermTd>
                        <TermTd colSpan={10}>{sumSabbath || 0}</TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd rowSpan={2} colSpan={20}>
                            안식일
                            <br />
                            평균출석생
                        </TermTd>

                        <TermTd colSpan={20}>지난기</TermTd>

                        <TermTd colSpan={20}>이번기</TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={20}>{getCommaSeparatedNumber(termReportPrevData?.sum_sabbath)}</TermTd>

                        <TermTd colSpan={20}>{sumSabbath || 0}</TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={60}>2번째 7번째 안식일 예배 참석자 수</TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={20}>구분</TermTd>

                        <TermTd colSpan={20}>안식일학교</TermTd>

                        <TermTd colSpan={20}>설교예배</TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={20}>2번째 안식일</TermTd>
                        <TermTd colSpan={20} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.sabbath_school_attendance_2nd}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'sabbath_school_attendance_2nd'}
                                disabled={disabled}
                            />
                        </TermTd>

                        <TermTd colSpan={20} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.sabbath_attendance_2nd}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'sabbath_attendance_2nd'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={20}>7번째 안식일</TermTd>

                        <TermTd colSpan={20} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.sabbath_school_attendance_7th}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'sabbath_school_attendance_7th'}
                                disabled={disabled}
                            />
                        </TermTd>

                        <TermTd colSpan={20} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.sabbath_attendance_7th}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'sabbath_attendance_7th'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd rowSpan={2} colSpan={12}>
                            수침자
                        </TermTd>
                        <TermTd colSpan={12}>장년</TermTd>
                        <TermTd colSpan={12}>청년</TermTd>
                        <TermTd colSpan={12}>중고생</TermTd>
                        <TermTd colSpan={12}>어린이</TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={12} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.baptized_senior}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'baptized_senior'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd colSpan={12} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.baptized_youth}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'baptized_youth'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd colSpan={12} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.baptized_student}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'baptized_student'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd colSpan={12} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.baptized_child}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'baptized_child'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={15}>수침자 합</TermTd>
                        <TermTd colSpan={15}>{getCommaSeparatedNumber(sumBaptized) || 0}</TermTd>
                        <TermTd colSpan={15}>금년누계</TermTd>

                        <TermTd colSpan={15}>{getCommaSeparatedNumber(termReportPrevData?.sum_baptized + sumBaptized)}</TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={30}>읽은양 회복수</TermTd>

                        <TermTd colSpan={30} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={toBeDeletedTermReportData?.restored_lost}
                                setValue={setToBeDeletedTermReportData}
                                reportKeyName={'restored_lost'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                </tbody>
            </TermTable>
        </div>
    );
};

export default AttendanceInfo;
