import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { CommonContext, UserContext } from '../contexts';

import AccessDenied from '../screens/board/AccessDenied';
import { isLogin } from '../data/firebase';

/**
 * 게시판 인증 처리를 한다
 *
 * 로그인 상태     |   카테고리 lv 0   |   카테고리 lv 10	|   카테고리 lv 30
 * ====================================================================
 * 로그인 X       | <Outlet />	   | 로그인 화면으로      | 로그인 화면으로
 * --------------------------------------------------------------------
 * 로그인(레벨 0)  | <Outlet />     | 접근 권한 X	     | 접근 권한 X
 * --------------------------------------------------------------------
 * 로그인(레벨 10) | <Outlet />	    | <Outlet />       | 접근 권한 X
 * --------------------------------------------------------------------
 * 로그인(레벨 30) | <Outlet />     | <Outlet />       | <Outlet />
 */
const CategoryAuth = () => {
    const { categories } = useContext(CommonContext);
    const { user } = useContext(UserContext);

    const { categoryId } = useParams();

    const currentCategory = categories?.find((category) => category.id === parseInt(categoryId));
    const location = useLocation();

    let result = '';

    try {
        if (!currentCategory) {
            return result;
        }

        if (!isLogin()) {
            // 로그인 X

            if (currentCategory.readLevel === 0) {
                // (모든 사람에게) 공개된 카테고리 이면..
                result = <Outlet />; // 접근 허가
            } else {
                result = <Navigate to={`/member/unauthorized/${categories?.find((c) => c.categoryCode === 'login')?.id}/login`} state={{ from: location }} />;
            }
        } else {
            // 로그인 O

            if (user.level >= currentCategory.readLevel) {
                // 카테고리 읽기 레벨 체크
                result = <Outlet />; // 접근 허가
            } else {
                result = <AccessDenied />;
            }
        }
    } catch (e) {
        console.log(e.code, e.massage);
    }

    return result;
};

export default CategoryAuth;
