import React, { useState, useContext, useEffect } from 'react';
import { CommonContext, ProgressContext } from '../../../contexts';
import { useLocation } from 'react-router-dom';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { getAreasData, getChurchesData, getPastorsData, getPastorChurchRelData, deletePastorChurchRel, createPastorChurchRel } from '../../../data/mysql_churchManager';

import ManagerHeader from './screen/ManagerHeader';
import ChurchDropTable from './screen/ChurchDropTable';
import TemporaryBox from './screen/TemporaryBox';

const AdminChurchesManager = () => {
  const { spinner } = useContext(ProgressContext);
  const { categories } = useContext(CommonContext);
  const { pathname } = useLocation();

  const currentYear = new Date();

  const [areas, setAreas] = useState([]); // 당장은 지역을 수정할 일이 없지만, 나중에 수정하게 될지 모르니 state로 해놓았음.
  const [churchList, setChurchList] = useState([]);
  const [pastorInfo, setPastorInfo] = useState([]);
  const [selectedArea, setSelectedArea] = useState(1);

  // 1. 지역정보와 교회의 리스트를 가져온다.
  useEffect(() => {
    if (churchList === [] || areas === []) return;

    const init = async () => {
      setAreas(await getAreasData({}));
      const churchesData = await getChurchesData({});
      const arrChurches = churchesData.sort((a, b) => a.name.localeCompare(b.name));
      setChurchList(arrChurches);
    };

    init();
  }, []);

  // 1. 목회자의 데이터를 가져온다.
  // 2. 가져온 목회자의 데이터 안에 교회-목회자 rel 데이터를 추가해준다.
  useEffect(() => {
    if (pastorInfo === []) return;
    const init = async () => {
      const pastorsData = await getPastorsData({});
      const pastorChurchRelData = await getPastorChurchRelData({});
      const arrPastorsData = pastorsData.sort((a, b) => a.name.localeCompare(b.name));
      setPastorInfo(
        // id가 166은 영남합회이므로 제외한다.
        arrPastorsData.filter((prevInfo) => prevInfo.id !== 166).map((prevInfo) => ({ ...prevInfo, c_id: pastorChurchRelData.find((newInfo) => newInfo.p_id === prevInfo.id)?.c_id })),
      );
    };

    init();
  }, []);

  /**
   * 해당 목회자-교회의 rel을 제거한다.
   */
  const _handleRemoveRel = async ({ p_id, c_id }) => {
    let result = false;

    try {
      spinner.start();

      result = await deletePastorChurchRel({ c_id, p_id });
    } catch (e) {
      console.log(e);
    } finally {
      spinner.stop();
    }
    return result;
  };

  /**
   * 해당 목회자-교회의 rel을 생성한다.
   */
  const _handleCreateRel = async ({ p_id, c_id }) => {
    let result = false;
    try {
      spinner.start();

      result = await createPastorChurchRel({ p_id, c_id });
    } catch (e) {
      console.log(e);
    } finally {
      spinner.stop();
    }
    return result;
  };

  return (
    <div id="primary" className="content-area">
      {/* <!--s:#content --> */}
      <div id="content" className="site-content">
        <article>
          <div>
            <div className="title-inner">
              <img src="/common/images/title.png" alt="" />
              <h1>{categories.find((category) => category.categoryCode === pathname.split('/')[4])?.categoryName}</h1>
            </div>
            <div style={{ height: 20 }}></div>
            <div style={{ clear: 'both' }}></div>
            <div className="title_subpage_solid_1" />
            <div style={{ height: 30 }}></div>

            <div className="container-fluid">
              <div className="row">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <ManagerHeader currentYear={currentYear} />
                  </div>
                  <div style={{ paddingLeft: 15, paddingTop: 10, fontSize: '0.9em' }}>* 월말, 기말보고에 영향이 있으므로 보고를 마친 후에 변경해주세요.</div>
                  <div className="panel-body" style={{ position: 'relative' }}>
                    <DndProvider backend={HTML5Backend}>
                      <div className="col-xs-10 col-sm-10 col-md-10">
                        <ChurchDropTable
                          churchList={churchList} //
                          areas={areas}
                          pastorInfo={pastorInfo}
                          setPastorInfo={setPastorInfo}
                          selectedArea={selectedArea}
                          setSelectedArea={setSelectedArea}
                          handleRemoveRel={_handleRemoveRel}
                          handleCreateRel={_handleCreateRel}
                        />
                      </div>
                      <div
                        className="col-xs-3 col-sm-2 col-md-2"
                        style={{
                          width: 150,
                          position: 'fixed',
                          top: '66%',
                          left: 'calc(84% + 80px)',
                          transform: 'translate(-50%, -50%)',
                          zIndex: 9999,
                        }}
                      >
                        <TemporaryBox
                          pastorInfo={pastorInfo} //
                          setPastorInfo={setPastorInfo}
                          handleRemoveRel={_handleRemoveRel}
                          handleCreateRel={_handleCreateRel}
                        />
                      </div>
                    </DndProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
      {/* <!--//e: #content --> */}
    </div>
  );
};

export default AdminChurchesManager;
