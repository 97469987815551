import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { CommonContext } from '../../contexts';

const Center = styled.div`
    display: flex;
    justify-content: center;
`;

const InfoPastors = () => {
    const { categories } = useContext(CommonContext);
    const { pathname } = useLocation();

    return (
        <div id='primary' className='content-area'>
            {/* <!--s:#content --> */}
            <div id='content' className='site-content'>
                <article>
                    <div>
                        <div className='title-inner'>
                            <img src='/common/images/title.png' alt='' />
                            <h1>{ categories.find( category => category.categoryCode === pathname.split('/')[4])?.categoryName}</h1>
                        </div>
                        <div style={{ height: 20 }}></div>
                        <div style={{ clear: 'both' }}></div>
                        <div className='title_subpage_solid_1'></div>
                        <div style={{ height: 30 }}></div>

                        <div id='content-wrap' className='container'>
                            <Center>
                                <img src='/common/images/workout_01.gif' alt='' />
                            </Center>
                        </div>   
                        <br />
                        <span style={{ fontFamily: '기본체', fontSize: '2em', color: '#336699'}}>준비중입니다.</span>
                    </div>
                    {/* <!-- .entry-content --> */}
                </article>
            </div>
            {/* <!--//e: #content --> */}
        </div>
    );
};

export default InfoPastors;