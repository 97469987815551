import React, { useEffect, useRef, useState, useContext } from 'react';
import { ProgressContext, UserContext } from '../../contexts';

import PopupDom from '../../components/PopupDom';
import PopupPostCode from '../../components/PopupPostCode';
import PopupChurch from '../../components/PopupChurch';

import { validateBirthDay, validatePhoneNumber } from '../../utils/common';
import { logout } from '../../data/firebase';
import { deleteUserAccount, editUser, getUserInfo } from '../../data/mysql';
import toast from 'react-hot-toast';

import { showDeleteConfirmBox } from '../../components/DeleteConfirmBox';
import { useNavigate } from 'react-router-dom';

const EditForm = () => {
    const { user, dispatch } = useContext(UserContext);
    const { spinner } = useContext(ProgressContext);

    const navigate = useNavigate();

    const addressDetailRef = useRef();

    const [isSendable, setIsSendable] = useState(false); // 모든 Input에 오류가 없으면, 회원 가입 버튼을 활성화시키기 위한 변수
    const [isChurchPopupOpen, setIsChurchPopupOpen] = useState(false); // 교회검색 팝업창 상태 관리(true: 팝업, false: 감춤)
    const [isAddrPopupOpen, setIsAddrPopupOpen] = useState(false); // 주소검색 팝업창 상태 관리(true: 팝업, false: 감춤)

    const [birthDay, setBirthDay] = useState(''); // *생년월일
    const [phoneNumber, setPhoneNumber] = useState(''); // *핸드폰 번호

    const [postCode, setPostCode] = useState(''); // 우편번호
    const [address, setAddress] = useState(''); // 주소
    const [addressDetail, setAddressDetail] = useState(''); // 주소 상세

    const [church, setChurch] = useState(''); // 출신교회

    const [errMessage, setErrMessage] = useState(''); // 에러 메시지

    // 가지고 온 유저 정보를 state 로 설정
    useEffect(() => {
        setBirthDay(user.birthDay);
        setPhoneNumber(user.phoneNumber);

        setPostCode(user.postCode);
        setAddress(user.address);
        setAddressDetail(user.addressDetail);

        setChurch(user.church);
    }, [user]);

    /* 모든 값(state)가 있고, 어떠한 에러 메시지도 없다면 ... */
    useEffect(() => {
        let _errMessage = '';

        if (!validateBirthDay(birthDay)) {
            _errMessage = '생년월일을 확인하세요';
        } else if (!validatePhoneNumber(phoneNumber)) {
            _errMessage = '핸드폰을 확인하세요';
        } else if (church.length < 1) {
            _errMessage = '출석교회를 확인하세요';
        } else {
            _errMessage = '';
        }

        setErrMessage(_errMessage);
    }, [phoneNumber, birthDay, church]);

    /* [회원가입] 버튼을 활성화 시킨다 */
    useEffect(() => {
        setIsSendable(phoneNumber && birthDay && church && !errMessage);
    }, [phoneNumber, birthDay, church, errMessage]);

    /* 생일 체크 */
    const _handleCheckBirthDay = (e) => {
        let temp = e.target.value;

        if (e.target.value.length === 8) {
            temp = temp.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
        }

        setBirthDay(temp);
        e.target.style.border = validateBirthDay(temp) ? '1px solid #ddd' : '1px solid red';
    };

    /* 핸드폰 체크 */
    const _handleCheckPhoneNumber = (e) => {
        let temp = e.target.value;

        if (temp.length === 10) {
            temp = temp.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        }

        if (temp.length === 13) {
            temp = temp.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
        }

        setPhoneNumber(temp);
        e.target.style.border = validatePhoneNumber(temp) ? '1px solid #ddd' : '1px solid red';
    };

    /* 초점(Focus)을 상세주소로 옮긴다. */
    const _handleFocusAddressDetail = () => {
        addressDetailRef.current.focus();
    };

    /**
     * 계정 정보를 수정한다.
     */
    const _handleEditButtonPress = async () => {
        try {
            spinner.start();

            const userId = await editUser({ birthDay, phoneNumber, postCode, address, addressDetail, church });
            dispatch(await getUserInfo({ userId })); // Context를 만들자.

            toast.success('사용자 정보가 수정 되었습니다');
        } catch (e) {
            console.log(e.code, e.message);
            toast.success(e.message);
        } finally {
            spinner.stop();
        }
    };

    /**
     * 회원 탈퇴를 한다.
     */
    const _handleDeleteAccount = async () => {
        try {
            spinner.start();

            await deleteUserAccount();
            await logout();

            toast.success('사용자 계정이 삭제 되었습니다.');
            navigate('/'); // 첫 화면으로 이동
        } catch (e) {
            console.log(e.message);
            toast.success(e.message);
        } finally {
            spinner.stop();
        }
    };

    return (
        <div id="primary" className="content-area">
            {/* <!--s:#content --> */}
            <div id="content" className="site-content">
                <article>
                    <div className="mem_level">
                        <ul>
                            <li>&nbsp;</li>
                            <li className="hover">사용자 정보수정</li>
                        </ul>
                    </div>
                    <div id="joinAgree">
                        {/* <!--s: write--> */}
                        <div className="sub_tit">
                            <h2>기본정보입력</h2>
                        </div>
                        <div className="table-write">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>이메일</th>
                                        <td>
                                            <div className="col-sm-6">{user.email}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>이름</th>
                                        <td>
                                            <div className="col-sm-6" style={{ display: 'flex', alignItems: 'center' }}>
                                                {user.name}
                                                &nbsp;
                                                <span style={{ color: '#777', fontSize: '0.8em' }}>{user.gender === 'M' ? '(남)' : '(여)'}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            생년월일<span className="red">*</span>
                                        </th>
                                        <td>
                                            <div className="col-sm-6">
                                                <input type="text" value={birthDay || ''} className="form-control required" maxLength="15" placeholder="예) 20220101" onChange={_handleCheckBirthDay} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            핸드폰<span className="red">*</span>
                                        </th>
                                        <td>
                                            <div className="col-sm-6">
                                                <input
                                                    type="text"
                                                    value={phoneNumber || ''}
                                                    className="form-control required"
                                                    maxLength="15"
                                                    placeholder="예) 01012345678"
                                                    onChange={_handleCheckPhoneNumber}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            출석교회<span className="red">*</span>
                                        </th>
                                        <td className="norbtn">
                                            <div className="col-sm-3 mb5">
                                                <input type="text" value={church || ''} className="form-control required" maxLength="15" placeholder="출석교회" readOnly="readonly" />
                                            </div>
                                            <div id="churchDom" style={{ position: 'relative', zIndex: 999 }}>
                                                {isChurchPopupOpen && (
                                                    <PopupDom domName="churchDom" style={{ width: '100%' }}>
                                                        <PopupChurch onClose={() => setIsChurchPopupOpen(false)} setChurch={setChurch} />
                                                    </PopupDom>
                                                )}
                                            </div>
                                            <button className="btn-black-s" onClick={() => setIsChurchPopupOpen(true)}>
                                                출석교회 검색
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>주소</th>
                                        <td className="norbtn">
                                            <div className="col-sm-3 mb5">
                                                <input type="text" value={postCode || ''} className="form-control" maxLength="6" readOnly="readonly" placeholder="우편번호" />
                                            </div>
                                            {/* 주소검색을 위한 팝업창  */}
                                            <div id="popupDom" style={{ position: 'relative', zIndex: 999 }}>
                                                {isAddrPopupOpen && (
                                                    <PopupDom domName="popupDom" style={{ width: '100%' }}>
                                                        <PopupPostCode
                                                            onClose={() => setIsAddrPopupOpen(false)}
                                                            setPostCode={setPostCode}
                                                            setAddress={setAddress}
                                                            setFocus={_handleFocusAddressDetail}
                                                        />
                                                    </PopupDom>
                                                )}
                                            </div>
                                            <button className="btn-black-s" onClick={() => setIsAddrPopupOpen(true)}>
                                                우편번호검색
                                            </button>
                                            <div className="col-sm-12 mb5">
                                                <input type="text" value={address || ''} className="form-control" placeholder="주소를 검색해주세요" readOnly="readonly" />
                                            </div>
                                            <div className="col-sm-12">
                                                <input
                                                    type="text"
                                                    value={addressDetail || ''}
                                                    ref={addressDetailRef}
                                                    className="form-control"
                                                    placeholder="상세주소를 입력하세요"
                                                    onChange={(e) => setAddressDetail(e.target.value)}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/* <!--//e: write--> */}
                        <p className="text-center">
                            <button className="btn-yellow-s fnone" onClick={_handleEditButtonPress} disabled={!isSendable}>
                                정보수정
                            </button>
                            <button className="btn-red-s fnone" onClick={() => showDeleteConfirmBox(() => _handleDeleteAccount({ email: user.email }))}>
                                탈퇴
                            </button>
                        </p>
                    </div>
                </article>
            </div>
            {/* <!--//e: #content --> */}
        </div>
    );
};

export default EditForm;
