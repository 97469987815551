import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import InputGroup4 from '../components/InputGroup4';
import InputGroup5 from '../components/InputGroup5';

const ActivitiesCount = forwardRef(({ reportDxData, setReportDxData, reportMxData, setReportMxData, disabled }, ref) => {
    const [seekerCnt, setSeekerCnt] = useState(0); // 구도자 방문수
    const [memberCnt, setMemberCnt] = useState(0); // 신자 방문수
    const [teachCnt, setTeachCnt] = useState(0); // 성경교수 수

    const [sermonCnt, setSermonCnt] = useState(0); // 설교한 수
    const [transportation, setTransportation] = useState(''); // 교통비
    const [activities, setActivities] = useState(''); // 활동내역 및 특기사항

    const [isSendable, setIsSendable] = useState(false); // 전송버튼 활성화 여부
    const [isChecked, setIsChecked] = useState(false);
    // const [disabled, setDisabled] = useState(false);

    // useEffect(() => {
    //     reportMxData.is_submitted === 'Y' ? setDisabled(true) : setDisabled(false);
    // }, [reportMxData]);

    useImperativeHandle(ref, () => ({
        resetState,
    }));

    const resetState = () => {
        setSeekerCnt(0);
        setMemberCnt(0);
        setTeachCnt(0);
        setSermonCnt(0);
        setTransportation('');
        setActivities('');
    };

    useEffect(() => {
        if (seekerCnt + memberCnt + teachCnt + sermonCnt > 0 || activities || transportation) {
            setIsSendable(true);
        } else {
            setIsSendable(false);
        }
    }, [seekerCnt, memberCnt, teachCnt, sermonCnt, transportation, activities]);

    /**
     * 체크가 되어 있다면 제거 버튼을 활성화 시켜준다.
     */
    useEffect(() => {
        let result;

        for (let week of reportDxData) {
            result = week.find((day) => day.checked);
            if (result) break;
        }
        setIsChecked(result ? true : false);
    }, [reportDxData]);

    /**
     * 등록 버튼을 누르면 체크 되어 있는 날짜에 각 cnt를 등록한다.
     * default 값으로 되어 있는 것은 등록하지 않는다.
     * 이후 모든 state를 초기화 한다.
     */
    const _handleSave = async () => {
        await setReportDxData(
            reportDxData.map((week) =>
                week.map((day) => {
                    return day.checked
                        ? {
                              ...day, //
                              seekerCnt: parseInt(seekerCnt) === 0 ? day.seekerCnt : parseInt(seekerCnt),
                              memberCnt: parseInt(memberCnt) === 0 ? day.memberCnt : parseInt(memberCnt),
                              teachCnt: parseInt(teachCnt) === 0 ? day.teachCnt : parseInt(teachCnt),
                              sermonCnt: parseInt(sermonCnt) === 0 ? day.sermonCnt : parseInt(sermonCnt),
                              transportation: transportation === '' ? day.transportation : parseInt(transportation.replace(/[^\d]+/g, '')),
                              activities: activities === '' ? day.activities : activities,
                              checked: false,
                          }
                        : { ...day };
                }),
            ),
        );
        resetState();
    };

    /**
     * 선택된 날짜의 cnt값을 모두 지운다.
     */
    const _handleDelete = async () => {
        await setReportDxData(
            reportDxData.map((week) =>
                week.map((day) => {
                    return day.checked
                        ? {
                              ...day, //
                              seekerCnt: 0,
                              memberCnt: 0,
                              teachCnt: 0,
                              sermonCnt: 0,
                              transportation: '',
                              activities: '',
                              checked: false,
                          }
                        : { ...day };
                }),
            ),
        );
        resetState();
    };

    return (
        <div className="row">
            <div className="panel">
                <div className="panel-heading">
                    <h3>활동내역 및 특기사항</h3>
                </div>

                <div className="panel-body">
                    <div className="col-xs-6 col-sm-3 col-md-3">
                        <InputGroup4 title={'방문(구도자)'} value={seekerCnt} setValue={setSeekerCnt} label={'구도자 방문수'} disabled={disabled} />
                    </div>
                    <div className="col-xs-6 col-sm-3 col-md-3">
                        <InputGroup4 title={'방문(신자)'} value={memberCnt} setValue={setMemberCnt} label={'신자 방문수'} disabled={disabled} />
                    </div>
                    <div className="col-xs-6 col-sm-3 col-md-3">
                        <InputGroup4 title={'성경교수'} value={teachCnt} setValue={setTeachCnt} label={'성경교수 횟수'} disabled={disabled} />
                    </div>
                    <div className="col-xs-6 col-sm-3 col-md-3">
                        <InputGroup4 title={'설교한수'} value={sermonCnt} setValue={setSermonCnt} label={'설교한 수'} disabled={disabled} />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4">
                        <InputGroup5 title={'교통비'} value={transportation} setValue={setTransportation} maxLength={9} disabled={disabled} unit={'원'} />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4">
                        <input
                            className="styled-input" //
                            value={activities}
                            placeholder="활동내역 및 특기사항을 입력하세요"
                            disabled={disabled}
                            onChange={({ target }) => setActivities(target.value)}
                        />
                    </div>
                    <div className="col-xs-6 col-sm-2 col-md-2">
                        <button
                            className={`styled-button ${isSendable && isChecked ? 'add' : 'disabled'}`} //
                            type="button"
                            disabled={!isSendable || !isChecked}
                            onClick={_handleSave}
                        >
                            등록
                        </button>
                    </div>
                    <div className="col-xs-6 col-sm-2 col-md-2">
                        <button
                            className={`styled-button ${isChecked ? 'remove' : 'disabled'}`} //
                            type="button"
                            disabled={!isChecked}
                            onClick={_handleDelete}
                        >
                            제거
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ActivitiesCount;
