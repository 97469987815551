import React, { useContext, useEffect, useState } from 'react';
import { ProgressContext } from '../../../contexts';
import { getPostCount, getPostList, getSearchedPostCount, searchPostList } from '../../../data/mysql';
import toast from 'react-hot-toast';
import { useParams, useNavigate } from 'react-router-dom';
import { set } from 'date-fns';
import { get } from 'jquery';

const SearchBar = ({
    setSearchTotalCount, //
    setSearchPosts,
    postsPerPage,
    postOffset,
    setPostOffset,
    isSearching,
    setIsSearching,
    setSearchItem,
    setSearchKeyword,
    searchItem,
    searchKeyword,
    getPostsMethod,
    SEARCH_URL,
}) => {
    const { spinner } = useContext(ProgressContext);
    const { categoryId } = useParams();
    const navigate = useNavigate();

    const [searchingKeyword, setSearchingKeyword] = useState('');
    const [letSearch, setLetSearch] = useState(false);

    /**
     * 검색 기능
     */
    const searchMethod = async () => {
        try {
            spinner.start();

            setSearchTotalCount(await getSearchedPostCount({ type: searchItem, keyword: searchKeyword, categoryId }));
            setSearchPosts(await searchPostList({ type: searchItem, keyword: searchKeyword, categoryId, limit: postsPerPage, offset: postOffset }));

            setPostOffset(0); // 첫 페이지로 셋팅한다.
            setIsSearching(true);

            navigate(`../list?${SEARCH_URL}`);

            toast.success('검색을 완료 하였습니다.');
        } catch (e) {
            console.log(e.message);
        } finally {
            setLetSearch(false);
            spinner.stop();
        }
    };

    useEffect(() => {
        if (searchKeyword && letSearch) {
            searchMethod();
        }
        setSearchingKeyword(searchKeyword);
    }, [searchKeyword]);

    const _handleListButton = () => {
        getPostsMethod(0);
        setSearchingKeyword('');
    };

    const _handleSearchButton = () => {
        setLetSearch(true);
        setSearchKeyword(searchingKeyword);
    };

    return (
        <div className="board-search-wrap">
            <div className="search-wrap">
                <div className="search-input">
                    {isSearching && (
                        <div>
                            <button className={'btn-gray-s'} tabIndex={2} onClick={_handleListButton} style={{ fontSize: '1em' }}>
                                목록
                            </button>
                        </div>
                    )}
                    <div>
                        <span className="sel">
                            <select className="form-control" onChange={({ target }) => setSearchItem(target.value)}>
                                <option value="title" defaultValue="selected">
                                    제목
                                </option>
                                <option value="name">작성자명</option>
                                <option value="content">내용</option>
                            </select>{' '}
                        </span>
                    </div>
                </div>
                <div className="search-btn">
                    <div>
                        <input
                            className="inpt full"
                            value={searchingKeyword}
                            tabIndex={1}
                            type="text"
                            placeholder="검색어를 입력해주세요."
                            onChange={(e) => setSearchingKeyword(e.target.value.trim())}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' && searchingKeyword) {
                                    _handleSearchButton();
                                    e.preventDefault();
                                }
                            }}
                        />
                    </div>
                    <div className="btn_wrap">
                        <button className={'btn-green-s'} tabIndex={2} onClick={_handleSearchButton} disabled={!searchingKeyword}>
                            검색
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchBar;
