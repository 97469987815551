import ReactDOMServer from 'react-dom/server'

const { kakao } = window;

/**
 * 카카오 지도를 초기화한다.
 * @returns 
 */
export const initKakaoMap = ({ container, lat, lng }) => {

    const options = {
        center: new kakao.maps.LatLng(lat, lng),
        level: 12
    };

    

		


    return new kakao.maps.Map(
        document.getElementById(container),     // 지도를 담을 영역
        options);
}

/**
 * 지도 셋팅을 한다
 * @returns 
 */
export const setKakaoMapOptions = async ({ map, draggable, zoomable }) => {

    // 지도 타입 변경 컨트롤을 생성한다
    var mapTypeControl = new kakao.maps.MapTypeControl();

    // 지도의 상단 우측에 지도 타입 변경 컨트롤을 추가한다
    map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT);
    
    // 지도에 확대 축소 컨트롤을 생성한다
    var zoomControl = new kakao.maps.ZoomControl();

    // 지도의 우측에 확대 축소 컨트롤을 추가한다
    map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);

    map.setDraggable(draggable);
    map.setZoomable(zoomable);
}

/**
 * 
 * @param map: 지도 객체
 * @param minLevel: 클러스터 할 최소 지도 레벨 
 * @param {*} param0 
 */
export const initClusterer = ({ map, minLevel }) => {
    return new kakao.maps.MarkerClusterer({
        map: map,
        averageCenter: true,
        minLevel: minLevel
    });
}

/**
 * 
 * @returns 
 */
export const getContent = ({ name, addr, tel, homepage }) => {

    const content = (
        <div className='location_overlay_wrap'>
            <div className='info'>
                <div className='title'>
                    { name }
                    <div className='close' onClick={() => {}/* 'closeOverlay()' */} title='닫기'></div>
                </div>
                <div className='body'>
                    <div className='img'>
                        <img src='/common/images/map_church.png' width='73' height='70' alt=''/>
                    </div>
                    <div className='desc'>
                        <span className='ellipsis'>{ addr }</span>
                        { homepage && (
                            <div>
                                <a href={ homepage } target='_blank' className='link' rel='noreferrer'>홈페이지</a>
                            </div>
                        )}
                    </div>
                </div>
            </div>   
        </div>
    );

    return ReactDOMServer.renderToString(content);
}