import React, { useEffect, useState } from 'react';
import { DataTypes } from '../../data/Types';

const SignupAgreements = ({ setSignupStep }) => {
    const [isAgreePolicy, setIsAgreePolicy] = useState(false);
    const [isAgreePrivacy, setIsAgreePrivacy] = useState(false);
    const [isSendable, setIsSendable] = useState(false);

    useEffect(() => {
        setIsSendable(isAgreePolicy && isAgreePrivacy);
    }, [isAgreePolicy, isAgreePrivacy]);

    const _handleChangeAllCheckbox = (checked) => {
        setIsAgreePolicy(checked);
        setIsAgreePrivacy(checked);
    };

    return (
        <div id='primary' className='content-area'>
            {/* <!--s:#content --> */}
            <div id='content' className='site-content'>
                <article>
                    <div className='mem_level'>
                        <ul>
                            <li className='hover'><span>01</span> 약관동의</li>
                            <li><span>02</span> 정보입력</li>
                            <li><span>03</span> 가입완료</li>
                        </ul>
                    </div>
                        <div id='joinAgree'>
                            <div className='agreeAll id_check'>
                                <label>
                                    <input type='checkbox' className='check' name='check_all' id='check_all' value='1' 
                                            onChange={(e) => _handleChangeAllCheckbox(e.currentTarget.checked) }
                                            checked={ isAgreePolicy && isAgreePrivacy? true: false}
                                    />
                                            
                                            <i></i>모든 약관을 확인하고 전체 동의합니다(전체 동의,선택 항목도 포함)
                                </label>
                            </div>
                            <div className='agreePolicy'>
                                <div className='id_check'>
                                    <label>
                                        <input type='checkbox' className='check' name='join_agree1' id='join_agree1' value='1' required 

                                                onChange={(e) => setIsAgreePolicy(e.currentTarget.checked) }
                                                checked={ isAgreePolicy? true: false }
                                        />
                                            <i></i>(필수)서비스 이용약관
                                    </label>
                                </div>
                                <textarea name='textarea' readOnly='readonly' value={`제1장 총칙

제1조 (목적)
이 약관은 영남합회 행정 위원회 결의에 의하여 영남합회에서 제공하는 합회 홈페이지의 이용 조건 및 절차에 관한 사항을 규정함을 목적으로 합니다.

제 2 조 (약관의 공지 및 준용)
① 이 약관의 내용은 서비스 화면에 게시하거나 기타의 방법으로 회원에게 공지함으로써 효력을 발생합니다.
② 운영자측은 영남합회의 결의를 얻어 이 약관을 수정할 수 있으며, 필요한 경우 행정위원회의 결의와 상충되지 않는 범위 내에서 임의로 변경할 수 있습니다. 변경된 약관은 제1항과 같은 방법으로 공지함으로써 효력을 발생합니다.

제 3 조 (약관 외 준칙)
이 약관에 명시되지 않은 사항은 한국연합회 행정위원회에서 정한 인터넷 윤리강령, 국가의 정보통신 및 인터넷 관련 규정, 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 등에 관한 법률 및 기타 관련법령의 규정에 의합니다.

제 4 조 (용어의 정의)
이 약관에서 사용하는 용어의 정의는 다음과 같습니다.

① 회원 : 영남합회 홍보부와 서비스 이용계약을 체결하고 이용자 아이디를 부여받은 자.
② 아이디(ID) : 회원 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 운영자측이 승인하는 문자와 숫자의 조합
③ 비밀번호 : 회원의 비밀 보호를 위해 회원 자신이 설정한 문자와 숫자의 조합
④ 운영자 : 영남합회 행정위원회에서 운영과 관련한 전반적인 책임을 위임한 자.
⑤ 부운영자 : 총무부 사무장
⑥ 관리자 : 운영자가 임명한 실무 관리자.
⑦ 해지 : 운영자 측 또는 회원이 서비스 개통 후 이용계약을 해약 하는 것


제 2 장 서비스 이용계약

제 5 조 (이용계약의 성립)
① 회원 가입 신청시 제공되는 “회원가입 신청서'의 ”동의함“ 단추를 누르면 이 약관에 동의하는 것으로 간주됩니다.
② 이용계약은 회원의 이용신청에 대하여 영남합회가 심사 후 승낙함으로써 성립합니다.

제 6 조 (이용신청)
① 이용신청은 온라인으로 운영자측이 제공하는 소정의 가입신청 양식에서 요구하는 사항을 기록하여 신청합니다.
② 회원자격은 침례를 받고 제칠일안식일예수재림교회에 입교한 신자들에 한하나, 침례받지 않았어도 홈페이지 기본운영 정신에 동의한자에게 자격을 줄 수 있습니다. 그러나 교회로부터 출교, 제명 등의 조치를 받았거나, 비록 징계를 받지 않았더라도 홈페이지를 통해 이설을 전파하거나 해교 행위 등을 하는 사람들은 서비스 사용의 제한을 받을 수 있습니다.
③ 온라인 가입신청 양식에 기재하는 모든 회원 정보는 실제 데이터인 것으로 간주하며 실명이나 실제 정보를 입력하지 않은 사용자는 법적인 보호를 받을 수 없으며, 서비스 사용의 제한을 받을 수 있습니다.

제 7 조 : 회원정보 사용에 대한 동의
① 운영자측이 제6조에 따라 신청서에 기재를 요구하는 회원의 개인정보는 본 이용계약의 이행과 본 이용계약상의 서비스제공을 위한 목적으로 이용합니다.
② 회원은 회원정보 변경을 통해 언제든지 개인 정보에 대한 열람 및 수정을 할 수 있습니다.
③ 회원이 이용신청서에 회원정보를 기재하고, 운영자측이 제공하는 본 약관에 따라 이용신청을 하는 것은 운영자측이 본 약관에 따라 이용신청서에 기재된 회원정보를 수집, 이용 및 제공하는 것에 동의하는 것으로 간주합니다.

제 8 조 (이용신청의 승낙)
① 운영자측은 회원이 제 6 조에서 정한 모든 사항을 정확히 기재하여 이용신청을 하였을 때 제2호와 제3호 경우를 예외로 하여 승낙할 수 있습니다.
② 운영자측은 다음 각 호의 1에 해당하는 이용신청에 대하여는 승낙을 유보할 수 있습니다.
㉮ 설비에 여유가 없는 경우
㉯ 기술상 지장이 있는 경우
㉰ 기타 운영자측의 사정상 이용승낙이 곤란한 경우
③ 운영자측은 다음 각 호의 1에 해당하는 이용신청에 대하여는 이를 승낙하지 아니 할 수 있습니다.
㉮ 이름이 실명이 아닌 경우
㉯ 다른 사람의 명의를 사용하여 신청한 경우
㉰ 이용신청시 필요내용을 허위로 기재하여 신청한 경우
㉱ 사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청한 경우
㉲ 사이트 이용 중 공공연하게 규정을 위반하고 운영진의 지도에 따르지 않을 경우
㉳ 기타 운영자측이 정한 이용신청요건이 미비되었을 때

제 9 조 (계약사항의 변경)
회원은 이용신청시 기재한 사항이 변경되었을 경우에는 온라인으로 수정을 해야 하고, 변경하지 않음으로 인하여 발생되는 문제의 책임은 회원에게 있습니다.


제 3 장 계약당사자의 의무

제 10 조 (운영자측의 의무)
① 운영자측은 서비스 제공과 관련해서 알고 있는 회원의 신상정보를 본인의 승낙없이 제 3자에게 누설, 배포하지 않습니다. 단, 전기통신기본법 등 법률의 규정에 의해 국가기관의 요구가 있는 경우, 범죄에 대한 수사상의 목적이 있거나 정보통신윤리 위원회의 요청이 있는 경우 또는 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우에는 그러하지 않습니다.
② 1항의 범위 내에서, 운영자측은 업무와 관련하여 회원 전체 또는 일부의 개인정보에 관한 통계 자료를 작성하여 이를 사용할 수 있고, 서비스를 통하여 회원의 컴퓨터에 쿠키를 전송할 수 있습니다. 이 경우 회원은 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 사용하는 컴퓨터의 브라우저의 설정을 변경할 수 있습니다.

제 11 조 (회원의 의무)
① 회원은 서비스를 이용할 때 다음 각 호의 행위를 하지 않아야 합니다.
㉮다른 회원의 ID를 부정하게 사용하는 행위
㉯서비스에서 얻은 정보를 운영자측의 사전승낙 없이 회원의 이용 이외의 목적으로 복제하거나 이를 출판 및 방송 등에 사용하거나 제3자에게 제공하는 행위
㉰운영자측의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 행위
㉱공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등을 타인에게 유포하는 행위
㉲범죄와 결부된다고 객관적으로 판단되는 행위
㉳기타 관계법령에 위배되는 행위
② 회원은 이 약관에서 규정하는 사항과 서비스 이용안내 또는 주의사항을 준수하여야 합니다.
③ 회원은 내용별로 운영자측이 서비스 공지사항에 게시하거나 별도로 공지한 운영방침 등을 준수하여야 합니다.
④ 회원은 운영자측의 사전 승낙없이는 서비스를 이용하여 영업활동을 할 수 없으며, 영업활동의 결과와 회원이 약관에 위반한 영업활동을 이용하여 발생한 결과에 대하여 운영자측은 책임을 지지 않습니다. 또한 회원은 이와 같은 영업활동에 대하여 운영자측에 대하여 손해배상의무를 집니다.
⑤ 회원은 자신이 올린 게시물이나 자료 등으로 발생한 민형사상 모든 문제에 대하여 책임을 집니다. 운영자측은 이에 대하여 책임을 지지 않습니다.
⑥ 회원은 운영자측의 명시적인 동의가 없는 한 서비스의 이용권한, 기타 이용계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.


제 4 장 서비스 이용

제 12 조 (회원 아이디(ID)와 비밀번호 관리에 대한 회원의 의무)
① 아이디(ID)와 비밀번호에 관한 모든 관리책임은 회원에게 있습니다. 회원에게 부여된 아이디(ID)와 비밀번호의 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.
② 자신의 아이디(ID)가 부정하게 사용된 경우 회원은 반드시 운영자측에 그 사실을 통보해야 합니다.

제 13조 (정보의 제공)
운영자측은 회원이 서비스 이용 중 필요가 있다고 인정되는 다양한 정보에 대해서 전자우편이나 서신우편 등의 방법으로 회원에게 제공할 수 있으며, 회원은 원치 않을 경우 정보수신을 거부를 할 수 있습니다.

제 14 조 (회원의 게시물)
운영자측은 회원이 게시하거나 등록하는 서비스내의 내용물이 다음 각 호의 1에 해당한다고 판단되는 경우에 사전통지 없이 삭제할 수 있습니다.

① 운영 목적에 부합하지 않는 내용
② 게시자 신상 정보가 불명할 때
③ 다른 사람의 이름으로 게시된 글
④ 사실이 아닌 풍문이나 추측에 근거한 내용
⑤ 사실이라도 명백히 다른 사람의 명예를 손상시킬 우려가 있는 내용
⑥ 합회, 교회, 지역간 갈등을 조장하는 내용
⑦ 미풍 양속에 저해되는 내용
⑧ 타인을 비방하는 정도가 통속적인 수준을 넘어서는 경우
⑨ 게시자의 소속장이 삭제를 요청할 경우
⑩ 교단 전체에 불이익을 줄 우려가 있다고 판단되는 내용
⑪ 범죄적 행위에 결부된다고 인정되는 내용일 경우
⑫ 저작권 등 기타 권리를 침해하는 내용인 경우
⑬ 재림교회의 신조에 반하는 이설을 게재하거나, 재림교회의 제규정을 위반한 사항이 있거나, 행정위원회 결정에 반하는 내용을 게재하여 홍보하는 경우
⑭ 위의 사항에 저촉되는 게시물들이 있는 다른 사이트와 링크하는 글을 올릴 경우
⑮ 기타 관계법령에 위반된다고 판단되는 경우

제 15 조 (게시물의 저작권)
서비스에 게재된 자료에 대한 권리는 다음 각 호와 같습니다.

① 게시물에 대한 권리와 책임은 게시자에게 있으며 운영자측은 게시자의 동의 없이는 이를 서비스 내 게재 이외에 영리적 목적으로 사용하지 않는 것을 원칙으로 합니다. 단, 이를 회원들에게 보급하기 위해 자료화 할 때는 운영자측은 14일간 공지한 후 게시자의 통보가 없는 경우 승락한 것으로 간주하여 이를 매체에 저장하여 보급할 수 있습니다.
② 회원은 서비스를 이용하여 얻은 정보를 타사이트에 무단 게재할 수 없으며, 이를 가공, 판매하는 행위 등 서비스에 게재된 자료를 상업적으로 사용할 수 없습니다.

제 16 조 (서비스 이용시간)
① 서비스의 이용은 운영자측의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로 합니다. 다만 정기 점검 등의 필요로 운영자측이 정한 날이나 시간은 그러하지 않습니다.

② 운영자측은 서비스를 일정범위로 분할하여 각 범위별로 이용가능 시간을 별도로 정할 수 있습니다. 이 경우 그 내용을 사전에 공지합니다.

제 17 조 (서비스 이용 책임)
회원은 운영자측에서 권한 있는 직원이 서명한 명시적인 서면에 구체적으로 허용한 경우를 제외하고는 서비스를 이용하여 불법상품을 판매하는 영업활동을 할 수 없으며 특히 해킹, 돈벌기 광고, 음란사이트를 통한 상업행위, 상용S/W 불법배포 등을 할 수 없습니다. 이를 어기고 발생한 영업활동의 결과 및 손실, 관계기관에 의한 구속 등 법적조치 등에 관해서는 운영자측에서 책임을 지지 않습니다.

제 18 조 (서비스 제공의 중지)
① 운영자측은 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수 있습니다.
가. 서비스용 설비의 보수 등 공사로 인한 부득이한 경우
나. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우
다. 기타 불가항력적 사유가 있는 경우

② 운영자측은 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는 일부를 제한하거나 정지할 있습니다.


제 5 장 계약해지 및 이용제한

제 19 조 (계약해지 및 이용제한)
① 회원이 이용계약을 해지하고자 하는 때에는 회원 본인이 온라인을 통해 운영자측에 해지 신청을 하여야 합니다.
② 운영자측은 회원이 다음 각 호의 1에 해당하는 행위를 하였을 경우 사전통지 없이 이용계약을 해지하거나 또는 기간을 정하여 서비스 이용을 중지할 수 있습니다.
가. 타인의 서비스 ID 및 비밀번호를 도용한 경우
나. 서비스 운영을 고의로 방해한 경우
다. 가입한 이름이 실명이 아닌 경우
라. 같은 사용자가 다른 ID로 이중등록을 한 경우
마. 공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우
바. 회원이 국익 또는 사회적 공익을 저해할 목적으로 서비스이용을 계획 또는 실행하는 경우
사. 타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우
아. 서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 광고성 정보를 전송하는 경우
자. 정보통신설비의 오작동이나 정보 등의 파괴를 유발시키는 컴퓨터 바이러스 프로그램 등을 유포하는 경우
차. 운영자측, 다른 회원 또는 제3자의 지적재산권을 침해하는 경우
카. 정보통신윤리위원회 등 외부기관의 시정요구가 있거나 불법선거운동과 관련하여 선거관리위원회의 유권해석을 받은 경우
타. 타인의 개인정보, 이용자 ID 및 비밀번호를 부정하게 사용하는 경우
파. 운영자측의 서비스 정보를 이용하여 얻은 정보를 운영자측의 사전 승낙없이 복제 또는 유통시키거나 상업적으로 이용하는 경우
하. 회원이 자신의 홈페이지와 게시판에 음란물을 게재하거나 음란사이트 링크하는 경우
거. 본 약관을 포함하여 기타 한국연합회 행정위원회가 정한 이용조건에 위반한 경우
너. 장기간 휴면 가입자에 대하여 통지할 경우 그 통지 기간 내에도 서비스이용에 대한 의사표현을 하지 않은 경우
더. 기타 운영자측의 서비스개선을 위한 운영자측 정책상 불가피할 경우

전 항 규정에 의해 이용제한을 하고자 할 경우에는 제한의 종류 및 기간 등 구체적 기준은 운영자측의 공지, 서비스안내, 세부규칙 등에 별도로 정하는 바에 의한다.

제 20 조 이용제한의 해제 절차
① 운영자측은 이용제한을 하고자 하는 경우에는 그 사유, 일시 및 기간을 정하여 게시판, E-Mail, 서면 또는 전화 등의 방법을 이용하여 해당 회원 또는 대리인에게 통지합니다.
② 다만, 운영자측에서 긴급하게 이용을 중지해야 할 필요가 있다고 인정하는 경우에는 전항의 과정없이 서비스 이용을 제한할 수 있습니다.
③ 제 21조 1항 규정에 의하여 서비스 이용중지를 통지 받은 회원 또는 그 대리인은 이용중지에 대하여 이의가 있을 경우 이의 신청을 할 수 있습니다.
④ 운영자측은 이용중지 기간 중에 그 이용중지 사유가 해소된 것이 확인되면 이용중지 조치를 해제할 수 있습니다.

제 21 조 (손해배상)
운영자측은 서비스 요금이 무료인 동안의 서비스 이용과 관련하여 운영자측의 고의, 과실에 의한 것이 아닌 한 회원에게 발생한 어떠한 손해에 관하여도 책임을 지지 않습니다.

제 22 조 (면책조항)
①운영자측은 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
②운영자측은 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.
③운영자측은 회원이 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며 그 밖에 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.
④운영자측은 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.

[부칙]

(시행일) 이 약관은 2013년 6월 12일부터 시행합니다.`} />
                        
                            </div>
                            <div className='agreePrivacy'>
                                <div className='id_check'>
                                    <label>
                                        <input type='checkbox' className='check' name='join_agree2' id='join_agree2' value='1' required 
                                                onChange={(e) => setIsAgreePrivacy(e.currentTarget.checked) }
                                                checked={ isAgreePrivacy? true: false }/>
                                        <i></i>(필수)개인정보 수집 및 이용
                                    </label>
                                </div>
                                <textarea name='textarea' className='textarea' readOnly='readonly' value={`수집하는 개인정보의 항목

가. 운영자측은 회원가입, 상담, 각종 서비스의 제공을 위해 최초 회원가입 당시 아래와 같은 개인정보를 수집하고 있습니다.
- 필수항목 : 성명, 주민등록번호, 아이디, 비밀번호, 닉네임, 이메일 주소, 현 소속합회, 직분, 주소, 전화번호, 휴대폰번호, 메일수신 여부, 회원사진, 자기소개

나. 서비스 이용과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
- IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 부정 이용 기록

개인정보의 수집 및 이용 목적

가. 회원관리
회원제 서비스 이용 및 제한적 본인 확인제에 따른 본인확인, 개인식별, 부정 이용방지와 비인가 사용방지, 가입의사 확인, 가입 및 가입횟수 제한, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달

나. 신규 서비스 개발
신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 접속빈도 파악, 회원의 서비스 이용에 대한 통계

개인정보의 보유 및 이용기간

이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.

가. 내부 방침에 의한 정보보유 사유
- 부정이용기록 보존 이유 : 부정 이용 방지
- 보존 기간 : 1년

나. 관련법령에 의한 정보보유 사유
상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 운영자측은 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
- 방문에 관한 기록 보존 이유 : 통신비밀보호법
- 보존 기간 : 3개월
- 본인확인에 관한 기록 보존 이유 : 정보통신 이용촉진 및 정보보호 등에 관한 법률
- 보존 기간 : 6개월`}/>
                            </div>
                            <p className='text-center'>
                                <button type='button' className='btn-yellow-s fnone' 
                                    onClick={() => setSignupStep(DataTypes.SIGNUP_SIGNUP_FORM) }  // 1: -> Signup Form
                                    title='다음' 
                                    disabled={ !isSendable }>다음</button>
                            </p>
                        </div>
                </article>
            </div>
            {/* <!--//e: #content --> */}
        </div>
    );
};

export default SignupAgreements;