import React from 'react';

const NoticeLabel = () => {
    return (
        <strong
            className="sns_lst"
            style={{
                backgroundColor: '#125452',
                color: '#fff',
                paddingTop: 7,
                paddingBottom: 7,
                paddingLeft: 10,
                paddingRight: 10,
                marginRight: 10,
                fontSize: 13,
                borderRadius: 5,
            }}
        >
            공지
        </strong>
    );
};

export default NoticeLabel;
