import React, { useEffect, useState } from 'react';
import { getCommaSeparatedNumber } from '../../../utils/common';
import InputNum from '../components/InputNum';
import styled from 'styled-components';

const TermTable = styled.table`
    border: 1px solid silver;
    border-radius: 3px;
    border-collapse: separate;
    background-color: rgb(236, 236, 236);
    margin-bottom: 10px;
    width: 100%;
`;

const TermTr = styled.tr`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 0.95em;
    text-align: center;
    vertical-align: middle;
    height: 35px;
`;

const TermTd = styled.td`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 0.95em;
    text-align: center;
    vertical-align: middle;
`;

const TermTh = styled.th`
    font-family: '기본체' !important;
    height: 35px;
    font-size: 1.2em;
    text-align: center;
    vertical-align: middle;
    background-color: rgb(212, 212, 212);
`;

const Evangelism = ({ disabled, termReportData, setTermReportData }) => {
    const [sum, setSum] = useState(0);

    useEffect(() => {
        setSum(
            getCommaSeparatedNumber(
                termReportData?.child_evangelical_meeting +
                    termReportData?.teen_evangelical_meeting +
                    termReportData?.pastor_evangelical_meeting +
                    termReportData?.small_group_evangelical_meeting +
                    termReportData?.evangelist_group_evangelical_meeting +
                    termReportData?.other_evangelical_meeting,
            ),
        );
    }, [termReportData]);

    return (
        <div>
            <TermTable>
                <thead style={{ height: '38px' }}>
                    <TermTr>
                        <TermTh colSpan={4}>전도활동</TermTh>
                    </TermTr>
                </thead>
                <tbody>
                    <TermTr>
                        <TermTd colSpan={3}>구분</TermTd>

                        <TermTd>횟수</TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={3}>어린이 전도회</TermTd>

                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.child_evangelical_meeting}
                                setValue={setTermReportData}
                                reportKeyName={'child_evangelical_meeting'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={3}>청소년 전도회</TermTd>

                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.teen_evangelical_meeting}
                                setValue={setTermReportData}
                                reportKeyName={'teen_evangelical_meeting'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>

                    <TermTr>
                        <TermTd colSpan={3}>목회자 전도회</TermTd>

                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.pastor_evangelical_meeting}
                                setValue={setTermReportData}
                                reportKeyName={'pastor_evangelical_meeting'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={3}>소그룹 전도회</TermTd>

                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.small_group_evangelical_meeting}
                                setValue={setTermReportData}
                                reportKeyName={'small_group_evangelical_meeting'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={3}>전도단 전도회</TermTd>

                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.evangelist_group_evangelical_meeting}
                                setValue={setTermReportData}
                                reportKeyName={'evangelist_group_evangelical_meeting'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={3}>기타 각종 전도회</TermTd>

                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.other_evangelical_meeting}
                                setValue={setTermReportData}
                                reportKeyName={'other_evangelical_meeting'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={3}>합계</TermTd>

                        <TermTd>{sum || 0}</TermTd>
                    </TermTr>
                </tbody>
            </TermTable>
        </div>
    );
};

export default Evangelism;
