import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100vh;
`;

const StyledH1 = styled.h1`
    margin-top: 10px;
    font-family: '기본체' !important;
    font-size: 3em;
    font-weight: bolder;
    /* text-align: center; */
    color: red;
    padding-bottom: 20px;
`;

const StyledH3 = styled.h3`
    font-family: '기본체' !important;
    font-size: 1.5em;
    /* text-align: center; */
    line-height: 38px;
`;

const StyledSpan = styled.span`
    font-family: '기본체' !important;
    font-size: 1em;
    padding-top: 20px;
`;

const ErrorPage = () => {
    return (
        <Container>
            <StyledH1>올바른 접근이 아닙니다.</StyledH1>
            <StyledH3>부목사, 전도사 등 담임목사가 아닐 경우 접근이 불가합니다.</StyledH3>
            <StyledSpan>해당 오류가 지속적으로 발생할 경우 홈페이지 관리자에게 문의바랍니다.</StyledSpan>
            <StyledSpan>문의번호 010-2121-7025</StyledSpan>
        </Container>
    );
};

export default ErrorPage;
