import React from 'react';
import { Link } from 'react-router-dom';
import { DataTypes } from '../../data/Types';

const SignupSuccess = ({ setSignupStep }) => {
    return (
        <div id='primary' className='content-area'>
            {/* <!--s:#content --> */}
            <div id='content' className='site-content'>
                <article>
                    <div className='mem_level'>
                        <ul>
                            <li><span>01</span> 약관동의</li>
                            <li><span>02</span> 정보입력</li>
                            <li className='hover'><span>03</span> 가입완료</li>
                        </ul>
                    </div>
                    <div className='join_wrap'>
                        <div className='finish'>
                            <p className='tit'>영남합회<span><br/> 회원가입이 완료</span>되었습니다.</p>
                            <p className='txt'>영남합회 회원으로 가입하시면<br/>합회보고, 자료실 등 다양한 서비스를 누리실 수 있습니다.</p>
                        </div>
                        <p className='text-center mt30'>
                            <Link to='../login' className='btn-yellow-s fnone' title='로그인'>로그인</Link>
                            <button className='btn-black-s fnone' 
                                    style={{ marginLeft: 10 }}
                                    title='처음으로'
                                    onClick={() => setSignupStep(DataTypes.SIGNUP_AGREEMENT)}   // 회원가입 처음으로
                            >처음으로</button>
                        </p>
                    </div>

                </article>
            </div>
            {/* <!--//e: #content --> */}
        </div>
    );
};

export default SignupSuccess;