import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { isLogin } from '../../../data/firebase';

import { UserContext } from '../../../contexts';

import BoardPagesHeader from './BoardPagesHeader';
import BoardList from './BoardList';
import Pagination from '../../../components/Pagination';

import BoardWriteButton from './BoardWriteButton';

const BoardTable = ({
    notices, //
    CATEGORY_NOTICE_ID,
    posts,
    CATEGORY_COUNSEL_ID,
    postTotalCount,
    postsPerPage,
    setPostOffset,
    currentCategory,
    postOffset,
    SEARCH_URL,
    COMMON_URL,
}) => {
    const { user } = useContext(UserContext);
    const { categoryId, groupCode } = useParams();

    return (
        <>
            <div className="table_wrap_scroll">
                <table className="tbl_skin3 center twd">
                    <BoardPagesHeader />
                    <tbody>
                        <BoardList // 공지사항
                            posts={notices}
                            CATEGORY_NOTICE_ID={CATEGORY_NOTICE_ID}
                            isNotice
                        />
                        {posts.length !== 0 ? (
                            <BoardList // 게시글
                                posts={posts}
                                groupCode={groupCode}
                                categoryId={categoryId}
                                CATEGORY_COUNSEL_ID={CATEGORY_COUNSEL_ID}
                                SEARCH_URL={SEARCH_URL}
                                COMMON_URL={COMMON_URL}
                            />
                        ) : (
                            <tr>
                                <td colSpan={window.innerWidth > 767 ? 6 : 2}> 등록된 글이 존재하지 않습니다. </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <Pagination total={postTotalCount} itemsPerPage={postsPerPage} setOffset={setPostOffset} offset={postOffset} />

            <BoardWriteButton user={user} currentCategory={currentCategory} isLogin={isLogin} />
        </>
    );
};

export default BoardTable;
