import React from 'react';
import styled from 'styled-components';

const StyledH1 = styled.h1`
    margin-top: 10px;
    font-family: '조선일보명조' !important;
    font-size: 2em;
`;
const StyledH3 = styled.h3`
    font-family: '기본체' !important;
    font-size: 1em;
    padding: 10px 0 5px 0;
    font-weight: bolder;
    /* font-weight: 700; */
`;

const TermReportHeaderMiddle = ({ reportedFrom }) => {
    return (
        <table width={'100%'}>
            <tbody>
                <tr>
                    <td>
                        <StyledH1 className="text-center text-vertical-middle">교회 종합 기말보고</StyledH1>
                    </td>
                </tr>
                <tr>
                    <td>
                        <StyledH3 className="text-center text-vertical-middle">{reportedFrom}</StyledH3>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
export default TermReportHeaderMiddle;
