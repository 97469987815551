import React, { useEffect, useState } from 'react';

import InputNum from '../components/InputNum';
import InputCheckbox_middle from '../components/InputCheckbox_middle';
import TermReport from '../TermReport';
import { TermReportData } from '../data/data';
import styled from 'styled-components';
import InputCheckbox from '../components/InputCheckbox';

const TermTable = styled.table`
    border: 1px solid silver;
    border-radius: 3px;
    border-collapse: separate;
    background-color: rgb(236, 236, 236);
    margin-bottom: 10px;
    width: 100%;
`;

const TermTr = styled.tr`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 0.95em;
    text-align: center;
    vertical-align: middle;
    height: 42px;
`;

const TermTrHead = styled(TermTr)`
    height: 38px;
`;

const TermTd = styled.td`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 0.95em;
    text-align: center;
    vertical-align: middle;
`;

const TermTh = styled.th`
    font-family: '기본체' !important;
    height: 35px;
    font-size: 1.2em;
    text-align: center;
    vertical-align: middle;
    background-color: rgb(212, 212, 212);
`;

const YouthBusiness = ({ disabled, termReportData, setTermReportData }) => {
    return (
        <div>
            <TermTable>
                <thead>
                    <TermTrHead>
                        <TermTh colSpan={4}>청소년 사업</TermTh>
                    </TermTrHead>
                </thead>
                <tbody>
                    <TermTr>
                        <TermTd>구 분</TermTd>
                        <TermTd>조직수</TermTd>
                        <TermTd>등록회원</TermTd>
                        <TermTd>출석회원</TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd>청년선교회</TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.youth_group}
                                setValue={setTermReportData}
                                reportKeyName={'youth_group'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.youth_group_members}
                                setValue={setTermReportData}
                                reportKeyName={'youth_group_members'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.youth_group_attendance}
                                setValue={setTermReportData}
                                reportKeyName={'youth_group_attendance'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd>학 생 회</TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.student_group}
                                setValue={setTermReportData}
                                reportKeyName={'student_group'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.student_group_members}
                                setValue={setTermReportData}
                                reportKeyName={'student_group_members'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.student_group_attendance}
                                setValue={setTermReportData}
                                reportKeyName={'student_group_attendance'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd>패스파인더</TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.pathfinder}
                                setValue={setTermReportData}
                                reportKeyName={'pathfinder'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.pathfinder_members}
                                setValue={setTermReportData}
                                reportKeyName={'pathfinder_members'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.pathfinder_attendance}
                                setValue={setTermReportData}
                                reportKeyName={'pathfinder_attendance'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={2}>청소년관 소유여부</TermTd>
                        <TermTd colSpan={2} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputCheckbox //
                                value={termReportData?.student_room}
                                setValue={setTermReportData}
                                reportKeyName={'student_room'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={2}>청소년 지도교사 수</TermTd>
                        <TermTd colSpan={2} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.student_teachers}
                                setValue={setTermReportData}
                                reportKeyName={'student_teachers'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                </tbody>
            </TermTable>
        </div>
    );
};

export default YouthBusiness;
