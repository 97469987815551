import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';

import { DataTypes } from '../../data/Types';
import { CommonContext, ProgressContext, UserContext } from '../../contexts';
import { getCommaSeparatedNumber, getTimeByWritingTime, removeWhitespace } from '../../utils/common';

import { isLogin } from '../../data/firebase';
import { createComment, deleteComment, getCommentsByPostId } from '../../data/mysql';

import toast from 'react-hot-toast';

let CATEGORY_COUNSEL_ID = null;

const Comment = ({ post }) => {
    const { settings } = useContext(CommonContext);
    const { spinner } = useContext(ProgressContext);
    const { user } = useContext(UserContext);

    const { categoryId } = useParams();

    const { search } = useLocation();
    const { id } = queryString.parse(search);

    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState('');

    const [isSubmitable, setIsSubmitable] = useState(false);

    useEffect(() => {
        CATEGORY_COUNSEL_ID = settings.find( setting => setting.name === 'category_counsel_id')?.value;

        const init = async () => {
            setComments( await getCommentsByPostId({ postId: id }) );
        }; init();

    }, []);

    /* [저장] 버튼을 활성화 시킨다 */
    useEffect(() => {
        setIsSubmitable(removeWhitespace(comment).length > 0);
    }, [comment]);

    // 댓글 등록 버튼
    const _handleCommentSendButtonPress = async () => {
        try {
            spinner.start();

            await createComment({ postId: id, comment });
            setComments( await getCommentsByPostId({ postId: id }) );
            
            setComment('');
            toast.success('새 댓글이 등록 되었습니다');
        } catch (e) {
            console.log(e.message);
        } finally {
            spinner.stop();
        }
    };

    /**
     * 댓글을 삭제한다.
     */
    const _handleDeleteComment = async ({ currentTarget }) => {
        const commentId = currentTarget.value;

        try {
            spinner.start();

            await deleteComment({ postId: id, commentId });
            
            setComments( await getCommentsByPostId({ postId: id }) );

            toast.success('댓글이 삭제 되었습니다');
        } catch (e) {
            console.log(e.message);
        } finally {
            spinner.stop();
        }

    };

    return (
        <div className='comments'>
            <h2>
                댓글
                <span className='count'>
                    <span id='commentCount176_0'>{getCommaSeparatedNumber(comments?.length)}</span>
                </span>
            </h2>
            <div id='entry176Comment'>
                {/* comment input */}
                <div className='comment-form'>
                    {/* <!--
                            <div className='field'> // 비밀글 작성

                            <input type='text' name='name' placeholder='이름' value=''>
                            <input type='password' name='password' maxlength='8' placeholder='비밀번호' value=''>

                            <div className='secret'>
                                <input type='checkbox' name='secret' id='secret'>
                                <label tabindex='0'>비밀글</label>
                            </div>
                        </div>
                        --> */}
                    <textarea
                        name='comment'
                        cols=''
                        rows='4'
                        placeholder={!isLogin() ? '로그인하면 댓글을 입력할 수 있습니다.' : '여러분의 소중한 댓글을 입력해주세요.'}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        onSubmit={(e) => setComment(e.target.value.trim())}
                        disabled={!isLogin() ? true : false}
                    />
                    <div className='submit'>
                        <button
                            type='submit'
                            className='btn'
                            style={{ opacity: isSubmitable ? 1 : 0.5 }} // disabled에 해당하는 Style Sheet 적용이 없어서 ...
                            disabled={!isSubmitable}
                            onClick={_handleCommentSendButtonPress}
                        >
                            등록
                        </button>
                    </div>
                </div>
            </div>
            {/* comment list */}
            <div className='comment-list'>
                {comments?.map((comment) => {
                    return (
                        <ul key={comment.id}>
                            <li>
                                <div className='author-meta'>
                                    <span className='nickname'>
                                    {   !(CATEGORY_COUNSEL_ID === parseInt(categoryId)) ?   // 신앙상담소
                                            (comment.name) 
                                            : ( // 신앙상담소 글이면 .. 
                                                
                                                    post?.uid === comment.uid ? 
                                                        <>
                                                            <img style={{ width: 37 }} src={`../../../common/images/admin/owner_${ comment.gender === 'F'? 'woman': 'man'}.png`} alt={ comment.role } />
                                                            <span className='small-text'>{ comment.role }{ post?.id}</span>
                                                        </>
                                                        : <>
                                                            <img style={{ width: 37 }} src={`../../../common/images/admin/${
                                                                { 
                                                                    목회자: 'pastor',
                                                                    집사: 'deacon',
                                                                    장로: 'elder',
                                                                    평신도: 'layman',
                                                                    청년: 'youth',
                                                                    기타: 'etc'
                                                                }[comment.role]
                                                            }_${ comment.gender === 'F'? 'woman': 'man'}.png`} alt={ comment.role } />
                                                            <span className='small-text'>{ comment.role }</span>
                                                        </>
                                                    
                                                    
                                            )
                                    }
                                    </span>
                                    <span className='date'>{getTimeByWritingTime(comment.createdAt)}</span>
                                    <div className='commentbtn'>
                                        {
                                            // 로그인한 사용자의 uid와 댓글의 uid(userId)가 같으면 삭제할 수 있는 권한을 가진다.
                                            user?.uid !== comment.userId || user.level >= DataTypes.USER_LEVEL_MANAGER ? (
                                                <button className='delete' value={comment.id} onClick={_handleDeleteComment}>
                                                    삭제
                                                </button>
                                            ) : (
                                                ''
                                            )
                                        }
                                    </div>
                                </div>
                                <div className='commenttxt'>
                                    <p>{comment.content}</p>
                                </div>
                            </li>
                        </ul>
                    );
                })}
            </div>
        </div>
    );
};

export default Comment;
