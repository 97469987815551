import React, { useContext, useEffect, useState } from 'react';
import { CommonContext } from '../../contexts';
import { useLocation } from 'react-router-dom';
import { getAdmins } from '../../data/mysql';

const InfoAdmins = () => {
    const { categories } = useContext(CommonContext);
    const { pathname } = useLocation();
    const [admins, setAdmins] = useState([]);

    useEffect(() => {
        const init = async () => {
            setAdmins(await getAdmins());
        };
        init();
    }, []);

    return (
        <div id="primary" className="content-area">
            {/* <!--s:#content --> */}
            <div id="content" className="site-content">
                <article>
                    <div>
                        <div className="title-inner">
                            <img src="/common/images/title.png" alt="" />
                            <h1>{categories.find((category) => category.categoryCode === pathname.split('/')[4])?.categoryName}</h1>
                        </div>
                        <div style={{ height: 20 }}></div>
                        <div style={{ clear: 'both' }}></div>
                        <div className="title_subpage_solid_1"></div>
                        <div style={{ height: 30 }}></div>

                        <div className="greetings_wrap">
                            <div className="table_wrap">
                                <table className="tbl_basic">
                                    <colgroup>
                                        <col style={{ width: 80 }} />
                                        <col style={{ width: 150 }} />
                                        <col />
                                        <col />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>NO.</th>
                                            <th>성 명</th>
                                            <th>전화번호</th>
                                            <th>이메일</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {admins?.map((admin, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <th>{idx + 1}</th>
                                                    <td>{admin?.name}</td>
                                                    <td>{admin?.tel}</td>
                                                    <td>{admin?.email}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {/* <!-- .entry-content --> */}
                </article>
            </div>
            {/* <!--//e: #content --> */}
        </div>
    );
};

export default InfoAdmins;
