import React, { useContext, useEffect, useRef, useState } from 'react';
import { CommonContext, ProgressContext, UserContext } from '../../../contexts';
import { getActivityTermReport, getAdminChiefConfirm, getTermReportDataForAdmin } from '../../../data/mysql_term';
import queryString from 'query-string';

import toast from 'react-hot-toast';
import ErrorPage from '../../../screens/termReport/screen/ErrorPage';

import SummingUpTermReportBaseInfo from './screen/SummingUpTermReportBaseInfo';
import SummingUpClassify from './screen/SummingUpClassify';
import { useLocation } from 'react-router-dom';
import { showConfirmBox } from '../../../components/ConfirmBox';
import { Icon } from '@iconify/react';

const SummingUpTermReport = () => {
    const { spinner } = useContext(ProgressContext);

    const { search } = useLocation();
    const { dt } = queryString.parse(search);

    const [summingUpData, setSummingUpData] = useState({}); // 보고된 데이터의 합산통계 데이터 [{교회}, {기관}, {합계}]로 가져온다.
    const [isError, setIsError] = useState(false);
    const [stage, setStage] = useState(2); // '교회/기관/전체'의 변경 관리한다.
    const [executiveInfo, setExecutiveInfo] = useState([]); // 임직원 데이터 자료

    useEffect(() => {
        const getSummingUpData = async () => {
            try {
                spinner.start();
                let result = await getTermReportDataForAdmin({ dt });
                if (!result) {
                    setIsError(true);
                    toast.error('데이터를 가져오는데 실패하였습니다.');
                }
                console.log(result);
                setSummingUpData(result);
            } catch (e) {
                console.log(e);
                toast.error('데이터를 가져오는데 실패하였습니다.');
            } finally {
                spinner.stop();
            }
        };

        const getAdminChiefData = async () => {
            let result = await getAdminChiefConfirm({ dt });

            setExecutiveInfo(result);
        };

        getSummingUpData();
        getAdminChiefData();
    }, [dt]);

    return (
        <div className="content-area">
            <div className="site-content">
                <div style={{ marginTop: 20 }}>{''}</div>
                <div>
                    <div className="title-inner">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', paddingRight: 10, paddingLeft: 10 }}>
                            <div>
                                <h1>연합회 보고</h1>
                            </div>
                            <div>
                                <button
                                    className="styled-icon" //
                                    style={{ fontSize: 23 }}
                                    onClick={() => window.close()}
                                >
                                    <Icon
                                        icon="bi:x-lg" //
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: 20 }}></div>
                    <div style={{ clear: 'both' }}></div>
                    <div className="title_subpage_solid_1"></div>
                    <div className="container-fluid">
                        {isError ? (
                            <ErrorPage />
                        ) : (
                            <>
                                <SummingUpTermReportBaseInfo //
                                    summingUpData={summingUpData}
                                    stage={stage}
                                    dt={dt}
                                />
                                <SummingUpClassify //
                                    dt={dt}
                                    summingUpData={summingUpData}
                                    stage={stage}
                                    setStage={setStage}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SummingUpTermReport;
