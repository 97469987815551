import React from 'react';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';

import DragItem from '../components/DragItem';
import SelectOption from '../components/SelectOption';

const ListTable = styled.table`
    border: 1px solid silver;
    border-radius: 3px;
    border-collapse: separate;
    background-color: rgb(236, 236, 236);
    margin-bottom: 10px;
    width: 100%;
`;
const DropTable = styled.table`
    border-collapse: separate;
    background-color: rgb(236, 236, 236);
    margin: 0px;
    width: 100%;
`;

const ListTr = styled.tr`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 0.95em;
    text-align: center;
    vertical-align: middle;
    height: 36px;
`;

const ListTd = styled.td`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 0.95em;
    text-align: center;
    vertical-align: middle;
`;

const ListTh = styled.th`
    font-family: '기본체' !important;
    height: 35px;
    font-size: 1.2em;
    text-align: center;
    vertical-align: middle;
    background-color: rgb(212, 212, 212);
`;

const ChurchDropTable = ({
    churchList,
    areas,
    pastorInfo, //
    setPastorInfo,
    selectedArea,
    setSelectedArea,

    handleRemoveRel,
    handleCreateRel,
}) => {
    const DropBox = ({ children, church, head }) => {
        const [{ isOver, canDrop }, drop] = useDrop({
            accept: 'CARD',
            drop: () => ({ c_id: church.id, churchName: church.name }),
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
        });

        const getBackgroundColor = () => {
            if (isOver) {
                if (canDrop) {
                    return 'rgb(188,251,255)';
                } else if (!canDrop) {
                    return 'rgb(255,188,188)';
                }
            } else {
                return '';
            }
        };

        return (
            <DropTable>
                <tbody>
                    <ListTr ref={drop} style={{ backgroundColor: getBackgroundColor() }}>
                        <ListTd>{head}</ListTd>

                        <ListTd colSpan={3}>
                            <div style={{ display: 'flex' }}>{children}</div>
                        </ListTd>
                    </ListTr>
                </tbody>
            </DropTable>
        );
    };

    const assignedPastors = (churchId, churchName) => {
        return pastorInfo
            .filter((pastor) => pastor.c_id === churchId)
            .map((pastor, index) => (
                <DragItem
                    key={index}
                    id={pastor.id}
                    name={pastor.name}
                    currentChurchId={pastor.c_id} // 현재 위치한 교회의 ID
                    currentChurchName={churchName} //  현재 위치한 교회의 이름
                    setPastorInfo={setPastorInfo}
                    pastorInfo={pastorInfo}
                    churchList={churchList}
                    handleRemoveRel={handleRemoveRel}
                    handleCreateRel={handleCreateRel}
                    className="dragItem-inTable"
                />
            ));
    };

    return (
        <div>
            <ListTable>
                <thead style={{ height: '38px' }}>
                    <ListTr>
                        <ListTh colSpan={4}>
                            <SelectOption
                                options={areas.filter((area) => area.public === 'Y').map((area) => area)} //
                                value={selectedArea}
                                setValue={setSelectedArea}
                            />
                        </ListTh>
                    </ListTr>
                </thead>
                <tbody>
                    <ListTr>
                        <td colSpan={4}>
                            {churchList
                                ?.filter((info) => info.area_id === selectedArea)
                                .map((church, index) => {
                                    return (
                                        <DropBox //
                                            key={index}
                                            church={church}
                                            head={church.name}
                                        >
                                            {assignedPastors(church.id, church.name)}
                                        </DropBox>
                                    );
                                })}
                        </td>
                    </ListTr>
                </tbody>
            </ListTable>
        </div>
    );
};

export default ChurchDropTable;
