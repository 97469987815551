import React from 'react';
import styled from 'styled-components';
import DragItem from '../components/DragItem';
import DropBox from '../components/DropBox';

const ListTable = styled.table`
    border: 1px solid silver;
    border-radius: 3px;
    border-collapse: separate;
    background-color: rgb(236, 236, 236);
    margin-bottom: 10px;
    width: 100%;
`;

const ListTr = styled.tr`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 0.95em;
    text-align: center;
    vertical-align: middle;
`;

const DropTd = styled.td`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 0.95em;
    text-align: center;
    vertical-align: middle;
`;

const ListTh = styled.th`
    font-family: '기본체' !important;
    height: 35px;
    font-size: 1.2em;
    text-align: center;
    vertical-align: middle;
    background-color: rgb(212, 212, 212);
`;

const TemporaryBox = ({
    pastorInfo, //
    setPastorInfo,
    handleRemoveRel,
    handleCreateRel,
}) => {
    const unAssignedPastors = () => {
        return pastorInfo
            .filter((pastor) => !pastor.c_id)
            .map((pastor, index) => (
                <DragItem
                    key={index}
                    id={pastor.id}
                    name={pastor.name}
                    currentChurchId={pastor.c_id} // 현재 위치한 교회
                    setPastorInfo={setPastorInfo}
                    pastorInfo={pastorInfo}
                    handleRemoveRel={handleRemoveRel}
                    handleCreateRel={handleCreateRel}
                    className="dragItem-outTable"
                />
            ));
    };

    return (
        <div>
            <ListTable>
                <thead style={{ height: '38px' }}>
                    <ListTr>
                        <ListTh>목회자</ListTh>
                    </ListTr>
                </thead>
                <tbody>
                    <ListTr>
                        <DropTd>
                            <DropBox>이름표 보관{unAssignedPastors()}</DropBox>
                        </DropTd>
                    </ListTr>
                </tbody>
            </ListTable>
        </div>
    );
};

export default TemporaryBox;
