import { useEffect, useState } from 'react';
import { useImmer } from 'use-immer';

import { getChurchSectionList } from '../../../../data/mysql_term';
import styles from './AdminConfirmSections.module.css';

const AdminConfirmSections = ({ onSelectionChange }) => {
  const [sections, updateSections] = useImmer([]);
  const [allChecked, setAllChecked] = useState(false); // 전체선택, 전체해제

  useEffect(() => {
    getChurchSectionList().then((result) =>
      updateSections((draft) =>
        result
          .filter((r) => r.public === 'Y') // 공개된 섹션만
          .forEach((item) => {
            item.isChecked = false;
            draft.push(item);
          }),
      ),
    );
  }, []);

  /**
   * 선택된 섹션들이 바뀔 때마다 부모 컴포넌트에게 알려서 리스트를 업데이트하도록 한다.
   */
  useEffect(() => {
    const checkedSections = sections.filter((sect) => sect.isChecked).map((sect) => sect.id);
    onSelectionChange(checkedSections);
  }, [sections]);

  /**
   * 전체 선택, 해제 토글한다.
   */
  const toggleAllSections = () => {
    setAllChecked((prev) => !prev);
    updateSections((draft) => {
      draft.forEach((sect) => {
        sect.isChecked = !allChecked;
      });
    });
  };

  return (
    <div className={styles.sections}>
      <button className={styles.section_item} onClick={toggleAllSections}>
        {allChecked ? '전체 해제' : '전체 선택'}
      </button>

      {sections.map((sect, index) => (
        <button
          key={sect.id}
          className={
            sect.isChecked === true //
              ? `${styles.section_item} ${styles.section_item_active}`
              : `${styles.section_item} ${styles.section_item_inactive}`
          }
          onClick={() => {
            updateSections((draft) => {
              draft[index].isChecked = !draft[index].isChecked;
            });
          }}
        >
          {sect.name}
        </button>
      ))}
    </div>
  );
};

export default AdminConfirmSections;
