import React, { useContext, useEffect, useState } from 'react';
import { format, getQuarter, startOfQuarter, subQuarters } from 'date-fns';
import { useLocation, useParams } from 'react-router-dom';
import { ProgressContext } from '../../../contexts';
import { getTermReportDataByAdmin, submitTermReportDataByAdmin } from '../../../data/mysql_term';

import queryString from 'query-string';
import toast from 'react-hot-toast';
import ErrorPage from '../../termReport/screen/ErrorPage';

import SearchingBox from './screen/SearchingBox';
import TermReportTable from '../../termReport/screen/TermReportTable';
import { Icon } from '@iconify/react';

const AdminManageTermReport = () => {
    const { spinner } = useContext(ProgressContext);

    const { search } = useLocation();
    const { dt } = queryString.parse(search);
    const { id } = queryString.parse(search);

    const [termReportInfo, setTermReportInfo] = useState({});
    const [termReportData, setTermReportData] = useState({});
    const [toBeDeletedTermReportData, setToBeDeletedTermReportData] = useState({});
    const [termReportPrevData, setTermReportPrevData] = useState({});

    const [churchId, setChurchId] = useState(id); // church ID
    const [churchName, setChurchName] = useState(''); // church Name

    const [isError, setIsError] = useState(false);
    const [isSendable, setIsSendable] = useState(true);
    const [disabled, setDisabled] = useState(false);

    const currentTerm = new Date(parseInt(dt * 1000));

    // 교회를 선택할 때 마다 실행된다. 검색한 교회 보고서의 기본 정보를 가져온다.
    useEffect(() => {
        const init = async () => {
            try {
                spinner.start();

                let result = await getTermReportDataByAdmin({ churchId, dt });
                if (!result) throw new Error();

                setTermReportInfo(result.termReportInfo);
                setTermReportData(result.termReportData);
                setToBeDeletedTermReportData(result.toBeDeletedTermReportData);
                setTermReportPrevData(result.termReportPrevData);
            } catch (e) {
                setIsError(true);

                toast.error('데이터 로드에 실패하였습니다.');
                console.log(e);
            } finally {
                spinner.stop();
            }
        };
        init();
    }, [churchId, churchName]);

    /**
     * 레포트를 전송한다.
     */
    const _handleSendReport = async () => {
        try {
            spinner.start();

            const result = await submitTermReportDataByAdmin({ reportId: termReportInfo.id, termReportData, toBeDeletedTermReportData });
            console.log(result);
            if (!result) throw new Error();

            setTermReportInfo((report) => ({
                ...report,
                is_submitted: 'Y',
            }));

            setIsSendable(false);
            toast.success('레포트를 전송하였습니다.');
        } catch (e) {
            toast.error('레포트 전송에 실패하였습니다.');
            console.log(e);
        } finally {
            spinner.stop();
        }
    };

    useEffect(() => {
        termReportInfo?.is_submitted === 'Y' ? setDisabled(true) : setDisabled(false);
        setChurchName(termReportInfo.church_name);
    }, [termReportInfo]);

    return (
        <div className="content-area">
            <div className="site-content">
                <div style={{ marginTop: 20 }}>{''}</div>
                <div>
                    <div className="title-inner">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', paddingRight: 10, paddingLeft: 10, paddingBottom: 0 }}>
                            <div>
                                <h1>기말보고(관리자)</h1>
                            </div>
                            <div className="col-xs-5 col-sm-3 col-md-3" style={{ display: 'flex', justifyContent: 'end', padding: 0 }}>
                                <div style={{ marginRight: 20 }}>
                                    {termReportInfo?.is_submitted === 'Y' ? (
                                        <div />
                                    ) : (
                                        <button
                                            style={{ marginBottom: 0, backgroundColor: '#125452', fontSize: 26 }}
                                            className="styled-icon" //
                                            onClick={_handleSendReport}
                                            title="레포트 전송"
                                        >
                                            <Icon
                                                icon="bi:pencil-square" //
                                            />
                                        </button>
                                    )}
                                </div>
                                <div>
                                    <button
                                        className="styled-icon" //
                                        style={{ fontSize: 26 }}
                                        onClick={() => window.close()}
                                    >
                                        <Icon
                                            icon="bi:x-lg" //
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: 20 }}></div>
                    <div style={{ clear: 'both' }}></div>
                    <div className="title_subpage_solid_1"></div>
                    <div className="container-fluid">
                        {isError ? (
                            <ErrorPage />
                        ) : (
                            <>
                                <div className="row">
                                    <SearchingBox //
                                        setChurchId={setChurchId}
                                        churchName={churchName}
                                        setChurchName={setChurchName}
                                    />
                                </div>
                                <div className="row">
                                    <div className="panel">
                                        <div className="panel-heading">
                                            <h3 className="between">
                                                {format(currentTerm, 'yyyy')}년 제 {getQuarter(currentTerm)}기 보고
                                            </h3>
                                        </div>
                                        <TermReportTable //
                                            disabled={disabled}
                                            termReportPrevData={termReportPrevData}
                                            termReportInfo={termReportInfo}
                                            termReportData={termReportData}
                                            setTermReportData={setTermReportData}
                                            toBeDeletedTermReportData={toBeDeletedTermReportData}
                                            setToBeDeletedTermReportData={setToBeDeletedTermReportData}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminManageTermReport;
