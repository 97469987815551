import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CommonContext } from '../../contexts';

const BoardHeader = () => {
    const { categoryId } = useParams();
    const { categories } = useContext(CommonContext);

    return (
        <header>
            <div className='title-inner'>
                <img src='/common/images/title.png' alt='' />
                <h1>{ categories.find( category => category.id === parseInt(categoryId) )?.categoryName }</h1>
            </div>
            <div style={{ height: 20 }}></div>
            <div style={{ clear: 'both' }}></div>
            <div className='title_subpage_solid_1'></div>
            <div style={{ height: 30 }}></div>
        </header>
    );
};

export default BoardHeader;