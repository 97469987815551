import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DataTypes } from '../../data/Types';
import { getBanners } from '../../data/mysql';

const MainRollingSlide = () => {
    const [publishedBanners, setPublishedBanners] = useState([]);
    let slideNumber = 0;

    useEffect(() => {
        const init = async () => {
            setPublishedBanners(await getBanners());
        };
        init();
    }, []);

    return (
        <section>
            <div className="carousel slide" id="myCarousel" data-ride="carousel">
                <div className="carousel-inner wow fadeInDown" data-wow-delay="0.5s">
                    {
                        /* 게시된 배너(Published Banner)가 없을 때, 첫 화면을 기본 화면으로 띄운다. */
                        publishedBanners.length === 0 ? (
                            <div className="item active">
                                <img src="../common/images/slider-img01.jpg" style={{ width: '100%' }} alt="영남합회에 오신 여러분을 환영합니다." />
                            </div>
                        ) : (
                            /* DB에 등록된 배너를 화면에 출력한다. */
                            publishedBanners.map((banner) => {
                                return (
                                    <div key={banner.id} className={`item ${slideNumber++ === 0 ? 'active' : ''}`}>
                                        <img src={process.env.REACT_APP_DB_SERVER_URL + banner.image_url} style={{ width: '100%' }} alt="" />
                                    </div>
                                );
                            })
                        )
                    }
                </div>
                {/* <!--이전, 다음 버튼--> */}
                <Link className="left carousel-control" to="#myCarousel" data-slide="prev">
                    <span>
                        <i className="fa fa-chevron-left"></i>
                    </span>
                </Link>
                <Link className="right carousel-control" to="#myCarousel" data-slide="next">
                    <span>
                        <i className="fa fa-chevron-right"></i>
                    </span>
                </Link>
            </div>
            {/* <!--//e:slideshow--> */}
        </section>
    );
};

export default MainRollingSlide;
