import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';

const StyledTd = styled.td`
    /* padding: 2px; */
    border: 0.5px solid black;
    font-family: '기본체' !important;
    font-size: 0.7em;
    line-height: 15px;
    height: 23px;

    @media print {
        /* padding: 2px; */
        border: 0.5px solid black;
        font-family: '기본체' !important;
        font-size: 0.7em;
        text-align: center;
        line-height: 15px;
        height: 23px;
    }
`;
const StyledH3 = styled.h3`
    font-family: '기본체' !important;
    font-size: 1em;
    padding: 10px 0 5px 0;
    font-weight: bolder;
    /* font-weight: 700; */
`;

const TermReportHeaderLeft = ({ termData }) => {
    return (
        <table width={'100%'}>
            <tbody>
                <tr>
                    <td height={'23px'}>
                        <StyledH3> {`${termData?.termReportInfo?.dt ? format(new Date(termData?.termReportInfo?.dt * 1000), 'yyyy년 제 Q기') : ''}`} </StyledH3>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table width={'100%'}>
                            <thead>
                                <tr>
                                    <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                        * 매 기말 보고 마감일 (합회도착)
                                    </StyledTd>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <StyledTd className="text-center text-vertical-middle">1기 : 4월 10일</StyledTd>
                                    <StyledTd className="text-center text-vertical-middle">3기 : 10월 10일</StyledTd>
                                </tr>
                                <tr>
                                    <StyledTd className="text-center text-vertical-middle">2기 : 7월 10일</StyledTd>
                                    <StyledTd className="text-center text-vertical-middle">4기 : 1월 10일</StyledTd>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
export default TermReportHeaderLeft;
