import axios from 'axios';
import fileDownload from 'js-file-download';

import { auth } from './firebase';

/* [Main] ---------------------------------------------------------------------------- BEGIN */
/**
 * (카테고리를 묶는) 그룹을 가지고 온다.
 */
export const getDefaultSettings = async () => {
    let list = [];

    try {
        const response = await axios.get(`${process.env.REACT_APP_DB_SERVER_URL}/api/getDefaultSettings.php`);
        list = response.data;
    } catch (e) {
        console.log(e.message);
    }

    return list;
};

/**
 * (카테고리를 묶는) 그룹을 가지고 온다.
 */
export const getCategoryGroupAll = async () => {
    let list = [];

    try {
        const response = await axios.get(`${process.env.REACT_APP_DB_SERVER_URL}/api/getGroups.php`);
        list = response.data;
    } catch (e) {
        console.log(e.message);
    }

    return list;
};

/**
 * 모든 카테고리를 가지고 온다.
 * @returns 성공시 snapshot, 실패시 false
 */
export const getCategoryAll = async () => {
    let list = [];

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/getCategories.php`);
        list = response.data;
    } catch (e) {
        console.log(e.message);
    }

    return list;
};

/**
 * 모든 카테고리를 가지고 온다.
 * @returns 성공시 snapshot, 실패시 false
 */
export const getGroupCategoryRel = async () => {
    let list = [];

    try {
        const response = await axios.get(`${process.env.REACT_APP_DB_SERVER_URL}/api/getGroupCategoryRel.php`);
        list = response.data;
    } catch (e) {
        console.log(e.message);
    }

    return list;
};

/**
 * 한 줄로 된 교회 데이터를 가지고 온다.
 * ex> 영남합회::대구광역시 남구 명덕시장길 100(대명5동 59-2)|거제하늘빛교회::경남 거제시 거제중앙로 25길 39(고현동 855-7)|울산중앙교회::울산광역시 남구 봉월로 28번길 4(신정2동 1211-20) ...
 */
export const getAllChurchInfo = async () => {
    let result = [];

    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/info/churches`);
        result = response.data || [];
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/* [Main] ---------------------------------------------------------------------------- END */

/**
 * 회원가입을 한다.
 * @returns 성공: uid, 실패: false
 */
export const signup = async ({ email, password, name, gender, birthDay, phoneNumber, postCode, address, addressDetail, church, role, school, major }) => {
    let result = false;

    // 1. Firebase에 이메일로 로그인 인증을 받는다.
    const { user } = await auth.createUserWithEmailAndPassword(email, password);

    await user.updateProfile({
        displayName: name,
        phoneNumber: '+82' + phoneNumber?.replace(/-/gi, '').substring(1), // 010-3159-4137 ⇢ +821031594137로 변경
    });

    if (!user.uid) return result; // user 값이 비어있으면 실패

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/createNewMember.php`, {
            uid: user.uid,
            name,
            email,
            gender,
            birthday: birthDay,
            phone: phoneNumber,
            post_code: postCode,
            address,
            address_detail: addressDetail,
            church,
            role,
            school,
            major,
        });

        if (response.data.result) {
            result = user.uid;
        }
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/** 해당 User ID로 사용자의 정보를 가지고 온다. */
export const getUserInfo = async ({ userId }) => {
    let result = false;

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/getMemberInfoByUID.php`, {
            uid: userId,
        });

        if (response.data) {
            result = response.data[0];
        }
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 사용자 계정을 삭제한다.
 * 1. DB(mysql)에서 사용자 정보 삭제
 * 2. Firebase Authentication에서 인증 삭제
 * @returns
 */
export const deleteUserAccount = async () => {
    const user = auth.currentUser;
    let result = false;

    // await db.collection('members').doc(user.uid).delete();
    // 1. DB(mysql)에서 사용자 정보 삭제
    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/deleteMember.php`, {
            uid: user.uid,
        });

        if (response.data.result) {
            result = user.uid;
        }
    } catch (e) {
        console.log(e.message);
    }

    // 2. Firebase Authentication에서 인증 삭제
    await user.delete();

    return result;
};

/**
 * 회원 정보를 수정한다.
 * @returns
 */
export const editUser = async ({ birthDay, phoneNumber, postCode, address, addressDetail, church }) => {
    // 비밀번호를 업데이트 한다.
    const user = auth.currentUser;
    let result = false;

    try {
        const response = await axios.put(`${process.env.REACT_APP_DB_SERVER_URL}/api/updateMemberInfo.php`, {
            uid: user.uid,
            birthday: birthDay,
            phone: phoneNumber,
            post_code: postCode,
            address,
            address_detail: addressDetail,
            church,
        });

        if (response.data.result) {
            result = user.uid;
        }
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 멤버의 카운트를 얻어온다.
 */
export const getMemberCount = async () => {
    let result = 0;

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/getMemberCount.php`, {
            uid: auth.currentUser.uid,
        });

        result = response.data[0].cnt;
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 회원 리스트를 가져온다.
 */
export const getMemberList = async ({ limit, offset }) => {
    let result = [];

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/getMembers.php`, {
            uid: auth.currentUser.uid,
            limit,
            offset,
        });
        result = response.data || [];
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 *
 * @param type - 이름, 출석교회, 직급, 휴대전화, 이메일, 주소
 * @param keyword : 키워드
 * @returns
 */
export const getSearchedMemberCount = async ({ type, keyword }) => {
    let result = 0;

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/getSearchedMemberCount.php`, {
            type,
            keyword,
        });

        result = response.data[0].cnt;
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * @param type - 이름, 출석교회, 직급, 휴대전화, 이메일, 주소
 * @param keyword : 키워드
 * @param limit : 한 페이지에 들어갈 회원수
 * @param offset : 오프셋
 * User Id(Firebase uid)에 해당하는
 */
export const searchMemberList = async ({ type, keyword, limit, offset }) => {
    let result = [];

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/searchMembers.php`, {
            uid: auth.currentUser.uid,
            type,
            keyword,
            limit,
            offset,
        });

        // console.log(response);
        result = response.data || [];
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/** 해당 멤버의 레벨(level)을 수정한다. */
export const updateMemberLevel = async ({ memberId, level }) => {
    let result = false;
    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/updateMemberLevel.php`, {
            uid: memberId,
            level,
        });

        if (response.data.result) {
            result = response.data.result;
        }
    } catch (e) {
        console.log(e.message);
    }
    return result;
};

/**
 * 메인 롤링 배너를 가지고 온다.
 */
export const getBanners = async () => {
    let result = [];

    try {
        const response = await axios.get(`${process.env.REACT_APP_DB_SERVER_URL}/api/getBanners.php`);

        if (!response.data.error) {
            result = response.data;
        }
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 새로운 배너(Main Rolling Banner)를 생성한다.
 *
 * 1. DB(Firebase), Banners 컬렉션에 문서(Document) 생성
 * 2. 만들어진 문서 ID를 할당하여 이미지 이름으로 업로드 한다.
 * 3. 만들어진 문서에 값을 셋팅한다.
 * @returns id - Banner 문서 ID 값
 */
export const createNewBanner = async ({ bannerImageFile, bannerLink }) => {
    let result = false;

    const formData = new FormData();
    formData.append('image', bannerImageFile);
    formData.append('link', bannerLink);

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/createNewBanner.php`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        });

        if (response.data.result) {
            result = response.data.result;
        }
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 해당 배너(Banner ID)를 삭제한다.
 *
 * 1. Storage에 올라가있는 이미지 파일을 지운다.
 * 2. DB(Firebase), Banners 컬렌션에서 해당 문서(Docuemnt)를 삭제
 * @param bannerId 배너 ID
 */
export const deleteBanner = async ({ bannerId }) => {
    let result = false;

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/deleteBanner.php`, {
            id: bannerId,
        });

        if (!response.data.error) {
            result = true;
        }
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 해당 배너(Banner ID)의 link 값을 수정한다.
 * @param {*} bannerId -
 *            bannerLink
 */
export const updateBanner = async ({ bannerId, bannerLink }) => {
    let result = false;

    try {
        const response = await axios.put(`${process.env.REACT_APP_DB_SERVER_URL}/api/updateBanner.php`, {
            id: bannerId,
            link: bannerLink,
        });

        if (!response.data.error) {
            result = true;
        }
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 행정위원 리스트를 가지고 온다.
 */
export const getAdmins = async () => {
    let result = [];

    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/info/admins`, {});

        result = response.data;
    } catch (e) {
        console.log(e.message);
    }

    return result;
};
/* [Board] ---------------------------------------------------------------------------- BEGIN */
/**
 * 글을 생성한다.
 * @param title 제목
 * @param department 관련 부서
 * @param content 글 내용
 * @param categoryId 카테고리 id
 * @param postId 글 id

 * @returns
 */
export const createPost = async ({ title, department, content, categoryId, files }) => {
    let result = false;

    const formData = new FormData();

    formData.append('uid', auth.currentUser.uid);
    formData.append('department', department);
    formData.append('title', title);
    formData.append('content', content);
    formData.append('category_id', categoryId);

    files.map((file, index) => formData.append(`files[${index}]`, file));

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/createNewPost.php`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        });

        if (response.data.status === 'success') {
            result = response.data.post_id;
        }
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 해당 카테고리의 Post를 가지고
 */
export const getPostList = async ({ categoryId, limit, offset }) => {
    let result = [];

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/getPosts.php`, {
            category_id: categoryId,
            limit,
            offset,
        });

        result = response.data;
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 해당 카테고리의 Post를 가지고
 */
export const getPostCount = async ({ categoryId }) => {
    let result = 0;

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/getPostCount.php`, {
            category_id: categoryId,
        });

        result = response.data[0].cnt;
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 글의 조회수 1 증가시킨다.
 * @returns
 */
export const increasePostHits = async ({ postId }) => {
    try {
        await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/updatePostHits.php`, {
            post_id: postId,
        });
    } catch (e) {
        console.log(e.message);
    }
};

/**
 * SNS 공유 횟수를 1 증가시킨다.
 * @param postId: 해당 글의 ID
 * @param sns: sns 종류(kakaotalk, band, twitter, facebook)
 * @returns
 */
export const increaseSnsHits = async ({ postId, sns }) => {
    try {
        await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/updateSnsHits.php`, {
            post_id: postId,
            sns,
        });
    } catch (e) {
        console.log(e.message);
    }
};

/**
 * 글의 조회수 1 증가시킨다.
 * @returns
 */
export const confirmPost = async ({ postId, confirm }) => {
    let result = 0;

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/updatePostConfirm.php`, {
            post_id: postId,
            confirm,
        });

        result = response.data.confirm;
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * @param type - 이름, 출석교회, 직급, 휴대전화, 이메일, 주소
 * @param keyword : 키워드
 * @param categoryId : 카테고리 ID
 * @param limit : 한 페이지에 들어가는 글의 갯수
 * @param offest : 글의 시작 위치(offest)
 *
 * User Id(Firebase uid)에 해당하는
 */
export const searchPostList = async ({ type, keyword, categoryId, limit, offset }) => {
    let result = false;

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/searchPosts.php`, {
            type,
            keyword,
            category_id: categoryId,
            limit,
            offset,
        });

        result = response.data || [];
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 *
 * @param type - 이름, 출석교회, 직급, 휴대전화, 이메일, 주소
 * @param keyword : 키워드
 * @param categoryId : 카테고리 ID
 * @returns
 */
export const getSearchedPostCount = async ({ type, keyword, categoryId }) => {
    let result = 0;

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/getSearchedPostCount.php`, {
            type,
            keyword,
            category_id: categoryId,
        });

        result = response.data[0]?.cnt || 0;
    } catch (e) {
        console.log(e.message);
    }

    return result;
};
/**
 * 해당 Post ID로 Post의 내용을 가지고 온다.
 * @param postId - 해당 글의 Id
 * @param categoryId - 해당 카테고리 Id
 *
 * @returns
 */
export const getPost = async ({ postId, categoryId }) => {
    let result = {};

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/getPostByID.php`, {
            post_id: postId,
            category_id: categoryId,
        });

        result = response.data[0] || {};
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 해당 Post에 연결되어 있는 파일들을 가지고 온다.
 * @param postId - 해당 Post Id
 * @returns
 */
export const getFilesByPostId = async ({ postId }) => {
    let result = [];

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/getFilesByPostID.php`, {
            post_id: postId,
        });

        result = response.data || [];
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 해당 Post에 연결되어 있는 파일들을 가지고 온다.
 * @param postId - 해당 Post Id
 * @returns
 */
export const getSnsCountPostId = async ({ postId }) => {
    let result = [];

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/getSnsCountByPostID.php`, {
            post_id: postId,
        });

        result = response.data[0] || [];
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 해당 글을 삭제한다. 하위에 포함된 댓글(comments)과 파일(files)도 모두 삭제한다.
 * @param postId 글 ID
 * @param categoryId 카테고리 ID
 */
export const deletePost = async ({ postId, categoryId }) => {
    let result = false;

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/deletePost.php`, {
            post_id: postId,
            category_id: categoryId,
        });

        result = response.data || [];
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 글을 수정한다.
 * @param department 관련 부서
 * @param categoryId 카테고리 id
 * @param postId 글 id
 * @param title 제목
 * @param content 글 내용
 * @param files 파일 목록

 * @returns postId
 */
export const editPost = async ({ department, categoryId, postId, title, content, filesToDelete, files }) => {
    let result = false;

    const formData = new FormData();
    formData.append('department', department);
    formData.append('category_id', categoryId);
    formData.append('post_id', postId);
    formData.append('title', title);
    formData.append('content', content);

    formData.append('files_to_delete', filesToDelete); // 삭제할 파일 ID 배열

    // 업로드할 새로운 파일
    files
        .filter((file) => !file.id)
        .forEach((file, index) => {
            formData.append(`files_to_upload[${index}]`, file);
        });

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/updatePost.php`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        });

        if (response.data.status === 'success') {
            result = true;
        }
    } catch (e) {
        console.log(e.message);
    }

    return result;
};
/* [Board] ---------------------------------------------------------------------------- END */

/* [Comment] ---------------------------------------------------------------------------- BEGIN */
/**
 * 댓글을 만든다.
 * @param postId 글 ID
 * @param categoryId 글 ID
 * @param comment 댓글 내용
 */
export const createComment = async ({ postId, comment }) => {
    let result = false;

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/createNewComment.php`, {
            post_id: postId,
            uid: auth.currentUser.uid,
            comment: comment,
        });

        if (response.data.status === 'success') {
            result = true;
        }
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 해당 글의 댓글을 가지고 온다.
 * @param postId - Post ID
 * @returns
 */
export const getCommentsByPostId = async ({ postId }) => {
    let result = [];

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/getCommentsByPostID.php`, {
            post_id: postId,
        });

        result = response.data || [];
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 댓글을 삭제한다.
 * @param postId 글 ID
 * @param comment 댓글 내용
 */
export const deleteComment = async ({ postId, commentId }) => {
    let result = false;

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/deleteComment.php`, {
            post_id: postId,
            comment_id: commentId,
        });

        result = response.data || [];
    } catch (e) {
        console.log(e.message);
    }

    return result;
};
/* [Comment] ---------------------------------------------------------------------------- END */

/**
 * 파일 다운로드
 */
export const downloadFile = async ({ filePath, fileType, fileName }) => {
    try {
        axios
            .get(`${process.env.REACT_APP_DB_SERVER_URL + filePath}`, {
                responseType: 'blob',
            })
            .then((res) => {
                fileDownload(res.data, `${fileName}`);
            });
    } catch (e) {
        console.log(e.message);
    }
};

/**
 * 해당 Post에 연결되어 있는 파일들을 가지고 온다.
 * @param postId - 해당 Post Id
 * @returns
 */
export const getAllSchoolInfo = async () => {
    let result = [];

    try {
        const response = await axios.get(
            `https://www.career.go.kr/cnet/openapi/getOpenApi?apiKey=9ff4bc8a69b0863588dd97201ca9603e&svcType=api&svcCode=SCHOOL&contentType=json&gubun=univ_list&perPage=700`,
        );

        result = response.data.dataSearch.content || [];
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/* [Locations] ---------------------------------------------------------------------------- BEGIN */
/**
 * 해당 Post에 연결되어 있는 파일들을 가지고 온다.
 * @param type: 해당 타입(교회, 식당, 풍경, 업체, ...)
 * @param area: 지역(영남합회, 동서중한, 호남, 충청합회)
 * @param item: 아이템(이름, 주소)
 * @param keyword: 검색 키워드
 * @returns
 */
export const getLocationsByKeyword = async ({ type, area, item, keyword }) => {
    let result = [];

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/getLocationsByKeyword.php`, {
            type,
            area,
            item,
            keyword,
        });

        result = response.data || [];
    } catch (e) {
        console.log(e.message);
    }

    return result;
};
/* [Locations] ---------------------------------------------------------------------------- END */

/**
 * 합회 일정에 뿌릴 캘린더 정보(Active)를 가지고 온다.
 * @returns
 */
export const getPublicCalendars = async () => {
    let result = [];

    try {
        const response = await axios.get(`${process.env.REACT_APP_DB_SERVER_URL}/api/getPublicCalendars.php`, {});

        result = response.data || [];
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 *
 * @returns
 */
export const getAllCalendars = async () => {
    let result = [];

    try {
        const response = await axios.get(`${process.env.REACT_APP_DB_SERVER_URL}/api/getAllCalendars.php`, {});

        result = response.data || [];
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 구독할 새로운 캘린더를 생성한다.
 * @param calendarName: 캘린더 이름
 * @param calendarId: 구글 캘린더 아이디
 * @param calendarColor: 캘린더 색상
 * @returns
 */
export const createNewSubscribeCalendar = async ({ calendarName, calendarId, calendarColor }) => {
    let result = [];

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/createNewCalendar.php`, {
            name: calendarName,
            calendar_id: calendarId,
            color: calendarColor,
        });

        result = response.data;
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 해당 아이디의 캘린더를 지운다
 * @param calendarId: 지울 캘린더 번호
 * @returns
 */
export const deleteSubscribedCalendar = async ({ calendarId }) => {
    let result = [];

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/deleteCalendar.php`, {
            calendar_id: calendarId,
        });

        result = response.data;
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 해당 아이디의 캘린더 공개 상태(Y, N)를 변경한다.
 * @param calendarId: 바꿀 캘린더 아이디
 * @param publicState: 공개 상태 변경
 * @returns
 */
export const updateCalendarPublicState = async ({ calendarId, publicState }) => {
    let result = [];

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/updateCalendarPublicState.php`, {
            calendar_id: calendarId,
            public_state: publicState,
        });

        result = response.data;
    } catch (e) {
        console.log(e.message);
    }

    return result;
};
/**
 * 해당 캘린더 아이디의 컬러(hex)를 변경한다.
 * @param calendarId: 캘린더 아이디
 * @param calendarName: 캘린더 이름
 * @returns
 */
export const updateCalendarName = async ({ calendarId, calendarName }) => {
    let result = [];

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/updateCalendarName.php`, {
            calendar_id: calendarId,
            calendar_name: calendarName,
        });

        result = response.data;
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 해당 아이디의 캘린더의 컬러(hex)를 변경한다.
 * @param calendarId: 바꿀 캘린더 아이디
 * @param color: 공개 상태 변경
 * @returns
 */
export const updateCalendarColor = async ({ calendarId, color }) => {
    let result = [];

    try {
        const response = await axios.post(`${process.env.REACT_APP_DB_SERVER_URL}/api/updateCalendarColor.php`, {
            calendar_id: calendarId,
            color,
        });

        result = response.data;
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 해당 보고서 아이디에 따라 월(月) 데이터를 가지고 온다.
 * @param userId: 사용자 아이디
 * @returns
 */
export const getReportMxDataByReportId = async ({ reportId }) => {
    let result = [];

    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/reports/${reportId}/mxData`, {});

        result = response.data[0];
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 보고서의 기본 정보를 가지고 온다.
 * @param userId: 사용자 아이디
 * @returns
 */
export const getReportMxData = async ({ userId, dt }) => {
    let result = [];

    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/reports/${userId}`, {
            params: { dt },
        });

        result = response.data[0];
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 일별 데이터를 온다.
 * @param {array} reportID: 레포트 ID
 * @returns
 */
export const getReportDxData = async ({ reportId }) => {
    let result = [];

    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/reports/${reportId}/dxData`, {
            params: { reportId },
        });

        result = response.data;
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 이전 데이터(지난달 서적 부수, 수침자 누계)
 * @param {number} reportId: 레포트 ID
 * @param {number} pastorId: 목회자 아이디
 * @param {number} dt: 해당 월(月) unix_timestamp
 * @returns
 */
export const getReportPrevData = async ({ reportId, pastorId, dt }) => {
    let result = [];

    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/reports/${reportId}/prevData`, {
            params: { pastorId, dt },
        });

        result = response.data;
    } catch (e) {
        console.log(e.message);
    }

    return result;
};
/**
 *
 * @param dt:  param0
 * @returns
 */
export const getMonthlyActivityReport = async ({ currentMonth }) => {
    let result = [];

    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/reports/month/${currentMonth}`, {});

        result = response.data[0];
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 일별 데이터를 저장한다.
 * @param {array} reportID: 레포트 ID
 * @param {array} dxData: 일 데이터
 * @returns
 */
export const saveReportDxData = async ({ reportId, dxData }) => {
    let result = [];

    try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/reports/${reportId}/temp`, {
            content: JSON.stringify(dxData),
        });

        result = response.data;
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 데이터를 임시로 저장한다.
 * @param {array} reportID: 레포트 ID
 * @returns
 */
export const getSeekers = async ({ reportId }) => {
    let result = [];

    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/reports/${reportId}/seekers`, {});

        result = response.data;
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 보고서를 저장한다.
 * @param {number} reportId: 보고서 ID
 * @param {object} reportMxData: 월 데이터
 * @returns
 */
export const saveReportData = async ({ reportId, mxData, dxData }) => {
    let result = [];

    try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/reports/${reportId}/save`, {
            mxData,
            content: JSON.stringify(dxData),
        });

        console.log(response);
        result = response.data;
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 보고서를 제출한다.
 * @param {number} reportId: 보고서 ID
 * @param {object} reportMxData: 월 데이터
 * @param {array} reportDxData: 일 데이터
 * @returns
 */
export const submitReportData = async ({ reportId, reportMxData, reportDxData }) => {
    let result = [];

    try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/reports/${reportId}/submit`, {
            mxData: reportMxData,
            dxData: reportDxData,
        });

        console.log(response);
        result = response.data;
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

/**
 * 관리자 페이지에서 제출한 월말 보고서의 제출을 취소한다.
 */
export const rejectMonthlyReport = async ({ reportId }) => {
    let result = false;

    try {
        const response = await axios.delete(`${process.env.REACT_APP_CMS_BACKEND_SERVER_URL}/reports/monthly/${reportId}/submission`, {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
            params: {},
        });
        if (response) {
            result = true;
        }
    } catch (e) {
        console.log(e);
    }

    return result;
};
