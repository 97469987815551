import React from 'react';
import { addQuarters, isThisQuarter, subQuarters } from 'date-fns';

import { Icon } from '@iconify/react';

const ArrowButtons = ({ currentTerm, setCurrentTerm }) => {
    const prevTerm = () => setCurrentTerm(subQuarters(currentTerm, 1));
    const nextTerm = () => setCurrentTerm(addQuarters(currentTerm, 1));

    return (
        <div>
            <Icon icon="bi:arrow-left-circle-fill" style={{ marginRight: 4 }} onClick={prevTerm} />
            {!isThisQuarter(addQuarters(currentTerm, 1)) ? ( //
                <Icon icon="bi:arrow-right-circle-fill" onClick={nextTerm} />
            ) : (
                <Icon icon="bi:arrow-right-circle-fill" style={{ color: '#ccc' }} />
            )}
        </div>
    );
};

export default ArrowButtons;
