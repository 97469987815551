import React from 'react';

const SelectCondition = ({
    value, //
    options,
    targetId,
    handleChange,
}) => {
    return (
        <select
            className="styled-select contents"
            value={value}
            onChange={(e) => {
                handleChange(e, targetId);
            }}
        >
            {options.map((option) => (
                <option key={option.id} value={option.id}>
                    {option.name}
                </option>
            ))}
        </select>
    );
};

export default SelectCondition;
