import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";

const Container = styled.div`
    position: fixed;
    z-index: 999;
    opacity: 0.3;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: black;
`;

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
    width: 100%;
    height: 100%;
    display: block;
    margin: 0 auto;
`;

const Spinner = () => {
    const theme = useContext(ThemeContext);
    return (
        <Container>
            <HashLoader color={ theme.colorDefault } loading={ true } css={ override } size={ 200 } />
        </Container>
    );
};

export default Spinner;