import React, { useEffect, useState } from 'react';

import InputNum from '../components/InputNum';
import styled from 'styled-components';

const TermTable = styled.table`
    border: 1px solid silver;
    border-radius: 3px;
    border-collapse: separate;
    background-color: rgb(236, 236, 236);
    margin-bottom: 10px;
    width: 100%;
`;

const TermTr = styled.tr`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 0.95em;
    text-align: center;
    vertical-align: middle;
    height: 38px;
`;

const TermTd = styled.td`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 0.95em;
    text-align: center;
    vertical-align: middle;
`;

const TermTh = styled.th`
    font-family: '기본체' !important;
    height: 35px;
    font-size: 1.2em;
    text-align: center;
    vertical-align: middle;
    background-color: rgb(212, 212, 212);
`;

const BasicInfo = ({ disabled, termReportData, setTermReportData }) => {
    const [sumHousehold, setSumHousehold] = useState(0); // 세대수 합
    const [sumElder, setSumElder] = useState(0); // 장로 합
    const [sumDeacon, setSumDeacon] = useState(0); // 집사 합

    useEffect(() => {
        setSumHousehold(termReportData?.household_family + termReportData?.household_single);
        setSumElder(termReportData?.elder_men + termReportData?.elder_women);
        setSumDeacon(termReportData?.deacon + termReportData?.deaconess);
    }, [termReportData]);

    return (
        <div>
            <TermTable>
                <thead style={{ height: '38px' }}>
                    <TermTr>
                        <TermTh colSpan={8}>기 본 사 항</TermTh>
                    </TermTr>
                </thead>
                <tbody>
                    <TermTr>
                        <TermTd colSpan={2}>재적 침례교인수</TermTd>
                        <TermTd colSpan={2} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum
                                value={termReportData?.baptize_members} //
                                setValue={setTermReportData}
                                reportKeyName={'baptize_members'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd colSpan={2}>출석관리 교인수</TermTd>
                        <TermTd colSpan={2} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum
                                value={termReportData?.managed_members} //
                                setValue={setTermReportData}
                                reportKeyName={'managed_members'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd rowSpan={2} colSpan={2}>
                            교인세대수
                        </TermTd>
                        <TermTd colSpan={2}>가족</TermTd>
                        <TermTd colSpan={2}>개인</TermTd>
                        <TermTd colSpan={2}>합계</TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={2} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum
                                tail={'세대'} //
                                value={termReportData?.household_family}
                                setValue={setTermReportData}
                                reportKeyName={'household_family'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd colSpan={2} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum
                                tail={'세대'} //
                                value={termReportData?.household_single}
                                setValue={setTermReportData}
                                reportKeyName={'household_single'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd colSpan={2}>{sumHousehold || 0}</TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={2}>구 분</TermTd>
                        <TermTd colSpan={2}>남</TermTd>
                        <TermTd colSpan={2}>여</TermTd>
                        <TermTd colSpan={2}>합계</TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={2}>장로수</TermTd>
                        <TermTd colSpan={2} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum
                                value={termReportData?.elder_men} //
                                setValue={setTermReportData}
                                reportKeyName={'elder_men'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd colSpan={2} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum
                                value={termReportData?.elder_women} //
                                setValue={setTermReportData}
                                reportKeyName={'elder_women'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd colSpan={2}>{sumElder || 0}</TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={2}>집사수</TermTd>
                        <TermTd colSpan={2} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum
                                value={termReportData?.deacon} //
                                setValue={setTermReportData}
                                reportKeyName={'deacon'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd colSpan={2} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum
                                value={termReportData?.deaconess} //
                                setValue={setTermReportData}
                                reportKeyName={'deaconess'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd colSpan={2}>{sumDeacon || 0}</TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={4}>안식일학교 수</TermTd>

                        <TermTd colSpan={3} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum
                                value={termReportData?.sabbath_school} //
                                setValue={setTermReportData}
                                reportKeyName={'sabbath_school'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd>
                            <div className="styled-tail text-center">
                                <span className="tail" style={{ verticalAlign: 'text-top' }}>
                                    개
                                </span>
                            </div>
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={4}>안식일학교 반수/교사수</TermTd>

                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum
                                value={termReportData?.sabbath_school_class} //
                                setValue={setTermReportData}
                                reportKeyName={'sabbath_school_class'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd>
                            <div className="styled-tail text-center">
                                <span className="tail" style={{ verticalAlign: 'text-top' }}>
                                    반
                                </span>
                            </div>
                        </TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.sabbath_school_teacher}
                                setValue={setTermReportData}
                                reportKeyName={'sabbath_school_teacher'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd>
                            <div className="styled-tail text-center">
                                <span className="tail" style={{ verticalAlign: 'text-top' }}>
                                    명
                                </span>
                            </div>
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={4}>교사회 운영횟수</TermTd>

                        <TermTd colSpan={3} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.teacher_association_operation}
                                setValue={setTermReportData}
                                reportKeyName={'teacher_association_operation'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd>
                            <div className="styled-tail text-center">
                                <span className="tail" style={{ verticalAlign: 'text-top' }}>
                                    회
                                </span>
                            </div>
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={4}>평균공부자수</TermTd>

                        <TermTd colSpan={3} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.average_studious}
                                setValue={setTermReportData}
                                reportKeyName={'average_studious'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd>
                            <div className="styled-tail text-center">
                                <span className="tail" style={{ verticalAlign: 'text-top' }}>
                                    명
                                </span>
                            </div>
                        </TermTd>
                    </TermTr>
                </tbody>
            </TermTable>
        </div>
    );
};

export default BasicInfo;
