import React, { useEffect, useRef, useState, useContext } from 'react';
import { ProgressContext, UserContext } from '../../contexts';

import PopupDom from '../../components/PopupDom';
import PopupPostCode from '../../components/PopupPostCode';

import { removeWhitespace, saveUserInfoOnDevice, vaildateEmail, vaildatePassword, validateBirthDay, validatePhoneNumber } from '../../utils/common';
import { getAllSchoolInfo, signup } from '../../data/mysql';
import toast from 'react-hot-toast';
import PopupChurch from '../../components/PopupChurch';
import { DataTypes } from '../../data/Types';
import PopupSchool from '../../components/PopupSchool';

const SignupForm = ({ setSignupStep }) => {
    const { dispatch } = useContext(UserContext);
    const { spinner } = useContext(ProgressContext);

    const emailRef = useRef();
    const addressDetailRef = useRef();
    const majorRef = useRef();

    const [isSendable, setIsSendable] = useState(false); // 모든 Input에 오류가 없으면, 회원 가입 버튼을 활성화시키기 위한 변수
    const [isChurchPopupOpen, setIsChurchPopupOpen] = useState(false); // 교회검색 팝업창 상태 관리(true: 팝업, false: 감춤)
    const [isSchoolPopupOpen, setIsSchoolPopupOpen] = useState(false); // 대학교검색 팝업창 상태 관리(true: 팝업, false: 감춤)
    const [isAddrPopupOpen, setIsAddrPopupOpen] = useState(false); // 주소검색 팝업창 상태 관리(true: 팝업, false: 감춤)

    const [email, setEmail] = useState(''); // *이메일
    const [password, setPassword] = useState(''); // *비밀번호
    const [passwordConfirm, setPasswordConfirm] = useState(''); // *비밀번호 확인

    const [name, setName] = useState(''); // *이름
    const [gender, setGender] = useState('M'); // *남성, 여성
    const [birthDay, setBirthDay] = useState(''); // *생년월일
    const [phoneNumber, setPhoneNumber] = useState(''); // *핸드폰 번호

    const [school, setSchool] = useState(''); // *대학생[ACT]를 위한 항목 (대학교)
    const [major, setMajor] = useState(''); // *대학생[ACT]를 위한 항목 (전공)

    const [churchId, setChurchId] = useState(0);

    const [churchName, setChurchName] = useState(''); // 출신교회
    const [role, setRole] = useState('평신도'); // 교회에서의 역할 (기본: 평신도)

    const [postCode, setPostCode] = useState(''); // 우편번호
    const [address, setAddress] = useState(''); // 주소
    const [addressDetail, setAddressDetail] = useState(''); // 주소 상세

    const [errMessage, setErrMessage] = useState(''); // 에러 메시지

    /* 시작할 때(마운트시) */
    useEffect(() => {
        emailRef.current.focus();
    }, []);

    /* 모든 값(state)가 있고, 어떠한 에러 메시지도 없다면 ... */
    useEffect(() => {
        let _errMessage = '';

        if (!vaildateEmail(email)) {
            _errMessage = '이메일을 확인하세요';
        } else if (!vaildatePassword(password)) {
            _errMessage = '비밀번호는 영문, 숫자, 특수문자를 포함, 6자리 이상 입력하세요.';
        } else if (password !== passwordConfirm) {
            _errMessage = '비밀번호를 동일하게 사용하세요';
        } else if (name.length < 1) {
            _errMessage = '이름을 확인하세요';
        } else if (!validateBirthDay(birthDay)) {
            _errMessage = '생년월일을 확인하세요';
        } else if (!validatePhoneNumber(phoneNumber)) {
            _errMessage = '핸드폰을 확인하세요';
        } else if (removeWhitespace(churchName)?.length <= 0) {
            _errMessage = '출석교회를 확인하세요';
        } else if (role === '청년') {
            if (!school || !major) {
                _errMessage = '학교와 전공을 학인하세요';
            }
        } else {
            _errMessage = '';
        }

        setErrMessage(_errMessage);
    }, [name, email, password, passwordConfirm, phoneNumber, gender, birthDay, churchName, role, school, major]);

    /* [회원가입] 버튼을 활성화 시킨다 */
    useEffect(() => {
        setIsSendable(email && password && passwordConfirm && phoneNumber && gender && birthDay && churchName && role && !errMessage);
    }, [name, email, password, passwordConfirm, phoneNumber, gender, birthDay, churchName, role, errMessage]);

    /* 이메일 체크 */
    const _handleCheckEmail = (e) => {
        setEmail(removeWhitespace(e.target.value));
        e.target.style.border = vaildateEmail(e.target.value) ? '1px solid #ddd' : '1px solid red';
    };

    /* 패스워드 체크 */
    const _handleCheckPassword = (e) => {
        setPassword(removeWhitespace(e.target.value));
        e.target.style.border = vaildatePassword(e.target.value) ? '1px solid #ddd' : '1px solid red';
    };

    /* 패스워드 확인 체크 */
    const _handleCheckPasswordConfirm = (e) => {
        setPasswordConfirm(removeWhitespace(e.target.value));
        e.target.style.border = password === e.target.value ? '1px solid #ddd' : '1px solid red';
    };

    /* 이름 체크 */
    const _handleCheckName = (e) => {
        setName(e.target.value.trim());
        e.target.style.border = e.target.value.trim() ? '1px solid #ddd' : '1px solid red';
    };

    /* 생일 체크 */
    const _handleCheckBirthDay = (e) => {
        let temp = e.target.value;

        if (e.target.value.length === 8) {
            temp = temp.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
        }

        setBirthDay(temp);
        e.target.style.border = validateBirthDay(temp) ? '1px solid #ddd' : '1px solid red';
    };

    /* 핸드폰 체크 */
    const _handleCheckPhoneNumber = (e) => {
        let temp = e.target.value;

        if (temp.length === 10) {
            temp = temp.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        }

        if (temp.length === 13) {
            temp = temp.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
        }

        setPhoneNumber(temp);
        e.target.style.border = validatePhoneNumber(temp) ? '1px solid #ddd' : '1px solid red';
    };

    const _handleSchoolChange = (e) => {
        console.log(e.target.value);
    };

    /* 초점(Focus)을 상세주소로 옮긴다. */
    const _handleFocusAddressDetail = () => {
        addressDetailRef.current.focus();
    };

    /* 초점(Focus)을 전공(학과)으로 옮긴다. */
    const _handleFocusMajor = () => {
        majorRef.current.focus();
    };

    /**
     * 이메일과 비밀번호로 회원가입을 한다.
     */
    const _handleSignupButtonPress = async () => {
        try {
            spinner.start();
            const uid = await signup({ email, password, name, gender, birthDay, phoneNumber, postCode, address, addressDetail, church: churchName, role, school, major });

            if (!uid) throw new Error('회원가입에 실패하였습니다');
            dispatch(uid); // Context를 만들자.

            setSignupStep(DataTypes.SIGNUP_SUCCESS); // 2. 회원가입 성공 페이지
            saveUserInfoOnDevice({ email }); // 클라이언트에 로그인 정보 저장
            toast.success('회원가입이 완료 되었습니다');
        } catch (e) {
            toast.error(e.message);
        } finally {
            spinner.stop();
        }
    };

    return (
        <div id="primary" className="content-area">
            {/* <!--s:#content --> */}
            <div id="content" className="site-content">
                <article>
                    <div className="mem_level">
                        <ul>
                            <li>
                                <span>01</span> 약관동의
                            </li>
                            <li className="hover">
                                <span>02</span> 정보입력
                            </li>
                            <li>
                                <span>03</span> 가입완료
                            </li>
                        </ul>
                    </div>
                    <div id="joinAgree">
                        {/* <!--s: write--> */}
                        <div className="sub_tit">
                            <h2>기본정보입력</h2>
                        </div>
                        <div className="table-write">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>
                                            이메일<span className="red">*</span>
                                        </th>
                                        <td>
                                            <div className="col-sm-6">
                                                <input
                                                    type="text"
                                                    ref={emailRef}
                                                    value={email}
                                                    className="form-control required"
                                                    maxLength="100"
                                                    placeholder="예) you@gmail.com"
                                                    onChange={_handleCheckEmail}
                                                    // returnKeyType='next'
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            비밀번호<span className="red">*</span>
                                        </th>
                                        <td>
                                            <div className="col-sm-6">
                                                <input
                                                    type="password"
                                                    value={password}
                                                    className="form-control required"
                                                    maxLength="15"
                                                    placeholder="영문, 숫자, 특수문자를 포함, 6자리 이상 입력하세요"
                                                    onChange={_handleCheckPassword}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            비밀번호 확인<span className="red">*</span>
                                        </th>
                                        <td>
                                            <div className="col-sm-6">
                                                <input
                                                    type="password"
                                                    value={passwordConfirm}
                                                    className="form-control required"
                                                    maxLength="15"
                                                    placeholder="비밀번호확인을 입력하세요"
                                                    onChange={_handleCheckPasswordConfirm}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            이름<span className="red">*</span>
                                        </th>
                                        <td>
                                            <div className="col-sm-6">
                                                <input type="text" value={name} className="form-control required" maxLength="30" placeholder="이름을 입력하세요" onChange={_handleCheckName} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            성별<span className="red">*</span>
                                        </th>
                                        <td>
                                            <div className="filter-list">
                                                <ul className="filter-list-in">
                                                    <li>
                                                        <p>
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    name="re_sex" // name을 삭제하면 구분이 안됨
                                                                    value="M"
                                                                    checked={gender === 'M' ? 'checked' : ''}
                                                                    onChange={(e) => setGender(e.target.value)}
                                                                />
                                                                <i></i>남자
                                                            </label>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <label>
                                                                <input type="radio" name="re_sex" value="F" checked={gender === 'F' ? 'checked' : ''} onChange={(e) => setGender(e.target.value)} />
                                                                <i></i>여자
                                                            </label>
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            생년월일<span className="red">*</span>
                                        </th>
                                        <td>
                                            <div className="col-sm-6">
                                                <input type="text" value={birthDay} className="form-control required" maxLength="15" placeholder="예) 20220101" onChange={_handleCheckBirthDay} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            핸드폰<span className="red">*</span>
                                        </th>
                                        <td>
                                            <div className="col-sm-6">
                                                <input
                                                    type="text"
                                                    value={phoneNumber}
                                                    className="form-control required"
                                                    maxLength="15"
                                                    placeholder="예) 01012345678"
                                                    onChange={_handleCheckPhoneNumber}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            출석교회<span className="red">*</span>
                                        </th>
                                        <td className="norbtn">
                                            <div className="col-sm-3 mb5">
                                                <input type="text" value={churchName} className="form-control required" maxLength="15" placeholder="출석교회" readOnly="readonly" />
                                            </div>
                                            <div id="churchDom" style={{ position: 'relative', zIndex: 999 }}>
                                                {isChurchPopupOpen && (
                                                    <PopupDom domName="churchDom" style={{ width: '100%' }}>
                                                        <PopupChurch onClose={() => setIsChurchPopupOpen(false)} setChurchName={setChurchName} setChurchId={setChurchId} />
                                                    </PopupDom>
                                                )}
                                            </div>
                                            <button className="btn-black-s" onClick={() => setIsChurchPopupOpen(true)}>
                                                출석교회 검색
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            직분<span className="red">*</span>
                                        </th>
                                        <td>
                                            <div className="filter-list">
                                                <ul className="filter-list-in">
                                                    <li>
                                                        <p>
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    name="re_role"
                                                                    value="평신도"
                                                                    checked={role === '평신도' ? 'checked' : ''}
                                                                    onChange={(e) => setRole(e.target.value)}
                                                                />
                                                                <i />
                                                                평신도
                                                            </label>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <label>
                                                                <input type="radio" name="re_role" value="청년" checked={role === '청년' ? 'checked' : ''} onChange={(e) => setRole(e.target.value)} />
                                                                <i />
                                                                청년(ACT)
                                                            </label>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <label>
                                                                <input type="radio" name="re_role" value="집사" checked={role === '집사' ? 'checked' : ''} onChange={(e) => setRole(e.target.value)} />
                                                                <i />
                                                                집사
                                                            </label>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <label>
                                                                <input type="radio" name="re_role" value="장로" checked={role === '장로' ? 'checked' : ''} onChange={(e) => setRole(e.target.value)} />
                                                                <i />
                                                                장로
                                                            </label>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    name="re_role"
                                                                    value="목회자"
                                                                    checked={role === '목회자' ? 'checked' : ''}
                                                                    onChange={(e) => setRole(e.target.value)}
                                                                />
                                                                <i />
                                                                목회자
                                                            </label>
                                                        </p>
                                                    </li>
                                                    {/* <li><p><label><input type='radio' name='re_role' value='기타' checked={ role === '기타'? 'checked': ''} onChange={(e) => setRole(e.target.value) }/><i/>기타</label></p></li> */}
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                    {role === '청년' && (
                                        <tr>
                                            <th>
                                                대학교<span className="red">*</span>
                                            </th>
                                            <td>
                                                <div className="col-sm-5 mb5">
                                                    <input type="text" value={school} className="form-control required" maxLength="32" placeholder="대학교" readOnly="readonly" />
                                                </div>
                                                <div id="schoolDom" style={{ position: 'relative', zIndex: 999 }}>
                                                    {isSchoolPopupOpen && (
                                                        <PopupDom domName="schoolDom" style={{ width: '100%' }}>
                                                            <PopupSchool onClose={() => setIsSchoolPopupOpen(false)} setSchool={setSchool} onFocus={_handleFocusMajor} />
                                                        </PopupDom>
                                                    )}
                                                </div>
                                                <div className="col-sm-3 mb5">
                                                    <button className="btn-black-s" onClick={() => setIsSchoolPopupOpen(true)}>
                                                        대학교 검색
                                                    </button>
                                                </div>

                                                <div className="col-sm-5 mb5">
                                                    <input
                                                        type="text"
                                                        ref={majorRef}
                                                        value={major}
                                                        className="form-control required"
                                                        maxLength="15"
                                                        placeholder="전공(학과)을 입력하세요"
                                                        onChange={(e) => setMajor(removeWhitespace(e.target.value))}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <th>주소</th>
                                        <td className="norbtn">
                                            <div className="col-sm-3 mb5">
                                                <input type="text" value={postCode} className="form-control" maxLength="6" readOnly="readonly" placeholder="우편번호" />
                                            </div>
                                            {/* 주소검색을 위한 팝업창  */}
                                            <div id="popupDom" style={{ position: 'relative', zIndex: 999 }}>
                                                {isAddrPopupOpen && (
                                                    <PopupDom domName="popupDom" style={{ width: '100%' }}>
                                                        <PopupPostCode
                                                            onClose={() => setIsAddrPopupOpen(false)}
                                                            setPostCode={setPostCode}
                                                            setAddress={setAddress}
                                                            setFocus={_handleFocusAddressDetail}
                                                        />
                                                    </PopupDom>
                                                )}
                                            </div>
                                            <button className="btn-black-s" onClick={() => setIsAddrPopupOpen(true)}>
                                                우편번호검색
                                            </button>
                                            <div className="col-sm-12 mb5">
                                                <input type="text" value={address} className="form-control" placeholder="주소를 검색해주세요" readOnly="readonly" />
                                            </div>
                                            <div className="col-sm-12">
                                                <input
                                                    type="text"
                                                    value={addressDetail}
                                                    ref={addressDetailRef}
                                                    className="form-control"
                                                    placeholder="상세주소를 입력하세요"
                                                    onChange={(e) => setAddressDetail(e.target.value)}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {errMessage && <span className="main-color-important">* {errMessage}</span>}
                        </div>
                        <div></div>
                        {/* <!--//e: write--> */}
                        <p className="text-center">
                            <button type="button" className="btn-yellow-s fnone" onClick={_handleSignupButtonPress} disabled={!isSendable}>
                                회원가입
                            </button>
                        </p>
                    </div>
                </article>
            </div>
            {/* <!--//e: #content --> */}
        </div>
    );
};

export default SignupForm;
