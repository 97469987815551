import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../contexts';
import { DataTypes } from '../../../data/Types';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

const BoardRouter = ({
    groupCode, //
    post,
    isNotice = false,
    CATEGORY_NOTICE_ID,
    isSearching,
    searchKeyword,
    postOffset,
    SEARCH_URL,
    COMMON_URL,
}) => {
    const { user } = useContext(UserContext);
    const [boardName, setBoardName] = useState('common');

    useEffect(() => {
        groupCode === 'reports' ? setBoardName('reports') : isNotice ? setBoardName('notice') : setBoardName('common');
    }, []);

    const boardLink = {
        notice: (
            <Link to={`../../posts/${CATEGORY_NOTICE_ID}/view?id=${post.id}`} style={{ fontWeight: 600 }}>
                {post.title}{' '}
            </Link>
        ),
        reports:
            user.uid === post.uid || user.level >= DataTypes.USER_LEVEL_MANAGER ? (
                <Link to={isSearching ? `../view?${SEARCH_URL}&id=${post.id}` : `../view?${COMMON_URL}&id=${post.id}`}>{post.title}</Link>
            ) : (
                <Link to="#" onClick={() => toast.error('해당 글은 작성자만 볼 수 있습니다')}>
                    {post.title}
                </Link>
            ),
        common: <Link to={isSearching ? `../view?${SEARCH_URL}&id=${post.id}` : `../view?${COMMON_URL}&id=${post.id}`}>{post.title}</Link>,
    };

    return boardLink[boardName];
};

export default BoardRouter;
