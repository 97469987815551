import React, { useState, useEffect, createContext } from 'react';
import { getCategoryGroupAll, getCategoryAll, getGroupCategoryRel, getDefaultSettings } from '../data/mysql';

/**
 * SideMenuContext로 사이드 메뉴를 보여주는 것을 처리하지만,
 * 전역에서 관리해야할 상태값이 많으면 CommonContext 또는 Redux로 갈아타야 하나? - 2021.12.22.(수) @ 부산서부교회 전도사 사택
 * --> Category를 DB(Firebase)에서 가져와야 하므로... 결국 CommonContext로 갈아탐 - 2021.12.30.(금) @부산서부교회 전도사 사택
 */
const CommonContext = createContext({ 
    categoryGroup: [],
    categories: [],
    groupCategoryRel: [],
    sideMenuVisible: false,

    dispatchSideMenuVisible: () => {},
});

const CommonProvider = ({ children }) => {
    const [settings, setSettings] = useState([]);
    const [categoryGroup, setCategoryGroup] = useState([]);
    const [categories, setCategories] = useState([]);
    const [groupCategoryRel, setGroupCategoryRel] = useState([]);
    const [sideMenuVisible, setSideMenuVisible] = useState(false);

    /**
     * DB(mysql)에서 그룹(groups)과 카테고리(categories) 값을 가지고 온다.
     * : 이렇게 처음 시작할 때 가져오면, 다음에 사용될 때는 DB호출을 줄일 수 있다.
     */
    useEffect(() => {

        const init = async () => {
            setSettings(await getDefaultSettings());            // 0. 기본 설정 값을 가지고 온다.
            setCategoryGroup(await getCategoryGroupAll());      // 1. 카테고리를 담고 있는 그룹을 가지고 온다.
            setCategories(await getCategoryAll());              // 2. 카테고리를 가지고 온다.
            setGroupCategoryRel(await getGroupCategoryRel());   // 3. 그룹과 카테고리 연관 정보를 가지고 온다.
        }; init();

    }, []);

    const value = { settings,
                    categoryGroup, categories, groupCategoryRel,
                    sideMenuVisible, dispatchSideMenuVisible: setSideMenuVisible };

    return <CommonContext.Provider value={ value }>{ children }</CommonContext.Provider>
};

export { CommonContext, CommonProvider };