/** @format */

import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { CommonContext, ProgressContext, UserContext } from '../../contexts';

import { getCommaSeparatedNumber, getConvertedByteSize, getFormattedTime, makeUrl } from '../../utils/common';

import { DataTypes } from '../../data/Types';

import queryString from 'query-string';
import Comment from './Comment';
import BoardHeader from './BoardHeader';

import toast from 'react-hot-toast';
import { showDeleteConfirmBox } from '../../components/DeleteConfirmBox';
import { confirmPost, deletePost, getFilesByPostId, getPost, getSnsCountPostId, increasePostHits, increaseSnsHits } from '../../data/mysql';

import 'suneditor/dist/css/suneditor.min.css';

let CATEGORY_COUNSEL_ID = null;

const BoardView = () => {
    const { settings } = useContext(CommonContext);
    const { spinner } = useContext(ProgressContext);
    const { user } = useContext(UserContext);

    const navigate = useNavigate();

    const { categoryId } = useParams();
    const { search } = useLocation();
    const { id, searching, keyword, offset, item } = queryString.parse(search);

    const [post, setPost] = useState({});
    const [sns, setSns] = useState([0, 0, 0, 0]);
    const [files, setFiles] = useState([]);

    // 0. 마운트 할 때
    useEffect(() => {
        /**
         * 1. 글 조회수를 +1 한다. 매니저 이상일 경우 카운트하지 않는다.
         */
        if (!(user.level >= DataTypes.USER_LEVEL_MANAGER)) {
            increasePostHits({ postId: id, category: categoryId });
        }

        CATEGORY_COUNSEL_ID = settings.find((setting) => setting.name === 'category_counsel_id')?.value;

        const init = async () => {
            setPost(await getPost({ postId: id, categoryId }));
            setFiles(await getFilesByPostId({ postId: id }));
            setSns(await getSnsCountPostId({ postId: id }));
            // console.log( await getSnsCountPostId({ postId: id }));
        };
        init();

        const script = document.createElement('script');

        script.src = 'https://developers.kakao.com/sdk/js/kakao.js';
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    /**
     * [관리자 모드] 글을 확인한다.
     */
    const _handleConfirmPost = async (confirm) => {
        try {
            spinner.start();

            await confirmPost({ postId: id, confirm });
            setPost(await getPost({ postId: id, categoryId })); // 다시 수정된 글(Post)을 가져온다.
        } catch (e) {
            console.log(e.code, e.message);
        } finally {
            spinner.stop();
        }
    };

    /**
     * 해당 글을 삭제한다.
     */
    const _handleDeletePost = async () => {
        try {
            spinner.start();

            await deletePost({ postId: id, categoryId });

            navigate(searching ? makeUrl('../list', { searching, item, keyword, offset }) : makeUrl('../list', { offset }));
            toast.success('해당 글이 삭제 되었습니다');
        } catch (e) {
            console.log(e.code, e.message);
        } finally {
            spinner.stop();
        }
    };

    /**
     * 현재의 글을 SNS에 공유한다.
     */
    const _handlePostShare = async ({ postId, sns, title, content }) => {
        let targetURL = null;
        const postURL = document.URL;

        switch (sns) {
            case 'kakaotalk':
                if (window.Kakao) {
                    const kakao = window.Kakao;
                    const imgURL = process.env.REACT_APP_DB_SERVER_URL + files.find((file) => file.type?.split('/')[0] === 'image')?.path;

                    if (!kakao.isInitialized()) {
                        // 중복방지
                        kakao.init(process.env.REACT_APP_KAKAO_KEY);
                    }

                    kakao.Link.sendDefault({
                        objectType: 'feed',
                        content: {
                            title: title, // 보여질 제목
                            description:
                                content
                                    .replace(/<[^>]*>?/g, '')
                                    .replace(/&nbsp;/g, ' ')
                                    .slice(0, 300) + '...', // html 및 &nbsp; 제거
                            imageUrl: imgURL, // 콘텐츠 URL
                            link: {
                                mobileWebUrl: document.URL,
                                webUrl: document.URL,
                            },
                        },
                    });
                }
                break;
            case 'band':
                targetURL = `http://www.band.us/plugin/share?body=${encodeURIComponent(title)}&route=${encodeURIComponent(postURL)}`;

                break;
            case 'twitter':
                targetURL = `http://twitter.com/share?url="${encodeURIComponent(postURL)}&text=${encodeURIComponent(title)}`;
                break;
            case 'facebook':
                targetURL = `http://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(postURL)}`;
                break;
            default:
                console.log('잘못된 접근입니다');
        }

        if (targetURL) {
            window.open(targetURL, '_blank');
        }

        await increaseSnsHits({ postId, sns });
        setSns(await getSnsCountPostId({ postId: id })); // SNS 카운트 가져오기
    };

    return (
        <div id="primary" className="content-area">
            {/* <!--s:#content --> */}
            <div id="content" className="site-content">
                <article>
                    <div>
                        <BoardHeader />

                        {/* [View Content] --------------------------------------------------------- */}
                        <div className="article intro">
                            <section>
                                <div style={{ clear: 'both' }}></div>
                                <div className="table-view">
                                    <div className="view-header">
                                        <div className="view-title">
                                            <h2>
                                                {
                                                    /* 매니저에 의해서 확인된 글은 표시를 해준다. */
                                                    !post.confirm ? '' : <img src={`../../../common/images/confirm.png`} alt="" style={{ width: 23, marginRight: 4 }} />
                                                }
                                                {post?.title}
                                            </h2>
                                        </div>
                                        <div className="view-info">
                                            <span className="write">
                                                <em>작성자</em>
                                                {
                                                    !(CATEGORY_COUNSEL_ID === parseInt(categoryId)) ? (
                                                        <span>{post?.department || post.name}</span>
                                                    ) : (
                                                        // 일반 게시판: 이름 공개
                                                        <span>
                                                            {post.role}
                                                            {post.id}
                                                        </span>
                                                    ) // 익명 게시판(고민상담소): 역할을 공개
                                                }
                                            </span>
                                            <span className="date">
                                                <em>등록일</em>
                                                {post.createdAt === undefined ? <span>-</span> : <span>{getFormattedTime(post?.createdAt)}</span>}
                                            </span>
                                            <span className="hits">
                                                <em className="tit-visible">조회수</em>
                                                <span>{getCommaSeparatedNumber(post?.hits)}</span>
                                            </span>

                                            {/* [공유하기] 소셜 네트워크 연결 */}
                                            <ul className="sns-share">
                                                <li>
                                                    <img
                                                        id="btnKakao"
                                                        onClick={() => {
                                                            // bindKakaoButton({ postId: post.id, title: post.title, content: post?.content });
                                                            _handlePostShare({ postId: post.id, sns: 'kakaotalk', title: post.title, content: post?.content }); // 1) Kakao 공유 버튼 연결
                                                            // _handlePostShare({ postId: post.id, sns: 'kakaotalk', title: post.title, content: post?.content });  // 2) Kakao 공유 연결
                                                        }}
                                                        width={24}
                                                        src="../../../common/images/sns_kakaotalk.png"
                                                        alt="카카오톡"
                                                        title={`카카오톡: ${getCommaSeparatedNumber(sns.kakaotalk)} 회`}
                                                    />
                                                </li>
                                                <li>
                                                    <img
                                                        onClick={() => _handlePostShare({ postId: post.id, sns: 'band', title: post.title })}
                                                        width={24}
                                                        src="../../../common/images/sns_band.png"
                                                        alt="네이버밴드"
                                                        title={`네이버밴드: ${getCommaSeparatedNumber(sns.band)} 회`}
                                                    />
                                                </li>
                                                <li>
                                                    <img
                                                        onClick={() => _handlePostShare({ postId: post.id, sns: 'twitter', title: post.title })}
                                                        width={24}
                                                        src="../../../common/images/sns_twitter.png"
                                                        alt="트위터"
                                                        title={`트위터: ${getCommaSeparatedNumber(sns.twitter)} 회`}
                                                    />
                                                </li>
                                                <li>
                                                    <img
                                                        onClick={() => _handlePostShare({ postId: post.id, sns: 'facebook', title: post.title })}
                                                        width={24}
                                                        src="../../../common/images/sns_facebook.png"
                                                        alt="페이스북"
                                                        title={`페이스북: ${getCommaSeparatedNumber(sns.facebook)} 회`}
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="view-body">
                                        <div dangerouslySetInnerHTML={{ __html: post?.content }}></div>
                                        <div className="attached-images">
                                            {
                                                // 첨부파일에 이미지가 있으면 화면에 출력한다.
                                                files
                                                    .filter((file) => file.type?.split('/')[0] === 'image')
                                                    .map((file) => {
                                                        return (
                                                            <div key={file.id} style={{ paddingTop: 10 }}>
                                                                <img src={process.env.REACT_APP_DB_SERVER_URL + file.path} alt="" />
                                                            </div>
                                                        );
                                                    })
                                            }
                                        </div>
                                    </div>

                                    {files.map((file, index) => {
                                        return (
                                            <div key={file.id} className="view-footer file-area">
                                                <div className="footer-title">
                                                    <i className="xi-file-o" aria-hidden="true"></i> 파일 #{index + 1}
                                                </div>
                                                <div className="footer-content">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                href={`${process.env.REACT_APP_DB_SERVER_URL}/api/downloadFile.php?file_name=${file.name}&file_path=${file.path}`}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                download={true}
                                                            >
                                                                {file.name}
                                                            </a>
                                                            <span className="file-size">( {getConvertedByteSize(file.size)} )</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div>
                                    <p className="text-right mt30" style={{ padding: 10 }}>
                                        {
                                            // 매니저는 이 글의 확인(비확인)을 수정할 수 있다.
                                            user.level >= DataTypes.USER_LEVEL_MANAGER &&
                                                (!post.confirm ? (
                                                    <button className="btn_yellow" title="확인" onClick={() => _handleConfirmPost(1)}>
                                                        확인
                                                    </button>
                                                ) : (
                                                    <button className="btn_light_red" title="확인취소" onClick={() => _handleConfirmPost(0)}>
                                                        확인 취소
                                                    </button>
                                                ))
                                        }
                                        {
                                            // 자신이 작성한 글 외에는 수정 할 수 없다. (예외: 관리자 권한)
                                            (user.uid === post.uid || user.level >= DataTypes.USER_LEVEL_MANAGER) && (
                                                <Link
                                                    to={searching ? makeUrl('../edit', { searching, item, keyword, offset, id: post?.id }) : makeUrl('../edit', { offset, id: post?.id })}
                                                    className="btn_green"
                                                    title="수정"
                                                    style={{ marginLeft: 10 }}
                                                >
                                                    수정
                                                </Link>
                                            )
                                        }
                                        {
                                            // 자신이 작성한 글 외에는 삭제 할 수 없다. (예외: 관리자 권한)
                                            (user.uid === post.uid || user.level >= DataTypes.USER_LEVEL_MANAGER) && (
                                                <button to="#" className="btn_gray" title="삭제" style={{ marginLeft: 10 }} onClick={() => showDeleteConfirmBox(_handleDeletePost)}>
                                                    삭제
                                                </button>
                                            )
                                        }

                                        <Link
                                            to={searching ? makeUrl('../list', { searching, item, keyword, offset }) : makeUrl('../list', { offset })}
                                            className="btn_blue"
                                            title="목록"
                                            style={{ marginLeft: 10 }}
                                        >
                                            목록
                                        </Link>
                                    </p>
                                </div>

                                {/* [Comments] --------------------------------------------------------- */}
                                <Comment post={post} />

                                {/* 완성되지 않은 기능은 숨긴다. ㅋㅋ {renderViewNav()} */}
                            </section>
                        </div>
                    </div>
                    {/* <!-- .entry-content --> */}
                </article>
            </div>
            {/* <!--//e: #content --> */}
        </div>
    );
};

export default BoardView;
