import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CommonContext } from '../../contexts/Common';
import { getTimeByWritingTime } from '../../utils/common';
import { getPostList } from '../../data/mysql';

let CATEGORY_OFFICIAL_ID = null;

const MainOfficialNotes = () => {
    const [officialNotes, setOfficialNotes] = useState([]);
    const { settings } = useContext(CommonContext);

    useEffect(() => {
        CATEGORY_OFFICIAL_ID = settings.find((setting) => setting.name === 'category_official_id')?.value;

        if (CATEGORY_OFFICIAL_ID) {
            const update = async () => {
                setOfficialNotes(await getPostList({ categoryId: CATEGORY_OFFICIAL_ID, limit: 5, offset: 0 }));
            };
            update();
        }
    }, [settings]);

    return (
        <div className="nmcdn_col1 col-sm-4 wow fadeInLeft" data-wow-delay="1.2s">
            <div className="nmcdn_card">
                <div className="nmcdn_in_box">
                    <div className="cdnot_tit">
                        <div className="tit">
                            합회공문
                            <Link to={`board/posts/${CATEGORY_OFFICIAL_ID}/list?offset=0`} className="main_add_more" title="합회공문 더보기"></Link>
                        </div>
                    </div>
                    <div className="cdnot_cont">
                        <div className="tab_div cmmShowHideTabsDiv">
                            <div className="tab_div_in">
                                <div>
                                    <ul className="cdnot_noti">
                                        {officialNotes.map((note) => {
                                            return (
                                                <li key={note.id} className="first txt">
                                                    <Link to={`/board/posts/${CATEGORY_OFFICIAL_ID}/view?id=${note.id}`}>
                                                        {note.title}
                                                        &nbsp;
                                                        {/* {getTimeDiff( parseInt(note.createdAt) ) <= 1 ? (
                                                                <span className='newIcon'>
                                                                    <i className='xi-new' />
                                                                </span>
                                                            ) : (
                                                                ''
                                                        )} */}
                                                    </Link>
                                                    <span className="date">{getTimeByWritingTime(note.createdAt)}</span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainOfficialNotes;
