/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import { DataTypes } from '../../data/Types';

const Footer = () => {
    return (
        <div id='nmFooter' className='nm_footer'>
            {/* <!--s:line03--> */}
            <section className='line03 wow fadeInUp' data-wow-delay='0.4s'>
                {/* <!--s:nm_cdnot--> */}
                <div className='nm_cdnot'>
                    {/* <!-- 패밀리 사이트 링크 : S --> */}
                    <div className='nmcdn_col1 col-sm-12'>
                        <div className='nmcdn_card'>
                            <div className='nmcdn_in_box nmcdn_in_box1'>
                                <div className='nm_crb nm_crb1'>
                                    <div className='container '>
                                        <ul className='lst'>
                                            <li>
                                                <a href='http://www.adventist.or.kr/' className='box' target='_blank' rel='noreferrer'>
                                                    <span className='img'>
                                                        <img src='/common/images/main/fimg01.gif' alt='' />
                                                    </span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='http://sspm.or.kr/' className='box' target='_blank' rel='noreferrer'>
                                                    <span className='img'>
                                                        <img src='/common/images/main/fimg02.gif' alt='' />
                                                    </span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='http://www.ay.or.kr/2010/' className='box' target='_blank' rel='noreferrer'>
                                                    <span className='img'>
                                                        <img src='/common/images/main/fimg03.gif' alt='' />
                                                    </span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='http://sdai.kr/' className='box' target='_blank' rel='noreferrer'>
                                                    <span className='img'>
                                                        <img src='/common/images/main/fimg04.gif' alt='' />
                                                    </span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='http://sekcabcmall.com/' className='box' target='_blank' rel='noreferrer'>
                                                    <span className='img'>
                                                        <img src='/common/images/main/fimg05.gif' alt='' />
                                                    </span>
                                                </a>
                                            </li>
                                        </ul>
                                        <div className='clr'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- 패밀리 사이트 링크 : E --> */}
                </div>
                {/* <!--//e:nm_cdnot--> */}
            </section>
            {/* <!--//e:line03--> */}
            <div className='clr'></div>
            <div className='nm_foot_bt wow fadeInUp' data-wow-delay='0.5s'>
                <div className='in_box clearfix'>
                    <div className='nmfb_lt nanum'>
                        <ul className='lst'>
                            <li className='first'>
                                <Link to='/privacy' className='txt'>
                                    <b>개인정보처리방침</b>
                                </Link>
                            </li>
                            <li>
                                <Link to='/denial' className='txt'>
                                    이메일 무단 수집 거부
                                </Link>
                            </li>
                            <li>
                                <Link to='/license' className='txt'>
                                    정보
                                </Link>
                            </li>
                        </ul>
                        <div className='txts'>
                            (우)42250 대구광역시 수성구 월드컵로 125(시지동 568)
                            <Link to='#' className='nmfb_mail'>
                                <i className='fa fa-envelope'></i>sekcpdt@kuc.or.kr
                            </Link>
                            <a href={`tel:${DataTypes.WEBSITE_INFO_TEL}`} className='nmfb_tel'>
                                <i className='fa fa-phone' />
                                {`${DataTypes.WEBSITE_INFO_TEL}`}
                            </a>
                            <br />
                            Copyright@2021 영남합회 All Rights Reserved.
                        </div>
                    </div>
                    <div className='nmfb_rt'>
                        <ul className='sns_lst'>
                            <li>
                                <a href='http://sekc.dothome.co.kr/' target='_blank' rel='noreferrer'>
                                    이전 홈페이지 바로가기
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
