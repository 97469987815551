import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import OwlCarousel from 'react-owl-carousel';
import { CommonContext } from '../../contexts/Common';
import { getFilesByPostId, getPostList } from '../../data/mysql';
import { DataTypes } from '../../data/Types';

let CATEGORY_NEWS_ID = null; // 교회합회소식

const MainChurchNews = () => {
    const [news, setNews] = useState([]);
    const { settings } = useContext(CommonContext);

    useEffect(() => {
        CATEGORY_NEWS_ID = settings.find((setting) => setting.name === 'category_news_id')?.value;

        if (CATEGORY_NEWS_ID) {
            const update = async () => {
                const list = await getPostList({ categoryId: CATEGORY_NEWS_ID, limit: 10, offset: 0 });

                setNews(
                    await Promise.all(
                        list.map(async (news) => {
                            // console.log(news.id);

                            const files = await getFilesByPostId({ postId: news.id });
                            const img = files.find((file) => file.type?.split('/')[0] === 'image');

                            return { ...news, img: process.env.REACT_APP_DB_SERVER_URL + img?.path };
                        }),
                    ),
                );
            };
            update();
        }
    }, [settings]);

    return (
        <div className="nmcdn_col1 col-sm-8 wow fadeInLeft" data-wow-delay="0.4s">
            <div className="nmcdn_card">
                <div className="nmcdn_in_box">
                    <div className="cdnot_tit">
                        <div className="tit">
                            교회합회소식
                            <Link to={`/board/posts/${CATEGORY_NEWS_ID}/list?offset=0`} className="main_add_more" title="교회합회소식 더보기"></Link>
                        </div>
                    </div>
                    <div className="large-12 columns">
                        {
                            /**
                             * news가 존재하면, OwlCarousel에 데이터를 넣어준다.
                             * 아래와 같이 코딩하지 않으면, OwlCarousel 화면이 사라지는 현상이 발생한다.
                             * : 아마도.. OwlCarousel이 바인딩 될 때, <item>들이 없으면 동작하지 않는 것 같다.
                             */
                            news.length > 0 && (
                                <OwlCarousel
                                    className="owl-theme"
                                    items={2}
                                    margin={10}
                                    nav
                                    loop
                                    responsive={{ 0: { items: 1 }, 600: { items: 2 }, 1000: { items: 2 } }}
                                    navText={['', '']} // 왼쪽 오른쪽 버튼 디자인을 셋팅할 수 있다.
                                >
                                    {news.map((post) => {
                                        return (
                                            <div key={post.id} className="item">
                                                <div className="ProBox">
                                                    <div className="thumbWrap">
                                                        <Link className="thumb" to={`/board/posts/${CATEGORY_NEWS_ID}/view?id=${post.id}`}>
                                                            <img src={post.img} alt="" />
                                                        </Link>
                                                    </div>
                                                    <p>
                                                        <span>{post.title}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </OwlCarousel>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainChurchNews;
