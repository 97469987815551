import React, { useState, useContext, useEffect } from 'react';
import { CommonContext, ProgressContext } from '../../../contexts';
import { subQuarters, startOfQuarter, getQuarter, getYear, format } from 'date-fns';
import { useLocation } from 'react-router-dom';

import ActivityTermReportHeader from './screen/ActivityTermReportHeader';
import ProgressiveBar from '../components/ProgressiveBar';
import AdminActivityTermReportContent from './screen/AdminActivityTermReportContent';

import { getActivityTermReport, getCarryOverResult, getTermReportExcel } from '../../../data/mysql_term';
import toast from 'react-hot-toast';
import SearchingBox from './screen/SearchingBox';

const AdminActivityTermReports = () => {
  const { spinner } = useContext(ProgressContext);
  const { categories } = useContext(CommonContext);
  const { pathname } = useLocation();

  const [disabled, setDisabled] = useState(false);

  const [termReports, setTermReports] = useState([]); // 기말보고 리스트 (보고된 교회 목록)
  const [currentTerm, setCurrentTerm] = useState(subQuarters(new Date(), 1));

  const [churchId, setChurchId] = useState(''); // church ID
  const [churchName, setChurchName] = useState(''); // church Name

  const timeZone = 'Asia/Seoul';
  const KoreaStartOfQuarter = Math.floor(startOfQuarter(currentTerm, { timeZone: timeZone }).getTime() / 1000);

  const termReportByChurchId = termReports.filter((info) => info.c_id === churchId).map((termReport) => termReport);

  useEffect(() => {
    const init = async () => {
      setTermReports(await getActivityTermReport({ dt: KoreaStartOfQuarter }));
    };
    init();
  }, [currentTerm]);

  useEffect(() => {
    const init = async () => {
      try {
        spinner.start();

        const submittedAll = termReports.every((report) => report.is_submitted === 'Y');
        if (submittedAll) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
      } catch (e) {
        toast.error('보고 여부가 확인되지 않습니다');
        console.log(e);
      } finally {
        spinner.stop();
      }
    };
    init();
  }, [termReports]);

  // 모든 보고서를 이월시킨다
  const _handleCarryOverReport = async ({ dt }) => {
    try {
      spinner.start();
      const result = await getCarryOverResult({ dt });
      if (!result) {
        throw new Error();
      } else {
        setTermReports(await getActivityTermReport({ dt }));
      }
    } catch (e) {
      console.log(e);
    } finally {
      window.open(`/adminConfirmTermReport?dt=${dt}`);
      spinner.stop();
    }
  };

  // xlsx 다운로드
  const _handleDownloadXlsx = async ({ dt }) => {
    try {
      spinner.start();
      const result = await getTermReportExcel({ dt });

      if (result) {
        const year = new Date(dt * 1000).getFullYear(); // dt 값을 이용하여 연도를 추출
        const quarter = getQuarter(new Date(dt * 1000)); // dt 값을 이용하여 분기를 추출
        const filename = `${year}년 제 ${quarter}기 보고 정리.xlsx`; // 파일 이름 생성

        const url = window.URL.createObjectURL(new Blob([result])); // Blob 객체를 URL로 변환
        const link = document.createElement('a'); // a 태그 생성
        link.href = url; // href 속성에 URL 할당
        link.setAttribute('download', `${filename}`); // 다운로드할 파일 이름 설정
        document.body.appendChild(link); // body에 a 태그 추가
        link.click(); // 클릭 이벤트 발생
        document.body.removeChild(link); // body에서 a 태그 제거
        window.URL.revokeObjectURL(url); // URL 객체 해제

        toast.success('파일 다운로드에 성공하였습니다.');
      } else {
        throw new Error();
      }
    } catch (e) {
      console.log(e);
      toast.error('파일 다운로드에 실패하였습니다.');
    } finally {
      spinner.stop();
    }
  };

  window.handleAllReportSubmission = async (dt) => {
    setTermReports(await getActivityTermReport({ dt }));
    toast.success(`[${format(new Date(dt * 1000), 'yyyy')}년 제 ${getQuarter(new Date(dt * 1000))}기] 보고가 완료되었습니다.`);
  };

  return (
    <div id="primary" className="content-area">
      {/* <!--s:#content --> */}
      <div id="content" className="site-content">
        <article>
          <div>
            <div className="title-inner">
              <img src="/common/images/title.png" alt="" />
              <h1>{categories.find((category) => category.categoryCode === pathname.split('/')[4])?.categoryName}</h1>
            </div>
            <div style={{ height: 20 }}></div>
            <div style={{ clear: 'both' }}></div>
            <div className="title_subpage_solid_1"></div>
            <div style={{ height: 30 }}></div>

            <div className="container-fluid">
              <div className="row">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <ActivityTermReportHeader //
                      currentTerm={currentTerm}
                      setCurrentTerm={setCurrentTerm}
                    />
                  </div>
                  <div className="panel-body">
                    <ProgressiveBar
                      currentValue={termReports.filter((r) => r?.is_submitted === 'Y')?.length} // 현재 보고서 제출 수
                      maxValue={termReports.length} // 전체 보고서 수
                    />
                  </div>
                </div>

                <div className="panel">
                  <div className="panel-body" style={{ paddingTop: 0 }}>
                    <div className="col-xs-12 col-sm-12 col-md-12 mt5 mb5">{disabled === false ? '* 개별 교회 수정을 완료해주세요.' : '* 이미 이월되었으므로 수정이 불가능합니다.'}</div>
                    <div className="col-xs-12 col-sm-4 col-md-4">
                      <button //
                        className={disabled ? 'styled-button disabled' : 'styled-button report'}
                        onClick={() => {
                          _handleCarryOverReport({ dt: KoreaStartOfQuarter });
                        }}
                        // disabled={disabled}
                      >
                        연합회 보고
                      </button>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4">
                      <button //
                        type="submit"
                        className="styled-button download"
                        onClick={() => {
                          _handleDownloadXlsx({ dt: KoreaStartOfQuarter });
                        }}
                      >
                        xlsx 다운로드
                      </button>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4">
                      <button //
                        className="styled-button save"
                        onClick={() => window.open(`/summingUpTermReport?dt=${KoreaStartOfQuarter}`)}
                      >
                        보고현황 확인
                      </button>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: 30 }}>
                  <SearchingBox //
                    churchId={churchId}
                    setChurchId={setChurchId}
                    churchName={churchName}
                    setChurchName={setChurchName}
                  />
                </div>

                <AdminActivityTermReportContent
                  termReports={churchId ? termReportByChurchId : termReports} //
                  setTermReports={setTermReports}
                  disabled={disabled}
                  KoreaStartOfQuarter={KoreaStartOfQuarter}
                />
              </div>
            </div>
          </div>
        </article>
      </div>
      {/* <!--//e: #content --> */}
    </div>
  );
};

export default AdminActivityTermReports;
