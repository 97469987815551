import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import InputCheckbox from '../components/InputCheckbox';
import InputNum from '../components/InputNum';

const TermTable = styled.table`
    border: 1px solid silver;
    border-radius: 3px;
    border-collapse: separate;
    background-color: rgb(236, 236, 236);
    margin-bottom: 10px;
    width: 100%;
`;

const TermTr = styled.tr`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 0.95em;
    text-align: center;
    vertical-align: middle;
    height: 36px;
`;

const TermTd = styled.td`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 0.95em;
    text-align: center;
    vertical-align: middle;
`;

const TermTh = styled.th`
    font-family: '기본체' !important;
    height: 35px;
    font-size: 1.2em;
    text-align: center;
    vertical-align: middle;
    background-color: rgb(212, 212, 212);
`;

const KidBusiness = ({ disabled, termReportData, setTermReportData }) => {
    return (
        <div>
            <TermTable>
                <thead style={{ height: '38px' }}>
                    <TermTr>
                        <TermTh colSpan={4}>어린이 사업</TermTh>
                    </TermTr>
                </thead>
                <tbody>
                    <TermTr>
                        <TermTd colSpan={2}>어린이관 소유여부</TermTd>
                        <TermTd colSpan={2} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputCheckbox //
                                value={termReportData?.child_room}
                                setValue={setTermReportData}
                                reportKeyName={'child_room'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={2}>어린이 교사수</TermTd>
                        <TermTd colSpan={2} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.child_teacher}
                                setValue={setTermReportData}
                                reportKeyName={'child_teacher'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={2}>어린이 정규안교 개최여부</TermTd>
                        <TermTd colSpan={2} style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputCheckbox //
                                value={termReportData?.child_sabbath_school}
                                setValue={setTermReportData}
                                reportKeyName={'child_sabbath_school'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={2} rowSpan={2}>
                            어린이 저녁예배 실시여부
                        </TermTd>
                        <TermTd>화</TermTd>
                        <TermTd>금</TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputCheckbox //
                                value={termReportData?.child_tus_evening_service}
                                setValue={setTermReportData}
                                reportKeyName={'child_tus_evening_service'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputCheckbox //
                                value={termReportData?.child_fri_evening_service}
                                setValue={setTermReportData}
                                reportKeyName={'child_fri_evening_service'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={2} rowSpan={2}>
                            어린이 성경학교
                        </TermTd>
                        <TermTd>개최수</TermTd>
                        <TermTd>참석자수</TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.child_bible_school}
                                setValue={setTermReportData}
                                reportKeyName={'child_bible_school'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.child_bible_school_attendance}
                                setValue={setTermReportData}
                                reportKeyName={'child_bible_school_attendance'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                </tbody>
            </TermTable>
        </div>
    );
};

export default KidBusiness;
