import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Textarea from '../components/Textarea';

const TermTable = styled.table`
    border: 1px solid silver;
    border-radius: 3px;
    border-collapse: separate;
    background-color: rgb(236, 236, 236);
    margin-bottom: 10px;
    width: 100%;
    height: 320px;
`;

const TermTr = styled.tr`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 0.95em;
    text-align: center;
    vertical-align: middle;
`;

const TermTd = styled.td`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 0.95em;
    text-align: center;
    vertical-align: middle;
`;

const TermTh = styled.th`
    font-family: '기본체' !important;
    height: 35px;
    font-size: 1.2em;
    text-align: center;
    vertical-align: middle;
    background-color: rgb(212, 212, 212);
`;

const ChangedClassMember = ({ disabled, termReportData, setTermReportData }) => {
    const listRef = useRef();

    return (
        <div>
            <TermTable>
                <thead style={{ height: '38px' }}>
                    <TermTr>
                        <TermTh colSpan={2}>감소한 안식일학교 이름 / 반생 이름</TermTh>
                    </TermTr>
                </thead>
                <tbody>
                    <TermTr>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <Textarea
                                ref={listRef}
                                value={termReportData?.reduce_sabbath_school_name}
                                placeholder={'안식일학교 이름을 입력하세요'}
                                setValue={setTermReportData}
                                reportKeyName={'reduce_sabbath_school_name'}
                                disabled={disabled}
                            />
                        </TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <Textarea
                                ref={listRef}
                                value={termReportData?.reduce_sabbath_school_members_name}
                                placeholder={'반생의 이름을 입력하세요'}
                                setValue={setTermReportData}
                                reportKeyName={'reduce_sabbath_school_members_name'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr style={{ height: '38px' }}>
                        <TermTh colSpan={2}>증가한 안식일학교 이름 / 반생 이름</TermTh>
                    </TermTr>
                    <TermTr>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <Textarea
                                ref={listRef}
                                value={termReportData?.increase_sabbath_school_name}
                                placeholder={'안식일학교 이름을 입력하세요'}
                                disabled={disabled}
                                setValue={setTermReportData}
                                reportKeyName={'increase_sabbath_school_name'}
                            />
                        </TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <Textarea
                                ref={listRef}
                                value={termReportData?.increase_sabbath_school_members_name}
                                placeholder={'반생의 이름을 입력하세요'}
                                setValue={setTermReportData}
                                reportKeyName={'increase_sabbath_school_members_name'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                </tbody>
            </TermTable>
        </div>
    );
};

export default ChangedClassMember;
