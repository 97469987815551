import React, { useContext, useEffect, useRef, useState } from 'react';
import { CommonContext, ProgressContext } from '../contexts';
import { getCommaSeparatedNumber } from '../utils/common';
import { getLocationsByKeyword } from '../data/mysql';
import { initClusterer, initKakaoMap, setKakaoMapOptions } from '../utils/map';
import { DataTypes } from '../data/Types';
import { Link } from 'react-router-dom';

let clusterer = null; 
let markers = null;

const Location = () => {
    const searchRef = useRef();

    const { settings } = useContext(CommonContext);

    const { kakao } = window;
    const { spinner } = useContext(ProgressContext);

    const [locations, setLocations] = useState([]);                // 장소(교회, 식당, 풍경, 평신도 업체)
    const [selectedLocation, setSelectedLocation] = useState();
    const [selectedPosition, setSelectedPosition] = useState();
    
    const [total, setTotal] = useState(0);                         // 검색 갯수
    
    const [searchType, setSearchType] = useState('교회');           // 기본 타입은 '교회'
    const [searchArea, setSearchArea] = useState('영남합회');        // 기본 지역은 '영남합회'
    const [searchItem, setSearchItem] = useState('name');          // 기본 항목은 '이름(name)'
    const [searchKeyword, setSearchKeyword] = useState('');        // 검색 키워드
    
    const [map, setMap] = useState(null);
    const [mapType, setMapType] = useState('roadmap');      // 맵 타입(지도: 로드맵, 스카이뷰)

    
    /**
     * 마운트시
     */
    useEffect(() => {
        const init = async () => {
            searchRef.current.focus();

            try {
                spinner.start();
                
                // 1. 카카오 지도를 초기화 한다.
                setMap( initKakaoMap({ container: 'location', lat: DataTypes.MAP_YC_LAT, lng: DataTypes.MAP_YC_LNG}) );
                // 2. 지도 데이터를 불러온다.
                setLocations( await getLocationsByKeyword({ type: searchType, area: searchArea, item: searchItem, keyword: searchKeyword }) );

            } catch (e) {
                console.log(e.message);
            } finally {
                spinner.stop();
            }
        }; init();
    }, []);

    /**
     * 지도가 생성되면
     */
    useEffect(() => {
        if ( map ) {
            setKakaoMapOptions({ map, draggable: true, zoomable: true });    // 지도 옵션
            clusterer = initClusterer({ map, minLevel: 10 });
        }
    }, [map]);

    /**
     * 지도를 다시 그린다.
     */
    useEffect(() => {

        try {
            spinner.start();

            setTotal(locations.length); // 총 갯수를 구한다.

            clusterer?.clear();         // 지도를 초기화

            markers = locations.map( loc => {

                const marker = new kakao.maps.Marker({
                    position : new kakao.maps.LatLng(loc.lat, loc.lng),
                    title: `${loc.id}|${loc.type}|${loc.name}|${loc.addr}|${loc.tel}|${loc.homepage}`,
                    clickable: true,
                });

                kakao.maps.event.addListener(marker, 'click', function() {
                    setSelectedLocation( marker.getTitle() ); // 1|영남합회|대구광역시 수성구 월드컵로 125(시지동 568)|053-654-1767|https://sekc.or.kr
                    setSelectedPosition( marker.getPosition() );
                });

                return marker;
            });

            clusterer?.addMarkers(markers); // 다시 그린다.
        } catch (e) {
            console.log(e.message);
        } finally {
            spinner.stop();
        }
    }, [locations]);


    /**
     * 지도 데이터(locations)가 들어오면 화면을 다시 그린다.
     */
    useEffect(() => {
        
        try {
            spinner.start();

            const update = async () => {
                // 2. 지도 데이터를 불러온다.
                setLocations( await getLocationsByKeyword({ type: searchType, area: searchType === '기관'? '': searchArea || searchArea, item: searchItem, keyword: searchKeyword }) );
                
            }; update();
        } catch (e) {
            console.log(e.message);
        } finally {
            spinner.stop();
        }
    }, [searchType, searchArea]);

    /**
     * 지도타입 컨트롤의 지도 또는 스카이뷰 버튼을 클릭하면 호출되어 지도타입을 바꾸는 함수입니다
     */
    useEffect(() => {
        if ( !map ) return;

        if ( mapType === 'roadmap' ) {
            map.setMapTypeId(kakao.maps.MapTypeId.ROADMAP); 
        } else {
            map.setMapTypeId(kakao.maps.MapTypeId.HYBRID); 
        }
            
    }, [mapType])

    /**
     * 검색한다
     */
    const _handleSearchKeyword = async () => {
        try {
            spinner.start();

            setLocations( await getLocationsByKeyword({ type: searchType, area: searchType === '기관'? '': searchArea, item: searchItem, keyword: searchKeyword }) );
        } catch (e) {
            console.log(e.message);
        } finally {
            spinner.stop();
        }
    }

    return (
        <div className='location_wrap'>
            <div className='map_filter'>
                <div className='map_total'>전체: <strong>{ getCommaSeparatedNumber(total) }</strong></div>
                <div className='map_blank'/>
                <div className='map_area'>
                    <select className='form-control' 
                            onChange={(e) => setSearchArea(e.target.value)}
                            defaultValue={'영남합회'}
                    >
                        <option value=''>전체</option>
                        <option value='동중한합회'>동중한합회</option>
                        <option value='서중한합회'>서중한합회</option>
                        <option value='영남합회'>영남합회</option>
                        <option value='충청합회'>충청합회</option>
                        <option value='호남합회'>호남합회</option>
                        <option value='제주특별자치도'>제주특별자치도</option>
                    </select>
                </div>
                <div className='map_search'>
                    <select className='form-control' 
                            onChange={(e) => setSearchItem(e.target.value)}
                            defaultValue={'name'}
                    >
                        <option value='name'>이름</option>
                        <option value='addr'>주소</option>
                    </select>
                </div>
                <div className='input-group fl-right map_search'>
                    <input
                        type='text'
                        className='form-control'
                        ref={ searchRef }
                        onChange={(e) => setSearchKeyword(e.target.value.trim())}
                        onSubmit={ _handleSearchKeyword }
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                _handleSearchKeyword();
                                e.preventDefault();
                            }
                        }}
                        placeholder='검색할 키워드'
                    />
                    <span className='input-group-btn'>
                        <button className='btn btn-primary btn-sm' type='button'
                                onClick={ _handleSearchKeyword }
                        >
                            검색
                        </button>
                    </span>
                </div>
            </div>
            <div className="map_wrap">
                <div id='location' style={{ width: '100%', height: 500 }}></div>
                {/* <!-- 지도타입 컨트롤 div 입니다 -->
                <div className='custom_typecontrol radius_border'>
                    <span id='btnRoadmap' className={ mapType === 'roadmap'? 'selected_btn': 'default_btn' } onClick={() => setMapType('roadmap')}>지도</span>
                    <span id='btnSkyview' className={ mapType === 'skyview'? 'selected_btn': 'default_btn' } onClick={() => setMapType('skyview')}>스카이뷰</span>
                </div>
                {/* <!-- 지도 확대, 축소 컨트롤 div 입니다 --> 
                <div className='custom_zoomcontrol radius_border'> 
                    <span onClick={ _handleZoomIn }><img src='https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/ico_plus.png' alt='확대' /></span>  
                    <span onClick={ _handleZoomOut }><img src='https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/ico_minus.png' alt='축소'/></span>
                </div> */}
                <div className="category">
                    <ul>
                        <li id="coffeeMenu" className={ searchType === '교회'? 'menu_selected': ''} onClick={() => setSearchType('교회') }>
                            <img width={30} src='/common/images/map_church.png' alt=''/>
                            <span className='selected'>재림교회</span>
                        </li>
                        <li id="storeMenu" className={ searchType === '식당'? 'gubun menu_selected': 'gubun'} onClick={() => setSearchType('식당') }>
                            <img width={30} src='/common/images/map_food.png' alt=''/>
                            <span>추천식당</span>
                        </li>
                        <li id="storeMenu" className={ searchType === '풍경'? 'gubun menu_selected': 'gubun'} onClick={() => setSearchType('풍경') }>
                            <img width={30} src='/common/images/map_hotspot.png' alt=''/>
                            <span>자연풍경</span>
                        </li>
                        <li id="storeMenu" className={ searchType === '사업장'? 'gubun menu_selected': 'gubun'} onClick={() => setSearchType('사업장') }>
                            <img width={30} src='/common/images/map_shop.png' alt=''/>
                            <span>사업장</span>
                        </li>
                        <li id="storeMenu" className={ searchType === '기관'? 'gubun menu_selected': 'gubun'} onClick={() => setSearchType('기관') }>
                            <img width={30} src='/common/images/map_public.png' alt=''/>
                            <span>학교/기관</span>
                        </li>
                    </ul>
                </div>
            </div>
            
            <div className='location_info'>
                <div className={selectedLocation? 'picture': 'picture blank'}>
                    <img src={`/common/images/map_${
                        { 
                            교회: 'church',
                            식당: 'food',
                            풍경: 'hotspot',
                            사업장: 'shop',
                            기관: 'public'
                        }[selectedLocation?.split('|')[1]]
                    }.png`} alt='' />
                    
                </div>
                <div className='detail'>
                    <span className='name'>{ selectedLocation?.split('|')[2] || '-'}</span>
                    <span className='addr'>{ selectedLocation?.split('|')[3] || '장소를 클릭하시면 상세정보를 볼 수 있습니다'}</span>
                    <span className='etc'>
                        { selectedLocation?.split('|')[4] && (<>
                            {selectedLocation.split('|')[4]}
                            <a href={`tel:${selectedLocation.split('|')[4]}`}>
                                [연결]
                            </a>
                        </>
                        )}
                        &nbsp;&nbsp; 
                        { selectedLocation?.split('|')[5] && (
                            <a href={selectedLocation?.split('|')[5]} target='_blank' rel='noreferrer' title={selectedLocation.split('|')[5]}>
                                [홈페이지]
                            </a>
                        )}
                    </span>
                </div>
                <div className='shortcut'>
                    { selectedPosition? (
                        <a href={`https://map.kakao.com/link/to/${selectedLocation?.split('|')[2]},${selectedPosition?.getLat()},${selectedPosition?.getLng()}`} target='_blank' rel="noreferrer">
                            <img style={{ margin: 4, width: 40 }} src='/common/images/kakao_map.png' alt=''/>
                            <br /><span className='small-text'>바로가기</span>
                        </a>
                    ): (
                        <img style={{ margin: 4, width: 40, filter: 'grayscale(100%)'}} src='/common/images/kakao_map.png' alt=''/>
                    )}
                    
                </div>
            </div>

            <div className='location_info_detail'>
                &nbsp;
            </div>

            <div className='location_update_info'>
                { settings.find(set => set.name === 'map_update_info').value }
            </div>

            <div className='map_data_board'>
                <Link to={`/board/posts/${ settings.find(set => set.name === 'category_mapdata_id')?.value}/list`}>
                    <button type="button" 
                        className="btn btn-info btn-sm"
                        // onClick={ }
                        >
                        지도 데이터 추가/수정/문의
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default Location;