import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './AdminConfirmTermReport.module.css';

import queryString from 'query-string';
import toast from 'react-hot-toast';
import { format, getQuarter } from 'date-fns';
import { useImmer } from 'use-immer';

import { getTermReportDataForConfirm, putConfirmedTermReportData, putConfirmedTermReportDataByReportId } from '../../../../data/mysql_term';
import { showConfirmBox } from '../../../../components/ConfirmBox';
import { ProgressContext } from '../../../../contexts';
import InputNum from '../components/InputNum';

import { getChurchSectionList } from '../../../../data/mysql_term';
import { getCommaSeparatedNumber } from '../../../../utils/common';
import AdminConfirmSections from './AdminConfirmSections';
import PopupHeader from '../../../../components/modules/headers/PopupHeader';

const reportKeys = [
  'tract_and_booklet',
  'child_evangelical_meeting',
  'teen_evangelical_meeting',
  'pastor_evangelical_meeting',
  'small_group_evangelical_meeting',
  'evangelist_group_evangelical_meeting',
  'other_evangelical_meeting',
  'child_bible_school',
  'child_bible_school_attendance',
  'baptized_child',
  'baptized_youth',
  'baptized_student',
  'baptized_senior',
];

const AdminConfirmTermReport = () => {
  const { spinner } = useContext(ProgressContext);
  const { search } = useLocation();

  const { dt } = queryString.parse(search);
  const currentTerm = new Date(parseInt(dt * 1000));

  const [originalReportData, updateOriginalReportData] = useImmer([]);
  const [filteredReportData, updateFilteredReportData] = useImmer([]);

  const [fixedReportData, setFixedReportData] = useState(null);

  useEffect(() => {
    spinner.start();
    getTermReportDataForConfirm({ dt })
      .then((report) => {
        updateOriginalReportData(report);
        updateFilteredReportData([]);

        setFixedReportData(report.find((r) => r.church_name === '영남합회'));
      })
      .finally(() => spinner.stop());
  }, []);

  useEffect(() => {
    setFixedReportData(originalReportData.find((r) => r.church_name === '영남합회'));
  }, [originalReportData]);

  const handleCheckedSections = (checkedSections) => {
    updateFilteredReportData(originalReportData.filter((r) => checkedSections.includes(r.sect_id)));
  };

  /**
   * 연합회 보고서를 제출한다.
   */
  const handleSubmitReport = async () => {
    try {
      spinner.start();
      const result = await putConfirmedTermReportData({ dt, reportData: originalReportData });

      if (!result) {
        throw new Error();
      }

      window.opener.handleAllReportSubmission(dt);
      window.close();
    } catch (e) {
      console.log(e);
      toast.error('보고에 실패했습니다');
    } finally {
      spinner.stop();
    }
  };

  /**
   * 해당 보고서를 저장한다.
   * @param {object} report
   * @returns
   */
  const handleSingleReportSave = async (report) => {
    try {
      spinner.start();
      const result = await putConfirmedTermReportDataByReportId(report);

      if (!result) {
        throw new Error();
      } else {
        toast.success(`[${report.church_name}]의 보고서가 저장되었습니다`);
      }
    } catch (e) {
      console.log(e);
      toast.error(`저장에 실패했습니다`);
    } finally {
      spinner.stop();
    }
  };

  return (
    <div style={{ width: 'auto', margin: 40, marginBottom: 150 }}>
      <div className="site-content">
        <div style={{ marginTop: 20 }}>{''}</div>
        <div>
          <PopupHeader onClick={() => showConfirmBox('연합회 보고를 하시겠습니까?', `연합회 보고를 하시면, 더이상 기말보고를 수정할 수 없습니다.`, handleSubmitReport, 'delete-button')} />
          <div className="container-fluid">
            <div className="row">
              <div className="panel">
                <div className="panel-heading">
                  <h3 className="between">
                    {format(currentTerm, 'yyyy')}년 제 {getQuarter(currentTerm)}기 보고
                  </h3>
                </div>
                <AdminConfirmSections onSelectionChange={handleCheckedSections} /> {/* 교회 섹션 */}
                <div className={styles.container}>
                  <table style={{ width: '100%', marginBottom: 5 }}>
                    <thead>
                      <tr>
                        <th className={styles.header} rowSpan={2}>
                          교회
                        </th>
                        <th className={styles.header_bottom0} width="7%">
                          전도지
                        </th>
                        <th className={styles.header} colSpan={6} width="33%">
                          전도회
                        </th>
                        <th className={styles.header} colSpan={2} width="13%">
                          어린이 성경학교
                        </th>
                        <th className={styles.header} colSpan={4} width="23%">
                          수침자
                        </th>
                        <th className={styles.header_right0} rowSpan={2} width="5%">
                          비고
                        </th>
                      </tr>
                      <tr>
                        {['소책자', '어린이', '청소년', '목회자', '소그룹', '전도단', '기타', '개최', '참석자', '장년', '청년', '중고생', '어린이'].map((title, index) => (
                          <th className={styles.header} key={index}>
                            {title}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {/* 고정 데이터(영남합회) ------- */}
                      <tr className={styles.data_fixed}>
                        <td className={styles.data_fixed_text}>[{fixedReportData?.church_name}]</td>
                        {fixedReportData &&
                          reportKeys.map((key) => (
                            <td className={styles.data} key={key}>
                              <InputNum targetId={fixedReportData.id} value={fixedReportData[key]} setValue={updateOriginalReportData} keyName={key} />
                            </td>
                          ))}
                        <td className={styles.data_right0}>
                          <button className={styles.send_button} onClick={() => handleSingleReportSave(fixedReportData)}>
                            수정
                          </button>
                        </td>
                      </tr>

                      {/* 필터된 데이터 ------- */}
                      {filteredReportData?.map((filtered, index) => (
                        <tr
                          key={filtered.id}
                          style={{
                            backgroundColor: index % 2 === 0 ? '#efefef' : 'transparent',
                          }}
                        >
                          <td className={styles.data_text}>
                            {index + 1}. {filtered.church_name}
                            <span className={styles.data_text_small}>({filtered.section_name?.replace('지구', '')})</span>
                          </td>
                          {reportKeys.map((key) => (
                            <td className={`${styles.data} ${styles.readonly}`} key={key}>
                              {getCommaSeparatedNumber(filtered[key])}
                              {/* <InputNum targetId={filtered.id} value={filtered[key]} setValue={updateOriginalReportData} keyName={key} /> */}
                            </td>
                          ))}
                          <td className={styles.data_right0}></td>
                        </tr>
                      ))}

                      {/* 필터된 데이터 합계 ------- */}
                      {filteredReportData.length > 0 && (
                        <tr>
                          <td className={`${styles.data_total} ${styles.data_total_filtered} ${styles.readonly}`}>합계</td>
                          {reportKeys.map((key) => (
                            <td className={`${styles.data_total} ${styles.data_total_filtered} ${styles.readonly}`} key={key}>
                              {getCommaSeparatedNumber(
                                //
                                filteredReportData.reduce((total, item) => total + (item[key] || 0), 0),
                              )}
                            </td>
                          ))}
                          <td className={`${styles.data_total} ${styles.data_total_filtered_right}`}>&nbsp;</td>
                        </tr>
                      )}

                      {/* 전체 데이터 합계 ------- */}
                      <tr>
                        <td className={`${styles.data_total} ${styles.data_total_all}`}>전체 합계</td>
                        {reportKeys.map((key) => (
                          <td className={`${styles.data_total} ${styles.data_total_all}`} key={key}>
                            {getCommaSeparatedNumber(
                              //
                              originalReportData?.reduce((total, item) => total + (item[key] || 0), 0),
                            )}
                          </td>
                        ))}
                        <td className={`${styles.data_total} ${styles.data_total_all_right}`}>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminConfirmTermReport;
