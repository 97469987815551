import React from 'react';

const InputGroup = ({ title, value, label, disabled, reportColumnName, setReportMxData }) => {
    const _handleOnChange = ({ target }) => {
        setReportMxData((report) => ({
            ...report, //
            [reportColumnName]: parseInt(target.value),
        }));
    };

    return (
        <div className="styled-group">
            <span className="styled-left">{title}</span>
            <select //
                className="styled-right"
                aria-label={label}
                onChange={_handleOnChange}
                value={value}
                disabled={disabled}
            >
                {Array.from(Array(100).keys(), (_, i) => {
                    return <option key={i}>{_}</option>;
                })}
            </select>
        </div>
    );
};

export default InputGroup;
