import React from 'react';

const InputCheckbox = ({ value, setValue, reportKeyName, disabled }) => {
    const _handleChangeChecked = () => {
        setValue((report) => ({
            ...report,
            [reportKeyName]: !value,
        }));
    };

    return (
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <input type="checkbox" value={value || false} checked={value || false} onChange={_handleChangeChecked} disabled={disabled} />
        </div>
    );
};

export default InputCheckbox;
