import React from 'react';
import styled from 'styled-components';

const Center = styled.div`
    display: flex;
    justify-content: center;
`;

const Warning = () => {
    return (
        <div id='content-wrap' className='container'>
            <Center>
                <img src='/common/images/caution.png' alt=''/>                
            </Center>
        </div>    
    );
};

export default Warning;