import React from 'react';
import { getTimeDiff } from '../../../utils/common';

const NewIcon = ({ createdAt }) => {
    return getTimeDiff(createdAt) <= 1 ? (
        <span className="newIcon">
            &nbsp;
            <i className="xi-new" />
        </span>
    ) : (
        ''
    );
};

export default NewIcon;
