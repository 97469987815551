import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { CommonContext } from '../contexts';
import { isLogin } from '../data/firebase';

/**
 * 기본 인증 처리를 한다. URL 직접 접근을 막는다.
 * 
 * 1. 공개 페이지   ---> restricted(제한 X)  ---> 로그인 여부와 관계 없이 모든 페이지 열람
 *               ---> restricted(제한 O)  ---> 로그인 후 접근할 수 없는 페이지(로그인, 회원가입, 비밀번호 재설정)
 * 2. 비공개 페이지  --->  로그인 사용자만 접근가능한 페이지(회원 정보 수정)
 * 
 * @param isPublic 공개 여부(공개, 비공개)
 * @param restricted 제한
 */
const BaseAuth = ({ isPublic, restricted }) => {
    const { categories } = useContext(CommonContext);
    const location = useLocation();
    

    let result = '';

    if ( isPublic ) {
        result = isLogin() && restricted ? <Navigate to='/' /> : <Outlet />;
    } else {
        result = !isLogin() ? <Navigate to={`/member/unauthorized/${categories?.find(c => c.categoryCode === 'login')?.id}/login`} state={{ from: location }} /> : <Outlet />;
    }

    return result;
};

export default BaseAuth;