import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TermReportList from '../components/TermReportList';

const StyledContainer = styled.div`
    margin: 30px 0 0 0;
    display: flex;
    flex-direction: column;
`;

const StyledATag = styled.a`
    font-family: '기본체';
`;

const AdminActivityTermReportContent = ({ termReports, disabled, KoreaStartOfQuarter }) => {
    const [stage, setStage] = useState(0); // 제출하지 않은 교회, 제출한 교회 구분

    useEffect(() => {
        disabled ? setStage(1) : setStage(0);
    }, [disabled]);

    return (
        <StyledContainer>
            <div>
                <ul className="nav nav-tabs">
                    <li role="presentation" className={stage === 0 ? 'active' : ''}>
                        <StyledATag
                            href="#"
                            onClick={() => {
                                setStage(0);
                            }}
                        >
                            {`미제출(${termReports?.filter((r) => r.is_submitted !== 'Y').length})`}
                        </StyledATag>
                    </li>
                    <li role="presentation" className={stage === 1 ? 'active' : ''}>
                        <StyledATag
                            href="#"
                            onClick={() => {
                                setStage(1);
                            }}
                        >
                            {`완료(${termReports?.filter((r) => r.is_submitted === 'Y').length})`}
                        </StyledATag>
                    </li>
                    <li role="presentation" className={stage === 2 ? 'active' : ''}>
                        <StyledATag
                            href="#"
                            onClick={() => {
                                setStage(2);
                            }}
                        >
                            {`xlsx 다운로드(${termReports?.length})`}
                        </StyledATag>
                    </li>
                </ul>
            </div>

            {stage === 0 && <TermReportList termReports={termReports} stage={stage} conditionFunc={(r) => r.is_submitted !== 'Y'} KoreaStartOfQuarter={KoreaStartOfQuarter} />}
            {stage === 1 && <TermReportList termReports={termReports} stage={stage} conditionFunc={(r) => r.is_submitted === 'Y'} KoreaStartOfQuarter={KoreaStartOfQuarter} />}
            {stage === 2 && <TermReportList termReports={termReports} stage={stage} conditionFunc={(r) => r} KoreaStartOfQuarter={KoreaStartOfQuarter} />}
        </StyledContainer>
    );
};

export default AdminActivityTermReportContent;
