import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CommonContext } from '../../contexts';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { getPublicCalendars } from '../../data/mysql';


const InfoSchedule = () => {
    const { categories } = useContext(CommonContext);
    const { pathname } = useLocation();

    const [calendars, setCalendars] = useState([]);

    /**
     * 마운트시
     */
    useEffect(() => {
        const init = async() => {
            const temp = await getPublicCalendars();
            
            setCalendars( temp.map(cal => ({...cal, checked: true})) );
        }; init();
    }, []);

    /**
     * 캘린더를 체크할 때
     */
    const _handleCheckboxChange = ({ calendarId, checkValue }) => {
        setCalendars( calendars.map( cal => cal.id === calendarId? {...cal, checked: checkValue }: cal ) );
    }

    return (
        <div id='primary' className='content-area'>
            {/* <!--s:#content --> */}
            <div id='content' className='site-content'>
                <article>
                    <div>
                        <div className='title-inner'>
                            <img src='/common/images/title.png' alt='' />
                            <h1>{ categories.find( category => category.categoryCode === pathname.split('/')[4])?.categoryName}</h1>
                        </div>
                        <div style={{ height: 20 }}></div>
                        <div style={{ clear: 'both' }}></div>
                        <div className='title_subpage_solid_1'></div>
                        <div style={{ height: 30 }}></div>

                        <div className='calendar-filter'>
                            <ul>
                            { calendars.map( (cal, idx) => {
                                return <li key={ idx }>
                                    <div className='calendar-item'>
                                        <input type='checkbox' 
                                                style={{ accentColor: cal.color }}
                                                defaultChecked={ cal.checked } 
                                                onChange={(e) => _handleCheckboxChange({ calendarId: cal.id, checkValue: e.target.checked }) }
                                                />
                                        <span className='item-name'>{ cal.name }</span>
                                    </div>
                                </li>;
                            }) }
                            </ul>
                        </div>

                        <FullCalendar
                            height={1000}
                            googleCalendarApiKey={process.env.REACT_APP_GOOGLE_CALENDAR_API_KEY}
                            plugins={[ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, googleCalendarPlugin ]}
                            // dateClick={ _handleDateClick}
                            initialView="dayGridMonth"
                            // weekNumbers={true}   // 몇주차?
                            weekends={true}
                            headerToolbar={{
                                left: 'prev,next today',
                                center: 'title',
                                right: 'dayGridMonth,timeGridWeek,listWeek'        
                            }}
                            locale='ko'
                            buttonText={{
                                today : "오늘",
                                month : '월',
                                week : '주',
                                day : '일',
                                list: '목록'
                            }}
                            eventSources={ calendars.filter( cal => cal.checked === true ) }

                            // 현재 시간
                            nowIndicator={true}
                            now={ new Date() }

                            // 업무시간
                            businessHours={{
                                daysOfWeek: [ 1, 2, 3, 4, 5 ], // Monday - Thursday
                                startTime: '9:00', // a start time (10am in this example)
                                endTime: '18:00', // an end time (6pm in this example)
                            }}
                        />
                    </div>
                    {/* <!-- .entry-content --> */}
                </article>
            </div>
            {/* <!--//e: #content --> */}
        </div>
    );
};

export default InfoSchedule;