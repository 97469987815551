import React from 'react';
import { Link } from 'react-router-dom';

const AccessDenied = () => {
    return (
        <div id='primary' className='content-area'>
            <div id='content' className='site-content'>
                <article>
                    <div className='join_wrap'>
                        <div className='finish'>
                            <p className='tit'>해당 게시판에<br/><span>접근 권한</span>이 없습니다.</p>
                            <p className='txt'>
                                자세한 사항은 영남합회로 문의해 주세요.<br/>
                            </p>
                        </div>
                        <p className='text-center mt30'>
                            <Link className='btn-black-s fnone' 
                                    style={{ marginLeft: 10 }}
                                    title='처음으로'
                                    to='/'
                            >처음으로</Link>
                        </p>
                    </div>

                </article>
            </div>
            {/* <!--//e: #content --> */}
        </div>
    );
};

export default AccessDenied;