import React, { useState, useContext, useEffect } from 'react';
import { CommonContext, ProgressContext } from '../../../contexts';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';

import { createChurchInfo, deleteChurchData, editChurchInfo, getAreasData, getChurchesData, getChurchesInfoById } from '../../../data/mysql_churchManager';
import { TemporaryChurchInfo } from './data/data';
import ChurchesTable from './screen/ChurchesTable';

const AdminChurchEditor = () => {
    const { spinner } = useContext(ProgressContext);
    const { categories } = useContext(CommonContext);
    const { pathname } = useLocation();

    const [areas, setAreas] = useState([]); // 나중에 areas를 수정하기 위해 필요할 수 있어서 스테이트로 관리
    const [selectedArea, setSelectedArea] = useState(1);
    const [churchData, setChurchData] = useState([]);
    const [churchInfo, setChurchInfo] = useState(TemporaryChurchInfo); // 교회 생성을 위한 정보를 임시로 담아두기 위해 필요한 state

    const [refresh, setRefresh] = useState(false); // 백엔드와 통신하는 함수 동작시 화면 리렌더링

    // 1. 지역정보와 교회의 리스트를 가져온다.
    useEffect(() => {
        if (churchData === [] || areas === []) return;

        const init = async () => {
            const areasData = await getAreasData({});
            const openedArea = areasData.filter((area) => area.public === 'Y').map((area) => area);
            setAreas(openedArea);
            const churchesData = await getChurchesData({});
            const result = churchesData
                .map((info) => ({
                    ...info, //
                    newName: info.name, // 수정할 교회의 이름
                    homepage: info.homepage ? info.homepage : 'https://sekc.or.kr',
                    isAddrPopupOpen: false, // 주소검색창을 연다.
                    isOpen: false,
                    isAgency: info.agency === 1 ? true : false,
                }))
                .sort((a, b) => a.name.localeCompare(b.name));
            setChurchData(result);
        };

        init();
    }, [refresh]);

    /**
     * 해당 교회의 데이터를 제거한다.
     */
    const _handleDelChurch = async ({ churchName, churchId }) => {
        let result = false;
        try {
            spinner.start();
            setRefresh(true);

            const response = await deleteChurchData({ churchId });

            if (response) {
                result = true;
                toast.success(`[${churchName}]의 데이터가 삭제되었습니다.`);
            }
        } catch (e) {
            toast.error(`[${churchName}]의 데이터를 삭제하는데 실패했습니다.`);
            console.log(e);
        } finally {
            spinner.stop();
            setRefresh(false);
        }
    };
    /**
     * 해당 교회의 데이터를 수정하기 전의 상태로 돌린다.
     * 교회를 생성중인 경우 값을 지운다.
     */
    const _handleResetChurch = async ({ churchId }) => {
        let result = false;
        try {
            spinner.start();
            setRefresh(true);

            const response = await getChurchesInfoById({ churchId });

            console.log(response);
            if (response) {
                churchData
                    .filter((church) => church.id === churchId)
                    .map(() => {
                        return response;
                    });

                result = true;
                toast.success('초기화 되었습니다');
            }
        } catch (e) {
            toast.error('초기화에 실패하였습니다');
            console.log(e);
        } finally {
            spinner.stop();
            setRefresh(false);

            churchData
                .filter((church) => church.id === churchId)
                .map((church) => {
                    return { ...church, isOpen: true };
                });
        }
    };
    /**
     * 해당 교회의 변경된 데이터를 저장한다.
     */
    const _handleEditChurch = async ({
        churchId, //
        confId,
        areaId,
        name,
        tel,
        addr,
        homepage,
        type,
        state,
        agency,
    }) => {
        let result = false;
        try {
            spinner.start();
            setRefresh(true);

            const response = await editChurchInfo({ churchId, confId, areaId, name, tel, addr, homepage, type, state, agency });

            if (response) {
                result = true;
                toast.success(`[${name}]의 데이터가 저장되었습니다.`);
            }
        } catch (e) {
            toast.error(`[${name}]의 데이터를 저장하는데 실패했습니다.`);
            console.log(e);
        } finally {
            spinner.stop();
            setRefresh(false);
        }
    };

    /**
     * 새로운 교회의 데이터를 생성한다.
     */
    const _handleCreateChurch = async ({
        confId, //
        areaId,
        name,
        tel,
        addr,
        homepage,
        type,
        state,
        agency,
    }) => {
        let result = false;
        try {
            spinner.start();
            setRefresh(true);

            const response = await createChurchInfo({ confId, areaId, name, tel, addr, homepage, type, state, agency });

            if (response) {
                result = true;
                toast.success(`[${name}]의 데이터가 생성되었습니다.`);
                setChurchInfo(TemporaryChurchInfo);
            }
        } catch (e) {
            toast.error(`[${name}]의 데이터를 생성하는데 실패했습니다.`);
            console.log(e);
        } finally {
            spinner.stop();
            setRefresh(false);
        }
    };

    return (
        <div id="primary" className="content-area">
            {/* <!--s:#content --> */}
            <div id="content" className="site-content">
                <article>
                    <div>
                        <div className="title-inner">
                            <img src="/common/images/title.png" alt="" />
                            <h1>{categories.find((category) => category.categoryCode === pathname.split('/')[4])?.categoryName}</h1>
                        </div>
                        <div style={{ height: 20 }}></div>
                        <div style={{ clear: 'both' }}></div>
                        <div className="title_subpage_solid_1" />
                        <div style={{ height: 30 }}></div>

                        <div className="container-fluid">
                            <div className="row">
                                <div className="panel panel-default">
                                    <div className="panel-heading">교회 생성 / 정보 수정</div>

                                    <div className="panel-body">
                                        <div className="col-xs-12 col-sm-12 col-md-12">
                                            <ChurchesTable
                                                areas={areas}
                                                churchData={churchData} //
                                                setChurchData={setChurchData}
                                                selectedArea={selectedArea}
                                                setSelectedArea={setSelectedArea}
                                                churchInfo={churchInfo} // 교회생성을 위한 state
                                                setChurchInfo={setChurchInfo} // 교회생성을 위한 state
                                                handleDelChurch={_handleDelChurch}
                                                handleEditChurch={_handleEditChurch}
                                                handleCreateChurch={_handleCreateChurch}
                                                handleResetChurch={_handleResetChurch}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
            {/* <!--//e: #content --> */}
        </div>
    );
};
export default AdminChurchEditor;
