import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';

import TermReportHeaderLeft from '../../../termReport/components/TermReportHeaderLeft';
import TermReportHeaderMiddle from '../../../termReport/components/TermReportHeaderMiddle';
import TermReportHeaderRight from '../../../termReport/components/TermReportHeaderRight';
import TermReportBody from '../../../termReport/components/TermReportBody';
import { getActivityTermReport } from '../../../../data/mysql_term';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledATag = styled.a`
    font-family: '기본체';
`;

const STAGE_NAME = ['영남합회(교회)', '영남합회(기관)', '영남합회'];

const SummingUpClassify = ({ summingUpData, stage, setStage, dt }) => {
    const [churchList, setChurchList] = useState([]);

    useEffect(() => {
        const init = async () => {
            const result = await getActivityTermReport({ dt });

            let stagingChurchList = [];
            // [[교회], [기관], [합계] 를 생성
            stagingChurchList.push(result.filter((church) => church.agency === 0)); // 교회
            stagingChurchList.push(result.filter((church) => church.agency === 1)); // 기관
            stagingChurchList.push(result.filter((church) => church.agency === 0 || church.agency === 1)); // 합계
            setChurchList(stagingChurchList);
        };
        init();
    }, []);

    return (
        <div className="row">
            <div className="panel">
                <div className="panel-heading">
                    <h3>{`${summingUpData[stage]?.termReportInfo?.dt ? format(new Date(summingUpData[stage]?.termReportInfo?.dt * 1000), 'yyyy년 제 Q기 보고') : ''}`}</h3>
                </div>
                <div className="panel-body" style={{ paddingBottom: 0 }}>
                    <StyledContainer>
                        <div>
                            <ul className="nav nav-tabs">
                                <li role="presentation" className={stage === 0 ? 'active' : ''}>
                                    <StyledATag
                                        href="#"
                                        onClick={() => {
                                            setStage(0);
                                        }}
                                    >
                                        교회 합계
                                    </StyledATag>
                                </li>
                                <li role="presentation" className={stage === 1 ? 'active' : ''}>
                                    <StyledATag
                                        href="#"
                                        onClick={() => {
                                            setStage(1);
                                        }}
                                    >
                                        기관 합계
                                    </StyledATag>
                                </li>
                                <li role="presentation" className={stage === 2 ? 'active' : ''}>
                                    <StyledATag
                                        href="#"
                                        onClick={() => {
                                            setStage(2);
                                        }}
                                    >
                                        전체 합계
                                    </StyledATag>
                                </li>
                            </ul>
                        </div>

                        <div
                            className="container"
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                textAlign: 'center',
                                marginTop: 15,
                            }}
                        >
                            <div>
                                <table>
                                    <thead>
                                        <tr>
                                            <td>
                                                <TermReportHeaderLeft termData={summingUpData[stage]} />
                                            </td>
                                            <td />
                                            <td className="text-center text-vertical-middle">
                                                <TermReportHeaderMiddle reportedFrom={STAGE_NAME[stage]} />
                                            </td>
                                            <td />
                                            <td className="text-vertical-bottom">
                                                <TermReportHeaderRight //
                                                    signLeft={'선교부장'}
                                                    signMiddle={'총 무'}
                                                    signRight={'합회장'}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={5} height={'5px'} />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <TermReportBody //
                                            termData={summingUpData[stage]}
                                            isAdmin={true}
                                            churchList={churchList[stage]}
                                        />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </StyledContainer>
                </div>
            </div>
        </div>
    );
};

export default SummingUpClassify;
