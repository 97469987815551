import React from 'react';
import { useDrag } from 'react-dnd';
import { Icon } from '@iconify/react';
import toast from 'react-hot-toast';

const DragItem = ({
    id, //
    name,
    currentChurchId,
    currentChurchName,
    pastorInfo,
    setPastorInfo,
    className,
    handleRemoveRel,
    handleCreateRel,
}) => {
    const handleClick = async (item) => {
        try {
            const result = await handleRemoveRel({ p_id: item.id, c_id: item.currentChurchId });

            if (result) {
                setPastorInfo(pastorInfo?.map((pastor) => (pastor.id === item.id ? { ...pastor, c_id: null } : pastor)));
                toast.success(`[${item.name}] 목사가 보관함으로 이동되었습니다.`);
            } else {
                toast.error(`[${item.name}] 목사가 보관함으로 이동중 에러가 발생했습니다.`);
            }
        } catch (e) {
            toast.error(`[${item.name}] 목사가 보관함으로 이동중 에러가 발생했습니다.`);
        }
    };

    const [{ isDragging }, drag] = useDrag({
        type: 'CARD',
        item: { id, name, currentChurchId, currentChurchName },
        end: async (item, monitor) => {
            const dropResult = monitor.getDropResult();

            if (dropResult) {
                const { c_id, churchName } = dropResult || {};

                // item.currentChurchId : 이름표가 현재 위치한 교회
                // c_id : 이름표가 이동할 교회
                if (item.currentChurchId === c_id) {
                    setPastorInfo(pastorInfo?.map((pastor) => (pastor.id === item.id ? { ...pastor, c_id } : pastor)));
                } else if (item.currentChurchId && !c_id) {
                    try {
                        const result = await handleRemoveRel({ p_id: item.id, c_id: item.currentChurchId });
                        if (result) {
                            setPastorInfo(pastorInfo?.map((pastor) => (pastor.id === item.id ? { ...pastor, c_id: null } : pastor)));
                            toast.success(`[${item.name}] 목사가 보관함으로 이동되었습니다.`);
                        }
                    } catch (e) {
                        toast.error(`[${item.name}] 목사가 보관함으로 이동에 실패하였습니다.`);
                    }
                } else if (!item.currentChurchId && c_id) {
                    try {
                        const result = await handleCreateRel({ p_id: item.id, c_id });
                        if (result) {
                            setPastorInfo(pastorInfo?.map((pastor) => (pastor.id === item.id ? { ...pastor, c_id } : pastor)));
                            toast.success(`[${item.name}] 목사가 [${churchName}]으로 이동되었습니다.`);
                        }
                    } catch (e) {
                        toast.error(`[${item.name}] 목사가 [${churchName}]으로 이동하는데 실패했습니다.`);
                    }
                } else if (item.currentChurchId && c_id) {
                    try {
                        const result = await handleRemoveRel({ p_id: item.id, c_id: item.currentChurchId });
                        if (result) {
                            //? 왜 아래처럼 작성하면 토스트가 나오지 않는가?
                            // (await handleCreateRel({ p_id: item.id, c_id })) &&
                            //     setPastorInfo(pastorInfo?.map((pastor) => (pastor.id === item.id ? { ...pastor, c_id } : pastor))) &&
                            //     toast.success(`[${item.name}] 목사가 [${churchName}]으로 이동되었습니다.`);
                            const result = await handleCreateRel({ p_id: item.id, c_id });
                            if (result) {
                                setPastorInfo(pastorInfo?.map((pastor) => (pastor.id === item.id ? { ...pastor, c_id } : pastor)));
                                toast.success(`[${item.name}] 목사가 [${churchName}]으로 이동되었습니다.`);
                            } else {
                                toast.error(`[${item.name}] 목사가 [${item.currentChurchName}]에서 이동하는데 실패했습니다.`);
                            }
                        }
                    } catch (e) {
                        toast.error(`[${item.name}] 목사가 [${item.currentChurchName}]에서 이동하는데 실패했습니다.`);
                    }
                }
            }
        },

        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    return (
        <div ref={drag} className={className} style={{ display: 'flex', opacity: isDragging ? 0.4 : 1 }}>
            <div style={{ width: currentChurchId ? '90%' : '100%' }}>{name}</div>
            {currentChurchId ? (
                <button //
                    className="dragItemButton"
                    onClick={() => handleClick({ id, name, currentChurchId, currentChurchName })}
                >
                    <Icon
                        icon="bi:x-circle-fill" //
                        color="rgba(153, 153, 153, 0.6)"
                    />
                </button>
            ) : null}
        </div>
    );
};

export default DragItem;
