import React from 'react';

import Header from '../common/Header';
import MobileMenu from '../common/MobileMenu';
import SubHeaderBar from '../common/SubHeaderBar';
import Footer from '../common/Footer';

const License = () => {
    return (
        <>
            <Header />
            <MobileMenu /> {/* 사이드 메뉴(Mobile Menu): 사이즈를 줄였을 때, 왼쪽 사이드에 붙는 메뉴 */}
            <SubHeaderBar /> {/* 홈 > 합회소개 > 합회소개 */}
            <div id='content-wrap' className='container'>
                {/* <!--s:#primary --> */}
                <div id='primary' className='content-area content-area1'>
                    {/* <!--s:#content --> */}
                    <div id='content' className='site-content'>
                        <article>
                            <div>
                                <div className='title-inner'>
                                    <img src='../common/images/title.png' alt='' />
                                    <h1>License</h1>
                                </div>
                                <div style={{ height: 20 }}></div>
                                <div style={{ clear: 'both' }}></div>
                                <div className='title_subpage_solid_1'></div>
                                <div style={{ height: 30 }}></div>

                                <div className='article intro'>
                                    <div id='txt' className='txt_list'>
                                        <ul>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- .entry-content --> */}
                        </article>
                    </div>
                    {/* <!--//e: #content --> */}
                </div>
                {/* <!--//e: #primary --> */}

                <div className='clr'></div>
            </div>
            <Footer />
        </>
    );
};

export default License;
