/** @format */

export const DataTypes = {
    USER_LEVEL_BLOCKED: -1, // 차단된 사용자
    USER_LEVEL_UNIDENTIFIED: 0, // 미인증 사용자
    USER_LEVEL_IDENTIFIED: 10, // 인증을 거친 사용자(가입 승인)
    USER_LEVEL_PASTOR: 20, // 목회자
    USER_LEVEL_STAFF: 30, // 게시판에 공지
    USER_LEVEL_MANAGER: 40, // 회원 관리, 메인 롤링 슬라이드 제어
    USER_LEVEL_DIRECTOR: 50, // 디지털 영남합회(통계 추이, 분석)
    USER_LEVEL_MASTER: 100, // 모든 권한

    // WEBSITE_INFO_TEL: '010-3159-4137',
    WEBSITE_INFO_TEL: '053-654-1767',

    // 게시판 options
    BOARD_DEFAULT_PAGING_COUNT: 20,

    //? local DB
    // DB_SERVER_URL: 'https://sekc.or.kr/sekc/backend',
    // BACKEND_SERVER_URL: 'http://localhost:4000',
    // CMS_BACKEND_SERVER_URL: 'http://localhost:3004',
    //? 배포시 살리 코드
    // DB_SERVER_URL: 'https://sekc.or.kr/sekc/backend',
    // BACKEND_SERVER_URL: 'https://data.sekc.or.kr',
    // CMS_BACKEND_SERVER_URL: 'https://data.cms.sekc.or.kr',

    // 회원가입
    SIGNUP_AGREEMENT: 10,
    SIGNUP_SIGNUP_FORM: 20,
    SIGNUP_SUCCESS: 50,

    // 지도
    MAP_YC_LAT: 35.84046644244145, // 영남합회 위도
    MAP_YC_LNG: 128.68456644680592, // 영남합회 경도
};
