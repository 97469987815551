import React, { useContext } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CommonContext } from '../../contexts';
import { MENU } from '../../data/Menu';

const SubHeaderBar = () => {
    const { groupCode } = useParams();
    const { categoryId } = useParams();
    const { categoryGroup, categories } = useContext(CommonContext);

    const { pathname } = useLocation();

    return (
        <section>
        {/* <!--s:page-header--> */}
        <header className='page-header wpex-supports-mods'>
            {/* <!--s:page-header-inner--> */}
            <div className='page-header-inner container clr'>
                <nav className='site-breadcrumbs wpex-clr position-under-title'>
                    <div className='breadcrumb-trail'>
                        <span>
                            <Link to='/' title='영남합회' className='trail-begin'>
                                <span itemProp='title'>
                                    <span className='fa fa-home'></span>
                                    <span className='display-none'>Home</span>
                                </span>
                            </Link>
                        </span>
                        { 
                            /* 그룹코드(gourpCode)가 있다는 것은 이 페이지가 게시판(board)라는 것을 의미한다. 
                                : grupCode는 게시판 주소(/board/:groupCode/:categoryId)에서만 들어온다.
                            */
                            !groupCode ? (
                                <>
                                <span className='sep'>
                                    <span className='fa fa-angle-right' />
                                </span> { MENU.find( item => item.categoryId === pathname.split('/')[2] )?.groupName }
                                <span className='sep'>
                                    <span className='fa fa-angle-right' />
                                </span> { MENU.find( item => item.categoryId === pathname.split('/')[2] )?.categoryName }
                                
                                </>
                            ) : (
                                <>
                                <span className='sep'>
                                    <span className='fa fa-angle-right' />
                                </span> { categoryGroup.find( group => group.groupCode === groupCode )?.groupName }
                                <span className='sep'>
                                    <span className='fa fa-angle-right'></span>
                                </span> { categories.find( category => category.id === parseInt(categoryId) )?.categoryName }
                                </>
                            ) 
                        }
                    </div>
                </nav>
            </div>
            {/* <!--//e:page-header-inner--> */}
        </header>
        {/* <!--//e:page-header--> */}
    </section>
    );
};

export default SubHeaderBar;