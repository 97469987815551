import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { format } from 'date-fns';

import { getReportDxData, getReportMxDataByReportId, getReportPrevData } from '../../data/mysql';
import { getCommaSeparatedNumber, getCommaSeparatedNumber2, saveReportPDF } from '../../utils/common';

import { useContext } from 'react';
import { ProgressContext } from '../../contexts';
import toast from 'react-hot-toast';

const StyledTd = styled.td`
    padding: 2px;
    border: 0.5px solid black;
    font-family: '기본체' !important;
    font-size: 0.8em;
    line-height: 15px;
`;
const StyledTd2 = styled.td`
    padding: 2px;
    font-family: '기본체' !important;
    font-size: 0.8em;
    line-height: 15px;
`;
const StyledTdGray = styled.td`
    padding: 2px;
    border: 0.5px solid black;
    font-family: '기본체' !important;
    font-size: 0.8em;
    text-align: center;
    background-color: #ccc;
    line-height: 15px;

    @media print {
        padding: 2px;
        border: 0.5px solid black;
        font-family: '기본체' !important;
        font-size: 0.8em;
        text-align: center;
        background-color: #ccc !important;
        line-height: 15px;
    }
`;

const StyledSpan = styled.span`
    font-family: '기본체' !important;
    font-size: 0.8em;
`;
const StyledSpan2 = styled.span`
    font-family: '기본체' !important;
    font-size: 0.8em;
    padding-top: 2px;
    margin-left: 3px;
    margin-right: 7px;
`;
const StyledH1 = styled.h1`
    margin-top: 10px;
    font-family: '조선일보명조' !important;
    font-size: 2em;
`;
const StyledH3 = styled.h3`
    font-family: '기본체' !important;
    font-size: 1em;
    padding: 10px 0 5px 0;
    font-weight: bolder;
    /* font-weight: 700; */
`;
const StyledDiv = styled.div`
    font-family: '조선일보명조' !important;
    font-size: 0.8em;
    line-height: 15px;
`;

const MonthlyReportPrint = () => {
    const { spinner } = useContext(ProgressContext);
    const { reportId, exportMethod } = useParams();

    const [reportMxData, setReportMxData] = useState(); // 월(月) 데이터
    const [reportDxData, setReportDxData] = useState(); // 일(日) 데이터
    const [reportPrevData, setReportPrevData] = useState(); // 이전 데이터(지난달 서적 부수, 수침자 누계)

    useEffect(() => {
        const init = async () => {
            setReportMxData(await getReportMxDataByReportId({ reportId }));
            setReportDxData(await getReportDxData({ reportId }));
        };
        init();
    }, []);

    useEffect(() => {
        if (!reportMxData || !reportMxData.p_id || !reportMxData.dt) return;

        const update = async () => {
            setReportPrevData(
                await getReportPrevData({
                    reportId, // 레포트 번호
                    pastorId: reportMxData.p_id, // 목회자 아이디
                    dt: reportMxData.dt,
                }),
            );
        };
        update();
    }, [reportMxData]);

    useEffect(() => {
        if (!reportMxData || !reportDxData || !reportPrevData) return;

        exportMethod === 'print' && _handlePrintReport();
        exportMethod === 'download' && _handleDownloadReport();
    }, [reportMxData, reportDxData, reportPrevData]);

    /**
     * 레포트를 인쇄(print)한다.
     */
    const _handlePrintReport = () => {
        window.print();
        // window.close();
    };

    /**
     * 다운로드 한다.
     */
    const _handleDownloadReport = async () => {
        if (!reportMxData) return;

        try {
            spinner.start();
            await saveReportPDF({ tableId: 'reportTable', fileName: `월말보고서_${format(new Date(reportMxData?.dt * 1000), 'yyyy년MM월')}.pdf` });
            toast.success('파일이 다운로드 되었습니다.');
        } catch (e) {
            console.log(e);
            toast.error('파일 다운도르에 실패하였습니다.');
        } finally {
            spinner.stop();
            // window.close();
        }
    };

    return (
        <div
            className="container"
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
            }}
        >
            <div
                id="reportTable"
                style={{
                    maxWidth: 900,
                    paddingTop: 20,
                }}
            >
                <StyledH1>목회 활동 월말 보고서</StyledH1>
                <StyledH3> {`${reportMxData?.dt ? format(new Date(reportMxData?.dt * 1000), 'yyyy년 M월') : ''}`}</StyledH3>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <table style={{ minWidth: 550 }}>
                        <tbody>
                            <tr>
                                <StyledTd2 colSpan={12} style={{ textAlign: 'right' }}>
                                    {reportMxData?.church_name} {reportMxData?.pastor_name}
                                </StyledTd2>
                            </tr>
                            <tr>
                                <StyledTd rowSpan={3} className="text-center" style={{ verticalAlign: 'middle' }}>
                                    일<br />
                                    <br />자
                                </StyledTd>
                                <StyledTd rowSpan={3} className="text-center" style={{ verticalAlign: 'middle' }}>
                                    요<br />
                                    <br />일
                                </StyledTd>
                                <StyledTd colSpan={2} className="text-center">
                                    방 문
                                </StyledTd>
                                <StyledTd rowSpan={3} className="text-center" style={{ verticalAlign: 'middle' }}>
                                    성<br />경<br />교<br />수
                                </StyledTd>
                                <StyledTd rowSpan={3} className="text-center" style={{ verticalAlign: 'middle' }}>
                                    설<br />교<br />한<br />수
                                </StyledTd>
                                <StyledTd rowSpan={3} className="text-center" style={{ verticalAlign: 'middle' }}>
                                    활동내역 및 특기
                                </StyledTd>
                                <StyledTd rowSpan={3} className="text-center" style={{ padding: 10, verticalAlign: 'middle' }}>
                                    교통비
                                </StyledTd>
                                <StyledTdGray className="text-center">구도자</StyledTdGray>
                                <StyledTdGray colSpan={3} className="text-center">
                                    정기간행물
                                </StyledTdGray>
                            </tr>
                            <tr>
                                <StyledTd rowSpan={2} className="text-center" style={{ verticalAlign: 'middle' }}>
                                    구<br />도<br />자
                                </StyledTd>
                                <StyledTd rowSpan={2} className="text-center" style={{ verticalAlign: 'middle' }}>
                                    신<br />
                                    <br />자
                                </StyledTd>
                                <StyledTd>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <StyledSpan>접촉중</StyledSpan>
                                        <StyledSpan>{reportMxData?.seekers_contact} 명</StyledSpan>
                                    </div>
                                </StyledTd>
                                <StyledTd className="text-center">서적명</StyledTd>
                                <StyledTd className="text-center">지난달(기)</StyledTd>
                                <StyledTd className="text-center">이번달(기)</StyledTd>
                            </tr>
                            <tr>
                                <StyledTd>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <StyledSpan>공부자</StyledSpan>
                                        <StyledSpan>{reportMxData?.seekers_study} 명</StyledSpan>
                                    </div>
                                </StyledTd>
                                <StyledTd className="text-center">교회지남</StyledTd>
                                <StyledTd className="text-right">{getCommaSeparatedNumber(reportPrevData?.books_compas || 0)} 부</StyledTd>
                                <StyledTd className="text-right">{getCommaSeparatedNumber(reportMxData?.books_compas || 0)} 부</StyledTd>
                            </tr>
                            {Array.from(Array(32).keys(), (_, i) => {
                                return (
                                    <tr key={i}>
                                        {31 > i ? (
                                            <>
                                                <StyledTd className="text-center">{i + 1}</StyledTd>
                                                <StyledTd className="text-center">
                                                    {reportMxData?.dt &&
                                                        { 0: '일', 1: '월', 2: '화', 3: '수', 4: '목', 5: '금', 6: '토' }[
                                                            new Date(format(new Date(reportMxData.dt * 1000), `yyyy-MM-${i + 1}`)).getDay() // 요일을 구해온다.
                                                        ]}
                                                </StyledTd>
                                                <StyledTd className="text-center">{reportDxData?.visits?.find((v) => parseInt(format(new Date(v.dt * 1000), `d`)) === i + 1)?.seekers_cnt}</StyledTd>
                                                <StyledTd className="text-center">{reportDxData?.visits?.find((v) => parseInt(format(new Date(v.dt * 1000), `d`)) === i + 1)?.members_cnt}</StyledTd>
                                                <StyledTd className="text-center">{reportDxData?.teaches?.find((v) => parseInt(format(new Date(v.dt * 1000), `d`)) === i + 1)?.cnt}</StyledTd>
                                                <StyledTd className="text-center">{reportDxData?.sermons?.find((v) => parseInt(format(new Date(v.dt * 1000), `d`)) === i + 1)?.cnt}</StyledTd>
                                                <StyledTd>{reportDxData?.activities?.find((v) => parseInt(format(new Date(v.dt * 1000), `d`)) === i + 1)?.content}</StyledTd>
                                                <StyledTd className="text-right">
                                                    {getCommaSeparatedNumber2(reportDxData?.transportation?.find((v) => parseInt(format(new Date(v.dt * 1000), `d`)) === i + 1)?.cost || 0)}
                                                </StyledTd>
                                                {i === 0 && (
                                                    <>
                                                        <StyledTd>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <StyledSpan>침례반</StyledSpan>
                                                                <StyledSpan>{getCommaSeparatedNumber(reportMxData?.seekers_baptism || 0)} 명</StyledSpan>
                                                            </div>
                                                        </StyledTd>
                                                        <StyledTd className="text-center">장년교과</StyledTd>
                                                        <StyledTd className="text-right">{getCommaSeparatedNumber(reportPrevData?.books_senior || 0)} 부</StyledTd>
                                                        <StyledTd className="text-right">{getCommaSeparatedNumber(reportMxData?.books_senior || 0)} 부</StyledTd>
                                                    </>
                                                )}
                                                {i === 1 && (
                                                    <>
                                                        <StyledTdGray className="text-center">구도자명단</StyledTdGray>
                                                        <StyledTd className="text-center">청소년교과</StyledTd>
                                                        <StyledTd className="text-right">{getCommaSeparatedNumber(reportPrevData?.books_teen || 0)} 부</StyledTd>
                                                        <StyledTd className="text-right">{getCommaSeparatedNumber(reportMxData?.books_teen || 0)} 부</StyledTd>
                                                    </>
                                                )}
                                                {i > 1 && (
                                                    <>
                                                        <StyledTd className="text-center">{reportMxData?.seekers_list && reportMxData?.seekers_list[i - 2]}</StyledTd>
                                                    </>
                                                )}
                                                {i === 2 && (
                                                    <>
                                                        <StyledTd className="text-center">유년교과</StyledTd>
                                                        <StyledTd className="text-right">{getCommaSeparatedNumber(reportPrevData?.books_junior || 0)} 부</StyledTd>
                                                        <StyledTd className="text-right">{getCommaSeparatedNumber(reportMxData?.books_junior || 0)} 부</StyledTd>
                                                    </>
                                                )}
                                                {i === 3 && (
                                                    <>
                                                        <StyledTd className="text-center">교인세대수</StyledTd>
                                                        <StyledTd colSpan={2} className="text-right">
                                                            {getCommaSeparatedNumber(reportMxData?.members_family || 0)} 세대
                                                        </StyledTd>
                                                    </>
                                                )}
                                                {i === 4 && (
                                                    <>
                                                        <StyledTd className="text-center">교인수</StyledTd>
                                                        <StyledTd colSpan={2} className="text-right">
                                                            {getCommaSeparatedNumber(reportMxData?.members || 0)} 명
                                                        </StyledTd>
                                                    </>
                                                )}
                                                {i === 5 && (
                                                    <>
                                                        <StyledTd className="text-center">안교생수</StyledTd>
                                                        <StyledTd colSpan={2} className="text-right">
                                                            {getCommaSeparatedNumber(reportMxData?.members_school || 0)} 명
                                                        </StyledTd>
                                                    </>
                                                )}
                                                {i === 6 && (
                                                    <>
                                                        <StyledTdGray colSpan={3} className="text-center">
                                                            수침자
                                                        </StyledTdGray>
                                                    </>
                                                )}
                                                {i === 7 && (
                                                    <>
                                                        <StyledTd className="text-center">이번달</StyledTd>
                                                        <StyledTd colSpan={2} className="text-right">
                                                            {getCommaSeparatedNumber(reportMxData?.baptism || 0)} 명
                                                        </StyledTd>
                                                    </>
                                                )}
                                                {i === 8 && (
                                                    <>
                                                        <StyledTd className="text-center">누계</StyledTd>
                                                        <StyledTd colSpan={2} className="text-right">
                                                            {getCommaSeparatedNumber(reportPrevData?.baptism || 0)} 명
                                                        </StyledTd>
                                                    </>
                                                )}
                                                {i === 9 && (
                                                    <>
                                                        <StyledTdGray colSpan={3}>잃은양</StyledTdGray>
                                                    </>
                                                )}
                                                {i === 10 && (
                                                    <>
                                                        <StyledTd className="text-center">확인수</StyledTd>
                                                        <StyledTd colSpan={2} className="text-right">
                                                            {getCommaSeparatedNumber(reportMxData?.lost_confirm || 0)} 명
                                                        </StyledTd>
                                                    </>
                                                )}
                                                {i === 11 && (
                                                    <>
                                                        <StyledTd className="text-center">접촉수</StyledTd>
                                                        <StyledTd colSpan={2} className="text-right">
                                                            {getCommaSeparatedNumber(reportMxData?.lost_contact || 0)} 명
                                                        </StyledTd>
                                                    </>
                                                )}
                                                {i === 12 && (
                                                    <>
                                                        <StyledTd className="text-center">회복수</StyledTd>
                                                        <StyledTd colSpan={2} className="text-right">
                                                            {getCommaSeparatedNumber(reportMxData?.lost_recover || 0)} 명
                                                        </StyledTd>
                                                    </>
                                                )}
                                                {i === 13 && (
                                                    <>
                                                        <StyledTdGray colSpan={3}>예배평균출석지수</StyledTdGray>
                                                    </>
                                                )}
                                                {i === 14 && (
                                                    <>
                                                        <StyledTd className="text-center">화요일</StyledTd>
                                                        <StyledTd colSpan={2} className="text-right">
                                                            {getCommaSeparatedNumber(reportMxData?.attendance_tue || 0)} 명
                                                        </StyledTd>
                                                    </>
                                                )}
                                                {i === 15 && (
                                                    <>
                                                        <StyledTd className="text-center">금요일</StyledTd>
                                                        <StyledTd colSpan={2} className="text-right">
                                                            {getCommaSeparatedNumber(reportMxData?.attendance_fri || 0)} 명
                                                        </StyledTd>
                                                    </>
                                                )}
                                                {i === 16 && (
                                                    <>
                                                        <StyledTd className="text-center">안식일</StyledTd>
                                                        <StyledTd colSpan={2} className="text-right">
                                                            {getCommaSeparatedNumber(reportMxData?.attendance_sat || 0)} 명
                                                        </StyledTd>
                                                    </>
                                                )}
                                                {i === 17 && (
                                                    <>
                                                        <StyledTdGray bgcolor="#FF0000" colSpan={3}>
                                                            통신란
                                                        </StyledTdGray>
                                                    </>
                                                )}
                                                {i === 18 && (
                                                    <>
                                                        <StyledTd colSpan={3} rowSpan={15}>
                                                            {reportMxData?.request.split('\n').map((line) => {
                                                                return <p style={{ margin: 0 }}>{line}</p>;
                                                            })}
                                                        </StyledTd>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <StyledTd className="text-center">계</StyledTd>
                                                <StyledTd className="text-center"></StyledTd>
                                                <StyledTd className="text-center">{reportDxData?.visits?.reduce((prev, curr) => prev + curr.seekers_cnt, 0)}</StyledTd>
                                                <StyledTd className="text-center">{reportDxData?.visits?.reduce((prev, curr) => prev + curr.members_cnt, 0)}</StyledTd>
                                                <StyledTd className="text-center">{reportDxData?.teaches?.reduce((prev, curr) => prev + curr.cnt, 0)}</StyledTd>
                                                <StyledTd className="text-center">{reportDxData?.sermons?.reduce((prev, curr) => prev + curr.cnt, 0)}</StyledTd>
                                                <StyledTd className="text-center"></StyledTd>
                                                <StyledTd className="text-right">{getCommaSeparatedNumber(reportDxData?.transportation?.reduce((prev, curr) => prev + curr.cost, 0))}</StyledTd>
                                                <StyledTd className="text-center"></StyledTd>
                                            </>
                                        )}
                                    </tr>
                                );
                            })}
                            <tr>
                                <StyledTd colSpan={7}>
                                    <div style={{ display: 'flex', marginLeft: 7 }}>
                                        <input type="checkbox" checked={reportMxData?.by_church_car ? true : false} readOnly />
                                        <StyledSpan2>교회차량</StyledSpan2>
                                        &nbsp;
                                        <input type="checkbox" checked={reportMxData?.by_public_transport ? true : false} readOnly />
                                        <StyledSpan2>무차량 교통비 보조</StyledSpan2>
                                        <input type="checkbox" checked={reportMxData?.by_own_car ? true : false} readOnly />
                                        <StyledSpan2>개인소유 차량 보조</StyledSpan2>
                                    </div>
                                </StyledTd>
                                <StyledTd colSpan={2}></StyledTd>
                            </tr>
                            <tr>
                                <td colSpan={12}>
                                    <StyledDiv>
                                        <StyledH3>참고사항</StyledH3>
                                        1. 활동지역 및 특기사항란에는 당일 활동지역과 행사를 기록한다.
                                        <br />
                                        2. 해당일 합회 공식 행사에 사용한 경비 영수증은 월말 보고서에 첨부하여 보고하고, 월 지급 교통비는 해당 [ ]에 표하고 해당년도 지정 금액을 교통비란에 기록한다.{' '}
                                        <br />
                                        3. 개인 소유 차량(교회 차량이 아닌)은 목회활동에 한하여 보험료와 유류비를 보조한다. <br />
                                        4. 성경교수는 정규예배시 성경을 가르친 경우는 포함하지 않는다. <br />
                                        5. 구도자 명단은 성경공부를 정규적으로 하고 있는 자만을 기록한다. <br />
                                        6. 교인 세대수는 1명이라도 1세대로 한다. <br />
                                        7. 특별계획이나 제안 사항은 통신란이나 뒷면에 기재한다. <br />
                                        8. 이 보고서는 매월 10일 안에 합회에 도착하도록 작성하여 합회장에게 보낸다.
                                    </StyledDiv>
                                </td>
                            </tr>
                            <tr>
                                <StyledTd2 colSpan={12} style={{ textAlign: 'right' }}>
                                    보고일: {reportMxData?.submitted_at ? format(new Date(reportMxData?.submitted_at * 1000), 'yyyy년 MM월 dd일') : ''}
                                </StyledTd2>
                            </tr>
                            {/* <tr>
                                <StyledTd2 colSpan={12} style={{ textAlign: 'right' }}>
                                    접수일: {reportMxData?.submitted_at ? format(new Date(), 'yyyy년 MM월 dd일') : ''}
                                </StyledTd2>
                            </tr> */}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default MonthlyReportPrint;
