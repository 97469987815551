import React, { useEffect } from 'react';

import Header from './common/Header';
import MobileMenu from './common/MobileMenu';
import MainRollingSlide from './main/MainRollingSlide';
import MainContent from './main/MainContent';
import Footer from './common/Footer';

const MainTemplate = () => {

    useEffect(() => {
        /** 
         * 중요한 사항은 홈페이에 토스트(Toast)로 뜨게 만든다. 
         * 이후에 DB에서 데이터를 가지고 오는 것으로 만들 예정 
         * 2022.01.09.(월) @부산서부교회 전도사 사택
         * : 일단 숨김
        */
        // toast( custom => (
        //         <div className='cutom-toast-wrap'>
        //             홈페이지를 새롭게 개편하였습니다.
        //         </div>
        //     ), { duration: 4000 }
        // );
    }, [])

    return (
        <div id='body'>
            <div id='wrap'>
                <Header />            {/* 탑(로그인 관련), 메인 메뉴(합회소개, 합회보고, 자료실, 게시판) */}
                <MobileMenu />        {/* 사이드 메뉴(Mobile Menu): 사이즈를 줄였을 때, 왼쪽 사이드에 붙는 메뉴 */}

                {/* <a id="showHere" href='/'> </a> Sticky Header 를 위한 것!! 하하;; 안되니 숨기자. */}

                <MainRollingSlide />  {/* 메인 롤링 슬라이드: 배너 슬라이드가 롤링되어 돌아간다 (4 - 8개) */}
                <MainContent />       {/* 메인 컨텐츠: 공지사항 | 합회공문 | 자유게시판 | 미디어센터 | 교회합회소식 | 합회안내 */}

                <Footer />            {/* 푸터(Footer): 패밀리 사이트 링크, Copyright */}

            </div>
        </div>
    );
};

export default MainTemplate;