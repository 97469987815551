/** @format */

import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import toast from 'react-hot-toast';
import { Link, useLocation } from 'react-router-dom';
import { CommonContext, ProgressContext } from '../../contexts';
import { DataTypes } from '../../data/Types';
import { getFormattedTime, getKoreanAge } from '../../utils/common';
import { getMemberCount, getMemberList, getSearchedMemberCount, searchMemberList, updateMemberLevel } from '../../data/mysql';
import Pagination from '../../components/Pagination';

const AdminList = () => {
    const { categories } = useContext(CommonContext);
    const { pathname } = useLocation();
    const { spinner } = useContext(ProgressContext);

    const [members, setMembers] = useState([]);
    const [searchItem, setSearchItem] = useState('name'); // 기본 검색은 '이름(name)'
    const [searchKeyword, setSearchKeyword] = useState('');

    const [totalCount, setTotalCount] = useState(1);
    const [pageCount, setpageCount] = useState(15); // 한 페이지에 들어갈 글의 갯 수
    const [offset, setOffset] = useState(0);

    // 마운트 할때, member를 가지고 온다.
    // 1. 접근권한이 있는지 확인한다.
    useEffect(() => {
        const init = async () => {
            spinner.start();

            // console.log('memberCount:', await getMemberCount() );
            setTotalCount(await getMemberCount());
            setMembers(await getMemberList({ limit: pageCount, offset }));

            spinner.stop();
        };
        init();
    }, []);

    useEffect(() => {
        const update = async () => {
            spinner.start();

            setMembers(await searchMemberList({ type: searchItem, keyword: searchKeyword, limit: pageCount, offset }));

            spinner.stop();
        };
        update();
    }, [offset, pageCount, totalCount]);

    /**
     * 해당 항목(SearchItem)에 따라 키워드(SearchKeyword)로 검색한다.
     */
    const _handleSearchKeyword = async () => {
        // if ( !searchKeyword ) return;

        try {
            spinner.start();

            setOffset(1);
            setOffset(0);
            setTotalCount(await getSearchedMemberCount({ type: searchItem, keyword: searchKeyword }));
            setMembers(await searchMemberList({ type: searchItem, keyword: searchKeyword, limit: pageCount, offset }));

            toast.success('검색을 완료 하였습니다.');
        } catch (e) {
            console.log(e.message);
        } finally {
            spinner.stop();
        }
    };

    /**
     * 모든 체크박스를 토글(true, false) 한다.
     */
    const _handleCheckAllMembers = (checked) => {
        setMembers(members.map((member) => ({ ...member, checked: checked })));
    };

    /**
     * 해당 멤버의 체크박스를 클릭했을 때, 해당 멤버의 체크박스를 토글(true, false) 한다.
     */
    const _handleCheckMembers = ({ memberId, checked }) => {
        setMembers(members.map((member) => (member.uid === memberId ? { ...member, checked: checked } : member)));
    };

    /**
     * 체크된 사용자의 Level을 조정한다(가입 승인, ) 승인한다.
     */
    const _handleSetMemberLevel = async (level) => {
        try {
            spinner.start();

            // 1. 체크된(cheked) 멤버만 거른다(filter).
            const filteredMembers = members.filter((member) => member.checked);

            // 2. DB에 Filtered된 멤버를 레벨(level)을 업데이트 한다.
            //    : Promise.all은 비동기(async)가 다 처리된 후 -> 다음 동작
            await Promise.all(
                filteredMembers.map(async (member) => {
                    await updateMemberLevel({ memberId: member.uid, level });
                }),
            );

            // 3. 렌더링을 하기 위해서 DB와 동일하게 화면의 상태를 만든다.
            setMembers(await searchMemberList({ type: searchItem, keyword: searchKeyword }));
            toast.success('수정 되었습니다');
        } catch (e) {
            console.log(e.message);
        } finally {
            spinner.stop();
        }
    };

    /**
     *
     * 페이지 번호를 클릭하면 해당 페이지로 넘어간다.
     * @param {*} e
     */
    const handleClick = async (e) => {
        const newOffset = (e.selected * pageCount) % totalCount;
        setOffset(newOffset);
    };

    return (
        <div id="primary" className="content-area">
            {/* <!--s:#content --> */}
            <div id="content" className="site-content">
                <article>
                    <div>
                        <div className="title-inner">
                            <img src="/common/images/title.png" alt="" />
                            <h1>{categories.find((category) => category.categoryCode === pathname.split('/')[4])?.categoryName}</h1>
                        </div>
                        <div style={{ height: 20 }}></div>
                        <div style={{ clear: 'both' }}></div>
                        <div className="title_subpage_solid_1"></div>
                        <div style={{ height: 30 }}></div>
                        <div className="box-table">
                            <div className="box-table-header">
                                <div className="btn-group pull-right list-button" role="group" aria-label="...">
                                    <button type="button" className="btn btn-info btn-sm" onClick={() => _handleSetMemberLevel(DataTypes.USER_LEVEL_IDENTIFIED)}>
                                        가입 승인
                                    </button>
                                    <button type="button" className="btn btn-success btn-sm" onClick={() => _handleSetMemberLevel(DataTypes.USER_LEVEL_PASTOR)}>
                                        목회자 인증
                                    </button>
                                    <button type="button" className="btn btn-primary btn-sm" onClick={() => _handleSetMemberLevel(DataTypes.USER_LEVEL_STAFF)}>
                                        스탭 등업
                                    </button>
                                    <button type="button" className="btn btn-warning btn-sm" onClick={() => _handleSetMemberLevel(DataTypes.USER_LEVEL_MANAGER)}>
                                        매니저 등업
                                    </button>
                                    <button type="button" className="btn btn-red btn-sm" onClick={() => _handleSetMemberLevel(DataTypes.USER_LEVEL_BLOCKED)}>
                                        차단
                                    </button>
                                </div>
                            </div>
                            <div className="row-wd fl-left-m text-right search-form pd-10 form-inline">
                                <div className="dataTables_filter">
                                    <div className="text-all">
                                        전체: <strong>{totalCount || 0}</strong>명
                                    </div>
                                    <label>
                                        <select className="form-control" onChange={(e) => setSearchItem(e.target.value)} defaultValue={'name'}>
                                            <option value="role">역할</option>
                                            <option value="name">이름</option>
                                            <option value="church">출석교회</option>
                                            <option value="phone">휴대전화</option>
                                            <option value="email">이메일</option>
                                            <option value="address">주소</option>
                                            {/* <option value='2'>권한</option> */}
                                        </select>{' '}
                                    </label>
                                    <div className="input-group fl-right">
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={(e) => setSearchKeyword(e.target.value.trim())}
                                            onSubmit={_handleSearchKeyword}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    _handleSearchKeyword();
                                                    e.preventDefault();
                                                }
                                            }}
                                            placeholder="검색할 키워드"
                                        />
                                        <span className="input-group-btn">
                                            <button className="btn btn-primary btn-sm" type="button" onClick={_handleSearchKeyword}>
                                                검색
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-hover table-striped table-bordered">
                                    <colgroup>
                                        <col width="3%" /> {/* 체크박스 */}
                                        <col width="5%" /> {/* 역할 */}
                                        <col width="22%" /> {/* 이름 */}
                                        <col width="12%" /> {/* 출석교회 */}
                                        <col width="40%" /> {/* 주소 */}
                                        <col width="5%" /> {/* 승인 */}
                                        <col width="13%" /> {/* 가입일 */}
                                    </colgroup>
                                    <thead>
                                        <tr style={{ backgroundColor: 'silver' }}>
                                            <th>
                                                <input type="checkbox" onClick={(e) => _handleCheckAllMembers(e.target.checked)} />
                                            </th>
                                            <th>
                                                <Link to="#" className="title-text">
                                                    역 할
                                                </Link>
                                            </th>
                                            <th>
                                                <Link to="#" className="title-text">
                                                    이 름
                                                </Link>
                                            </th>
                                            <th>
                                                <Link to="#" className="title-text">
                                                    출 석 교 회
                                                </Link>
                                            </th>
                                            <th>
                                                <Link to="#" className="title-text">
                                                    주 소
                                                </Link>
                                            </th>
                                            <th>
                                                <Link to="#" className="title-text">
                                                    승 인
                                                </Link>
                                            </th>
                                            <th>
                                                <Link to="#" className="title-text">
                                                    가입일
                                                </Link>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {members.length === 0 ? (
                                            <tr>
                                                <td colSpan={10} style={{ padding: 50, textAlign: 'center' }}>
                                                    회원이 존재하지 않습니다.
                                                </td>
                                            </tr>
                                        ) : (
                                            members.map((member) => {
                                                return (
                                                    <tr key={member.uid}>
                                                        <td className="text-center">
                                                            <input
                                                                type="checkbox"
                                                                className="list-chkbox"
                                                                value={member.uid}
                                                                checked={member.checked}
                                                                onChange={(e) => _handleCheckMembers({ memberId: member.uid, checked: e.target.checked })}
                                                            />
                                                        </td>
                                                        <td className="text-center">
                                                            <img
                                                                width={35}
                                                                src={`/common/images/admin/${
                                                                    {
                                                                        목회자: 'pastor',
                                                                        집사: 'deacon',
                                                                        장로: 'elder',
                                                                        평신도: 'layman',
                                                                        청년: 'youth',
                                                                        기타: 'etc',
                                                                    }[member.role]
                                                                }_${member.gender === 'F' ? 'woman' : 'man'}.png`}
                                                                alt={member.role}
                                                            />
                                                            <br />
                                                            <span className="small-text">{member.role}</span>
                                                        </td>
                                                        <td className="text-left">
                                                            <span className="default-text">{member.name}</span>
                                                            <span className="small-text">({getKoreanAge({ birthDay: member.birthDay })})</span>
                                                            <br />
                                                            <a href={`tel:${member.phoneNumber}`}>{member.phoneNumber}</a>
                                                            <br />
                                                            <a href={`mailto:${member.email}`}>{member.email}</a>
                                                        </td>
                                                        <td className="text-center">
                                                            <span className="default-text">{member.church}</span>
                                                        </td>
                                                        <td className="text-left">
                                                            <span className="default-text">
                                                                {member.address} {member.addressDetail}
                                                            </span>
                                                        </td>
                                                        <td className="text-center">
                                                            {
                                                                <>
                                                                    <img
                                                                        width={35}
                                                                        src={`/common/images/admin/${
                                                                            {
                                                                                '-1': 'reject',
                                                                                0: 'unauth',
                                                                                10: 'auth',
                                                                                20: 'pastor',
                                                                                30: 'staff',
                                                                                40: 'manager',
                                                                            }[member.level]
                                                                        }_${member.gender === 'F' ? 'woman' : 'man'}.png`}
                                                                        alt={member.level}
                                                                    />
                                                                    <br />
                                                                </>
                                                            }
                                                            <span className="small-text">
                                                                {
                                                                    {
                                                                        '-1': '차단',
                                                                        0: '승인요청',
                                                                        10: '승인', // 승인된 사용자는 화면에 표시하지 않는다.
                                                                        20: '목회자',
                                                                        30: '스태프',
                                                                        40: '매니저',
                                                                    }[member.level]
                                                                }
                                                            </span>
                                                        </td>
                                                        <td className="text-center">
                                                            <span className="default-text">{getFormattedTime(member.createdAt)}</span>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="box-info">
                                <div className="btn-group pull-right list-button" role="group" aria-label="...">
                                    <button type="button" className="btn btn-info btn-sm" onClick={() => _handleSetMemberLevel(DataTypes.USER_LEVEL_IDENTIFIED)}>
                                        가입 승인
                                    </button>
                                    <button type="button" className="btn btn-success btn-sm" onClick={() => _handleSetMemberLevel(DataTypes.USER_LEVEL_PASTOR)}>
                                        목회자 인증
                                    </button>
                                    <button type="button" className="btn btn-primary btn-sm" onClick={() => _handleSetMemberLevel(DataTypes.USER_LEVEL_STAFF)}>
                                        스탭 등업
                                    </button>
                                    <button type="button" className="btn btn-warning btn-sm" onClick={() => _handleSetMemberLevel(DataTypes.USER_LEVEL_MANAGER)}>
                                        매니저 등업
                                    </button>
                                    <button type="button" className="btn btn-red btn-sm" onClick={() => _handleSetMemberLevel(DataTypes.USER_LEVEL_BLOCKED)}>
                                        차단
                                    </button>
                                </div>
                            </div>
                            {/* 페이지 네이션 */}
                            <Pagination total={totalCount} itemsPerPage={pageCount} setOffset={setOffset} />
                        </div>
                    </div>
                </article>
            </div>
            {/* <!--//e: #content --> */}
        </div>
    );
};

export default AdminList;
