import React from 'react';
import styled from 'styled-components';
import DaumPostcode from 'react-daum-postcode';

const postCodeStyle = {
    display: 'block',
    position: 'absolute',
    top: '10%',
    width: '100%',
    padding: '7px',
};

const StyledCloseButton = styled.button`
    position: absolute;
    right: -10px;
    top: -15px;
    border-radius: 50px;
    font-size: 2em;
    padding: 7px;
    background-color: transparent;
`;

const PopupPostCode = ({ onClose, setPostCode, setAddress, setFocus }) => {
    // 우편번호 검색 후 주소 클릭 시 실행될 함수, data callback 용
    const handlePostCode = (data) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }

            if (data.buildingName !== '') {
                extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
            }

            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        setPostCode(data.zonecode);
        setAddress(fullAddress);
        setFocus();
        onClose();
    };

    return (
        <div>
            <DaumPostcode style={postCodeStyle} onComplete={handlePostCode} />
            {/* // 닫기 버튼 생성 */}
            <StyledCloseButton className="postCode_btn" onClick={() => onClose()}>
                <span className="material-icons" style={{ fontSize: '1em' }}>
                    cancel
                </span>
            </StyledCloseButton>
        </div>
    );
};

export default PopupPostCode;
