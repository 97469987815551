import React, { useContext, useEffect } from 'react';
import BoardList from './BoardList';
import BoardPagesHeader from './BoardPagesHeader';
import Pagination from '../../../components/Pagination';
import { searchPostList } from '../../../data/mysql';
import { ProgressContext } from '../../../contexts';
import { Icon } from '@iconify/react';
import { useParams } from 'react-router-dom';

const BoardSearchTable = ({
    setSearchPosts, //
    isSearching,
    setPostOffset,
    searchPosts,
    searchTotalCount,
    postsPerPage,
    searchItem,
    searchKeyword,
    postOffset,
    CATEGORY_COUNSEL_ID,
    SEARCH_URL,
    COMMON_URL,
}) => {
    const { spinner } = useContext(ProgressContext);

    const { categoryId, groupCode } = useParams();

    useEffect(() => {
        const update = async () => {
            spinner.start();
            setSearchPosts(await searchPostList({ type: searchItem, keyword: searchKeyword, categoryId, limit: postsPerPage, offset: postOffset }));

            spinner.stop();
        };
        update();
    }, [postOffset]);

    return searchPosts?.length === 0 ? (
        <div className="table_wrap_scroll">
            <div style={{ width: '100%', height: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Icon icon="clarity:error-standard-line" fontSize="130px" color="#dadada" style={{ margin: '20px' }} />
                <p style={{ fontSize: '34px', fontWeight: '600', marginBottom: 0 }}>검색결과가 없습니다.</p>
                <p style={{ fontSize: '17px', color: '#aaa' }}>새로운 검색어로 검색하시기 바랍니다.</p>
            </div>
        </div>
    ) : (
        <>
            <div className="table_wrap_scroll">
                {isSearching && (
                    <table style={{ borderTop: '1px solid #333', width: '100%' }}>
                        <tr>
                            <td className="search" style={{ height: '85px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <p style={{ fontSize: '16px', fontWeight: '600', marginBottom: 0 }}>요청하신 검색결과입니다.</p>
                                <p style={{ fontSize: '14px' }}>총 {searchTotalCount}개의 게시물이 검색되었습니다.</p>
                            </td>
                        </tr>
                    </table>
                )}
                <table className="tbl_skin3 center twd">
                    <>
                        <BoardPagesHeader />
                        <tbody>
                            <BoardList //
                                posts={searchPosts}
                                groupCode={groupCode}
                                categoryId={categoryId}
                                CATEGORY_COUNSEL_ID={CATEGORY_COUNSEL_ID}
                                isSearching={isSearching}
                                searchKeyword={searchKeyword}
                                postOffset={postOffset}
                                SEARCH_URL={SEARCH_URL}
                                COMMON_URL={COMMON_URL}
                            />
                        </tbody>
                    </>
                </table>
            </div>

            <Pagination total={searchTotalCount} itemsPerPage={postsPerPage} setOffset={setPostOffset} offset={postOffset} />
        </>
    );
};

export default BoardSearchTable;
