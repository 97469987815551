import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { getFormattedTime, getFormattedTimeWithSeconds, getKoreanAge, getTimeByWritingTime } from '../../utils/common';

const Attendance = () => {
  const location = useLocation();
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const memberId = urlParams.get('m_id'); // Replace 'memberId' with the actual key

  const [checkedList, setCheckedList] = useState([]);

  // Step 1: Get today's date
  const today = new Date();

  // Step 2: Get the year, month, and day
  const year = today.getFullYear();
  const month = today.getMonth() + 1; // Months are zero-based
  const day = today.getDate();

  // Step 3: Convert them to strings and combine them in the desired format
  const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

  useEffect(() => {
    axios.get(`http://172.30.1.13:3004/test/attendance?m_id=${memberId}`).then((res) => {
      // console.log(res.data);
      setCheckedList(res.data);
    });
  }, [memberId]);

  const handleReset = () => {
    console.log('handleReset');
    axios.delete(`http://172.30.1.13:3004/test/attendance`).then((res) => {
      setCheckedList([]);
    });
  };

  return (
    <div style={{ padding: '3px' }}>
      <div>출석한 인원({formattedDate})</div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {checkedList.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                width: '100%',
                backgroundColor: '#EAEAEA', //
                borderRadius: 3,
                margin: 3,
                padding: 3,
                paddingLeft: 3,
              }}
            >
              <span>{item.name}</span>{' '}
              <span style={{ color: '#998899' }}>
                ({item.gender}, {getKoreanAge({ birthDay: item.birthday })})
              </span>
              <br />
              <span style={{ fontSize: '10px' }}>{getFormattedTimeWithSeconds(item.checked_at)}</span>
            </div>
          );
        })}
      </div>
      <button
        style={{ backgroundColor: '#ff000030', borderRadius: 5, padding: 5, margin: 10, cursor: 'pointer' }}
        onClick={handleReset} //
      >
        초기화
      </button>
    </div>
  );
};

export default Attendance;
