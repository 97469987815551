import React, { useRef, useState, useEffect } from 'react';
import { getAllSchoolInfo } from '../data/mysql';

const PopupSchool = ({ setSchool, onClose, onFocus }) => {
    const schoolRef = useRef();

    const [schoolName, setSchoolName] = useState('');
    const [schools, setSchools] = useState([]);
    const [result, setResult] = useState([]);

    /* 검색어를 입력하면 자동으로 검색한다. */
    useEffect(() => {
        schoolRef.current.focus();

        const init = async () => {
            setSchools( await getAllSchoolInfo() );
        }; init();
        
    }, []);

    /* 검색어를 입력하면 자동으로 검색한다. */
    useEffect(() => {
        if ( schoolName.trim().length >= 1 ) {
            setResult( schools.filter( doc => doc.schoolName.indexOf(schoolName) !== -1) );
        } else {
            setResult([]);
        }
    }, [schoolName])

    const _handleChangeSchoolName = ({ target }) => {
        setSchoolName( target.value )
    }

    return (
        <div className='school-container'>
            <div className='close-btn' onClick={() => onClose() }>
                    <span className='material-icons' style={{ fontSize: '1em'}}>cancel</span>
            </div>
            <div className='school-header'>
                <div className='input-nm'>
                    <input ref={ schoolRef } 
                            value={ schoolName } 
                            placeholder='예) 삼육대학교'
                            onChange={ _handleChangeSchoolName }
                    />
                </div>
                <div className='search-btn'>
                    <span className='material-icons' style={{ fontSize: '1em'}}>search</span>
                </div>
                
            </div>
            <div className='school-main'> 
                <div className='search-info'>
                    { result.length === 0 ? (
                            <div style={{ padding: 10 }}>
                                <strong style={{ fontWeight: 600, color: '#D5A10E' }}>사용방법</strong><br/>
                                대학교 이름을 입력하시면 자동으로 검색됩니다.
                            </div>
                        ) : (
                            <div style={{ textAlign: 'right', fontSize: '0.8rem', color: '#777' }}>
                                검색된 대학교 <strong style={{ fontSize: '0.8rem'}}>{ result.length }</strong>
                            </div>
                        )
                    }
                </div>
                <div className='school-list'>
                    {   result.length === 0 ? (
                        <div className='school-info'>
                            <span style={{ color: '#777'}}>검색된 데이터가 없습니다.</span>
                        </div>
                        ) : (
                            result.map( (school, index) => (
                                <div className='school-info' key={ index } onClick={() => { setSchool(school.schoolName || ''); onClose(); onFocus(); }}>
                                    <div className='school-name'>{ school.schoolName }</div> 
                                    <div className='school-addr'>{ school.adres }</div>
                                    <span className='material-icons' style={{ fontSize: '1em', color: '#aaa'}}>north_west</span>
                                </div>
                            )
                        )
                    )}
                    
                </div>
            </div>
        </div>
    );
};

export default PopupSchool;