import React, { useEffect, useState } from 'react';
import InputGroup2 from '../components/InputGroup2';

const MembersCount = ({ memberCnt, memberFamilyCnt, memberSchoolCnt, setReportMxData, reportMxData, disabled }) => {
    // const [members, setMembers] = useState(0); // 교인수
    // const [families, setFamilies] = useState(0); // 교인세대수
    // const [students, setStudents] = useState(0); // 안교생수

    // const [disabled, setDisabled] = useState(false);

    // useEffect(() => {
    //     reportMxData.is_submitted === 'Y' ? setDisabled(true) : setDisabled(false);
    // }, [reportMxData.is_submitted]);

    // useEffect(() => {
    //     if (reportMxData !== {}) return;
    //     setReportMxData((report) => ({
    //         ...report, //
    //         members: parseInt(members),
    //         members_family: parseInt(families),
    //         members_school: parseInt(students),
    //     }));
    // }, [members, families, students]);

    // useEffect(() => {
    //     setMembers(memberCnt);
    //     setFamilies(memberFamilyCnt);
    //     setStudents(memberSchoolCnt);
    // }, [memberCnt, memberFamilyCnt, memberSchoolCnt]);

    return (
        <div className="row">
            <div className="panel">
                <div className="panel-heading">
                    <h3>교인 현황</h3>
                </div>
                <div className="panel-body">
                    <div className="col-xs-12 col-sm-4 col-md-4">
                        <InputGroup2
                            title={'교인세대수'} //
                            value={memberFamilyCnt}
                            setReportMxData={setReportMxData}
                            reportColumnName={'members_family'}
                            disabled={disabled}
                            unit={'세대'}
                        />
                    </div>
                    <div className="col-xs-6 col-sm-4 col-md-4">
                        <InputGroup2
                            title={'교인수'} //
                            value={memberCnt}
                            setReportMxData={setReportMxData}
                            reportColumnName={'members'}
                            disabled={disabled}
                            unit={'명'}
                        />
                    </div>
                    <div className="col-xs-6 col-sm-4 col-md-4">
                        <InputGroup2
                            title={'안교생수'} //
                            value={memberSchoolCnt}
                            setReportMxData={setReportMxData}
                            reportColumnName={'members_school'}
                            disabled={disabled}
                            unit={'명'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MembersCount;
