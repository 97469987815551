import React from 'react';

const CommentCount = ({ comments }) => {
    return (
        comments > 0 && (
            <span className="small-text" style={{ marginLeft: 3 }}>
                [{comments}]
            </span>
        )
    );
};

export default CommentCount;
