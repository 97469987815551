import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Icon } from '@iconify/react';
import { getCommaSeparatedNumber } from '../../../utils/common';
import { format, addMonths, subMonths, isSameMonth, isSameDay, addDays, startOfMonth, endOfMonth, startOfWeek, endOfWeek, isThisMonth } from 'date-fns';

const WorkCalendar = forwardRef(({ currentMonth, selectedDate, setCurrentMonth, setSelectedDate, reportDxData, setReportDxData, reportMxData, disabledDiv }, ref) => {
    const [disabled, setDisabled] = useState(false);

    useImperativeHandle(ref, () => ({
        initCalendar,
    }));

    const prevMonth = () => {
        setCurrentMonth(subMonths(currentMonth, 1));
    };
    const nextMonth = () => {
        setCurrentMonth(addMonths(currentMonth, 1));
    };

    /**
     * 해당 요일(월화수목금안)을 누르면 해당 달의 모든 요일(column)이 선택된다.
     * : 해당 요일을 선택했을 경우
     */
    const _handleClickDayRow = ({ dayRowIdx }) => {
        if (reportMxData.is_submitted === 'Y') return;
        const checkState = !reportDxData // 모든 날이 선택 되어있으면 false, 모두 선택되어 있지 않으면 true
            .filter((week) => week[dayRowIdx].isThisMonth) // 해당 달이 아닌 것은 검사 대상에서 뺀다.
            .every((week) => week[dayRowIdx].checked); // 모두 체크가 되어 있는가?

        setReportDxData(
            reportDxData.map((week) =>
                week.map((day, dayIdx) =>
                    dayIdx === dayRowIdx && day.isThisMonth //
                        ? { ...day, checked: checkState }
                        : day,
                ),
            ),
        );
    };

    /** 해당 날을 하나씩 눌렀을 때, 해당 날이 선택(checked) 된다. */
    const _handleClickDay = ({ weekIdx, dayIdx }) => {
        if (reportMxData.is_submitted === 'Y') return;
        setReportDxData(
            reportDxData.map((week, i) =>
                i !== weekIdx
                    ? week
                    : week.map(
                          (day, j) =>
                              j === dayIdx && day.isThisMonth //
                                  ? { ...day, checked: !day.checked } //
                                  : day, //
                      ),
            ),
        );
    };

    // 달력 그리기
    const initCalendar = async () => {
        if (!selectedDate || !currentMonth) return;

        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);

        const rows = [];
        let week = [];
        let day = startDate;

        while (day <= endDate) {
            // eslint-disable-next-line no-loop-func, array-callback-return
            Array.from(Array(7), () => {
                week.push({
                    checked: false,

                    day: format(day, 'd'),
                    dt: Math.floor(day.getTime() / 1000),
                    isThisMonth: isSameMonth(day, monthStart),
                    isToday: isSameDay(day, selectedDate),

                    seekerCnt: 0,
                    memberCnt: 0,
                    teachCnt: 0,
                    sermonCnt: 0,
                    transportation: 0,
                    activities: '',
                });
                day = addDays(day, 1);
            });

            rows.push(week);
            week = [];
        }

        return rows;
    };

    /**
     * 임시 일별(日)이 존재한다면 ReportDxData에 임시 일(日) 데이터를 넣는다.
     */
    useEffect(() => {
        const init = async () => {
            if (!reportMxData.saved_dx_data) return setReportDxData(await initCalendar());
            if (reportMxData.is_submitted === 'Y') return;

            const savedData = await JSON.parse(reportMxData.saved_dx_data);
            setReportDxData(savedData);
        };
        init();
    }, [reportMxData.dt]);

    useEffect(() => {
        const update = async () => {
            setReportDxData(await initCalendar());
        };
        update();
    }, [currentMonth]);

    /**
     * 보고가 되지 않았거나 월(月)이 바뀌지 않았다면 버튼을 비활성화 한다.
     */
    useEffect(() => {
        isSameMonth(currentMonth, selectedDate) || reportMxData.is_submitted === 'N' ? setDisabled(true) : setDisabled(false);
    }, [currentMonth, reportMxData]);

    return (
        <>
            <div className="row">
                <div className="work-calendar">
                    {/* Header ------- */}
                    <div className="header">
                        <div className="first">
                            <span className="text">
                                <span className="text month">{format(currentMonth, 'M')}월</span>
                                {format(currentMonth, 'yyyy')}
                            </span>
                        </div>
                        <div className="last">
                            <Icon icon="bi:arrow-left-circle-fill" onClick={prevMonth} style={{ marginRight: 4 }} />
                            {/* 10일 전 날짜로 가져오므로 버튼 활성화는 현재 날짜기준으로 동작하도록 한다 */}
                            {disabled ? <Icon icon="bi:arrow-right-circle-fill" style={{ color: '#eee' }} /> : <Icon icon="bi:arrow-right-circle-fill" onClick={nextMonth} />}{' '}
                        </div>
                    </div>
                    {/* Days [월, 화, 수, 목, 금, 토] ------- */}
                    <div className="days">
                        {Array.from(['일', '월', '화', '수', '목', '금', '안'], (day, dayRowIdx) => {
                            return (
                                <div className="text col" key={dayRowIdx} onClick={() => (disabledDiv ? {} : _handleClickDayRow({ dayRowIdx }))}>
                                    {day}
                                </div>
                            );
                        })}
                    </div>
                    {/* Body 달력 내용 -------
                [  1,  2,  3,  4,  5,  6,  7]
                [  7,  8,  9, 10, 11, 12, 13]
                [ 14, 15, 16, 17, 18, 19, 20]
                [ 21, 22, 23, 24, 25, 26, 27]
                [ 28, 29, 30, 31,   ,   ,   ]
            */}

                    <div className="body">
                        {reportDxData?.map((week, weekIdx) => {
                            return (
                                <div className="row" key={weekIdx}>
                                    {week.map((day, dayIdx) => {
                                        return (
                                            <div
                                                key={dayIdx}
                                                className={`col ${
                                                    !day.isThisMonth //
                                                        ? 'disabled'
                                                        : day.isToday //
                                                        ? 'today'
                                                        : 'valid'
                                                } ${
                                                    day.checked //
                                                        ? 'checked'
                                                        : ''
                                                } `}
                                                onClick={() => (disabledDiv ? {} : _handleClickDay({ weekIdx, dayIdx }))}
                                            >
                                                <span className={`day ${!day.isThisMonth ? 'inactive' : ''}`}>{day.day}</span>
                                                <div className="content">
                                                    {day.seekerCnt ? <span>방문(구도자): {day.seekerCnt}</span> : ''}
                                                    {day.memberCnt ? <span>방문(신자): {day.memberCnt}</span> : ''}
                                                    {day.teachCnt ? <span>성경교수: {day.teachCnt}</span> : ''}
                                                    {day.sermonCnt ? <span>설교한수: {day.sermonCnt}</span> : ''}
                                                    {day.transportation ? <span>교통비: {getCommaSeparatedNumber(day.transportation)}원</span> : ''}
                                                    {day.activities ? <span>활동내역: {day.activities}</span> : ''}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div>
                {/* controls ------- */}
            </div>
        </>
    );
});

export default WorkCalendar;
