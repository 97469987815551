import React, { useContext } from 'react';
import { ProgressContext } from '../../../../contexts';

import { openNewTab } from '../../../../utils/common';
import toast from 'react-hot-toast';

const STAGE_NAME = ['영남합회(교회)', '영남합회(기관)', '영남합회'];

const SummingUpTermReportBaseInfo = ({ dt, stage }) => {
    const { spinner } = useContext(ProgressContext);

    return (
        <div className="row" style={{ marginBottom: 10 }}>
            <div className="panel">
                <div className="panel-heading">
                    <h3>{STAGE_NAME[stage]}</h3>
                </div>
                <div className="panel-body">
                    <div className="col-xs-4 col-sm-4 col-md-4">
                        {/* <button //
                            className="styled-button report"
                            onClick={() => {
                                console.log('수정');
                            }}
                        >
                            수정
                        </button> */}
                    </div>
                    <div className="col-xs-4 col-sm-4 col-md-4">
                        <button //
                            className="styled-button download"
                            onClick={() => openNewTab(`/printTermReport/download/${stage}/${dt}`)}
                        >
                            다운로드
                        </button>
                    </div>
                    <div className="col-xs-4 col-sm-4 col-md-4">
                        <button //
                            className="styled-button print"
                            onClick={() => openNewTab(`/printTermReport/print/${stage}/${dt}`)}
                        >
                            인쇄
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SummingUpTermReportBaseInfo;
