import React, { useEffect, useState } from 'react';
import InputGroup2 from '../components/InputGroup2';

const AttendanceCount = ({ tuesdayCnt, fridayCnt, sabbathCnt, setReportMxData, reportMxData, disabled }) => {
    // const [tues, setTues] = useState(0); // 화요일 평균 출석
    // const [fris, setFris] = useState(0); // 금요일 평균 출석
    // const [sats, setSats] = useState(0); // 안식일 평균 출석

    // const [disabled, setDisabled] = useState(false);

    // useEffect(() => {
    //     reportMxData.is_submitted === 'Y' ? setDisabled(true) : setDisabled(false);
    // }, [reportMxData.is_submitted]);

    // useEffect(() => {
    //     if (reportMxData !== {}) return;
    //     setReportMxData((report) => ({
    //         ...report, //
    //         attendance_tue: parseInt(tues),
    //         attendance_fri: parseInt(fris),
    //         attendance_sat: parseInt(sats),
    //     }));
    // }, [tues, fris, sats]);

    // useEffect(() => {
    //     setTues(tuesdayCnt);
    //     setFris(fridayCnt);
    //     setSats(sabbathCnt);
    // }, [tuesdayCnt, fridayCnt, sabbathCnt]);

    return (
        <div className="row">
            <div className="panel">
                <div className="panel-heading">
                    <h3>예배 평균 출석자 수</h3>
                </div>
                <div className="panel-body">
                    <div className="col-xs-6 col-sm-4 col-md-4">
                        <InputGroup2
                            title={'화요일'} //
                            value={tuesdayCnt}
                            setReportMxData={setReportMxData}
                            reportColumnName={'attendance_tue'}
                            disabled={disabled}
                            unit={'명'}
                        />
                    </div>
                    <div className="col-xs-6 col-sm-4 col-md-4">
                        <InputGroup2
                            title={'금요일'} //
                            value={fridayCnt}
                            setReportMxData={setReportMxData}
                            reportColumnName={'attendance_fri'}
                            disabled={disabled}
                            unit={'명'}
                        />
                    </div>
                    <div className="col-xs-6 col-sm-4 col-md-4">
                        <InputGroup2
                            title={'안식일'} //
                            value={sabbathCnt}
                            setReportMxData={setReportMxData}
                            reportColumnName={'attendance_sat'}
                            disabled={disabled}
                            unit={'명'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AttendanceCount;
