import React from 'react';
import { getCommaSeparatedNumber } from '../../../utils/common';

const InputNum = ({ value, setValue, reportKeyName, disabled }) => {
    const _handleOnChange = ({ target }) => {
        let result = target?.value;

        result = result.replace(/^0/g, ''); // 1. 첫 글자가 0이면 변경
        result = result.replace(/[^0-9]/g, ''); // 2. 숫자로 변환

        setValue((report) => ({
            ...report,
            [reportKeyName]: result ? parseInt(result) : 0,
        }));
    };

    return (
        <div className="styled-inputNum" style={{ height: '100%' }}>
            <input
                className="styled-num" //
                value={getCommaSeparatedNumber(value)}
                onChange={_handleOnChange}
                maxLength={6}
                disabled={disabled}
            />
        </div>
    );
};

export default InputNum;
