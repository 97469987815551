import React, { useState } from 'react';
import styled from 'styled-components';
import ReportTable from './components/ReportTable';

const StyledContainer = styled.div`
    margin: 30px 5px 0 5px;
    display: flex;
    flex-direction: column;
`;

const StyledATag = styled.a`
    font-family: '기본체';
`;

const AdminActivityReportContent = ({
    reports, //
    stage,
    setStage,
    rejectMonthlyReport,
}) => {
    return (
        <StyledContainer>
            <div style={{ marginTop: 30 }}>
                <ul className="nav nav-tabs">
                    <li role="presentation" className={stage === 0 ? 'active' : ''}>
                        <StyledATag
                            href="#"
                            onClick={() => {
                                setStage(0);
                            }}
                        >
                            {`미제출(${reports?.filter((r) => r.is_submitted !== 'Y').length})`}
                        </StyledATag>
                    </li>
                    <li role="presentation" className={stage === 1 ? 'active' : ''}>
                        <StyledATag
                            href="#"
                            onClick={() => {
                                setStage(1);
                            }}
                        >
                            {`완료(${reports?.filter((r) => r.is_submitted === 'Y').length})`}
                        </StyledATag>
                    </li>
                </ul>
            </div>

            {stage === 0 && <ReportTable reports={reports} conditionFunc={(r) => r.is_submitted !== 'Y'} />}
            {stage === 1 && <ReportTable reports={reports} conditionFunc={(r) => r.is_submitted === 'Y'} rejectMonthlyReport={rejectMonthlyReport} stage={stage} />}
        </StyledContainer>
    );
};

export default AdminActivityReportContent;
