import React, { useState } from 'react';
import { getCommaSeparatedNumber } from '../../../../utils/common';
import styles from './InputNum.module.css';

const InputNum = ({ targetId, value, setValue, keyName }) => {
  const [inputValue, setInputValue] = useState(value);

  const handleOnChangeAndKeyDown = (e) => {
    let result = e.target.value;

    result = result.replace(/^0/g, ''); // 1. 첫 글자가 0이면 변경
    result = result.replace(/[^0-9]/g, ''); // 2. 숫자로 변환

    setInputValue(result);

    // if (e.type === 'keydown' && (e.key === 'Enter' || e.key === 'Tab')) {
    setValue((draft) => {
      const found = draft.find((r) => r.id === targetId);
      found[keyName] = parseInt(result);
    });
    // }
  };

  return (
    <input
      className={styles.input} //
      value={getCommaSeparatedNumber(inputValue)}
      onKeyDown={handleOnChangeAndKeyDown}
      onChange={handleOnChangeAndKeyDown}
      maxLength={7}
    />
  );
};

export default InputNum;
