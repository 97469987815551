import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = ({ total, itemsPerPage, setOffset, offset }) => {
    /**
     * 페이지 번호를 클릭하면 해당 페이지로 넘어간다.
     * @param {*} e
     */
    const handleClick = async (e) => {
        const newOffset = (e.selected * itemsPerPage) % total;
        setOffset(newOffset);
    };

    return (
        <div className="paging">
            <ReactPaginate
                pageCount={Math.ceil(total / itemsPerPage)}
                pageRangeDisplayed={5} // 페이지 수
                marginPagesDisplayed={3} // [1][2][3][4] ... [10][11][12] <-- marginPages (3개)
                breakLabel={'...'}
                previousLabel={'<'}
                nextLabel={'>'}
                onPageChange={handleClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
                forcePage={Math.ceil(total / itemsPerPage) > offset / itemsPerPage ? offset / itemsPerPage : 0} // 목록 버튼을 눌렀을 때, offset을 반영하여 페이지를 지정한다.
                // previousClassName={"previous-class-name"}
                // nextClassName={"next-class-name"}
            />
        </div>
    );
};

export default Pagination;
