import React, { useState } from 'react';

// const _handleAdd = (a, b) => {
//     console.log('aaa');
//     return a + b;
// };
// const _handleOnChange = (e) => {
//     console.log(e.target.value);
// };

// const Test = () => {
//     // Q1. 합을 구하는 함수를 만들어라.
//     //      인자(parameters)는 a, b를 사용한다.

//     const [number1, setNumber1] = useState(0);
//     const [number2, setNumber2] = useState(0);
//     const [sum, setSum] = useState(0);

//     return (
//         <>
//             <input
//                 type="number"
//                 value={number1}
//                 // onChange={(e) => {
//                 //     setNumber1(Number(e.target.value));
//                 // }}
//                 onChange={(e) => {
//                     _handleOnChange(e.target.value);
//                 }}
//             />
//             <input
//                 type="number"
//                 value={number2}
//                 onChange={(e) => {
//                     setNumber2(Number(e.target.value));
//                 }}
//             />
//             <button
//                 onClick={() => {
//                     setSum(_handleAdd(number1, number2));
//                 }}
//             >
//                 add
//             </button>
//             <div>result: {sum}</div>
//         </>
//     );
// };

// 정의된 함수 밖에 상태를 넣으면 에러가 뜬다. 위치가 중요하다.
// const [value, setValue] = useState('');

// const _handleOnlyNum = (event) => {
//     const result = event.target.value.replace(/\D/g, '');

//     setValue(result);
// };

// const Test = () => {
//     const [value, setValue] = useState('');

//     const _handleOnlyNum = (event) => {
//         const result = event.target.value.replace(/\D/g, '');

//         setValue(result);
//     };

//     return (
//         <div>
//             <input type="text" value={value} onChange={_handleOnlyNum} />
//             <button>submit</button>
//         </div>
//     );
// };

const Test = () => {
    const [value, setValue] = useState(0);

    return (
        <div>
            <input type="text" pattern="[0-9]*" value={value} onChange={(e) => setValue((v) => (e.target.validity.valid ? e.target.value : v))} />
        </div>
    );
};

export default Test;
