import axios from 'axios';

/**
 *  headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
 *  백앤드와 소통할 경우 토큰을 발급받기 위해 반드시 사용해야 한다.
 */

/**
 * 교회의 리스트{area_id, id, name}를 가져온다.
 */
export const getChurchesData = async () => {
    let result = [];

    try {
        const response = await axios.get(`${process.env.REACT_APP_CMS_BACKEND_SERVER_URL}/churches`, {
            params: {},
            headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
        });
        result = response.data;
    } catch (e) {
        console.log(e);
    }

    return result;
};
/**
 * 해당 id를 가진 교회의 정보{area_id, id, name...}를 가져온다.
 */
export const getChurchesInfoById = async ({ churchId }) => {
    let result = [];

    try {
        const response = await axios.get(`${process.env.REACT_APP_CMS_BACKEND_SERVER_URL}/churches/${churchId}`, {
            params: {},
            headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
        });
        result = response.data;
    } catch (e) {
        console.log(e);
    }

    return result;
};

/**
 * 교회의 리스트{area_id, id, name}를 가져온다.
 */
export const getAreasData = async ({ dt }) => {
    let result = [];

    try {
        const response = await axios.get(`${process.env.REACT_APP_CMS_BACKEND_SERVER_URL}/area`, {
            params: {},
            headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
        });

        // 1. 401 Error - Auth Error
        // 2. 403 Error - Forbidden
        // if (response.status >= 400) {
        //     // 400 ~ 499
        //     // toast.error('에러가 뜹니다. 요런 메시지... ')
        //     throw new Error('에러가 뜹니다!');
        // }

        result = response.data;
    } catch (e) {
        console.log(e);
    }

    return result;
};

/**
 * 목회자의 리스트{id, name, 외 등}를 가져온다.
 */
export const getPastorsData = async ({ dt }) => {
    let result = [];

    try {
        const response = await axios.get(`${process.env.REACT_APP_CMS_BACKEND_SERVER_URL}/pastors`, {
            params: {},
            headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
        });
        result = response.data;
    } catch (e) {
        console.log(e);
    }

    return result;
};

/**
 * 교회-목회자 rel 데이터{p_id, c_id}를 가져온다.
 */
export const getPastorChurchRelData = async ({ dt }) => {
    let result = [];

    try {
        const response = await axios.get(`${process.env.REACT_APP_CMS_BACKEND_SERVER_URL}/churchConnections`, {
            params: {}, // query string ?pastorId
            headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
        });
        result = response.data;
    } catch (e) {
        console.log(e);
    }

    return result;
};

/**
 * 교회-목회자 rel 정보{p_id, c_id}를 제거한다.
 */
export const deletePastorChurchRel = async ({ p_id, c_id }) => {
    let result = false;

    try {
        const response = await axios.delete(`${process.env.REACT_APP_CMS_BACKEND_SERVER_URL}/churchConnections/${c_id}`, {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
            params: { pastorId: p_id },
        });
        if (response) {
            result = true;
        }
    } catch (e) {
        console.log(e);
    }

    return result;
};

/**
 * 교회-목회자 rel 정보{p_id, c_id}를 생성한다.
 */
export const createPastorChurchRel = async ({ p_id, c_id }) => {
    let result = false;

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_CMS_BACKEND_SERVER_URL}/churchConnections`,
            { pastorId: p_id, churchId: c_id }, // body
            { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` } },
        );
        if (response) {
            result = true;
        }
    } catch (e) {
        console.log(e);
    }

    return result;
};

/**
 * 교회의 데이터를 삭제한다.
 */
export const deleteChurchData = async ({ churchId }) => {
    let result = false;

    try {
        const response = await axios.delete(`${process.env.REACT_APP_CMS_BACKEND_SERVER_URL}/churches/${churchId}`, {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
            params: {},
        });
        if (response) {
            result = true;
        }
    } catch (e) {
        console.log(e.response.data);
    }

    return result;
};
/**
 * 교회의 데이터를 수정한다.
 */
export const editChurchInfo = async ({
    churchId, //
    confId,
    areaId,
    name,
    tel,
    addr,
    homepage,
    type,
    state,
    agency,
}) => {
    let result = false;

    try {
        const response = await axios.put(
            `${process.env.REACT_APP_CMS_BACKEND_SERVER_URL}/churches/${churchId}`,
            { confId, areaId, name, tel, addr, homepage, type, state, agency }, // body
            { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` } },
        );
        if (response) {
            result = true;
        }
    } catch (e) {
        console.log(e);
    }

    return result;
};
/**
 * 새로운 교회의 데이터를 생성한다.
 */
export const createChurchInfo = async ({
    confId, //
    areaId,
    name,
    tel,
    addr,
    homepage,
    type,
    state,
    agency,
}) => {
    let result = false;
    console.log(areaId);
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_CMS_BACKEND_SERVER_URL}/churches/`,
            { confId, areaId, name, tel, addr, homepage, type, state, agency }, // body
            { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` } },
        );
        if (response) {
            result = true;
        }
    } catch (e) {
        console.log(e.response.data);
    }

    return result;
};
