import React, { useState } from 'react';
import { DataTypes } from '../../data/Types';

import SignupAgreements from './SignupAgreements';
import SignupForm from './SignupForm';
import SignupSuccess from './SignupSuccess';

const Signup = () => {
    const [signupStep, setSignupStep] = useState(DataTypes.SIGNUP_AGREEMENT); // 0: 동의서, 1: 회원가입 폼, 2: 회원가입 성공

    const renderSignupStep = (step) => {
        switch (step) {
            case DataTypes.SIGNUP_AGREEMENT:
                return <SignupAgreements setSignupStep={setSignupStep} />;
            case DataTypes.SIGNUP_SIGNUP_FORM:
                return <SignupForm setSignupStep={setSignupStep} />;
            case DataTypes.SIGNUP_SUCCESS:
                return <SignupSuccess setSignupStep={setSignupStep} />;
            default:
                <SignupAgreements setSignupStep={setSignupStep} />;
        }
    };

    return renderSignupStep(signupStep);
};

export default Signup;
