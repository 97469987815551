import React, { useEffect, useRef, useState } from 'react';
import { Icon } from '@iconify/react';

import InputGroup3 from '../components/InputGroup3';
import { validateText } from '../../../utils/common';

const AddSeekers = ({ reportMxData, setReportMxData, disabled }) => {
    const [name, setName] = useState('');
    const [isSendable, setIsSendable] = useState(true);
    const [deleteId, setDeleteId] = useState([]);

    // const [disabled, setDisabled] = useState(false);
    const [errMessage, setErrMessage] = useState('');

    // useEffect(() => {
    //     reportMxData.is_submitted === 'Y' ? setDisabled(true) : setDisabled(false);
    // }, [reportMxData.is_submitted]);

    const seekerRef = useRef();

    const _handleAddSeeker = () => {
        let list = reportMxData?.seekers_list ? [...reportMxData.seekers_list] : [];

        if (name !== '') list.push(name);
        setReportMxData({ ...reportMxData, seekers_list: list });

        setName('');
        seekerRef.current.focus();
    };

    const _handleOnKeyPressToSubmit = (e) => {
        if (e.key === 'Enter') {
            _handleAddSeeker();
        }
    };

    const _handleDeleteSeeker = ({ idxForDelete, id }) => {
        let list = [];
        list.push({ id });
        setDeleteId(list);

        setReportMxData({ ...reportMxData, seekers_list: reportMxData.seekers_list.filter((_, idx) => idx !== idxForDelete) });
    };

    useEffect(() => {
        validateText(name) ? setErrMessage('특수문자를 입력할 수 없습니다.') : setErrMessage('');
    }, [name]);

    useEffect(() => {
        name && !validateText(name) ? setIsSendable(true) : setIsSendable(false);
    }, [name]);

    return (
        <div className="row">
            <div className="panel">
                <div className="panel-heading">
                    <h3>구도자 명단</h3>
                </div>
                <div className="panel-body">
                    <div className="col-xs-12 col-sm-12 col-md-12" style={{ paddingBottom: 10 }}>
                        <h4 style={{ color: '#999' }}>구도자 명단을 이름으로만 한명씩 작성해주세요 {`ex) 홍길동`}</h4>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="styled-group">
                            <input
                                ref={seekerRef}
                                className="styled-left3" //
                                value={name}
                                placeholder={'이름을 한명씩 등록해주세요'}
                                onChange={({ target }) => setName(target.value)}
                                disabled={disabled}
                                onKeyPress={_handleOnKeyPressToSubmit}
                                maxLength={10}
                            />
                            <button
                                className={`styled-right3 ${isSendable ? 'add' : 'disabled'}`} //
                                type="button"
                                disabled={!isSendable}
                                onClick={_handleAddSeeker}
                            >
                                {'추가'}
                            </button>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-8 col-md-8 styled-text-group">
                        {reportMxData?.seekers_list?.map((seeker, idx) => {
                            return (
                                <div className="styled-icon" key={idx}>
                                    <span className="seeker">{seeker}</span>
                                    {reportMxData.is_submitted === 'N' ? (
                                        <Icon
                                            icon="bi:x-circle-fill" //
                                            onClick={() => _handleDeleteSeeker({ idxForDelete: idx, id: seeker?.id })}
                                            style={{ marginLeft: 4 }}
                                            color="rgba(153, 153, 153, 0.6)"
                                        />
                                    ) : (
                                        ''
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    {errMessage && (
                        <span className="main-color-important col-xs-12 col-sm-12 col-md-12" style={{ fontSize: '1em' }}>
                            * {errMessage}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddSeekers;
