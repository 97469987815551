import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';

const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledText = styled.span`
    font-family: '기본체';
`;

const ManagerHeader = ({
    currentYear, //
}) => {
    return (
        <Header>
            <div className="first">
                <StyledText>{format(currentYear, 'yyyy')}년 인사관리</StyledText>
            </div>
        </Header>
    );
};

export default ManagerHeader;
