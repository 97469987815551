import React, { useState, useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { showDeleteConfirmBox } from '../../components/DeleteConfirmBox';
import { CommonContext, ProgressContext } from '../../contexts';
import { DataTypes } from '../../data/Types';

import { createNewBanner, getBanners, deleteBanner, updateBanner } from '../../data/mysql';

const AdminRollingLinks = () => {
    const { categories } = useContext(CommonContext);
    const { spinner } = useContext(ProgressContext);
    const { pathname } = useLocation();

    const [publishedBanners, setPublishedBanners] = useState([]);

    const [newBannerImage, setNewBannerImage] = useState('');
    const [newBannerLink, setNewBannerLink] = useState('');

    useEffect(() => {
        // 마운트 될때, 게시된 Banners를 가지고 온다.
        const init = async () => {
            setPublishedBanners(await getBanners());
        };
        init();
    }, []);

    /* 메인 롤링 배너를 생성한다. */
    const _handleCreateNewBanner = async () => {
        try {
            spinner.start();

            await createNewBanner({ bannerImageFile: newBannerImage, bannerLink: newBannerLink });

            setNewBannerImage('');
            setNewBannerLink('');
            setPublishedBanners(await getBanners());

            toast.success('배너가 등록 되었습니다');
        } catch (e) {
            console.log(e.message);
        } finally {
            spinner.stop();
        }
    };

    /* 해당 배너를 삭제한다. */
    const _handleDeleteBanner = async ({ bannerId }) => {
        try {
            spinner.start();

            await deleteBanner({ bannerId }); // 삭제
            setPublishedBanners(await getBanners());

            toast.success('배너가 삭제 되었습니다');
        } catch (e) {
            console.log(e.message);
        } finally {
            spinner.stop();
        }
    };

    /* 해당 배너의 링크의 value를 변경한다. */
    const _handleChangeLink = ({ bannerId, value }) => {
        setPublishedBanners(publishedBanners.map((banner) => (banner.id === bannerId ? { ...banner, link: value } : banner)));
    };

    /* 변경된 링크를 가지고 배너를 수정한다. */
    const _handleUpdateBanner = async ({ bannerId, bannerLink }) => {
        try {
            spinner.start();

            // console.log(bannerId, bannerLink);
            await updateBanner({ bannerId, bannerLink });

            setPublishedBanners(await getBanners());
        } catch (e) {
            console.log(e.message);
        } finally {
            spinner.stop();
        }
    };

    return (
        <div id="primary" className="content-area">
            {/* <!--s:#content --> */}
            <div id="content" className="site-content">
                <article>
                    <div>
                        <div className="title-inner">
                            <img src="/common/images/title.png" alt="" />
                            <h1>{categories.find((category) => category.categoryCode === pathname.split('/')[4])?.categoryName}</h1>
                        </div>
                        <div style={{ height: 20 }}></div>
                        <div style={{ clear: 'both' }}></div>
                        <div className="title_subpage_solid_1"></div>
                        <div style={{ height: 30 }}></div>
                        <div className="fileWrap">
                            <div className="file-upload" style={{ border: '1px solid black' }}>
                                {/* <button className='file-upload-btn' type='button'>슬라이드 1</button> */}
                                <div className="image-upload-wrap">
                                    {
                                        /* [입력부] ------------------------------------------
                                        전송할 이미지를 먼저 화면에서 보여주고, 그리고 나서 DB(Firebase)에 업로드 한다. */
                                        newBannerImage ? (
                                            <div id="preview">
                                                <div className="close" onClick={() => setNewBannerImage('')}>
                                                    <i className="material-icons">cancel</i>
                                                </div>
                                                <img src={URL.createObjectURL(newBannerImage)} alt="" /> {/* Blob 객체를 가리키는 URL을 생성하여 DOM에서 참조할 수 있게 한다. */}
                                            </div>
                                        ) : (
                                            <>
                                                <input className="file-upload-input" type="file" value="" onChange={(e) => setNewBannerImage(e.target.files[0])} accept="image/*" />
                                                <div className="file-upload-image">
                                                    <h3>터치(or 클릭)해서 이미지를 추가하십시오.</h3>
                                                    <p>
                                                        <span style={{ fontSize: 40, color: '#ddd' }}>
                                                            <i className="material-icons">add_photo_alternate</i>
                                                        </span>
                                                    </p>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                                <div className="input-box">
                                    <input type="text" className="form-control required" placeholder="링크를 입력하세요." value={newBannerLink} onChange={(e) => setNewBannerLink(e.target.value)} />
                                </div>
                                <div className="file-upload-btn-wrap">
                                    <button
                                        className="send-button"
                                        type="button"
                                        value=""
                                        style={{ opacity: newBannerImage ? 1 : 0.4 }}
                                        disabled={newBannerImage ? false : true}
                                        onClick={_handleCreateNewBanner}
                                    >
                                        슬라이드 추가
                                    </button>
                                </div>
                            </div>

                            {
                                /* [출력부] ------------------------------------------
                                    DB(Firebase)에서 가져온 배너 리스트를 화면에 뿌려준다. */
                                publishedBanners.map((banner) => {
                                    return (
                                        <div className="file-upload" key={banner.id}>
                                            <div
                                                className="close"
                                                style={{ color: 'black' }}
                                                // onClick={() => console.log(banner.id) }>
                                                onClick={() => showDeleteConfirmBox(() => _handleDeleteBanner({ bannerId: banner.id }))}
                                            >
                                                <i className="material-icons" color="red">
                                                    delete_forever
                                                </i>
                                            </div>
                                            <div className="file-upload-btn" type="button">
                                                # {banner.id}
                                            </div>
                                            <div className="image-upload-wrap">
                                                <img src={process.env.REACT_APP_DB_SERVER_URL + banner.image_url} alt="" />
                                            </div>
                                            <div className="input-box" style={{ display: 'flex' }}>
                                                <input
                                                    type="text"
                                                    className="form-control required"
                                                    placeholder="링크를 입력하세요."
                                                    value={banner.link}
                                                    onChange={(e) => _handleChangeLink({ bannerId: banner.id, value: e.target.value })}
                                                />
                                                <button
                                                    className="btn_black btn-history-back"
                                                    style={{ marginLeft: 5 }}
                                                    onClick={() => _handleUpdateBanner({ bannerId: banner.id, bannerLink: banner.link })}
                                                    // onClick={() => alert(banner.link)}
                                                >
                                                    수정
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </article>
            </div>
            {/* <!--//e: #content --> */}
        </div>
    );
};

export default AdminRollingLinks;
