/** @format */

import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { CommonContext, ProgressContext } from '../../contexts';
import { getPostCount, getPostList, getSearchedPostCount, searchPostList } from '../../data/mysql';

import BoardHeader from './BoardHeader';
import SearchBar from './components/SearchBar';

import BoardSearchTable from './components/BoardSearchTable';

import BoardTable from './components/BoardTable';

import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { is } from 'date-fns/locale';

let CATEGORY_NOTICE_ID = null;
let CATEGORY_COUNSEL_ID = null;

const BoardPages = () => {
    const { categories, settings } = useContext(CommonContext);
    const { spinner } = useContext(ProgressContext);

    const { categoryId } = useParams();
    const navigate = useNavigate();

    const [notices, setNotices] = useState([]);

    const [posts, setPosts] = useState([]);
    const [postTotalCount, setPostTotalCount] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(15); // 한 페이지에 들어갈 글의 갯 수
    const [postOffset, setPostOffset] = useState(0); //

    const [searchPosts, setSearchPosts] = useState([]);
    const [searchTotalCount, setSearchTotalCount] = useState(1);
    const [searchItem, setSearchItem] = useState('title');
    const [searchKeyword, setSearchKeyword] = useState('');

    const [isSearching, setIsSearching] = useState(false);

    const currentCategory = categories?.find((category) => category.id === parseInt(categoryId));

    const { search } = useLocation();
    const { searching, keyword, offset, item } = queryString.parse(search);

    // 카테고리 아이디가 공지사항이면 공지사항을 가져온다.
    useEffect(() => {
        CATEGORY_NOTICE_ID = settings.find((setting) => setting.name === 'category_notice_id')?.value;
        CATEGORY_COUNSEL_ID = settings.find((setting) => setting.name === 'category_counsel_id')?.value;

        if (CATEGORY_NOTICE_ID !== currentCategory.id) {
            const update = async () => {
                setNotices(await getPostList({ categoryId: CATEGORY_NOTICE_ID, limit: 5, offset: 0 }));
            };
            update();
        } else {
            setNotices([]);
        }
    }, [settings, currentCategory]);

    // 글 목록 가져오기
    // 1. 스테이트 초기화
    const resetStates = (offset) => {
        setIsSearching(false);
        setSearchKeyword('');
        setSearchItem('title');
        setPostOffset(offset);
    };

    // 2. 해당 카테고리 글 갯수 가져오기
    const getPostsMethod = async (offset) => {
        spinner.start();

        resetStates(offset);
        setPostTotalCount(await getPostCount({ categoryId: currentCategory.id }));

        spinner.stop();
    };

    // 3. 카테고리 아이디가 바뀌면 글 목록을 가져온다.
    useEffect(() => {
        getPostsMethod(offset);
    }, [categoryId]);

    // 검색결과에 따른 글 목록 업데이트
    // 1. 검색한 결과에 대한 게시글 갯수와 게시글 목록을 가져온다.
    const fetchSearchData = async () => {
        try {
            spinner.start();

            setSearchTotalCount(await getSearchedPostCount({ type: searchItem, keyword: searchKeyword, categoryId }));
            setSearchPosts(await searchPostList({ type: searchItem, keyword: searchKeyword, categoryId, limit: postsPerPage, offset: postOffset }));
        } catch (e) {
            console.log(e.message);
        } finally {
            spinner.stop();
        }
    };
    // 2. url에 담긴 검색어와 타입, 오프셋을 가져와서 검색결과를 업데이트한다.
    const searchingMethodByUrl = () => {
        setIsSearching(true);
        setSearchItem(item);
        setSearchKeyword(keyword);
        setPostOffset(offset);

        fetchSearchData();
    };
    // 3. 검색여부를 url에서 파악하여 검색결과를 업데이트한다.
    useEffect(() => {
        if (searching) {
            searchingMethodByUrl();
        }
    }, [searching]);

    // 페이지네이션 이동시 검색결과를 업데이트한다.
    useEffect(() => {
        const update = async () => {
            spinner.start();

            if (isSearching) {
                setSearchPosts(await searchPostList({ type: searchItem, keyword: searchKeyword, categoryId, limit: postsPerPage, offset: postOffset }));
            } else {
                setPosts(await getPostList({ categoryId: currentCategory.id, limit: postsPerPage, offset: postOffset }));
            }

            spinner.stop();
        };
        update();
    }, [postOffset, postTotalCount]);

    // 주소 관리
    // 1. 하위 컴포넌트 반복성 코드 정리
    const SEARCH_URL = `searching=${isSearching}&item=${searchItem}&keyword=${searchKeyword}&offset=${postOffset}`;
    const COMMON_URL = `offset=${postOffset}`;
    // 2. 검색중인 경우 search 아닌 경우 common url로 이동
    useEffect(() => {
        navigate(isSearching && searchKeyword ? `../list?${SEARCH_URL}` : `../list?${COMMON_URL}`);
    }, [isSearching, postOffset, searchKeyword]);

    // Fetch items from another resources.

    const searchBarProps = {
        postsPerPage,
        postOffset,
        isSearching,
        searchItem,
        searchKeyword,
        setSearchTotalCount,
        setSearchPosts,
        setPostOffset,
        setIsSearching,
        setSearchItem,
        setSearchKeyword,
        getPostsMethod,
        SEARCH_URL,
    };

    const boardSearchTableProps = {
        isSearching,
        searchPosts,
        searchTotalCount,
        postsPerPage,
        searchItem,
        searchKeyword,
        postOffset,
        CATEGORY_COUNSEL_ID,
        setSearchPosts,
        setPostOffset,
        SEARCH_URL,
        COMMON_URL,
    };
    const boardTableProps = {
        notices,
        posts,
        postTotalCount,
        postsPerPage,
        currentCategory,
        CATEGORY_COUNSEL_ID,
        CATEGORY_NOTICE_ID,
        setPostOffset,
        postOffset,
        SEARCH_URL,
        COMMON_URL,
    };

    return (
        <div id="primary" className="content-area">
            {/* <!--s:#content --> */}
            <div id="content" className="site-content">
                <article>
                    <div>
                        <BoardHeader />

                        <div className="article intro">
                            <SearchBar {...searchBarProps} />

                            {isSearching ? ( //
                                <BoardSearchTable {...boardSearchTableProps} />
                            ) : (
                                <BoardTable {...boardTableProps} />
                            )}
                        </div>
                    </div>
                    {/* <!-- .entry-content --> */}
                </article>
            </div>
            {/* <!--//e: #content --> */}
        </div>
    );
};

export default BoardPages;
