import React, { useState } from 'react';
import PopupDom from '../../../../components/PopupDom';
import PopupChurch from '../../../../components/PopupChurch';
import { Icon } from '@iconify/react';

const SearchingBox = ({ churchId, setChurchId, churchName, setChurchName }) => {
    const [isChurchPopupOpen, setIsChurchPopupOpen] = useState(false); // 교회검색 팝업창 상태 관리(true: 팝업, false: 감춤)
    const Reset = () => {
        setChurchId('');
        setChurchName('');
    };

    return (
        <div className="panel">
            <div className="panel-heading">
                <h3>교회 검색</h3>
            </div>
            <div className="panel-body" style={{ paddingBottom: 10 }}>
                <div>
                    <div className="col-xs-6 col-sm-5">
                        <input type="text" value={churchName} className="form-control required" maxLength="15" placeholder="수정할 교회" readOnly="readonly" />
                    </div>
                    <div id="churchDom" style={{ position: 'relative', zIndex: 999 }}>
                        {isChurchPopupOpen && (
                            <PopupDom domName="churchDom" style={{ width: '100%' }}>
                                <PopupChurch onClose={() => setIsChurchPopupOpen(false)} setChurchId={setChurchId} setChurchName={setChurchName} />
                            </PopupDom>
                        )}
                    </div>
                    <div className="col-xs-2 col-sm-2">
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <button className="btn-black-s" style={{ padding: 2.5 }} onClick={() => setIsChurchPopupOpen(true)}>
                                교회검색
                            </button>
                            {churchId ? (
                                <button className="styled-icon" style={{ fontSize: 23, margin: 0, marginLeft: 5 }} onClick={() => Reset()}>
                                    <Icon
                                        icon="bi:arrow-counterclockwise" //
                                    />
                                </button>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchingBox;
