import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useParams } from 'react-router-dom';

import { ProgressContext } from '../../../contexts';
import { getTermReportDataByAdmin, getTermReportDataForAdmin } from '../../../data/mysql_term';
import { saveReportPDF } from '../../../utils/common';
import { format } from 'date-fns';

import TermReportHeaderLeft from '../../termReport/components/TermReportHeaderLeft';
import TermReportHeaderMiddle from '../../termReport/components/TermReportHeaderMiddle';
import TermReportHeaderRight from '../../termReport/components/TermReportHeaderRight';
import TermReportBody from '../../termReport/components/TermReportBody';

const STAGE_NAME = ['영남합회(교회)', '영남합회(기관)', '영남합회'];

const AdminTermReportPrint = () => {
    const { spinner } = useContext(ProgressContext);

    const { dt, stage, churchId, exportMethod } = useParams();

    const [summingUpData, setSummingUpData] = useState([]); // 보고된 데이터의 합산통계 데이터 [{교회}, {기관}, {합계}]로 가져온다.
    const [termReportData, setTermReportData] = useState({}); // 개별 교회의 보고서 데이터
    const [isMount, setIsMount] = useState(false);

    useEffect(() => {
        const init = async () => {
            try {
                spinner.start();
                if (churchId) {
                    let result = await getTermReportDataByAdmin({ churchId, dt });
                    if (!result) throw new Error();

                    setTermReportData(result);
                } else if (stage) {
                    let result = await getTermReportDataForAdmin({ dt });
                    if (!result) throw new Error();

                    setSummingUpData(result[stage]);
                }
                setIsMount(true);
            } catch (e) {
                toast.error('데이터를 가져오는데 실패하였습니다.');
                console.log(e);
            } finally {
                spinner.stop();
            }
        };
        init();
    }, [churchId, stage]);

    /**
     * 레포트를 인쇄(print)한다.
     */
    const _handlePrintReport = () => {
        window.print();
    };

    /**
     * 다운로드 한다.
     */
    const _handleDownloadReport = async () => {
        if (!summingUpData && !termReportData) return;

        const fileName = churchId
            ? `기말보고서_${format(new Date(termReportData?.termReportInfo?.dt * 1000), `yyyy년Q기 ${termReportData?.termReportInfo?.church_name}`)}.pdf`
            : `기말보고서_${format(new Date(summingUpData?.termReportInfo?.dt * 1000), `yyyy년Q기 ${STAGE_NAME[stage]}`)}.pdf`;

        try {
            spinner.start();
            await saveReportPDF({ tableId: 'reportTable', fileName: fileName });
            toast.success('파일이 다운로드 되었습니다.');
        } catch (e) {
            console.log(e);
            toast.error('파일 다운로드에 실패하였습니다.');
        } finally {
            spinner.stop();
        }
    };

    useEffect(() => {
        if (!isMount) return;

        if (!summingUpData && !termReportData) return;

        exportMethod === 'print' && _handlePrintReport();
        exportMethod === 'download' && _handleDownloadReport();
    }, [isMount]);

    return (
        <div
            className="container"
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
                marginTop: 15,
            }}
        >
            <div
                id="reportTable"
                style={{
                    maxWidth: 830,
                    maxHeight: 1000,
                    paddingTop: 10,
                }}
            >
                <table
                    style={{
                        '@media print': {
                            height: '297mm',
                            width: '210mm',
                        },
                    }}
                >
                    <thead>
                        <tr>
                            <td>
                                <TermReportHeaderLeft
                                    termData={churchId ? termReportData : summingUpData} //
                                />
                            </td>
                            <td />
                            <td className="text-center text-vertical-middle">
                                <TermReportHeaderMiddle
                                    reportedFrom={churchId ? termReportData?.termReportInfo?.church_name : STAGE_NAME[stage]} //
                                />
                            </td>
                            <td />
                            <td className="text-vertical-bottom">
                                <TermReportHeaderRight //
                                    signLeft={churchId ? '교회서기' : '선교부장'}
                                    signMiddle={churchId ? '수석장로' : '총 무'}
                                    signRight={churchId ? '담임목사' : '합회장'}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={5} height={'5px'} />
                        </tr>
                    </thead>
                    <tbody>
                        <TermReportBody termData={churchId ? termReportData : summingUpData} />
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AdminTermReportPrint;
