import styles from './PopupHeader.module.css';

const PopupHeader = ({ onClick }) => {
  return (
    <div>
      <div className={styles.title}>
        <h1>연합회 보고</h1>
        <button className="styled-button report" style={{ width: 165, margin: 15 }} onClick={onClick}>
          제출
        </button>
      </div>
      <div style={{ height: 20 }}></div>
      <div style={{ clear: 'both' }}></div>
      <div className="title_subpage_solid_1"></div>
    </div>
  );
};

export default PopupHeader;
