import React, { useRef, useState, useEffect } from 'react';
import { getAllChurchInfo } from '../data/mysql';

const PopupChurch = ({ setChurchId, setChurchName, onClose }) => {
    const churchRef = useRef();
    const [searchKeyWord, setSearchKeyWord] = useState('');
    const [churches, setChurches] = useState([]);
    const [result, setResult] = useState([]);

    /* 검색어를 입력하면 자동으로 검색한다. */
    useEffect(() => {
        churchRef.current.focus();

        const init = async () => {
            const churchInfo = await getAllChurchInfo();

            setChurches(churchInfo.map((c) => `${c.id}::${c.name}::${c.addr}`));
        };
        init();
    }, []);

    /* 검색어를 입력하면 자동으로 검색한다. */
    useEffect(() => {
        if (searchKeyWord.trim().length >= 1) {
            setResult(churches.filter((doc) => doc.indexOf(searchKeyWord) !== -1));
        } else {
            setResult([]);
        }
    }, [searchKeyWord]);

    const _handleChangeChurchName = ({ target }) => {
        setSearchKeyWord(target.value);
    };

    return (
        <div className="church-container">
            <div className="close-btn" onClick={() => onClose()}>
                <span className="material-icons" style={{ fontSize: '1em' }}>
                    cancel
                </span>
            </div>
            <div className="church-header">
                <div className="input-nm">
                    <input ref={churchRef} value={searchKeyWord} placeholder="예) 함양교회" onChange={_handleChangeChurchName} />
                </div>
                <div className="search-btn">
                    <span className="material-icons" style={{ fontSize: '1em' }}>
                        search
                    </span>
                </div>
            </div>
            <div className="church-main">
                <div className="search-info">
                    {result.length === 0 ? (
                        <div style={{ padding: 10 }}>
                            <strong style={{ fontWeight: 600, color: '#D5A10E' }}>사용방법</strong>
                            <br />
                            교회 이름을 입력하시면 자동으로 검색됩니다.
                        </div>
                    ) : (
                        <div style={{ textAlign: 'right', fontSize: '0.8rem', color: '#777' }}>
                            검색된 교회 <strong style={{ fontSize: '0.8rem' }}>{result.length}</strong>
                        </div>
                    )}
                </div>
                <div className="church-list">
                    {result.length === 0 ? (
                        <div className="church-info">
                            <span style={{ color: '#777' }}>검색된 데이터가 없습니다.</span>
                        </div>
                    ) : (
                        result.map((church, index) => (
                            <div
                                className="church-info"
                                key={index}
                                onClick={() => {
                                    setChurchId(parseInt(church.split('::')[0]) || 0);
                                    setChurchName(church.split('::')[1] || '');
                                    onClose();
                                }}
                            >
                                <div className="church-name">{church.split('::')[1]}</div>
                                <div className="church-addr">{church.split('::')[2]}</div>
                                <span className="material-icons" style={{ fontSize: '1em', color: '#aaa' }}>
                                    north_west
                                </span>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

export default PopupChurch;
