import React, { useContext, useEffect, useRef, useState } from 'react';
import { addDays, addMonths, isSameMonth, isThisMonth, set, startOfMonth, subDays, subMonths } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { CommonContext, ProgressContext, UserContext } from '../../contexts';

import { getReportDxData, getReportMxData, getSeekers, saveReportData, saveReportDxData, submitReportData } from '../../data/mysql';

import ActivitiesCount from './screen/ActivitiesCount';
import AttendanceCount from './screen/AttendanceCount';
import BooksCount from './screen/BooksCount';
import MembersCount from './screen/MembersCount';
import ReportBaseInfo from './screen/ReportBaseInfo';

import SeekerCount from './screen/SeekerCount';
import WorkCalendar from './screen/WorkCalendar';
import AddSeekers from './screen/AddSeekers';

import toast from 'react-hot-toast';
import LostCount from './screen/LostCount';
import AddRequest from './screen/AddRequest';
import TransportationSelect from './screen/TransportationSelect';
import ActivitiesCountSum from './screen/ActivitiesCountSum';

import { Icon } from '@iconify/react';

const MonthlyReport = () => {
    const { spinner } = useContext(ProgressContext);
    const { categories } = useContext(CommonContext);
    const { user } = useContext(UserContext);
    const { pathname } = useLocation();

    const activitiesRef = useRef({});
    const calendarRef = useRef({});

    const [currentMonth, setCurrentMonth] = useState(subDays(new Date(), 10)); // 업무보고는 해당 월이 자난 후 작성하므로 10일 유예를 두어 전월 달력을 우선적으로 뜨게 한다.
    const [selectedDate, setSelectedDate] = useState(new Date());

    const [reportMxData, setReportMxData] = useState({});
    const [reportDxData, setReportDxData] = useState([]);

    const [isSendable, setIsSendable] = useState(true);
    const [isMount, setIsMount] = useState(false);

    const [disabled, setDisabled] = useState(false);

    const [disabledMassage, setDisabledMassage] = useState('');

    const timeZone = 'Asia/Seoul';
    const KoreaStartOfMonth = Math.floor(startOfMonth(currentMonth, { timeZone: timeZone }).getTime() / 1000);
    const KoreaNextStartOfMonth = Math.floor(startOfMonth(addMonths(currentMonth, 1, { timeZone: timeZone })).getTime() / 1000);

    useEffect(() => {
        // 1. 보고서의 기본 정보를 가져온다.

        if (!reportMxData === {}) return;

        const init = async () => {
            let result = await getReportMxData({
                userId: user.id, //
                dt: KoreaStartOfMonth, // 그 달의 첫날을 Unix Time으로 보낸다
            });
            // 데이터를 받아왔을 때, c_id가 없으면, baseInfo에 버튼 대신 에러 메세지를 보여준다.
            if (result && !result.c_id) {
                setDisabledMassage('* 등록된 교회가 없습니다. 합회로 문의해주세요');
            }

            if (isMount) return setReportMxData(result);
            // 보고가 되지 않았다면 전달 가져오고 보고가 되었다면 이번달 보고서 데이터를 가져온다
            if (result.is_submitted === 'Y' && isThisMonth(addDays(currentMonth, 10))) {
                result = await getReportMxData({
                    userId: user.id, //
                    dt: KoreaNextStartOfMonth, // 그 달의 첫날을 Unix Time으로 보낸다
                });
                return setReportMxData(result), setIsMount(true);
            } else {
                return setReportMxData(result);
            }
        };

        init();
    }, [currentMonth]);

    /**
     * c_id가 없으면 disabledMassage가 활성화 되고, disabledMassage가 활성화 되면 에러 토스트를 보여준다.
     */
    useEffect(() => {
        if (disabledMassage) toast.error('등록된 교회가 없습니다. 합회에 문의해주세요.');
    }, [disabledMassage]);

    /**
     * 전달 보고를 완료하였다면 이번달 달력을 보여준다.
     */
    useEffect(() => {
        if (!isSameMonth(currentMonth, selectedDate)) setCurrentMonth(addMonths(currentMonth, 1));
    }, [isMount]);

    /**
     */
    useEffect(() => {
        reportMxData.is_submitted === 'Y' ? setIsSendable(false) : setIsSendable(true);

        if (!reportMxData.id) return;
        if (reportMxData.is_submitted === 'N') return;

        // 0. submit 되었다면
        const update = async () => {
            const initCalender = await calendarRef.current.initCalendar(); // 1. 달력기본 틀을 만든다.
            const submittedDxData = await getReportDxData({ reportId: reportMxData.id }); // 2. 일별 데이터를 가져온다.

            setReportDxData(
                initCalender.map((week) =>
                    week.map((day) => ({
                        ...day,
                        seekerCnt: submittedDxData.visits?.find((v) => day.dt === v.dt)?.seekers_cnt || 0,
                        memberCnt: submittedDxData.visits?.find((v) => day.dt === v.dt)?.members_cnt || 0,
                        teachCnt: submittedDxData.teaches?.find((v) => day.dt === v.dt)?.cnt || 0,
                        sermonCnt: submittedDxData.sermons?.find((v) => day.dt === v.dt)?.cnt || 0,
                        transportation: submittedDxData.transportation?.find((v) => day.dt === v.dt)?.cost || 0,
                        activities: submittedDxData.activities?.find((v) => day.dt === v.dt)?.content || '',
                    })),
                ),
            );
        };

        update();
    }, [reportMxData]);

    /**
     * 레포트를 전송한다.
     */
    const _handleSendReport = async () => {
        try {
            spinner.start();

            const result = await submitReportData({ reportId: reportMxData.id, reportMxData, reportDxData });
            setReportMxData(
                await getReportMxData({
                    userId: user.id, //
                    dt: KoreaStartOfMonth,
                }),
            );
            if (result) {
                setIsSendable(false);
                toast.success('레포트를 전송하였습니다.');
            } else {
                toast.error('레포트 전송에 실패하였습니다.');
            }
        } catch (e) {
            toast.error('레포트 전송에 실패하였습니다.');
            console.log(e);
        } finally {
            spinner.stop();
        }
    };

    /**
     * '다시 작성'버튼을 누르면 일 데이터를 초기화한다.
     */
    const _handleResetReport = async () => {
        setReportDxData(await calendarRef.current.initCalendar());
        activitiesRef.current.resetState();
        toast.success('초기화 되었습니다.');
    };

    /**
     * 월(月) 데이터를 임시로 저장한다.
     */
    const _handleSaveReport = async () => {
        let result;
        try {
            spinner.start();

            result = await saveReportData({ reportId: reportMxData.id, dxData: reportDxData, mxData: reportMxData });
            result && toast.success('저장 되었습니다.');

            return console.log('save: ', result);
        } catch (e) {
            console.log(e);
        } finally {
            spinner.stop();
        }
    };

    useEffect(() => {
        !reportMxData.c_id || reportMxData.is_submitted === 'Y' ? setDisabled(true) : setDisabled(false);
    }, [reportMxData.is_submitted]);

    return (
        <div id="primary" className="content-area">
            {/* <!--s:#content --> */}
            <div id="content" className="site-content">
                <article>
                    <div>
                        <div className="title-inner">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>
                                    <img src="/common/images/title.png" alt="" />
                                    <h1>{categories.find((category) => category.categoryCode === pathname.split('/')[4])?.categoryName}</h1>
                                </div>
                                <div
                                    style={{
                                        display: 'flex', //
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => window.open('https://youtu.be/LehiNxGCBVs')}
                                >
                                    <Icon icon="entypo-social:youtube-with-circle" width={40} color={'#888'} />
                                    <span style={{ fontFamily: '기본체', fontSize: '0.8em', color: '#888' }}>사용방법</span>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 20 }}></div>
                        <div style={{ clear: 'both' }}></div>
                        <div className="title_subpage_solid_1"></div>

                        <div className="container-fluid">
                            <ReportBaseInfo //
                                disabledMassage={disabledMassage}
                                disabled={disabled}
                                reportMxData={reportMxData}
                                isSendable={isSendable}
                                onSendButtonClick={_handleSendReport}
                                onResetButtonClick={_handleResetReport}
                                onSaveButtonClick={_handleSaveReport}
                            />

                            <WorkCalendar //
                                disabledDiv={disabled}
                                currentMonth={currentMonth}
                                selectedDate={selectedDate}
                                setCurrentMonth={setCurrentMonth}
                                reportDxData={reportDxData}
                                reportMxData={reportMxData}
                                setReportDxData={setReportDxData}
                                setSelectedDate={setSelectedDate}
                                ref={calendarRef}
                            />

                            <ActivitiesCountSum reportDxData={reportDxData} />

                            <ActivitiesCount //
                                disabled={disabled}
                                reportMxData={reportMxData}
                                reportDxData={reportDxData}
                                setReportDxData={setReportDxData}
                                setReportMxData={setReportMxData}
                                isSendable={isSendable}
                                ref={activitiesRef}
                            />

                            <SeekerCount
                                disabled={disabled}
                                contactCnt={reportMxData.seekers_contact} // 접촉중인 구도자
                                studyCnt={reportMxData.seekers_study} // 공부중인 구도자
                                baptismStudents={reportMxData.seekers_baptism} // 침례반 구도자
                                baptistCnt={reportMxData.baptism} // 수침자수
                                reportMxData={reportMxData}
                                setReportMxData={setReportMxData}
                            />
                            <LostCount
                                disabled={disabled}
                                confirmCnt={reportMxData.lost_confirm}
                                contactCnt={reportMxData.lost_contact}
                                recoverCnt={reportMxData.lost_recover}
                                reportMxData={reportMxData}
                                setReportMxData={setReportMxData}
                            />
                            <BooksCount
                                disabled={disabled}
                                compasCnt={reportMxData.books_compas} // 교회지남
                                seniorCnt={reportMxData.books_senior} // 장년교과
                                teenCnt={reportMxData.books_teen} // 청소년교과
                                juniorCnt={reportMxData.books_junior} // 유년교과
                                reportMxData={reportMxData}
                                setReportMxData={setReportMxData}
                            />

                            <MembersCount
                                disabled={disabled}
                                memberCnt={reportMxData.members} // 교인수
                                memberFamilyCnt={reportMxData.members_family} // 교인세대수
                                memberSchoolCnt={reportMxData.members_school} // 안교생수
                                reportMxData={reportMxData}
                                setReportMxData={setReportMxData}
                            />

                            <AttendanceCount
                                disabled={disabled}
                                tuesdayCnt={reportMxData.attendance_tue} // 화요일 평균 출석생수
                                fridayCnt={reportMxData.attendance_fri} // 금요일 평균 출석생수
                                sabbathCnt={reportMxData.attendance_sat} // 안식일(토) 평균 출석생수
                                reportMxData={reportMxData}
                                setReportMxData={setReportMxData}
                            />

                            <AddSeekers
                                disabled={disabled}
                                reportMxData={reportMxData} //
                                setReportMxData={setReportMxData}
                            />

                            <AddRequest
                                disabled={disabled}
                                request={reportMxData.request}
                                reportMxData={reportMxData} //
                                setReportMxData={setReportMxData}
                            />

                            <TransportationSelect
                                disabled={disabled}
                                starex={reportMxData.by_church_car}
                                bmw={reportMxData.by_public_transport}
                                myCar={reportMxData.by_own_car}
                                reportMxData={reportMxData} //
                                setReportMxData={setReportMxData}
                            />
                        </div>
                    </div>
                    {/* <!-- .entry-content --> */}
                </article>
            </div>
            {/* <!--//e: #content --> */}
        </div>
    );
};

export default MonthlyReport;
