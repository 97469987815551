import React from 'react';
import styled from 'styled-components';

const StyledTd = styled.td`
    /* padding: 2px; */
    border: 0.5px solid black;
    font-family: '기본체' !important;
    font-size: 0.7em;
    line-height: 15px;
    height: 23px;

    @media print {
        /* padding: 2px; */
        border: 0.5px solid black;
        font-family: '기본체' !important;
        font-size: 0.7em;
        text-align: center;
        line-height: 15px;
        height: 23px;
    }
`;

const TermReportHeaderRight = ({ signLeft, signMiddle, signRight }) => {
    return (
        <table width={'100%'}>
            <tbody>
                <tr>
                    <StyledTd className="text-center text-vertical-middle" rowSpan={2}>
                        확<br />인
                    </StyledTd>
                    <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                        {signLeft}
                    </StyledTd>
                    <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                        {signMiddle}
                    </StyledTd>
                    <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                        {signRight}
                    </StyledTd>
                </tr>
                <tr>
                    <StyledTd className="text-center text-vertical-middle" colSpan={3} style={{ height: '70px' }}></StyledTd>
                    <StyledTd className="text-center text-vertical-middle" colSpan={3}></StyledTd>
                    <StyledTd className="text-center text-vertical-middle" colSpan={3}></StyledTd>
                </tr>
            </tbody>
        </table>
    );
};
export default TermReportHeaderRight;
