/** @format */

import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CommonContext } from '../../contexts';
import { DataTypes } from '../../data/Types';

const InfoYCLocation = () => {
    const { kakao } = window;

    const { categories } = useContext(CommonContext);
    const { pathname } = useLocation();

    useEffect(() => {
        const container = document.getElementById('map');

        const options = {
            center: new kakao.maps.LatLng(35.84046644244145, 128.68456644680592),
            level: 3,
        };

        const map = new kakao.maps.Map(container, options);

        // 마커가 표시될 위치입니다
        const markerPosition = new kakao.maps.LatLng(35.84046644244145, 128.68456644680592);

        // 마커를 생성합니다
        const marker = new kakao.maps.Marker({
            position: markerPosition,
        });

        // 마커가 지도 위에 표시되도록 설정합니다
        marker.setMap(map);

        //드래그 막기
        // map.setDraggable(false);
        //줌 막기
        map.setZoomable(false);
    }, []);

    return (
        <div id='primary' className='content-area'>
            {/* <!--s:#content --> */}
            <div id='content' className='site-content'>
                <article>
                    <div>
                        <div className='title-inner'>
                            <img src='/common/images/title.png' alt='' />
                            <h1>{ categories.find( category => category.categoryCode === pathname.split('/')[4])?.categoryName}</h1>
                        </div>
                        <div style={{ height: 20 }}></div>
                        <div style={{ clear: 'both' }}></div>
                        <div className='title_subpage_solid_1'></div>
                        <div style={{ height: 30 }}></div>

                        <div className='greetings_wrap'>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', margin: 4, textAlign: 'center'  }}>

                                {/* <a href='tmap://search?name=영남합회' target='_blank' rel="noreferrer">
                                    <img style={{ margin: 4, width: 40 }} src='../common/images/tmap.png' alt=''/>
                                    <br />
                                    <span className='small-text'>TMAP</span>
                                </a>
                                <a href='https://map.kakao.com/link/to/영남합회,35.84046644244145,128.68456644680592' target='_blank' rel="noreferrer">
                                    <img style={{ margin: 4, width: 40 }} src='../common/images/naver_map.png' alt=''/>
                                    <br />
                                    <span className='small-text'>네이버맵</span>
                                </a>
                                <a href='https://map.kakao.com/link/to/영남합회,35.84046644244145,128.68456644680592' target='_blank' rel="noreferrer">
                                    <img style={{ margin: 4, width: 40 }} src='../common/images/google_map.png' alt=''/>
                                    <br />
                                    <span className='small-text'>구글맵</span>
                                </a> */}
                                <a href='https://map.kakao.com/link/to/영남합회,35.84046644244145,128.68456644680592' target='_blank' rel="noreferrer">
                                    <img style={{ margin: 4, width: 40 }} src='/common/images/kakao_map.png' alt=''/>
                                    <br />
                                    <span className='small-text'>길찾기</span>
                                </a>
                            </div>
                            {/* <!--
                                * 카카오맵 - 약도서비스
                                * 한 페이지 내에 약도를 2개 이상 넣을 경우에는
                                * 약도의 수 만큼 소스를 새로 생성, 삽입해야 합니다.
                            --> */}
                            {/* <!-- 1. 약도 노드 --> */}
                            <div id='map' className='root_daum_roughmap root_daum_roughmap_landing' style={{ width: '100%', height: 500 }}></div>

                            {/* <!-- 2. 설치 스크립트 --> */}
                            {/* <script charset='UTF-8' className='daum_roughmap_loader_script' src='https://ssl.daumcdn.net/dmaps/map_js_init/roughmapLoader.js'></script> */}

                            {/* <!-- 3. 실행 스크립트 --> */}
                            {/* <script charset='UTF-8'>
                                                            new daum.roughmap.Lander({
                                    'timestamp': '1638240818243',
                                    'key': '289er',
                                    'mapHeight': '400'
                                }).render();

                            </script> */}
                            <div className='map_detail'>
                                <div className='inner'>
                                    <div className='left'>
                                        <img src='/common/images/map_ico.png' alt='/' />
                                    </div>
                                    <div className='right'>
                                        <p> (우 42250) 대구광역시 수성구 월드컵로 125(시지동 568) </p>
                                        <span>
                                            <i>TEL.</i>{`${ DataTypes.WEBSITE_INFO_TEL }`}
                                        </span>
                                        <span>
                                            <i>FAX.</i>053-654-1760
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- .entry-content --> */}
                </article>
            </div>
            {/* <!--//e: #content --> */}
        </div>
    );
};

export default InfoYCLocation;
