import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Icon } from '@iconify/react';

import SelectOption from '../../adminChurchesManager/components/SelectOption';
import ChurchEditor from './ChurchEditor';

const ListTable = styled.table`
    border: 1px solid silver;
    border-radius: 3px;
    border-collapse: separate;
    background-color: rgb(236, 236, 236);
    margin-bottom: 10px;
    width: 100%;
`;
const ListTr = styled.tr`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 0.95em;
    text-align: center;
    vertical-align: middle;
    height: 36px;
`;
const CreateTr = styled.tr`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 1em;
    text-align: center;
    vertical-align: middle;
    height: 50px;
    background-color: white;
`;
const CreateTd = styled.td`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 1em;
    text-align: center;
    vertical-align: middle;
    background-color: white;
`;
const ListTd = styled.td`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 0.95em;
    text-align: center;
    vertical-align: middle;
`;
const ListTh = styled.th`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    height: 35px;
    font-size: 1.2em;
    text-align: center;
    vertical-align: middle;
    background-color: rgb(212, 212, 212);
`;
const EditDiv = styled.div`
    font-family: '기본체' !important;
    font-size: 1em;
    text-align: center;
    vertical-align: middle;
    align-items: center;
`;
const ListButton = styled.button`
    font-family: '기본체' !important;
    font-size: 1em;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    width: 100%;
`;
const CreateButton = styled.button`
    font-weight: 800;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    width: 100%;
    background-color: white;
`;

const ChurchesTable = ({
    areas,
    churchData, // 현재 교회 데이터
    setChurchData,
    selectedArea,
    setSelectedArea,
    churchInfo, // 새로운 교회 생성을 위한 정보
    setChurchInfo,

    handleDelChurch,
    handleEditChurch,
    handleCreateChurch,
    handleResetChurch,
}) => {
    const addressDetailRef = useRef();

    const [isOpen, setIsOpen] = useState(false);

    // 교회생성시 초기값 areaId를 넣어준다
    useEffect(() => {
        if (!churchInfo.areaId) {
            setChurchInfo((prevChurchInfo) => ({
                ...prevChurchInfo,
                areaId: selectedArea,
            }));
        }
    }, [churchInfo.areaId]);

    // 교회생성시 지역을 변경할 경우 선태한 지역의 areaId를 넣어준다
    useEffect(() => {
        setChurchInfo({ ...churchInfo, areaId: selectedArea });
    }, [selectedArea]);

    // churchData에서 수정할 교회의 편집창을 열고 닫게 만들어준다.
    const _handleIsOpen = (targetId) => {
        setChurchData((prevData) => {
            return prevData.map((info) => {
                if (targetId === info.id) {
                    return { ...info, isOpen: !info.isOpen };
                }
                return info;
            });
        });
    };
    // churchData에서 수정하고 있는 해당 교회의 이름을 변경해준다.
    const _handleNameChange = (e, targetId) => {
        {
            targetId
                ? setChurchData((prevData) => {
                      return prevData.map((info) => {
                          if (targetId === info.id) {
                              return { ...info, newName: e.target.value };
                          }
                          return info;
                      });
                  })
                : setChurchInfo((prevInfo) => ({ ...prevInfo, newName: e.target.value }));
        }
    };
    // churchData에서 수정하고 있는 해당 교회의 전화번호를 변경해준다.
    const _handleTelChange = (e, targetId) => {
        const inputVal = e.target.value;
        const numericOnly = inputVal.replace(/[^0-9]/g, ''); // 숫자만 남기고 제거

        if (inputVal !== numericOnly) {
            // 숫자가 아닌 문자가 포함되어 있는 경우
            e.preventDefault();
        }

        let temp = numericOnly;
        if (numericOnly.length === 10) {
            temp = numericOnly.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        } else if (numericOnly.length === 11) {
            temp = numericOnly.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
        } else if (numericOnly.length > 11) {
            temp = numericOnly.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
        }

        {
            targetId
                ? setChurchData((prevData) => {
                      return prevData.map((info) => {
                          if (targetId === info.id) {
                              return { ...info, tel: temp };
                          }
                          return info;
                      });
                  })
                : setChurchInfo((prevInfo) => ({ ...prevInfo, tel: temp }));
        }
    };
    // churchData에서 수정하고 있는 해당 교회의 homepage를 변경해준다.
    const _handleHomepageChange = (e, targetId) => {
        {
            targetId
                ? setChurchData((prevData) => {
                      return prevData.map((info) => {
                          if (targetId === info.id) {
                              return { ...info, homepage: e.target.value };
                          }
                          return info;
                      });
                  })
                : setChurchInfo((prevInfo) => ({ ...prevInfo, homepage: e.target.value }));
        }
    };
    // churchData에서 수정하고 있는 해당 교회의 type을 변경해준다.
    const _handleTypeChange = (e, targetId) => {
        {
            targetId
                ? setChurchData((prevData) => {
                      return prevData.map((info) => {
                          if (targetId === info.id) {
                              return { ...info, type: parseInt(e.target.value) };
                          }
                          return info;
                      });
                  })
                : setChurchInfo((prevInfo) => ({ ...prevInfo, type: parseInt(e.target.value) }));
        }
    };
    // churchData에서 수정하고 있는 해당 교회의 state를 변경해준다.
    const _handleStateChange = (e, targetId) => {
        {
            targetId
                ? setChurchData((prevData) => {
                      return prevData.map((info) => {
                          if (targetId === info.id) {
                              return { ...info, state: parseInt(e.target.value) };
                          }
                          return info;
                      });
                  })
                : setChurchInfo((prevInfo) => ({ ...prevInfo, state: parseInt(e.target.value) }));
        }
    };
    // churchData에서 수정하고 있는 해당 교회의 areaId를 변경해준다.
    const _handleAreaChange = (e, targetId) => {
        {
            targetId
                ? setChurchData((prevData) => {
                      return prevData.map((info) => {
                          if (targetId === info.id) {
                              return { ...info, areaId: parseInt(e.target.value) };
                          }
                          return info;
                      });
                  })
                : setChurchInfo((prevInfo) => ({ ...prevInfo, areaId: parseInt(e.target.value) }));
        }
    };
    // churchData에서 수정하고 있는 해당 교회의 isAddrPopupOpen를 변경해준다.
    const _handleIsAddrPopupChange = (targetId) => {
        {
            targetId
                ? setChurchData((prevData) => {
                      return prevData.map((info) => {
                          if (targetId === info.id) {
                              return { ...info, isAddrPopupOpen: !info.isAddrPopupOpen };
                          }
                          return info;
                      });
                  })
                : setChurchInfo((prevInfo) => ({ ...prevInfo, isAddrPopupOpen: !prevInfo.isAddrPopupOpen }));
        }
    };
    // churchData에서 수정하고 있는 해당 교회의 addressDetail을 변경해준다.
    const _handleAddrChange = (fullAddress, targetId) => {
        {
            targetId
                ? setChurchData((prevData) => {
                      return prevData.map((info) => {
                          if (targetId === info.id) {
                              return { ...info, addr: fullAddress };
                          }
                          return info;
                      });
                  })
                : setChurchInfo((prevInfo) => ({ ...prevInfo, addr: fullAddress }));
        }
    };

    // churchData에서 수정하고 있는 해당 교회의 addressDetail을 변경해준다.
    const _handleAddrDetailChange = (e, targetId) => {
        {
            targetId
                ? setChurchData((prevData) => {
                      return prevData.map((info) => {
                          if (targetId === info.id) {
                              return { ...info, addressDetail: e.target.value };
                          }
                          return info;
                      });
                  })
                : setChurchInfo((prevInfo) => ({ ...prevInfo, addressDetail: e.target.value }));
        }
    };

    /* 초점(Focus)을 상세주소로 옮긴다. */
    const _handleFocusAddressDetail = () => {
        addressDetailRef.current.focus();
    };

    // 해당 교회의 기관 여부를 체크해준다.
    const _handleCheckAgency = (e, targetId) => {
        {
            targetId
                ? setChurchData((prevData) => {
                      return prevData.map((info) => {
                          if (targetId === info.id) {
                              return { ...info, isAgency: !prevData.isAgency };
                          }
                          return info;
                      });
                  })
                : setChurchInfo((prevInfo) => ({ ...prevInfo, isAgency: !prevInfo.isAgency }));
        }
    };

    return (
        <div>
            <ListTable>
                <thead style={{ height: '38px' }}>
                    <ListTr>
                        <ListTh colSpan={5}>
                            <SelectOption
                                options={areas} //
                                value={selectedArea}
                                setValue={setSelectedArea}
                            />
                        </ListTh>
                    </ListTr>
                </thead>
                <tbody>
                    <CreateTr>
                        <CreateTd colSpan={5}>
                            <CreateButton //
                                onClick={() => setIsOpen(!isOpen)}
                            >
                                <EditDiv className="col-xs-11 col-sm-11 col-md-11 mb5 mt5">{isOpen ? '교회 생성중' : '+ 교회 생성'}</EditDiv>
                                <EditDiv className="col-xs-1 col-sm-1 col-md-1 mb5 mt5">
                                    {isOpen ? (
                                        <Icon
                                            icon="bi:chevron-up" //
                                            color="black"
                                        />
                                    ) : (
                                        <Icon
                                            icon="bi:chevron-down" //
                                            color="black"
                                        />
                                    )}
                                </EditDiv>
                            </CreateButton>
                        </CreateTd>
                    </CreateTr>
                    {isOpen ? (
                        <tr>
                            <td colSpan={5}>
                                <ChurchEditor
                                    areas={areas}
                                    church={churchInfo}
                                    setChurchInfo={setChurchInfo}
                                    selectedArea={selectedArea} // 교회 생성시 열람중인 교회지역을 초기값으로 갖게 하기 위해서 전달
                                    handleNameChange={_handleNameChange}
                                    handleTypeChange={_handleTypeChange}
                                    handleTelChange={_handleTelChange}
                                    handleStateChange={_handleStateChange}
                                    handleHomepageChange={_handleHomepageChange}
                                    handleAreaChange={_handleAreaChange}
                                    handleIsAddrPopupChange={_handleIsAddrPopupChange}
                                    handleFocusAddressDetail={_handleFocusAddressDetail}
                                    handleAddrChange={_handleAddrChange}
                                    addressDetailRef={addressDetailRef}
                                    handleAddrDetailChange={_handleAddrDetailChange}
                                    handleCreateChurch={handleCreateChurch}
                                    handleResetChurch={handleResetChurch}
                                    handleCheckAgency={_handleCheckAgency}
                                />
                            </td>
                        </tr>
                    ) : null}

                    {churchData
                        ?.filter((info) => info.area_id === selectedArea)
                        .map((church) => {
                            return (
                                <React.Fragment key={church.id}>
                                    <ListTr>
                                        <ListTd colSpan={5}>
                                            <ListButton //
                                                onClick={() => _handleIsOpen(church.id)}
                                            >
                                                <EditDiv className="col-xs-11 col-sm-11 col-md-11 mb5 mt5"> {church.name}</EditDiv>
                                                <EditDiv className="col-xs-1 col-sm-1 col-md-1 mb5 mt5">
                                                    {church.isOpen ? (
                                                        <Icon
                                                            icon="bi:chevron-up" //
                                                            color="black"
                                                        />
                                                    ) : (
                                                        <Icon
                                                            icon="bi:chevron-down" //
                                                            color="black"
                                                        />
                                                    )}
                                                </EditDiv>
                                            </ListButton>
                                        </ListTd>
                                    </ListTr>
                                    {church.isOpen ? (
                                        <tr>
                                            <td colSpan={5}>
                                                <ChurchEditor
                                                    areas={areas}
                                                    church={church}
                                                    handleNameChange={_handleNameChange}
                                                    handleTypeChange={_handleTypeChange}
                                                    handleTelChange={_handleTelChange}
                                                    handleStateChange={_handleStateChange}
                                                    handleHomepageChange={_handleHomepageChange}
                                                    handleAreaChange={_handleAreaChange}
                                                    handleIsAddrPopupChange={_handleIsAddrPopupChange}
                                                    handleFocusAddressDetail={_handleFocusAddressDetail}
                                                    handleAddrChange={_handleAddrChange}
                                                    handleAddrDetailChange={_handleAddrDetailChange}
                                                    addressDetailRef={addressDetailRef}
                                                    handleDelChurch={handleDelChurch}
                                                    handleEditChurch={handleEditChurch}
                                                    handleResetChurch={handleResetChurch}
                                                    handleCheckAgency={_handleCheckAgency}
                                                />
                                            </td>
                                        </tr>
                                    ) : null}
                                </React.Fragment>
                            );
                        })}
                </tbody>
            </ListTable>
        </div>
    );
};

export default ChurchesTable;
