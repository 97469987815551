import React, { useEffect, useRef, useState } from 'react';
import CheckboxGroup from '../components/CheckboxGroup';

import InputGroup3 from '../components/InputGroup3';

const TransportationSelect = ({ starex, bmw, myCar, reportMxData, setReportMxData, disabled }) => {
    // const [disabled, setDisabled] = useState(false);

    // useEffect(() => {
    //     reportMxData.is_submitted === 'Y' ? setDisabled(true) : setDisabled(false);
    // }, [reportMxData.is_submitted]);

    return (
        <div className="row">
            <div className="panel">
                <div className="panel-heading">
                    <h3>기타</h3>
                </div>
                <div className="panel-body">
                    <div className="col-xs-12 col-sm-4 col-md-4">
                        <CheckboxGroup
                            checked={starex || 0} //
                            label={'교회차량'}
                            disabled={disabled}
                            setReportMxData={setReportMxData}
                            reportColumnName={'by_church_car'}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4">
                        <CheckboxGroup
                            checked={bmw || 0} //
                            label={'무차량 교통비 보조'}
                            disabled={disabled}
                            setReportMxData={setReportMxData}
                            reportColumnName={'by_public_transport'}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4">
                        <CheckboxGroup
                            checked={myCar || 0} //
                            label={'개인소유 차량 보조'}
                            disabled={disabled}
                            setReportMxData={setReportMxData}
                            reportColumnName={'by_own_car'}
                        />
                    </div>
                    <div className="col-xs-8 col-sm-9 col-md-9" />
                </div>
            </div>
        </div>
    );
};

export default TransportationSelect;
