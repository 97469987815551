import React from 'react';
import { useDrop } from 'react-dnd';

const DropBox = ({ children }) => {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: 'CARD',
        drop: () => ({ c_id: null }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const getBackgroundColor = () => {
        if (isOver) {
            if (canDrop) {
                return 'rgb(188,251,255)';
            } else if (!canDrop) {
                return 'rgb(255,188,188)';
            }
        } else {
            return '';
        }
    };

    return (
        <div ref={drop} style={{ backgroundColor: getBackgroundColor(), height: 'auto', minHeight: '60px' }}>
            {children}
        </div>
    );
};
export default DropBox;
