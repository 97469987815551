import React from 'react';
import { DataTypes } from '../../data/Types';

import Header from '../common/Header';
import MobileMenu from '../common/MobileMenu';
import SubHeaderBar from '../common/SubHeaderBar';
import Footer from '../common/Footer';

const Privacy = () => {
    return (
        <>
            <Header />
            <MobileMenu /> {/* 사이드 메뉴(Mobile Menu): 사이즈를 줄였을 때, 왼쪽 사이드에 붙는 메뉴 */}
            <SubHeaderBar /> {/* 홈 > 합회소개 > 합회소개 */}
            <div id="content-wrap" className="container">
                <div id="primary" className="content-area content-area1">
                    <div id="content" className="site-content">
                        <article>
                            <div>
                                <div className="title-inner">
                                    <img src="../common/images/title.png" alt="" />
                                    <h1>개인정보처리방침</h1>
                                </div>
                                <div style={{ height: 20 }}></div>
                                <div style={{ clear: 'both' }}></div>
                                <div className="title_subpage_solid_1"></div>
                                <div style={{ height: 30 }}></div>
                                <div className="article intro">
                                    <div id="txt" className="txt_list">
                                        <p style={{ border: '1px solid #d4dae8', background: '#eceff6', margin: 0, padding: '17px 10px 15px 20px' }}>
                                            영남합회(이하 '영남합회'이라 함)는 회원의 개인정보 보호를 매우 중요시하며, 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』 및 전기통신사업법,
                                            통신비밀보호법을 준수하고 있습니다.
                                            <br />
                                            <br />
                                            영남합회은 개인정보처리방침을 수립하여 회원의 개인정보를 보호하고 있으며, 이를 홈페이지에 명시하여 회원이 온라인상에서 지역원에 제공한 개인정보가 어떠한
                                            용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치를 취하는지 알려드립니다.
                                        </p>
                                        <h3 className="h3_title">
                                            <i className="fa fa-check-circle"></i>제1조 (개인정보의 처리목적){' '}
                                        </h3>
                                        <p>
                                            '영남합회'은 다음의 목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다.
                                            <br />- 고객 가입의사 확인, 고객에 대한 서비스 제공에 따른 본인 식별.인증, 회원자격 유지.관리, 물품 또는 서비스 공급에 따른 금액 결제, 물품 또는 서비스의
                                            공급.배송 등
                                        </p>

                                        <h3 className="h3_title">
                                            <i className="fa fa-check-circle"></i>제2조 (개인정보의 처리 및 보유기간)
                                        </h3>
                                        <p>
                                            ① '영남합회'은 정보주체로부터 개인정보를 수집할 때 동의 받은 개인정보 보유ㆍ이용기간 또는 법령에 따른 개인정보 보유ㆍ이용기간 내에서 개인정보를
                                            처리ㆍ보유합니다.
                                            <br />② 구체적인 개인정보 처리 및 보유 기간은 다음과 같습니다.
                                        </p>
                                        <div>
                                            <ul className="bold" style={{ paddingLeft: 20 }}>
                                                <li>☞ 아래 예시를 참고하여 개인정보 처리업무와 개인정보 처리업무에 대한 보유기간 및 관련 법령, 근거 등을 기재합니다.</li>
                                                <li>(예시)- 고객 가입 및 관리 : 서비스 이용계약 또는 회원가입 해지시까지, 다만 채권ㆍ채무관계 잔존시에는 해당 채권ㆍ채무관계 정산시까지</li>
                                                <li>- 전자상거래에서의 계약ㆍ청약철회, 대금결제, 재화 등 공급기록 : 5년</li>
                                            </ul>
                                        </div>
                                        <h3 className="h3_title">
                                            <i className="fa fa-check-circle"></i>제3조(개인정보의 제3자 제공)
                                        </h3>
                                        <p>
                                            ① '영남합회'은 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
                                            <br />② 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
                                        </p>
                                        <div>
                                            <ul className="bold" style={{ paddingLeft: 20 }}>
                                                <li>1.</li>
                                                <li>- 개인정보를 제공받는 자 :</li>
                                                <li>- 제공받는 자의 개인정보 이용목적 : 이메일, 휴대전화번호, 비밀번호, 로그인ID, 이름</li>
                                                <li>- 제공받는 자의 보유.이용기간: 지체없이 파기</li>
                                            </ul>
                                        </div>
                                        <h3 className="h3_title">
                                            <i className="fa fa-check-circle"></i>제4조 (개인정보처리 위탁)
                                        </h3>
                                        <p>① '영남합회'은 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.</p>
                                        <div>
                                            <ul className="bold" style={{ paddingLeft: 20 }}>
                                                <li>1.</li>
                                                <li>- 위탁받는 자 (수탁자) : </li>
                                                <li>- 위탁하는 업무의 내용 : 불만처리 등 민원처리</li>
                                                <li>- 위탁기간 : 지체없이 파기</li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <p>
                                            ② '영남합회'은 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적ㆍ관리적 보호조치, 재위탁 제한, 수탁자에 대한
                                            관리ㆍ감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
                                        </p>
                                        <p>③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.</p>
                                        {/* <!--//e:#txt--> */}
                                        <h3 className="h3_title">
                                            <i className="fa fa-check-circle"></i>제5조(정보주체의 권리ㆍ의무 및 행사방법)
                                        </h3>
                                        <p>① 정보주체는 '영남합회'에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.</p>
                                        <div>
                                            <ul className="bold" style={{ paddingLeft: 20 }}>
                                                <li>1. 개인정보 열람요구</li>
                                                <li>2. 오류 등이 있을 경우 정정 요구</li>
                                                <li>3. 삭제요구</li>
                                                <li>4. 처리정지 요구</li>
                                            </ul>
                                        </div>
                                        <h3 className="h3_title">
                                            <i className="fa fa-check-circle"></i>제6조(처리하는 개인정보 항목)
                                        </h3>
                                        <p>'영남합회'은 다음의 개인정보 항목을 처리하고 있습니다.</p>
                                        <ul className="bold" style={{ paddingLeft: 20 }}>
                                            <li>
                                                1. 홈페이지 회원 가입 및 관리 &lt;개인정보 처리업무&gt;
                                                <br />
                                                <span className="mgl15">- 필수항목 : 성명, 아이디, 비밀번호, 전화번호</span>
                                                <br />
                                            </li>
                                        </ul>
                                        <h3 className="h3_title">
                                            <i className="fa fa-check-circle"></i>제7조(개인정보의 파기)
                                        </h3>
                                        <p>
                                            -파기절차 이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시
                                            파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
                                            <br />
                                            -파기기한
                                            <br />
                                            이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등
                                            그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.
                                        </p>
                                        <h3 className="h3_title">
                                            <i className="fa fa-check-circle"></i>제8조(개인정보의 안전성 확보조치)
                                        </h3>
                                        <p style={{ marginTop: 5 }}>
                                            ① 영남합회 은 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠기(cookie)’를 사용합니다. <br />② 쿠키는 웹사이트를 운영하는데
                                            이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
                                        </p>
                                        <div>
                                            <ul className="bold" style={{ paddingLeft: 20 }}>
                                                <li>
                                                    가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게
                                                    최적화된 정보 제공을 위해 사용됩니다.{' '}
                                                </li>
                                                <li>나. 쿠키의 설치ㆍ운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.</li>
                                                <li>다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</li>
                                            </ul>
                                        </div>
                                        <h3 className="h3_title">
                                            <i className="fa fa-check-circle"></i>제9조(개인정보 보호책임자)
                                        </h3>
                                        <p>
                                            ① '영남합회'은 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보
                                            보호책임자를 지정하고 있습니다.
                                        </p>
                                        <div>
                                            <ul className="bold" style={{ paddingLeft: 20 }}>
                                                <li>▶ 개인정보 보호책임자 </li>
                                                <li>성명 : 남시창</li>
                                                <li>직책 : 영남합회장 </li>
                                                <li>직급 : 영남합회장 </li>
                                                <li>연락처 : {DataTypes.WEBSITE_INFO_TEL}, sekcpdt@kuc.or.kr</li>
                                            </ul>
                                        </div>
                                        <p>
                                            ② 정보주체께서는 '영남합회'의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자
                                            및 담당부서로 문의하실 수 있습니다. '영남합회'은 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
                                        </p>

                                        <h3 className="h3_title">
                                            <i className="fa fa-check-circle"></i>제10조(개인정보 처리방침 변경)
                                        </h3>
                                        <p>
                                            ① 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여
                                            고지할 것입니다.
                                        </p>
                                        <h3 className="h3_title">
                                            <i className="fa fa-check-circle"></i>제11조(개인정보의 안전성 확보 조치)
                                        </h3>
                                        <p>
                                            1. 내부관리계획의 수립 및 시행
                                            <br />
                                            <span style={{ marginLeft: 20 }}>개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.</span>
                                            <br />
                                            2. 개인정보의 암호화
                                            <br />
                                            <span style={{ marginLeft: 20 }}>
                                                이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금
                                                기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
                                            </span>
                                            <br />
                                            3. 개인정보에 대한 접근 제한
                                            <br />
                                            <span style={{ marginLeft: 20 }}>
                                                개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며
                                                침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
                                            </span>
                                            <br />
                                            4. 문서보안을 위한 잠금장치 사용
                                            <br />
                                            <span style={{ marginLeft: 20 }}>개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.</span>
                                            <br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- .entry-content --> */}
                        </article>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Privacy;
