import axios from 'axios';

/**
 * 보고서의 기본 정보를 가지고 온다.
 * @param userId: 사용자 아이디
 * @returns
 */
export const getTermReportData = async ({ userId, dt }) => {
  let result = null;

  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/termReports/${userId}`, {
      params: { dt },
    });
    result = response.data;
  } catch (e) {
    console.log(e);
  }

  return result;
};

/**
 * 관리자가 사용할 보고서의 기본 정보를 가지고 온다.
 * @param churchId : 교회의 아이디
 * @returns
 */
export const getTermReportDataByAdmin = async ({ churchId, dt }) => {
  let result = [];

  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/termReports/admin/${churchId}`, {
      params: { dt },
    });
    result = response.data;
  } catch (e) {
    console.log(e);
  }

  return result;
};

/**
 * 관리자가 연합회에 보고할 데이터(교회, 기관, 전체 데이터)를 프린트 및 다운로드를 위해 가져온다.
 * @param
 * @returns
 */
export const getTermReportDataForAdmin = async ({ dt }) => {
  let result = [];

  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/termReports/admin/statistics/print`, {
      params: { dt },
    });
    result = response.data;
  } catch (e) {
    console.log(e);
  }

  return result;
};
/**
 * 보고서에 들어갈 임직원의 서명을 가져온다.
 * @param
 * @returns
 */
export const getAdminChiefConfirm = async ({ dt }) => {
  let result = [];

  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/info/admins`, {
      params: { dt },
    });
    result = response.data;
  } catch (e) {
    console.log(e);
  }

  return result;
};

/**
 * 프린트를 위해 해당기의 보고된 데이터를 가져온다.
 * @param {*} param0
 * @returns
 */
export const getTermReportDataByReportId = async ({ reportId }) => {
  let result = [];

  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/termReports/${reportId}/print`, {});

    result = response.data;
  } catch (e) {
    console.log(e.message);
  }

  return result;
};

/**
 * 보고서를 저장한다.
 * @param {number} reportId: 보고서 ID
 * @param {object} toBeDeletedTermReportData: 지워질 데이터(출석현황, 안식일학교)
 * @param {object} termReportData: 유지할 데이터(기본사항, 선교 및 교육훈련, 어린이 사업, 청소년 사업, 전도활동, 안식이학교 이름/반생 이름)
 * @returns
 */
export const saveTermReportData = async ({ reportId, termReportData, toBeDeletedTermReportData }) => {
  let result = [];

  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/termReports/${reportId}/save`, {
      termReportData,
      toBeDeletedTermReportData,
      isSubmitted: 'N',
    });

    result = response.data;
  } catch (e) {
    console.log(e.message);
  }

  return result;
};

/**
 * 보고서를 제출한다.
 * @param {number} reportId: 보고서 ID
 * @param {object} toBeDeletedTermReportData: 지워질 데이터(출석현황, 안식일학교)
 * @param {object} termReportData: 유지할 데이터(기본사항, 선교 및 교육훈련, 어린이 사업, 청소년 사업, 전도활동, 안식이학교 이름/반생 이름)
 * @returns
 */
export const submitTermReportData = async ({ reportId, termReportData, toBeDeletedTermReportData }) => {
  let result = [];

  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/termReports/${reportId}/save`, {
      termReportData,
      toBeDeletedTermReportData,
      isSubmitted: 'Y',
    });

    result = response.data;
  } catch (e) {
    console.log(e.message);
  }

  return result;
};

/**
 * 관리자가 보고서를 제출한다.
 * @param {number} reportId: 보고서 ID
 * @param {object} toBeDeletedTermReportData: 지워질 데이터(출석현황, 안식일학교)
 * @param {object} termReportData: 유지할 데이터(기본사항, 선교 및 교육훈련, 어린이 사업, 청소년 사업, 전도활동, 안식이학교 이름/반생 이름)
 * @returns
 */
export const submitTermReportDataByAdmin = async ({ reportId, termReportData, toBeDeletedTermReportData }) => {
  let result = [];

  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/termReports/${reportId}/save`, {
      termReportData,
      toBeDeletedTermReportData,
      isSubmitted: 'Y',
    });

    result = response.data;
  } catch (e) {
    console.log(e.message);
  }

  return result;
};

/**
 * 해당 기에 보고된 교회/보고되지 않은 교회의 데이터를 확인할 수 있도록 데이터를 가져온다.
 * @param {number} dt: 보고된 데이터의 기
 * @returns
 */
export const getActivityTermReport = async ({ dt }) => {
  let result = [];

  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/termReports/admin/statistics/list`, {
      params: { dt },
    });

    result = response.data;
  } catch (e) {
    console.log(e.message);
  }

  return result;
};

/**
 * 해당 분기의 보고완료 여부를 확인한다. [true || false]로 값을 가져온다.
 * @param {number} dt: 보고된 데이터의 분기
 * @returns
 */
export const getCarryOverResult = async ({ dt }) => {
  let result = false;

  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/termReports/admin/statistics/carryOver`, {
      params: { dt },
    });

    result = response.data;
  } catch (e) {
    console.log(e.message);
  }

  return result;
};

/** 
    해당 분기의 기말보고 데이터를 각 교회별로 정렬한 excel 파일을 다운로드한다.
*/
export const getTermReportExcel = async ({ dt }) => {
  let result = null;
  try {
    const response = await axios.get(`${process.env.REACT_APP_CMS_BACKEND_SERVER_URL}/reports/summary/quarterly/excel/${dt}`, {
      responseType: 'blob', // important
    });

    result = response.data;
  } catch (e) {
    console.error(e.message);
  }
  return result;
};

/** 
    해당 분기의 기말보고 데이터를 해당 id를 가진 교회의 데이터를 excel 파일로 다운로드한다.
*/
export const getTermReportExcelByChurchId = async ({ startedAt, endedAt, churchId }) => {
  let result = null;
  try {
    const response = await axios.get(`${process.env.REACT_APP_CMS_BACKEND_SERVER_URL}/reports/quarterly/excel/${churchId}?startedAt=${startedAt}&endedAt=${endedAt}`, {
      responseType: 'blob', // important
    });

    result = response.data;
  } catch (e) {
    console.error(e.message);
  }
  return result;
};

/**
 * 해당 기에 연합회 보고하기 전 최종 확인을 위한 특정 항목의 데이터를 가져온다.
 * 항목: 전도지 소책자, 침례자, 전도회, 어린이성경학교
 * @param {number} dt: 보고된 데이터의 기
 * @returns
 */
export const getTermReportDataForConfirm = async ({ dt }) => {
  let result = null;

  try {
    const response = await axios.get(`${process.env.REACT_APP_CMS_BACKEND_SERVER_URL}/reports/quarterly/confirm`, {
      params: { dt },
    });

    result = response.data;
  } catch (e) {
    console.log(e.message);
  }

  return result;
};

/**
 * 해당 기에 연합회 보고하기 전 최종 확인을 위한 특정 항목의 데이터를 가져온다.
 * 항목: 전도지 소책자, 침례자, 전도회, 어린이성경학교
 * @param {number} dt: 보고된 데이터의 기
 * @param {array} termReportData: 유지할 데이터(기본사항, 선교 및 교육훈련, 어린이 사업, 청소년 사업, 전도활동, 안식이학교 이름/반생 이름)
 * @param {array} toBeDeletedTermReportData: 지워질 데이터(출석현황, 안식일학교)
 * @returns
 */
export const putConfirmedTermReportData = async ({ dt, reportData }) => {
  let result = null;

  console.log(dt, reportData);
  try {
    const response = await axios.put(`${process.env.REACT_APP_CMS_BACKEND_SERVER_URL}/reports/quarterly/submit?dt=${dt}`, {
      reportData,
    });

    result = response.data;
  } catch (e) {
    console.log(e.message);
  }

  return result;
};

/**
 * 해당 기에 연합회 보고하기 전 최종 확인을 위한 특정 항목의 데이터를 수정한다.
 * 항목: 전도지 소책자, 침례자, 전도회, 어린이성경학교
 * @param {Object} report: 보고된 데이터의 기
 * @returns
 */
export const putConfirmedTermReportDataByReportId = async (report) => {
  let result = null;

  try {
    const response = await axios.put(`${process.env.REACT_APP_CMS_BACKEND_SERVER_URL}/reports/quarterly/submit/${report.id}?dt=${report.dt}`, {
      tract_and_booklet: report.tract_and_booklet,
      child_evangelical_meeting: report.child_evangelical_meeting,
      teen_evangelical_meeting: report.teen_evangelical_meeting,
      pastor_evangelical_meeting: report.pastor_evangelical_meeting,
      small_group_evangelical_meeting: report.small_group_evangelical_meeting,
      evangelist_group_evangelical_meeting: report.evangelist_group_evangelical_meeting,
      other_evangelical_meeting: report.other_evangelical_meeting,
      child_bible_school: report.child_bible_school,
      child_bible_school_attendance: report.child_bible_school_attendance,
      baptized_child: report.baptized_child,
      baptized_student: report.baptized_student,
      baptized_youth: report.baptized_youth,
      baptized_senior: report.baptized_senior,
    });

    result = response.data;
  } catch (e) {
    console.log(e.message);
  }

  return result;
};

/**
 * 교회 지구 리스트를 가지고 온다.
 * @returns 교회 지구(지역 리스트)
 */
export const getChurchSectionList = async () => {
  let result = [];

  try {
    const response = await axios.get(`${process.env.REACT_APP_CMS_BACKEND_SERVER_URL}/sections`);

    result = response.data;
  } catch (e) {
    console.log(e.message);
  }

  return result;
};
