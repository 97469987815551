import React, { useEffect, useState } from 'react';
import { addQuarters, format, getQuarter, isSameQuarter, isThisQuarter, startOfQuarter, subDays, subQuarters } from 'date-fns';

import AttendanceInfo from './AttendanceInfo';
import Evangelism from './Evangelism';
import KidBusiness from './KidsBusiness';
import MissionTraining from './MissionTraining';

import SchoolInfo from './SchoolInfo';
import YouthBusiness from './YouthBusiness';
import BasicInfo from './BasicInfo';
import ChangedClassMember from './ChangedClassMember';

const TermReportTable = ({
    toBeDeletedTermReportData, //
    setToBeDeletedTermReportData,
    termReportData,
    setTermReportData,
    termReportPrevData,
    disabled,
}) => {
    return (
        <>
            <div className="panel-body" style={{ paddingBottom: 0 }}>
                <div className="col-xs-12 col-sm-6 col-md-6">
                    <BasicInfo
                        disabled={disabled} //
                        termReportData={termReportData}
                        setTermReportData={setTermReportData}
                    />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6">
                    <MissionTraining
                        disabled={disabled} //
                        termReportData={termReportData}
                        setTermReportData={setTermReportData}
                    />
                </div>
            </div>
            <div className="panel-body" style={{ paddingBottom: 0 }}>
                <div className="col-xs-12 col-sm-6 col-md-6">
                    <AttendanceInfo
                        disabled={disabled} //
                        termReportPrevData={termReportPrevData}
                        toBeDeletedTermReportData={toBeDeletedTermReportData}
                        setToBeDeletedTermReportData={setToBeDeletedTermReportData}
                    />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6">
                    <SchoolInfo
                        disabled={disabled} //
                        termReportPrevData={termReportPrevData}
                        toBeDeletedTermReportData={toBeDeletedTermReportData}
                        setToBeDeletedTermReportData={setToBeDeletedTermReportData}
                    />
                </div>
            </div>

            <div className="panel-body" style={{ paddingBottom: 0 }}>
                <div className="col-xs-12 col-sm-6 col-md-6">
                    <KidBusiness
                        disabled={disabled} //
                        termReportData={termReportData}
                        setTermReportData={setTermReportData}
                    />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6">
                    <YouthBusiness
                        disabled={disabled} //
                        termReportData={termReportData}
                        setTermReportData={setTermReportData}
                    />
                </div>
            </div>
            <div className="panel-body" style={{ paddingBottom: 0 }}>
                <div className="col-xs-12 col-sm-6 col-md-6">
                    <Evangelism
                        disabled={disabled} //
                        termReportData={termReportData}
                        setTermReportData={setTermReportData}
                    />
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6">
                    <ChangedClassMember
                        disabled={disabled} //
                        termReportData={termReportData}
                        setTermReportData={setTermReportData}
                    />
                </div>
            </div>
            <div className="panel-body" />
        </>
    );
};

export default TermReportTable;
