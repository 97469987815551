export const ChurchType = [
    { id: 0, name: '미확인' },
    { id: 10, name: '교회' },
    { id: 20, name: '예배소' },
    { id: 30, name: '기타' },
];
// export const ChurchType = [
//     { id: 0, name: '미확인' },
//     { id: 10, name: '교회' },
//     { id: 20, name: '예배소' },
//     { id: 30, name: '기관' },
//     { id: 40, name: '기타' },
// ];
export const ChurchState = [
    { id: 0, name: '미확인' },
    { id: 10, name: '정상' },
    { id: 20, name: '주의' },
    { id: 30, name: '경고' },
    { id: 40, name: '닫음' },
];

export const TemporaryChurchInfo = {
    id: null, //
    confId: 1,
    areaId: null,
    name: '', // 기존의 교회 이름
    newName: '', // 수정한 교회 이름
    homepage: 'https://sekc.or.kr',
    tel: '',
    addr: '',
    addressDetail: '',
    type: 10,
    state: 10,
    isAddrPopupOpen: false,
    agency: 0,
    isAgency: false,
};
