import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CommonContext } from '../../contexts/Common';
import { getTimeByWritingTime } from '../../utils/common';
import { getPostList } from '../../data/mysql';

let CATEGORY_NOTICE_ID = null;

const MainNotices = () => {
    const [notices, setNotices] = useState([]);
    const { settings } = useContext(CommonContext);

    useEffect(() => {
        CATEGORY_NOTICE_ID = settings.find((setting) => setting.name === 'category_notice_id')?.value;

        if (CATEGORY_NOTICE_ID) {
            const update = async () => {
                setNotices(await getPostList({ categoryId: CATEGORY_NOTICE_ID, limit: 5, offset: 0 }));
            };
            update();
        }
    }, [settings]);

    return (
        <div className="nmcdn_col1 col-sm-4 wow fadeInLeft" data-wow-delay="0.9s">
            <div className="nmcdn_card">
                <div className="nmcdn_in_box">
                    <div className="cdnot_tit">
                        <div className="tit">
                            공지사항
                            <Link to={`board/posts/${CATEGORY_NOTICE_ID}/list?offset=0`} className="main_add_more" title="공지사항 더보기"></Link>
                        </div>
                    </div>
                    <div className="cdnot_cont">
                        <div>
                            <div>
                                <ul className="cdnot_noti">
                                    {notices.map((notice) => {
                                        return (
                                            <li key={notice.id} className="first txt">
                                                <Link to={`/board/posts/${CATEGORY_NOTICE_ID}/view?id=${notice.id}`}>
                                                    {notice.title}
                                                    &nbsp;
                                                    {/* {getTimeDiff( notice.createdAt ) <= 1 ? (
                                                            <span className='newIcon'>
                                                                <i className='xi-new' />
                                                            </span>
                                                        ) : (
                                                            ''
                                                    )} */}
                                                </Link>
                                                <span className="date">{getTimeByWritingTime(notice.createdAt)}</span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainNotices;
