import React, { useContext, useEffect } from 'react';

import { CommonContext } from '../../contexts';
import { useLocation } from 'react-router-dom';

import Location from '../../components/Location';

const InfoLocations = () => {
    const { categories } = useContext(CommonContext);
    const { pathname } = useLocation();

    /** */
    useEffect(() => {
    }, []);

    return (
        <div id='primary' className='content-area'>
            {/* <!--s:#content --> */}
            <div id='content' className='site-content'>
                <article>
                    <div>
                        <div className='title-inner'>
                            <img src='/common/images/title.png' alt='' />
                            <h1>{ categories.find( category => category.categoryCode === pathname.split('/')[4])?.categoryName}</h1>
                        </div>
                        <div style={{ height: 20 }}></div>
                        <div style={{ clear: 'both' }}></div>
                        <div className='title_subpage_solid_1'></div>
                        <div style={{ height: 30 }}></div>

                        <div id='content-wrap' className='container'>
                            <Location />
                        </div>   

                    </div>
                    {/* <!-- .entry-content --> */}
                </article>
            </div>
            {/* <!--//e: #content --> */}
        </div>
    );
};

export default InfoLocations;