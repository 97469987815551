import React, { useState, useContext, useEffect } from 'react';
import { CommonContext, ProgressContext } from '../../contexts';
import { startOfMonth, addMonths, subMonths, subDays } from 'date-fns';

import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { getMonthlyActivityReport, rejectMonthlyReport } from '../../data/mysql';

import ActivityReportHeader from './ActivityReportHeader';
import AdminActivityReportContent from './AdminActivityReportContent';
import ProgressiveBar from './components/ProgressiveBar';

const AdminActivityReports = () => {
    const { spinner } = useContext(ProgressContext);
    const { categories } = useContext(CommonContext);
    const { pathname } = useLocation();

    const [reports, setReports] = useState([]); // 월말보고 리스트
    const [currentMonth, setCurrentMonth] = useState(subDays(Date.now(), 10));

    const [stage, setStage] = useState(0); // 제출되지 않은 보고서
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        const init = async () => {
            // setReports(await getMonthlyActivityReport({ currentMonth: Math.floor(startOfMonth(currentMonth).getTime() / 1000) }));
            const reportsData = await getMonthlyActivityReport({ currentMonth: Math.floor(startOfMonth(currentMonth).getTime() / 1000) });
            const arrReportsData = reportsData.sort((a, b) => a.church_name.localeCompare(b.church_name)); // ㄱㄴㄷ 순으로 정렬
            setReports(arrReportsData.filter((prevInfo) => prevInfo.pastor_id !== 166).map((prevInfo) => ({ ...prevInfo }))); // p_id: 166은 영남합회 이므로 제외
        };

        init();
    }, [currentMonth, refresh]);

    const prevMonth = () => {
        setCurrentMonth(subMonths(currentMonth, 1));
    };
    const nextMonth = () => {
        setCurrentMonth(addMonths(currentMonth, 1));
    };

    // 제출된 보고서를 반려한다.
    const _rejectMonthlyReport = async ({ reportId, name }) => {
        try {
            spinner.start();

            const result = await rejectMonthlyReport({ reportId });

            if (result) {
                toast.success(`[${name}]의 보고서의 제출을 취소하였습니다.`);
                setRefresh(result);
            }
        } catch (e) {
            toast.error(`[${name}]의 보고서 제출을 취소하는데 실패했습니다.`);
            console.log(e);
        } finally {
            spinner.stop();
            setRefresh(false);
            setStage(1);
        }
    };

    return (
        <div id="primary" className="content-area">
            {/* <!--s:#content --> */}
            <div id="content" className="site-content">
                <article>
                    <div>
                        <div className="title-inner">
                            <img src="/common/images/title.png" alt="" />
                            <h1>{categories.find((category) => category.categoryCode === pathname.split('/')[4])?.categoryName}</h1>
                        </div>
                        <div style={{ height: 20 }}></div>
                        <div style={{ clear: 'both' }}></div>
                        <div className="title_subpage_solid_1"></div>
                        <div style={{ height: 30 }}></div>

                        <div className="container-fluid">
                            <div className="row">
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <ActivityReportHeader //
                                            currentMonth={currentMonth}
                                            prevMonth={prevMonth}
                                            nextMonth={nextMonth}
                                        />
                                    </div>
                                    <div className="panel-body">
                                        <ProgressiveBar
                                            currentValue={reports.filter((r) => r.is_submitted === 'Y')?.length} // 현재 보고서 제출 수
                                            maxValue={reports.length} // 전체 보고서 수
                                        />
                                    </div>
                                </div>

                                <AdminActivityReportContent
                                    reports={reports} //
                                    stage={stage}
                                    setStage={setStage}
                                    rejectMonthlyReport={_rejectMonthlyReport}
                                />
                            </div>
                        </div>
                    </div>
                </article>
            </div>
            {/* <!--//e: #content --> */}
        </div>
    );
};

export default AdminActivityReports;
