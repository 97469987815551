import React, { useState, useEffect, createContext } from 'react';
import { getLoginUserId, isLogin } from '../data/firebase';
import { getUserInfo } from '../data/mysql';

const UserContext = createContext({
    user: {
        id: null,
        uid: null,
        email: null,
        name: null,
        level: 0,
        gender: null,
        postCode: null,
        address: null,
        addressDetail: null,
        church: null,
        role: null,
        phoneNumber: null,
        birthDay: null,
        createdAt: null,
    },
    dispatch: () => {},
});

const UserProvider = ({ children }) => {
    const [user, setUser] = useState({});

    const dispatch = ({ id, email, uid, name, level, gender, postCode, address, addressDetail, church, role, phoneNumber, birthDay, createdAt }) => {
        setUser({ id, email, uid, name, level, gender, postCode, address, addressDetail, church, role, phoneNumber, birthDay, createdAt });
    };

    /**
     * 새로고침을 할 경우, UserContext의 값이 사라진다.
     * 즉, 저장한 로그인 정보도 사라진다는 말이다.
     * ==> 그래서 로그인할 때, 로그인 정보(UserId)를 sessionStorage에 남겨두고,
     *      새로고침을 할 때, 로그인 정보가 존재(UserId) 한다면..
     *      DB(firebase)에서 그 로그인 정보(UserId)로 값을 불러와서 UserContext를 다시 입력(dispatch) 한다.
     *
     * ==> 결론적으로, 새로고침을 하더라도 모든 컴포넌트에서 User의 정보를 불러서 읽을 수 있다.
     *
     * #참고: useEffect(() => {}, [])는 새로고침시 바인딩 된다는 특징을 이용.
     *
     * 2022.01.02.(일) @부산서부교회 전도사 사택
     */
    useEffect(() => {
        if (isLogin()) {
            const setLoginInfo = async () => {
                const userInfo = await getUserInfo({ userId: getLoginUserId() });

                if (userInfo) {
                    dispatch(userInfo);
                }
            };
            setLoginInfo();
        }
    }, []);

    const value = { user, dispatch };

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };
