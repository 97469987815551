import React, { useState, useEffect, useRef, useContext } from 'react';
import toast from 'react-hot-toast';
import { ProgressContext } from '../../contexts';
import { DataTypes } from '../../data/Types';
import { getUserInfoOnDevice, removeWhitespace, vaildateEmail } from '../../utils/common';
import { auth } from '../../data/firebase';


const ResetPassword = () => {
    const { spinner } = useContext(ProgressContext);
    const emailRef = useRef();

    const [email, setEmail] = useState('');
    const [isSendable, setIsSendable] = useState(false);

    useEffect(() => {
        const user = getUserInfoOnDevice();

        setEmail( user.email );
        emailRef.current.focus();
    }, []);

    useEffect(() => {
        setIsSendable( vaildateEmail(email));
    }, [email])

    
    /* 이메일 체크 */
    const _handleCheckEmail = (e) => {
        setEmail( removeWhitespace(e.target.value) );
        e.target.style.border = vaildateEmail(e.target.value)? '1px solid #ddd': '1px solid red';
    };

    const _handleSendButtonPress = async () => {
        try {
            spinner.start();

            await auth.sendPasswordResetEmail(email).then(() => {
                toast.success('이메일을 보냈습니다.');
            });
            
            setEmail('');
        } catch (e) {
            toast.error('등록되지 않은 이메일 정보입니다.');
        } finally {
            spinner.stop();
        }
        
    }

    return (
        <div id='primary' className='content-area'>
            <div id='content' className='site-content'>
                <article>

                <div className='login_wrap'>
                        <p className='login'>
                            비밀번호 <span>재설정</span>
                        </p>
                        <p>'등록된 이메일로 비밀번호를 재설정 하실 수 있습니다.'</p>
                        <fieldset>
                            <legend>비밀번호 재설정</legend>
                            <div className='login_insert'>
                                <div>
                                    <div className='inser_a'>
                                        <div className='inser_c'>
                                            <label>이메일</label>
                                        </div>
                                        <div className='inser_b'>
                                            <input
                                                tabIndex={1}
                                                type='text'
                                                ref={ emailRef }
                                                value={ email }
                                                className='form-control frm_input required'
                                                placeholder='이메일'
                                                onChange={ _handleCheckEmail }
                                            />
                                        </div>
                                    </div>
                                    <div className='id_check'>
                                        <a href={`tel:${ DataTypes.WEBSITE_INFO_TEL }`}><i className='fa fa-phone' />문의하기</a>
                                    </div>
                                </div>
                                <span className='login_btn'>
                                    <input
                                        tabIndex={2}
                                        type='button'
                                        value='보내기'
                                        disabled={ !isSendable }
                                        onClick={ _handleSendButtonPress }
                                    />
                                </span>
                            </div>
                        </fieldset>
                    </div>

                </article>
            </div>
        </div>
    );
};

export default ResetPassword;