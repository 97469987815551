/** @format */

import React from 'react';
import { ThemeProvider } from 'styled-components';
import { CommonProvider, ProgressProvider, UserProvider } from './contexts';
import { theme } from './theme';
import { Toaster } from 'react-hot-toast';
import Router from './routes';

function App() {
    return (
        <ThemeProvider theme={theme}>
            <UserProvider>
                <ProgressProvider>
                    <CommonProvider>
                        <Toaster />
                        <Router />
                    </CommonProvider>
                </ProgressProvider>
            </UserProvider>
        </ThemeProvider>
    );
}

export default App;
