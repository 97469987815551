import React from 'react';

const Textarea = ({ value, setValue, reportKeyName, disabled, placeholder }) => {
    const _handleOnChange = ({ target }) => {
        let result = target?.value;

        setValue((report) => ({
            ...report, //
            [reportKeyName]: result ? result : '',
        }));
    };
    return (
        <textarea
            className="styled-termReport-textarea" //
            value={value}
            placeholder={placeholder}
            onChange={_handleOnChange}
            disabled={disabled}
        />
    );
};

export default Textarea;
