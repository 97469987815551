import React from 'react';
import { Link } from 'react-router-dom';

const BoardWriteButton = ({ isLogin, user, currentCategory }) => {
    return (
        isLogin() &&
        user.level >= currentCategory?.writeLevel && (
            <div>
                <p className="text-right">
                    <Link to="../write" className="btn_black" title="등록">
                        등록
                    </Link>
                </p>
            </div>
        )
    );
};

export default BoardWriteButton;
