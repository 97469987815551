/** @format */

import React, { useContext, useEffect, useState, useRef } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CommonContext, ProgressContext, UserContext } from '../../contexts';
import { DataTypes } from '../../data/Types';

import { getConvertedByteSize, removeWhitespace } from '../../utils/common';
import { createPost } from '../../data/mysql';

import BoardHeader from './BoardHeader';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

const BoardWrite = () => {
    const { spinner } = useContext(ProgressContext);
    const { settings } = useContext(CommonContext);
    const { user } = useContext(UserContext);
    const { categoryId } = useParams();

    const inputRef = useRef();
    const textareaRef = useRef();

    const navigate = useNavigate();

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const [department, setDepartment] = useState(''); // 관련 부서
    const [departments, setDepartments] = useState([]); // 부서 목록: 총무부|목회부|재무부|교육부|청소년부|어린이부|디지털선교부 ...
    // const [option, setOption] = useState('');

    const [files, setFiles] = useState([{}, {}, {}, {}, {}]); // 5 Files upload

    const [isSendable, setIsSendable] = useState(false);

    /* 시작할 때(마운트 시) */
    useEffect(() => {
        inputRef.current.focus();
    }, []);

    /* [저장] 버튼을 활성화 시킨다 */
    useEffect(() => {
        setIsSendable(removeWhitespace(title) && removeWhitespace(content));
    }, [title, content]);

    /* [Admin] 셋팅값에서 관리자 부서의 종류를 가지고 온다. */
    useEffect(() => {
        const result = settings?.find((setting) => setting.name === 'admin_department')?.value;

        if (result) {
            setDepartments(result.split('|'));
        }
    }, [settings]);

    const _handleSubmitButtonPress = async () => {
        try {
            spinner.start();

            const postId = await createPost({ department, title, content, categoryId, files: files.filter((file) => file.name) });

            setTitle('');
            setContent('');

            navigate(`../view?id=${postId}`); // 글 작성 완료 후 작성한 글로 보낸다.
            toast.success('글이 등록 되었습니다');
        } catch (e) {
            console.log(e.message);
        } finally {
            spinner.stop();
        }
    };

    const _handleFileChange = ({ fileIndex, uploadFile }) => {
        user && user.level >= DataTypes.USER_LEVEL_STAFF
            ? setFiles(files.map((file, index) => (index === fileIndex ? uploadFile : file)))
            : uploadFile.size > 31457280 // 30MB
            ? toast.error('첨부 파일 최대용량은 30MB 입니다.')
            : setFiles(files.map((file, index) => (index === fileIndex ? uploadFile : file)));
    };

    // 선택된 파일를 파일 목록에서 제거하여 해당 항목을 비운다.
    const _handleCanceledFileUpload = ({ fileIndex }) => {
        setFiles(files.map((file, index) => (index === fileIndex ? {} : file)));
    };

    /**
     * 웹 에디터(SunEditor) 글을 작성할 때
     */
    const _handleChangeContent = (content) => {
        setContent(content);
    };

    /**
     * 관리자 기능: 해당 부서로 작성자를 표시한다.
     */
    const _handleChangeDepartment = (value) => {
        if (value !== 'default') {
            setDepartment(value);
        }
    };

    return (
        <div id="primary" className="content-area">
            {/* <!--s:#content --> */}
            <div id="content" className="site-content">
                <article>
                    <div>
                        <BoardHeader />

                        <section>
                            <div className="table-write">
                                <input type="hidden" name="return_url" id="return_url" value="" />
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>
                                                제목<span className="rs_fc_red">*</span>
                                            </th>
                                            <td>
                                                <div className="col-sm-10">
                                                    <input
                                                        ref={inputRef}
                                                        type="text"
                                                        className="form-control"
                                                        name="bc_subject"
                                                        id="bc_subject"
                                                        value={title}
                                                        onChange={({ target }) => setTitle(target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                textareaRef.current.focus();
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        {user && user.level >= DataTypes.USER_LEVEL_STAFF && (
                                            <tr>
                                                <th>관련부서</th>
                                                <td>
                                                    <label>
                                                        <select className="form-control" onChange={(e) => _handleChangeDepartment(e.target.value)} defaultValue={'default'}>
                                                            <option value="default">{user.name}</option>
                                                            {departments?.map((dept, idx) => (
                                                                <option value={dept} key={idx}>
                                                                    {dept}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </label>
                                                </td>
                                            </tr>
                                        )}
                                        {/* /* 로그인을 하고, 사용자 Level이 운영진 이상일 경우 공지를 할 수 있다. */}
                                        {/* user && user.level >= DataTypes.USER_LEVEL_STAFF && (
                                                <tr>
                                                    <th>옵션</th>
                                                    <td>
                                                        <div className='col-sm-10'>
                                                            <label className='checkbox-inline'>
                                                                <input type='checkbox' value='notice' onChange={({ target }) => setOption(target.value)} />
                                                                공지글
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) */}

                                        <tr>
                                            <th>
                                                내용 <span className="rs_bold rs_fc_red">* </span>
                                            </th>
                                            <td>
                                                <div className="col-sm-12">
                                                    <SunEditor
                                                        ref={textareaRef}
                                                        defaultValue="<p><span style='font-family: 기본체; font-size: 16px;'>&nbsp;</span></p>"
                                                        lang="ko"
                                                        height="340"
                                                        setOptions={{
                                                            defaultStyle: 'font-family: 기본체; font-size: 16px',
                                                            height: 200,
                                                            font: ['기본체', '강조체'],
                                                            buttonList: [
                                                                ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                                                ['bold', 'underline', 'italic', 'strike'],
                                                                '/', // Line break
                                                                ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'table'],
                                                                ['link', 'image', 'video', 'fullScreen', 'preview', 'print'],
                                                            ],
                                                            fontSize: [8, 9, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72],
                                                        }}
                                                        setContents={content}
                                                        onChange={_handleChangeContent}
                                                    />
                                                    {/* <textarea
                                                        ref={textareaRef}
                                                        id='bc_contents'
                                                        name='bc_contents'
                                                        className='smarteditor form-control dhtmleditor'
                                                        style={{ width: '100%', height: 350, resize: 'none' }}
                                                        // onChange={(setContent(content), setErrorMessage(content.trim() ? '' : '내용을 입력해주세요'))}
                                                        value={content}
                                                        onChange={({ target }) => setContent(target.value)}
                                                        onSumbit={() => setContent(removeWhitespace(content))}
                                                    ></textarea> */}
                                                </div>
                                            </td>
                                        </tr>

                                        {
                                            /* [Files 출력부] ====================================================== */
                                            files.map((file, index) => {
                                                return !file.name ? (
                                                    <tr>
                                                        <th>파일 #{index + 1}</th>
                                                        <td>
                                                            <div className="col-sm-10">
                                                                <input
                                                                    type="file"
                                                                    id="files"
                                                                    className="form-control upfile"
                                                                    value=""
                                                                    onChange={(e) => _handleFileChange({ fileIndex: index, uploadFile: e.target.files[0] })}
                                                                />
                                                                <span></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <tr>
                                                        <th>파일 #{index + 1}</th>
                                                        <td>
                                                            <div className="col-sm-10">
                                                                <span>{file.name}</span>
                                                                <span className="file-size">( {getConvertedByteSize(file.size)} )</span>
                                                            </div>
                                                            <button className="btn_gray" style={{ marginLeft: 10 }} onClick={() => _handleCanceledFileUpload({ fileIndex: index, fileId: file.id })}>
                                                                취소
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <p className="text-center mt30">
                                    <button
                                        type="submit"
                                        className="btn_green"
                                        style={{
                                            opacity: isSendable ? 1 : 0.5, // disabled에 해당하는 Style Sheet 적용이 없어서 ...
                                            margin: 10,
                                        }}
                                        disabled={!isSendable}
                                        onClick={_handleSubmitButtonPress}
                                    >
                                        저장
                                    </button>
                                    <Link to={`${categoryId}`} id="btnGroup" className="btn_black btn-history-back" title="취소">
                                        취소
                                    </Link>
                                </p>
                            </div>
                        </section>
                    </div>
                    {/* <!-- .entry-content --> */}
                </article>
            </div>
            {/* <!--//e: #content --> */}
        </div>
    );
};

export default BoardWrite;
