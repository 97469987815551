import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
    margin: 5px;
    padding: 5px;
    display: flex;
    justify-content: 'space-between';
    align-items: center;
`;
const StyledLeft = styled.div`
    flex: 1;
    margin-right: 5px;
`;
const StyledRight = styled.div`
    font-size: 0.7em;
    color: #555;
`;

const ProgressiveBar = ({ currentValue, maxValue }) => {
    return (
        <StyledContainer>
            <StyledLeft>
                <div className="progress">
                    <div
                        className="progress-bar" //
                        role="progressbar"
                        aria-valuenow="60"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: `${Math.floor((currentValue / maxValue) * 100)}%` }}
                    >
                        {`${Math.floor((currentValue / maxValue) * 100)}%`}
                    </div>
                </div>
            </StyledLeft>
            <StyledRight>{`(${currentValue}/${maxValue})`}</StyledRight>
        </StyledContainer>
    );
};

export default ProgressiveBar;
