import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// import InputCheckbox_small from '../components/InputCheckbox_small';
import InputNum from '../components/InputNum';
import InputCheckbox from '../components/InputCheckbox';

const TermTable = styled.table`
    border: 1px solid silver;
    border-radius: 3px;
    border-collapse: separate;
    background-color: rgb(236, 236, 236);
    margin-bottom: 10px;
    width: 100%;
`;

const TermTr = styled.tr`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 0.95em;
    text-align: center;
    vertical-align: middle;
    height: 38px;
`;

const TermTd = styled.td`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 0.95em;
    text-align: center;
    vertical-align: middle;
`;

const TermTh = styled.th`
    font-family: '기본체' !important;
    height: 35px;
    font-size: 1.2em;
    text-align: center;
    vertical-align: middle;
    background-color: rgb(212, 212, 212);
`;

const MissionTermTraining = ({ disabled, termReportData, setTermReportData }) => {
    return (
        <div>
            <TermTable>
                <thead>
                    <TermTr>
                        <TermTh colSpan={4}>선교 및 교육 훈련</TermTh>
                    </TermTr>
                </thead>
                <tbody>
                    <TermTr>
                        <TermTd colSpan={3}>소그룹 수 </TermTd>

                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.small_group}
                                setValue={setTermReportData}
                                reportKeyName={'small_group'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={3}>활동적인 소그룹수</TermTd>

                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.active_small_group}
                                setValue={setTermReportData}
                                reportKeyName={'active_small_group'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={3}>제자훈련 실행여부</TermTd>

                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputCheckbox //
                                value={termReportData?.disciple_training}
                                setValue={setTermReportData}
                                reportKeyName={'disciple_training'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={3}>전도활동에 참여한 자 수</TermTd>

                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.evangelist}
                                setValue={setTermReportData}
                                reportKeyName={'evangelist'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>

                    <TermTr>
                        <TermTd colSpan={3}>평신도 전도회 및 세미나 수</TermTd>

                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.laity_evangelical_meeting_and_seminar}
                                setValue={setTermReportData}
                                reportKeyName={'laity_evangelical_meeting_and_seminar'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={3}>개인성경교수 수</TermTd>

                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.personal_bible_teach}
                                setValue={setTermReportData}
                                reportKeyName={'personal_bible_teach'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={3}>평신도 활동으로 침례받은 수</TermTd>

                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.baptism_by_laity}
                                setValue={setTermReportData}
                                reportKeyName={'baptism_by_laity'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={3}>전도지, 소책자 전한 수</TermTd>

                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.tract_and_booklet}
                                setValue={setTermReportData}
                                reportKeyName={'tract_and_booklet'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={3}>전도훈련원 운영여부</TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputCheckbox //
                                value={termReportData?.evangelism_training_center}
                                setValue={setTermReportData}
                                reportKeyName={'evangelism_training_center'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                    <TermTr>
                        <TermTd colSpan={3}>지역사회 봉사 단체(반) 수</TermTd>
                        <TermTd style={{ backgroundColor: disabled ? 'rgb(236, 236, 236)' : '#fff' }}>
                            <InputNum //
                                value={termReportData?.community_service_group}
                                setValue={setTermReportData}
                                reportKeyName={'community_service_group'}
                                disabled={disabled}
                            />
                        </TermTd>
                    </TermTr>
                </tbody>
            </TermTable>
        </div>
    );
};

export default MissionTermTraining;
