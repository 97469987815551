import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import { Icon } from '@iconify/react';
import { openNewTab } from '../../../../utils/common';
import { ProgressContext } from '../../../../contexts';
import { getQuarter, getYear, startOfQuarter, subYears } from 'date-fns';
import { getTermReportExcelByChurchId } from '../../../../data/mysql_term';
import toast from 'react-hot-toast';

const StyledContainer = styled.div`
  margin: 30px 5px 0 5px;
  flex: 1;
  /* width: 100vw; */
`;
const StyledTh = styled.th`
  font-family: '기본체' !important;
  padding: 5px 5px;
  text-align: center;
  vertical-align: middle !important;
`;
const StyledTd = styled.td`
  font-family: '기본체' !important;
  text-align: center;
  vertical-align: middle !important;
`;
const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledDirection = styled.span`
  font-size: 0.8em;
  color: #ddd;
  font-weight: bold;
  background-color: transparent;
`;

const TermReportListForXlsx = ({ termReports, conditionFunc, KoreaStartOfQuarter, stage }) => {
  const { spinner } = useContext(ProgressContext);

  const timeZone = 'Asia/Seoul';

  let currentYear = getYear(new Date(), { timeZone: timeZone });
  let currentQuarter = getQuarter(new Date(), { timeZone: timeZone });
  let currentQuarterString = '';

  if (currentQuarter === 1) {
    currentQuarterString = '01';
  } else if (currentQuarter === 2) {
    currentQuarterString = '04';
  } else if (currentQuarter === 3) {
    currentQuarterString = '07';
  } else if (currentQuarter === 4) {
    currentQuarterString = '10';
  }

  const [startedAt, setStartedAt] = useState(KoreaStartOfQuarter);
  const [startedYear, setStartedYear] = useState(currentYear);
  const [startedQuarter, setStartedQuarter] = useState(currentQuarterString);

  const [endedAt, setEndedAt] = useState(KoreaStartOfQuarter);
  const [endedYear, setEndedYear] = useState(currentYear);
  const [endedQuarter, setEndedQuarter] = useState(currentQuarterString);

  const [sortOrder, setSortOrder] = useState('asc');

  const quarters = [
    { label: '1기', value: '01' },
    { label: '2기', value: '04' },
    { label: '3기', value: '07' },
    { label: '4기', value: '10' },
  ];

  const years = [];
  for (let i = 0; i < 30; i++) {
    const newYears = subYears(new Date(KoreaStartOfQuarter * 1000), i);

    years.push({
      label: `${newYears.getFullYear()}년`,
      value: `${newYears.getFullYear()}`,
    });
  }

  const Options = ({ options }) => {
    return options?.map((option) => {
      return (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      );
    });
  };

  const _handleState = ({ e, setState }) => {
    setState(e.target.value);
  };

  useEffect(() => {
    const startedDate = startOfQuarter(new Date(`${startedYear}-${startedQuarter}-01`), { timeZone: timeZone }).getTime() / 1000;
    const endedDate = startOfQuarter(new Date(`${endedYear}-${endedQuarter}-01`), { timeZone: timeZone }).getTime() / 1000;
    setStartedAt(startedDate);
    setEndedAt(endedDate);
  }, [startedYear, startedQuarter, endedYear, endedQuarter]);

  // xlsx 다운로드
  const _handleDownloadXlsxByDate = async ({ startedAt, endedAt, churchId, churchName }) => {
    try {
      spinner.start();
      const result = await getTermReportExcelByChurchId({ startedAt, endedAt, churchId });

      if (result) {
        const startedYear = new Date(startedAt * 1000).getFullYear(); // dt 값을 이용하여 연도를 추출
        const startedQuarter = getQuarter(new Date(startedAt * 1000)); // dt 값을 이용하여 분기를 추출
        const endedYear = new Date(endedAt * 1000).getFullYear(); // dt 값을 이용하여 연도를 추출
        const endedQuarter = getQuarter(new Date(endedAt * 1000)); // dt 값을 이용하여 분기를 추출
        const filename = `${churchName} ${startedYear}년 ${startedQuarter}기 - ${endedYear}년 ${endedQuarter}기.xlsx`; // 파일 이름 생성

        const url = window.URL.createObjectURL(new Blob([result])); // Blob 객체를 URL로 변환
        const link = document.createElement('a'); // a 태그 생성
        link.href = url; // href 속성에 URL 할당
        link.setAttribute('download', `${filename}`); // 다운로드할 파일 이름 설정
        document.body.appendChild(link); // body에 a 태그 추가
        link.click(); // 클릭 이벤트 발생
        document.body.removeChild(link); // body에서 a 태그 제거
        window.URL.revokeObjectURL(url); // URL 객체 해제

        toast.success('파일 다운로드에 성공하였습니다.');
      } else {
        throw new Error();
      }
    } catch (e) {
      console.log(e);
      toast.error('파일 다운로드에 실패하였습니다.');
    } finally {
      spinner.stop();
    }
  };

  // Step 2: Create a function to toggle the sort order
  const toggleSortOrder = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);

    termReports.sort((a, b) => {
      const churchA = a.church_name.toUpperCase();
      const churchB = b.church_name.toUpperCase();

      if (churchA < churchB) {
        return newSortOrder === 'asc' ? -1 : 1;
      }
      if (churchA > churchB) {
        return newSortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  return (
    <StyledContainer>
      {stage === 2 ? (
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col" width="10%" style={{ verticalAlign: 'middle' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>기간</div>
              </th>
              <StyledTh scope="col" width="90%">
                <div>
                  <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', fontSize: '1em', verticalAlign: 'middle' }}>
                    <select
                      className="styled-select yearsOfDt" //
                      value={startedYear}
                      onChange={(e) => _handleState({ e, setState: setStartedYear })}
                    >
                      {Options({ options: years })}
                    </select>
                    <select
                      className="styled-select quartersOfDt" //
                      value={startedQuarter}
                      onChange={(e) => _handleState({ e, setState: setStartedQuarter })}
                    >
                      {Options({ options: quarters })}
                    </select>
                    <div>~</div>
                    <select
                      className="styled-select yearsOfDt" //
                      value={endedYear}
                      onChange={(e) => _handleState({ e, setState: setEndedYear })}
                    >
                      {Options({ options: years })}
                    </select>
                    <select
                      className="styled-select quartersOfDt" //
                      value={endedQuarter}
                      onChange={(e) => _handleState({ e, setState: setEndedQuarter })}
                    >
                      {Options({ options: quarters })}
                    </select>
                  </div>
                  {startedAt > endedAt ? (
                    <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', fontSize: '0.6em', color: 'red', marginRight: '5px' }}>* 선택된 기간을 확인해주세요</div>
                  ) : null}
                </div>
              </StyledTh>
            </tr>
          </thead>
        </table>
      ) : null}
      <table className="table table-hover">
        <thead>
          <tr>
            <StyledTh scope="col" width="15%">
              교회번호
            </StyledTh>
            <StyledTh scope="col" width="70%" onClick={toggleSortOrder} style={{ cursor: 'pointer' }}>
              교회명 &nbsp;
              {sortOrder === 'asc' ? ( //
                <StyledDirection>▲</StyledDirection>
              ) : (
                <StyledDirection>▼</StyledDirection>
              )}
            </StyledTh>
            <StyledTh scope="col" width="15%">
              보고서
            </StyledTh>
          </tr>
        </thead>
        <tbody>
          {termReports?.filter(conditionFunc).map((info) => {
            return (
              <React.Fragment key={info.c_id}>
                <tr>
                  <StyledTh scope="row">{info.c_id}</StyledTh>
                  <StyledTd>
                    <StyledDiv>{info.church_name}</StyledDiv>
                  </StyledTd>
                  <StyledTd>
                    {stage === 2 ? (
                      <StyledDiv>
                        <button //
                          className="styled-icon"
                          onClick={() => {
                            console.log(info);
                            _handleDownloadXlsxByDate({ startedAt, endedAt, churchId: info.c_id, churchName: info.church_name });
                          }}
                          disabled={startedAt > endedAt}
                        >
                          <Icon
                            icon="bi:filetype-xlsx" //
                          />
                        </button>
                      </StyledDiv>
                    ) : (
                      <StyledDiv>
                        {info.is_submitted === 'Y' ? null : (
                          <button //
                            className="styled-icon grey"
                            style={{ marginRight: 10 }}
                            onClick={() => {
                              window.open(`/adminManageTermReport?dt=${KoreaStartOfQuarter}&id=${info.c_id}`);
                            }}
                          >
                            <Icon
                              icon="bi:pencil-fill" //
                            />
                          </button>
                        )}
                        <button //
                          className="styled-icon"
                          onClick={() => openNewTab(`/termReportByAdmin/download/${info.c_id}/${KoreaStartOfQuarter}`)}
                        >
                          <Icon
                            icon="bi:file-earmark-pdf" //
                          />
                        </button>
                      </StyledDiv>
                    )}
                  </StyledTd>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </StyledContainer>
  );
};

export default TermReportListForXlsx;
