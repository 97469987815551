import React from 'react';
import styled from 'styled-components';
import { getCommaSeparatedNumber } from '../../../utils/common';

const StyledTd = styled.td`
    /* padding: 2px; */
    border: 0.5px solid black;
    font-family: '기본체' !important;
    font-size: 0.7em;
    line-height: 15px;
    height: 23px;

    @media print {
        /* padding: 2px; */
        border: 0.5px solid black;
        font-family: '기본체' !important;
        font-size: 0.7em;
        text-align: center;
        line-height: 15px;
        height: 23px;
    }
`;
const StyledTdGray = styled.td`
    /* padding: 2px; */
    border: 0.5px solid black;
    font-family: '기본체' !important;
    font-size: 0.9em;
    text-align: center;
    background-color: #ccc;
    line-height: 28px;

    @media print {
        /* padding: 2px; */
        border: 0.5px solid black;
        font-family: '기본체' !important;
        font-size: 0.9em;
        text-align: center;
        background-color: #ccc !important;
        line-height: 28px;
    }
`;
const StyledSpan = styled.span`
    font-family: '기본체' !important;
    font-size: 0.8em;
`;

export const TermReportBody = ({ termData, isAdmin, churchList }) => {
    return (
        <>
            <tr>
                <td rowSpan={5}>
                    <table style={{ width: '100%', height: '637.5px' }}>
                        <thead>
                            <tr>
                                <StyledTdGray className="text-center text-vertical-middle" colSpan={20}>
                                    기본사항
                                </StyledTdGray>
                            </tr>
                        </thead>

                        <tbody>
                            {isAdmin ? (
                                <>
                                    <tr>
                                        <StyledTd className="text-center text-vertical-middle" colSpan={4}>
                                            집회소수
                                        </StyledTd>
                                        <StyledTd className="text-center text-vertical-middle" colSpan={4}>
                                            보고서 제출
                                        </StyledTd>
                                        <StyledTd className="text-center text-vertical-middle" rowSpan={2} colSpan={6}>
                                            재적 침례교인수
                                        </StyledTd>
                                        <StyledTd className="text-center text-vertical-middle" rowSpan={2} colSpan={6}>
                                            출석관리 교인수
                                        </StyledTd>
                                    </tr>
                                    <tr>
                                        <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                            교회
                                        </StyledTd>
                                        <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                            예배소
                                        </StyledTd>
                                        <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                            교회
                                        </StyledTd>
                                        <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                            예배소
                                        </StyledTd>
                                    </tr>
                                    <tr>
                                        <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                            {churchList?.filter((church) => church.type === 10).length}
                                        </StyledTd>
                                        <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                            {churchList?.filter((church) => church.type === 20).length}
                                        </StyledTd>
                                        <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                            {churchList?.filter((church) => church.type === 10 && church.is_submitted === 'Y').length}
                                        </StyledTd>
                                        <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                            {churchList?.filter((church) => church.type === 20 && church.is_submitted === 'Y').length}
                                        </StyledTd>

                                        <StyledTd className="text-center text-vertical-middle" colSpan={6}>
                                            {getCommaSeparatedNumber(termData?.termReportData?.baptize_members)}
                                        </StyledTd>
                                        <StyledTd className="text-center text-vertical-middle" colSpan={6}>
                                            {getCommaSeparatedNumber(termData?.termReportData?.managed_members)}
                                        </StyledTd>
                                    </tr>
                                </>
                            ) : (
                                <>
                                    <tr>
                                        <StyledTd className="text-center text-vertical-middle" colSpan={10}>
                                            재적 침례교인수
                                        </StyledTd>
                                        <StyledTd className="text-center text-vertical-middle" colSpan={10}>
                                            출석관리 교인수
                                        </StyledTd>
                                    </tr>
                                    <tr>
                                        <StyledTd className="text-center text-vertical-middle" colSpan={10}>
                                            {getCommaSeparatedNumber(termData?.termReportData?.baptize_members)}
                                        </StyledTd>
                                        <StyledTd className="text-center text-vertical-middle" colSpan={10}>
                                            {getCommaSeparatedNumber(termData?.termReportData?.managed_members)}
                                        </StyledTd>
                                    </tr>
                                </>
                            )}

                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={20}>
                                    평균출석생 수
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3} rowSpan={2}>
                                    구분
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3} rowSpan={2}>
                                    화
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3} rowSpan={2}>
                                    금
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={11}>
                                    안식일
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={4}>
                                    설교예배
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={4}>
                                    2부예배
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3} style={{ verticalAlign: 'middle' }}>
                                    합계
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3} style={{ verticalAlign: 'middle' }}>
                                    청장년
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3} style={{ verticalAlign: 'middle' }}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.average_senior_tue)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3} style={{ verticalAlign: 'middle' }}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.average_senior_fri)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={4}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.average_senior_sat)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={4}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.average_senior_sat_2)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3} style={{ verticalAlign: 'middle' }}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.average_senior_sat + termData?.toBeDeletedTermReportData?.average_senior_sat_2) || 0}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    중고생
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.average_student_tue)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.average_student_fri)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={4}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.average_student_sat)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={4}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.average_student_sat_2)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.average_student_sat + termData?.toBeDeletedTermReportData?.average_student_sat_2) || 0}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    어린이
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.average_child_tue)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.average_child_fri)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={4}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.average_child_sat)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={4}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.average_child_sat_2)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.average_child_sat + termData?.toBeDeletedTermReportData?.average_child_sat_2) || 0}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    계
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    {getCommaSeparatedNumber(
                                        termData?.toBeDeletedTermReportData?.average_senior_tue +
                                            termData?.toBeDeletedTermReportData?.average_student_tue +
                                            termData?.toBeDeletedTermReportData?.average_child_tue,
                                    ) || 0}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    {getCommaSeparatedNumber(
                                        termData?.toBeDeletedTermReportData?.average_senior_fri +
                                            termData?.toBeDeletedTermReportData?.average_student_fri +
                                            termData?.toBeDeletedTermReportData?.average_child_fri,
                                    ) || 0}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={4}>
                                    {getCommaSeparatedNumber(
                                        termData?.toBeDeletedTermReportData?.average_senior_sat +
                                            termData?.toBeDeletedTermReportData?.average_student_sat +
                                            termData?.toBeDeletedTermReportData?.average_child_sat,
                                    ) || 0}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={4}>
                                    {getCommaSeparatedNumber(
                                        termData?.toBeDeletedTermReportData?.average_senior_sat_2 +
                                            termData?.toBeDeletedTermReportData?.average_student_sat_2 +
                                            termData?.toBeDeletedTermReportData?.average_child_sat_2,
                                    ) || 0}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    {getCommaSeparatedNumber(
                                        termData?.toBeDeletedTermReportData?.average_senior_sat +
                                            termData?.toBeDeletedTermReportData?.average_senior_sat_2 +
                                            termData?.toBeDeletedTermReportData?.average_student_sat +
                                            termData?.toBeDeletedTermReportData?.average_student_sat_2 +
                                            termData?.toBeDeletedTermReportData?.average_child_sat +
                                            termData?.toBeDeletedTermReportData?.average_child_sat_2,
                                    ) || 0}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" rowSpan={2} colSpan={10}>
                                    안식일 평균출석생
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    지난기
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    이번기
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    {getCommaSeparatedNumber(termData?.termReportPrevData?.sum_sabbath) || 0}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    {getCommaSeparatedNumber(
                                        termData?.toBeDeletedTermReportData?.average_senior_sat +
                                            termData?.toBeDeletedTermReportData?.average_senior_sat_2 +
                                            termData?.toBeDeletedTermReportData?.average_student_sat +
                                            termData?.toBeDeletedTermReportData?.average_student_sat_2 +
                                            termData?.toBeDeletedTermReportData?.average_child_sat +
                                            termData?.toBeDeletedTermReportData?.average_child_sat_2,
                                    ) || 0}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={20}>
                                    2번째 7번째 안식일 예배 참석자 수
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" rowSpan={2} colSpan={10}>
                                    2번째 안식일
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    안식일학교
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    설교예배
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.sabbath_school_attendance_2nd) || 0}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.sabbath_attendance_2nd) || 0}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" rowSpan={2} colSpan={10}>
                                    7번째 안식일
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    안식일학교
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    설교예배
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.sabbath_school_attendance_7th) || 0}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.sabbath_attendance_7th) || 0}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2} rowSpan={2}>
                                    수<br />침<br />자
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    장년
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    청년
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    중고생
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    어린이
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    계
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    금년누계
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.baptized_senior)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.baptized_youth)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.baptized_student)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.baptized_child)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    {getCommaSeparatedNumber(
                                        termData?.toBeDeletedTermReportData?.baptized_senior +
                                            termData?.toBeDeletedTermReportData?.baptized_youth +
                                            termData?.toBeDeletedTermReportData?.baptized_student +
                                            termData?.toBeDeletedTermReportData?.baptized_child,
                                    ) || 0}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    {getCommaSeparatedNumber(
                                        termData?.toBeDeletedTermReportData?.baptized_senior +
                                            termData?.toBeDeletedTermReportData?.baptized_youth +
                                            termData?.toBeDeletedTermReportData?.baptized_student +
                                            termData?.toBeDeletedTermReportData?.baptized_child +
                                            termData?.termReportPrevData?.sum_baptized,
                                    ) || 0}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={10}>
                                    잃은양 회복수
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={10}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.restored_lost)}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5} rowSpan={2}>
                                    교인세대수
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    가족
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    개인
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    합계
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    {getCommaSeparatedNumber(termData?.termReportData?.household_family)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    {getCommaSeparatedNumber(termData?.termReportData?.household_single)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    {getCommaSeparatedNumber(termData?.termReportData?.household_family + termData?.termReportData?.household_single) || 0}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    구분
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    남
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    여
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    합계
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    장로수
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    {getCommaSeparatedNumber(termData?.termReportData?.elder_men)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    {getCommaSeparatedNumber(termData?.termReportData?.elder_women)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    {getCommaSeparatedNumber(termData?.termReportData?.elder_men + termData?.termReportData?.elder_women) || 0}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    집사수
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    {getCommaSeparatedNumber(termData?.termReportData?.deacon)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    {getCommaSeparatedNumber(termData?.termReportData?.deaconess)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={5}>
                                    {getCommaSeparatedNumber(termData?.termReportData?.deacon + termData?.termReportData?.deaconess) || 0}
                                </StyledTd>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td rowSpan={6} width={'5px'} />
                <td rowSpan={5}>
                    <table style={{ width: '100%', height: '637.5px' }}>
                        <thead>
                            <tr>
                                <StyledTdGray className="text-center text-vertical-middle" colSpan={10}>
                                    안식일학교
                                </StyledTdGray>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={6}>
                                    안식일학교 수
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={4}>
                                    {getCommaSeparatedNumber(termData?.termReportData?.sabbath_school)} 개
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={6}>
                                    안식일학교 반수/교사수
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.termReportData?.sabbath_school_class)} 반
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.termReportData?.sabbath_school_teacher)} 명
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3} rowSpan={2}>
                                    구분
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3} rowSpan={2}>
                                    연령
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={4}>
                                    안교생수
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    지난기
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    이번기
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" rowSpan={5}>
                                    장<br />년
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2} rowSpan={2}>
                                    노년
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    71세 이상
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.termReportPrevData?.sabbath_school_member_more_then_71)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.sabbath_school_member_more_then_71)}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    61-70
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.termReportPrevData?.sabbath_school_member_61_to_70)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.sabbath_school_member_61_to_70)}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2} rowSpan={3}>
                                    장년
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    51-60
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.termReportPrevData?.sabbath_school_member_51_to_60)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.sabbath_school_member_51_to_60)}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    41-50
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.termReportPrevData?.sabbath_school_member_41_to_50)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.sabbath_school_member_41_to_50)}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    31-40
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.termReportPrevData?.sabbath_school_member_31_to_40)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.sabbath_school_member_31_to_40)}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    청년
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    20-30
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.termReportPrevData?.sabbath_school_member_20_to_30)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.sabbath_school_member_20_to_30)}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" rowSpan={2}>
                                    중<br />고<br />생
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    고등학생
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    17-19
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.termReportPrevData?.sabbath_school_member_17_to_19)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.sabbath_school_member_17_to_19)}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    중학생
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    14-16
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.termReportPrevData?.sabbath_school_member_14_to_16)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.sabbath_school_member_14_to_16)}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" rowSpan={4}>
                                    어<br />린<br />이
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    소년
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    11-13
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.termReportPrevData?.sabbath_school_member_11_to_13)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.sabbath_school_member_11_to_13)}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    유년
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    8-10
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.termReportPrevData?.sabbath_school_member_8_to_10)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.sabbath_school_member_8_to_10)}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    유치
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    4-7
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.termReportPrevData?.sabbath_school_member_4_to_7)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.sabbath_school_member_4_to_7)}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    영아
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    1-3
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.termReportPrevData?.sabbath_school_member_1_to_3)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.sabbath_school_member_1_to_3)}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={6}>
                                    침례반
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.termReportPrevData?.sabbath_school_member_baptism_class)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.sabbath_school_member_baptism_class)}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={6}>
                                    장기결석자
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.termReportPrevData?.sabbath_school_member_long_term_absentee)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.sabbath_school_member_long_term_absentee)}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={6}>
                                    잃은양
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.termReportPrevData?.sabbath_school_member_lost)}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.toBeDeletedTermReportData?.sabbath_school_member_lost)}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={6}>
                                    계
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(
                                        termData?.termReportPrevData?.sabbath_school_member_more_then_71 +
                                            termData?.termReportPrevData?.sabbath_school_member_61_to_70 +
                                            termData?.termReportPrevData?.sabbath_school_member_51_to_60 +
                                            termData?.termReportPrevData?.sabbath_school_member_41_to_50 +
                                            termData?.termReportPrevData?.sabbath_school_member_31_to_40 +
                                            termData?.termReportPrevData?.sabbath_school_member_20_to_30 +
                                            termData?.termReportPrevData?.sabbath_school_member_17_to_19 +
                                            termData?.termReportPrevData?.sabbath_school_member_14_to_16 +
                                            termData?.termReportPrevData?.sabbath_school_member_11_to_13 +
                                            termData?.termReportPrevData?.sabbath_school_member_8_to_10 +
                                            termData?.termReportPrevData?.sabbath_school_member_4_to_7 +
                                            termData?.termReportPrevData?.sabbath_school_member_1_to_3 +
                                            termData?.termReportPrevData?.sabbath_school_member_baptism_class +
                                            termData?.termReportPrevData?.sabbath_school_member_long_term_absentee +
                                            termData?.termReportPrevData?.sabbath_school_member_lost,
                                    ) || 0}
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(
                                        termData?.toBeDeletedTermReportData?.sabbath_school_member_more_then_71 +
                                            termData?.toBeDeletedTermReportData?.sabbath_school_member_61_to_70 +
                                            termData?.toBeDeletedTermReportData?.sabbath_school_member_51_to_60 +
                                            termData?.toBeDeletedTermReportData?.sabbath_school_member_41_to_50 +
                                            termData?.toBeDeletedTermReportData?.sabbath_school_member_31_to_40 +
                                            termData?.toBeDeletedTermReportData?.sabbath_school_member_20_to_30 +
                                            termData?.toBeDeletedTermReportData?.sabbath_school_member_17_to_19 +
                                            termData?.toBeDeletedTermReportData?.sabbath_school_member_14_to_16 +
                                            termData?.toBeDeletedTermReportData?.sabbath_school_member_11_to_13 +
                                            termData?.toBeDeletedTermReportData?.sabbath_school_member_8_to_10 +
                                            termData?.toBeDeletedTermReportData?.sabbath_school_member_4_to_7 +
                                            termData?.toBeDeletedTermReportData?.sabbath_school_member_1_to_3 +
                                            termData?.toBeDeletedTermReportData?.sabbath_school_member_baptism_class +
                                            termData?.toBeDeletedTermReportData?.sabbath_school_member_long_term_absentee +
                                            termData?.toBeDeletedTermReportData?.sabbath_school_member_lost,
                                    ) || 0}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={6}>
                                    교사회 운영횟수
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={4}>
                                    {getCommaSeparatedNumber(termData?.termReportData?.teacher_association_operation)}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={6}>
                                    평균공부자수
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={4}>
                                    {getCommaSeparatedNumber(termData?.termReportData?.average_studious)}
                                </StyledTd>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td rowSpan={7} width={'5px'} />
                <td>
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <StyledTdGray className="text-center text-vertical-middle" colSpan={3}>
                                    선교 및 교육 훈련
                                </StyledTdGray>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    소그룹 수 / 활동적인 소그룹 수
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle">
                                    {getCommaSeparatedNumber(termData?.termReportData?.small_group)} / {getCommaSeparatedNumber(termData?.termReportData?.active_small_group)}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    제자훈련 실행여부
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.disciple_training)}</StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    전도활동에 참여한 자 수
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.evangelist)}</StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    평신도 전도회 및 세미나 수
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.laity_evangelical_meeting_and_seminar)}</StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    개인성경교수 수
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.personal_bible_teach)}</StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    평신도활동으로 침례받은 수
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.baptism_by_laity)}</StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    전도지, 소책자 전한 수
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.tract_and_booklet)}</StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    전도훈련원 운영여부
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.evangelism_training_center)}</StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    지역사회 봉사 단체(반)수
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.community_service_group)}</StyledTd>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            <tr>
                <td height={'5px'} />
            </tr>
            <tr>
                <td>
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <StyledTdGray className="text-center text-vertical-middle" colSpan={3}>
                                    전도활동
                                </StyledTdGray>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    구분
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle">횟수</StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    어린이 전도회
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.child_evangelical_meeting)}</StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    청소년 전도회
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.teen_evangelical_meeting)}</StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    목회자 전도회
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.pastor_evangelical_meeting)}</StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    소그룹 전도회
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.small_group_evangelical_meeting)}</StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    전도단 전도회
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.evangelist_group_evangelical_meeting)}</StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    기타 각종 전도회
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.other_evangelical_meeting)}</StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    합계
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle">
                                    {getCommaSeparatedNumber(
                                        termData?.termReportData?.child_evangelical_meeting +
                                            termData?.termReportData?.teen_evangelical_meeting +
                                            termData?.termReportData?.pastor_evangelical_meeting +
                                            termData?.termReportData?.small_group_evangelical_meeting +
                                            termData?.termReportData?.evangelist_group_evangelical_meeting +
                                            termData?.termReportData?.other_evangelical_meeting,
                                    ) || 0}
                                </StyledTd>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            <tr>
                <td height={'5px'} />
            </tr>
            <tr>
                <td>
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <StyledTdGray className="text-center text-vertical-middle" colSpan={4}>
                                    청소년 사업
                                </StyledTdGray>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle">구분</StyledTd>
                                <StyledTd className="text-center text-vertical-middle">조직수</StyledTd>
                                <StyledTd className="text-center text-vertical-middle">등록회원</StyledTd>
                                <StyledTd className="text-center text-vertical-middle">출석회원</StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle">청년선교회</StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.youth_group)}</StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.youth_group_members)}</StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.youth_group_attendance)}</StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle">학생회</StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.student_group)}</StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.student_group_members)}</StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.student_group_attendance)}</StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle">패스파인더</StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.pathfinder)}</StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.pathfinder_members)}</StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.pathfinder_attendance)}</StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    청소년관 소유여부
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.termReportData?.student_room)}
                                </StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    청소년 지도교사 수
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2}>
                                    {getCommaSeparatedNumber(termData?.termReportData?.student_teachers)}
                                </StyledTd>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            <tr>
                <td height={'5px'} />
            </tr>
            <tr>
                <StyledTd colSpan={3} style={{ paddingLeft: '10px' }}>
                    <table>
                        <tbody>
                            <tr>
                                <td width={'100%'}>
                                    <StyledSpan>*감소 안식일학교 이름 / 반생 이름:</StyledSpan>
                                </td>
                            </tr>
                            <tr>
                                <td height={'65px'}>
                                    <StyledSpan>
                                        {termData?.termReportData?.reduce_sabbath_school_name} /{termData?.termReportData?.reduce_sabbath_school_members_name}
                                    </StyledSpan>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <StyledSpan>*증가 안식일학교 이름 / 반생 이름:</StyledSpan>
                                </td>
                            </tr>
                            <tr>
                                <td height={'65px'}>
                                    <StyledSpan>
                                        {termData?.termReportData?.increase_sabbath_school_name} / {termData?.termReportData?.increase_sabbath_school_members_name}
                                    </StyledSpan>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </StyledTd>

                <td>
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <StyledTdGray colSpan={4} className="text-center text-vertical-middle">
                                    어린이 사업
                                </StyledTdGray>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    어린이관 소유여부
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.child_room)}</StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    어린이 교사수
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.child_teacher)}</StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={3}>
                                    어린이 정규안교 개최여부
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.child_sabbath_school)}</StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2} rowSpan={2}>
                                    어린이 저녁예배 실시여부
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle">화</StyledTd>
                                <StyledTd className="text-center text-vertical-middle">금</StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.child_tus_evening_service)}</StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.child_fri_evening_service)}</StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle" colSpan={2} rowSpan={2}>
                                    어린이 성경학교
                                </StyledTd>
                                <StyledTd className="text-center text-vertical-middle">개최수</StyledTd>
                                <StyledTd className="text-center text-vertical-middle">참석자수</StyledTd>
                            </tr>
                            <tr>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.child_bible_school)}</StyledTd>
                                <StyledTd className="text-center text-vertical-middle">{getCommaSeparatedNumber(termData?.termReportData?.child_bible_school_attendance)}</StyledTd>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            <tr>
                <td height={'5px'} />
            </tr>
            <tr>
                <td colSpan={4} height={'5px'} />
            </tr>
        </>
    );
};

export default TermReportBody;
