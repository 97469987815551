import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { getCommaSeparatedNumber } from '../../../utils/common';
import InputGroup5 from '../components/InputGroup5';

const ActivitiesCountSum = ({ reportDxData, setReportDxData, reportMxData, setReportMxData }) => {
    const [sum, setSum] = useState({});

    useEffect(() => {
        const seekerVisits = [];
        const memberVisits = [];
        const teaches = [];
        const sermons = [];
        const transportations = [];

        reportDxData.forEach((week) => {
            week.forEach((day) => {
                day.seekerCnt > 0 && // 구도자 방문
                    seekerVisits.push(day.seekerCnt);
                day.memberCnt > 0 && // 신자 방문
                    memberVisits.push(day.memberCnt);
                day.teachCnt > 0 && // 성경교수
                    teaches.push(day.teachCnt);
                day.sermonCnt > 0 && // 설교한수
                    sermons.push(day.sermonCnt);
                day.transportation > 0 && // 교통비
                    transportations.push(day.transportation);
            });
        });
        setSum({
            ...sum,
            seekerSum: seekerVisits.reduce((prev, curr) => prev + curr, 0),
            memberSum: memberVisits.reduce((prev, curr) => prev + curr, 0),
            teachSum: teaches.reduce((prev, curr) => prev + curr, 0),
            sermonSum: sermons.reduce((prev, curr) => prev + curr, 0),
            transportationSum: transportations.reduce((prev, curr) => prev + curr, 0),
        });
    }, [reportDxData]);

    return (
        <div className="row">
            <div className="panel">
                <div className="panel-body">
                    <div className="col-xs-6 col-sm-2 col-md-2">
                        <span className="sum">방문(구도자): {sum.seekerSum}회</span>
                    </div>
                    <div className="col-xs-6 col-sm-2 col-md-2">
                        <span className="sum">방문(신자): {sum.memberSum}회</span>
                    </div>
                    <div className="col-xs-6 col-sm-2 col-md-2">
                        <span className="sum">성경교수: {sum.teachSum}회</span>
                    </div>
                    <div className="col-xs-6 col-sm-2 col-md-2">
                        <span className="sum">설교한수: {sum.sermonSum}회</span>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4">
                        <span className="sum">교통비: {getCommaSeparatedNumber(sum.transportationSum)}원</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActivitiesCountSum;
