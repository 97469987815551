import React, { useEffect } from 'react';
import styled from 'styled-components';

import { showConfirmBox } from '../../../../components/ConfirmBox';
import PopupDom from '../../../../components/PopupDom';
import PopupAddr from '../components/PopupAddr';

import SelectCondition from '../components/SelectCondition';
import { ChurchState, ChurchType, TemporaryChurchInfo } from '../data/data';
import { tr } from 'date-fns/locale';

const ListTr = styled.tr`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 0.95em;
    text-align: center;
    vertical-align: middle;
    height: 36px;
`;
const EditTd = styled.td`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    font-size: 0.95em;
    text-align: center;
    vertical-align: middle;
    background-color: white;
`;
const EditTh = styled.th`
    border: 0.5px solid silver;
    font-family: '기본체' !important;
    height: 35px;
    font-size: 1em;
    text-align: center;
    vertical-align: middle;
    background-color: rgb(212, 212, 212);
`;
const EditDiv = styled.div`
    font-family: '기본체' !important;
    font-size: 1em;
    text-align: center;
    vertical-align: middle;
    align-items: center;
`;
const ListButton = styled.button`
    font-family: '기본체' !important;
    font-size: 1em;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    width: 94%;
`;

const ChurchEditor = ({
    areas,
    church,
    setChurchInfo,
    selectedArea,

    handleNameChange,
    handleTypeChange,
    handleTelChange,
    handleStateChange,
    handleHomepageChange,
    handleAreaChange,
    handleIsAddrPopupChange,
    handleFocusAddressDetail,
    handleAddrChange,
    addressDetailRef,
    handleAddrDetailChange,
    handleCheckAgency,

    handleDelChurch,
    handleEditChurch,
    handleResetChurch,
    handleCreateChurch,
}) => {
    const handleReset = () => {
        setChurchInfo(TemporaryChurchInfo);
    };

    return (
        <table style={{ width: '100%' }}>
            <tbody>
                <ListTr>
                    <EditTh>
                        {church.id ? '' : <span style={{ color: 'red' }}>*</span>}
                        교회이름
                    </EditTh>
                    <EditTd colSpan={2}>
                        <EditDiv className="col-xs-12 col-sm-12 col-md-12 mb5 mt5">
                            <input //
                                className="EditInput"
                                value={church.newName}
                                placeholder="교회이름을 입력해주세요"
                                onChange={(e) => handleNameChange(e, church.id)}
                            />
                        </EditDiv>
                    </EditTd>
                    <EditTh>형태</EditTh>
                    <EditTd>
                        <SelectCondition
                            options={ChurchType} // data.js 값
                            value={church.type ? church.type : ''}
                            targetId={church.id}
                            handleChange={handleTypeChange}
                        />
                    </EditTd>
                </ListTr>
                <ListTr>
                    <EditTh>전화번호</EditTh>
                    <EditTd colSpan={2}>
                        <EditDiv className="col-xs-12 col-sm-12 col-md-12 mb5 mt5">
                            <input //
                                className="EditInput"
                                value={church.tel}
                                placeholder={church.tel ? church.tel : '전화번호를 입력해주세요'}
                                onChange={(e) => handleTelChange(e, church.id)}
                            />
                        </EditDiv>
                    </EditTd>
                    <EditTh>상태</EditTh>
                    <EditTd>
                        <SelectCondition
                            options={ChurchState} // data.js 값
                            value={church.state ? church.state : ''}
                            targetId={church.id}
                            handleChange={handleStateChange}
                        />
                    </EditTd>
                </ListTr>
                <ListTr>
                    <EditTh>홈페이지</EditTh>
                    <EditTd colSpan={2}>
                        <EditDiv className="col-xs-12 col-sm-12 col-md-12 mb5 mt5">
                            <input //
                                className="EditInput"
                                value={church.homepage}
                                placeholder={church.homepage ? church.homepage : '교회 홈페이지를 입력해주세요'}
                                onChange={(e) => handleHomepageChange(e, church.id)}
                            />
                        </EditDiv>
                    </EditTd>
                    <EditTh>
                        {church.id ? '' : <span style={{ color: 'red' }}>*</span>}
                        지역
                    </EditTh>
                    <EditTd>
                        <SelectCondition
                            options={areas} // data.js 값
                            value={church.areaId ? church.areaId : selectedArea}
                            targetId={church.id}
                            handleChange={handleAreaChange}
                        />
                    </EditTd>
                </ListTr>
                <ListTr>
                    <EditTh rowSpan={2}>주소</EditTh>
                    <EditTd colSpan={3}>
                        <EditDiv className="col-xs-12 col-sm-12 col-md-12 mt5 mb5">
                            <input
                                className="EditInput" //
                                value={church.addr}
                                placeholder={church.addr ? church.addr : '주소를 검색해주세요'}
                                readOnly="readonly"
                            />
                        </EditDiv>
                    </EditTd>
                    <EditTd>
                        <ListButton className="EditButton search" onClick={() => handleIsAddrPopupChange(church.id)}>
                            주소검색
                        </ListButton>
                    </EditTd>
                </ListTr>
                <ListTr>
                    <EditTd colSpan={4}>
                        {/* 주소검색을 위한 팝업창  */}
                        <div id={`popupDom-${church.id}`} style={{ position: 'relative', zIndex: 999 }}>
                            {church.isAddrPopupOpen && (
                                <PopupDom domName={`popupDom-${church.id}`} style={{ width: '100%' }}>
                                    <PopupAddr
                                        onClose={() => handleIsAddrPopupChange(church.id)} //
                                        targetId={church.id}
                                        handleChange={handleAddrChange}
                                        setFocus={handleFocusAddressDetail}
                                    />
                                </PopupDom>
                            )}
                        </div>
                        <EditDiv className="col-sm-12 mt5 mb5">
                            <input
                                className="EditInput"
                                value={church.addressDetail} //
                                ref={addressDetailRef}
                                placeholder="상세주소를 입력하세요"
                                onChange={(e) => handleAddrDetailChange(e, church.id)}
                            />
                        </EditDiv>
                    </EditTd>
                </ListTr>
                <ListTr>
                    {church.id ? (
                        <>
                            <EditTh>기관</EditTh>
                            <EditTd>
                                <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <input
                                        type="checkbox" //
                                        checked={church.isAgency}
                                        onChange={(e) => handleCheckAgency(e, church.id)}
                                    />
                                </div>
                            </EditTd>
                            <EditTd>
                                <ListButton //
                                    className="EditButton remove"
                                    onClick={() =>
                                        showConfirmBox(
                                            `[${church.newName}]의 데이터를 제거하시겠습니까? `,
                                            `\n`,
                                            () => {
                                                handleDelChurch({ churchName: church.newName, churchId: church.id });
                                            },
                                            'delete-button',
                                        )
                                    }
                                >
                                    제거
                                </ListButton>
                            </EditTd>
                            <EditTd>
                                <ListButton //
                                    className="EditButton reset"
                                    onClick={() =>
                                        showConfirmBox(
                                            `입력한 데이터를 초기화하시겠습니까?`,
                                            `\n`,
                                            () => {
                                                handleResetChurch({ churchId: church.id });
                                            },
                                            'reset-button',
                                        )
                                    }
                                >
                                    초기화
                                </ListButton>
                            </EditTd>
                            <EditTd>
                                <ListButton //
                                    className="EditButton save"
                                    onClick={() =>
                                        showConfirmBox(
                                            `[${church.newName}]의 데이터를 저장하시겠습니까?`,
                                            `\n 전화번호: ${church.tel}\n 주소: ${church.addressDetail ? `${church.addr}(${church.addressDetail})` : church.addr}`,
                                            () => {
                                                handleEditChurch({
                                                    churchId: church.id,
                                                    confId: 1,
                                                    areaId: church.areaId,
                                                    name: church.newName,
                                                    tel: church.tel,
                                                    addr: church.addressDetail ? `${church.addr}(${church.addressDetail})` : church.addr,
                                                    homepage: church.homepage,
                                                    type: church.type,
                                                    state: church.state,
                                                    agency: church.isAgency === true ? 1 : 0,
                                                });
                                            },
                                            'send-button',
                                        )
                                    }
                                >
                                    저장
                                </ListButton>
                            </EditTd>
                        </>
                    ) : (
                        <>
                            <EditTh>기관</EditTh>
                            <EditTd>
                                <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <input
                                        type="checkbox" //
                                        checked={church.isAgency}
                                        onChange={(e) => handleCheckAgency(e, church.id)}
                                    />
                                </div>
                            </EditTd>
                            <td />
                            <EditTd>
                                <ListButton //
                                    className="EditButton reset"
                                    onClick={() =>
                                        showConfirmBox(
                                            `입력한 데이터를 초기화하시겠습니까?`,
                                            `\n`,
                                            () => {
                                                handleReset();
                                            },
                                            'reset-button',
                                        )
                                    }
                                >
                                    초기화
                                </ListButton>
                            </EditTd>
                            <EditTd>
                                <ListButton //
                                    className="EditButton save"
                                    onClick={() =>
                                        showConfirmBox(
                                            `[${church.newName}]의 데이터를 생성하시겠습니까?`,
                                            `\n 전화번호: ${church.tel}\n 주소: ${church.addressDetail ? `${church.addr}(${church.addressDetail})` : church.addr}`,
                                            () => {
                                                handleCreateChurch({
                                                    confId: 1, // 추후에 지역을 확장할 경우 수정 - 현재는 영남지역(1)이므로 고정값을 보내준다.
                                                    areaId: church.areaId,
                                                    name: church.newName,
                                                    tel: church.tel,
                                                    homepage: church.homepage,
                                                    type: church.type,
                                                    state: church.state,
                                                    addr: church.addressDetail ? `${church.addr}(${church.addressDetail})` : church.addr,
                                                    agency: church.isAgency === true ? 1 : 0,
                                                });
                                            },
                                            'send-button',
                                        )
                                    }
                                >
                                    생성
                                </ListButton>
                            </EditTd>
                        </>
                    )}
                </ListTr>
            </tbody>
        </table>
    );
};

export default ChurchEditor;
