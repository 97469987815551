import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CommonContext } from '../../contexts';

let CATEGORY_FREEBOARD_ID = null; // 자유게시판
let CATEGORY_COUNSEL_ID = null; // 미디어센터

const MainSubBanners = () => {
    const { settings } = useContext(CommonContext);

    useEffect(() => {
        CATEGORY_FREEBOARD_ID = settings.find((setting) => setting.name === 'category_freeboard_id')?.value;
        CATEGORY_COUNSEL_ID = settings.find((setting) => setting.name === 'category_counsel_id')?.value;
    }, [settings]);

    return (
        <div className="nmcdn_col1 col-sm-4 col-sm-4-1">
            <div className="nmcdn_card">
                <ul className="hs_banner">
                    <li className="wow fadeInRight" data-wow-delay="1.4s">
                        <div>
                            <h1>자유게시판</h1>
                            <p>영남합회의 열린 소통 공간입니다.</p>
                            <Link to={`/board/posts/${CATEGORY_FREEBOARD_ID}/list?offset=0`}>
                                자세히 보기<i className="fa fa-angle-right"></i>
                            </Link>
                        </div>
                    </li>
                    <li className="wow fadeInRight" data-wow-delay="1.4s">
                        <div>
                            <h1>신앙상담소</h1>
                            <p>익명으로 자유롭게 소통하는 공간입니다.</p>
                            <Link to={`/board/posts/${CATEGORY_COUNSEL_ID}/list?offset=0`}>
                                자세히 보기<i className="fa fa-angle-right"></i>
                            </Link>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default MainSubBanners;
