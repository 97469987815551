import React, { useEffect, useState } from 'react';
import InputGroup2 from '../components/InputGroup2';

const BooksCount = ({ compasCnt, seniorCnt, teenCnt, juniorCnt, setReportMxData, reportMxData, disabled }) => {
    // const [churchCompas, setChurchCompas] = useState(0); // 교회지남
    // const [seniors, setSeniors] = useState(0); // 장년교과
    // const [teenAgers, setTeenAgers] = useState(0); // 청소년교과
    // const [juniors, setJuniors] = useState(0); // 유년교과

    // const [disabled, setDisabled] = useState(false);

    // useEffect(() => {
    //     reportMxData.is_submitted === 'Y' ? setDisabled(true) : setDisabled(false);
    // }, [reportMxData.is_submitted]);

    // useEffect(() => {
    //     if (reportMxData === {}) return;
    //     setReportMxData((report) => ({
    //         ...report, //
    //         books_compas: parseInt(churchCompas),
    //         books_senior: parseInt(seniors),
    //         books_teen: parseInt(teenAgers),
    //         books_junior: parseInt(juniors),
    //     }));
    // }, [churchCompas, seniors, teenAgers, juniors]);

    // useEffect(() => {
    //     setChurchCompas(compasCnt);
    //     setSeniors(seniorCnt);
    //     setTeenAgers(teenCnt);
    //     setJuniors(juniorCnt);
    // }, [compasCnt, seniorCnt, teenCnt, juniorCnt]);

    return (
        <div className="row">
            <div className="panel">
                <div className="panel-heading">
                    <h3>정기간행물</h3>
                </div>
                <div className="panel-body">
                    <div className="col-xs-6 col-sm-3 col-md-3">
                        <InputGroup2
                            title={'교회지남'} //
                            value={compasCnt}
                            setReportMxData={setReportMxData}
                            reportColumnName={'books_compas'}
                            disabled={disabled}
                            unit={'부'}
                        />
                    </div>
                    <div className="col-xs-6 col-sm-3 col-md-3">
                        <InputGroup2
                            title={'장년교과'} //
                            value={seniorCnt}
                            setReportMxData={setReportMxData}
                            reportColumnName={'books_senior'}
                            disabled={disabled}
                            unit={'부'}
                        />
                    </div>
                    <div className="col-xs-6 col-sm-3 col-md-3">
                        <InputGroup2
                            title={'청소년교과'} //
                            value={teenCnt}
                            setReportMxData={setReportMxData}
                            reportColumnName={'books_teen'}
                            disabled={disabled}
                            unit={'부'}
                        />
                    </div>
                    <div className="col-xs-6 col-sm-3 col-md-3">
                        <InputGroup2
                            title={'유년교과'} //
                            value={juniorCnt}
                            setReportMxData={setReportMxData}
                            reportColumnName={'books_junior'}
                            disabled={disabled}
                            unit={'부'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BooksCount;
