export const theme = {
    /* color ------- */
    colorDefault: '#d5a10e',

    colorBackground: '#ffffff',
    
    colorWhite:'#ffffff',
    colorBlack: '#000000',
    
    /* Padding ------- */
    paddingDefault: 10,

    paddingLarge: 20,
    paddingSmall: 5,
    
};