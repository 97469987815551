import React, { useEffect } from 'react';
import { getCommaSeparatedNumber, getTimeByWritingTime } from '../../../utils/common';
import BoardRouter from './BoardRouter';
import BoardConfirmIcon from './BoardConfirmIcon';
import CommentCount from './CommentCount';
import NewIcon from './NewIcon';
import NoticeLabel from './NoticeLabel';

const BoardList = ({
    posts, //
    groupCode,
    CATEGORY_COUNSEL_ID,
    categoryId,
    isNotice = false,
    CATEGORY_NOTICE_ID,
    isSearching,
    searchKeyword,
    postOffset,
    SEARCH_URL,
    COMMON_URL,
}) => {
    const memberIcon = (post) => {
        return (
            <img
                style={{ width: 37 }}
                src={`../../../common/images/admin/${
                    {
                        목회자: 'pastor',
                        집사: 'deacon',
                        장로: 'elder',
                        평신도: 'layman',
                        청년: 'youth',
                        기타: 'etc',
                    }[post.role]
                }_${post.gender === 'F' ? 'woman' : 'man'}.png`}
                alt={post.role}
            />
        );
    };

    return posts.map((post) => {
        return (
            <React.Fragment key={post.id}>
                <tr>
                    <td className="left">
                        <div className="list_subject">
                            <span className="link">
                                <b className="red">{post.option}</b>
                                <BoardConfirmIcon confirm={post.confirm} />

                                {isNotice && <NoticeLabel />}

                                <BoardRouter //
                                    groupCode={groupCode}
                                    post={post}
                                    isNotice={isNotice}
                                    CATEGORY_NOTICE_ID={CATEGORY_NOTICE_ID}
                                    isSearching={isSearching}
                                    searchKeyword={searchKeyword}
                                    postOffset={postOffset}
                                    SEARCH_URL={SEARCH_URL}
                                    COMMON_URL={COMMON_URL}
                                />
                            </span>

                            <CommentCount comments={post.comments} />

                            <NewIcon createdAt={post.createdAt} />
                        </div>
                    </td>
                    <td className="write">{CATEGORY_COUNSEL_ID === parseInt(categoryId) ? memberIcon(post) : post?.department || post.name}</td>
                    <td className="date">{getTimeByWritingTime(post.createdAt)}</td>
                    <td className="views">{getCommaSeparatedNumber(post.hits)}</td>
                    <td className="file fileIcon">{!post.files ? '-' : <i className="xi-file-o" />}</td>
                    <td className="views">{getCommaSeparatedNumber(post.sns)}</td>
                </tr>
            </React.Fragment>
        );
    });
};

export default BoardList;
