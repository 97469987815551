import React from 'react';

import MainChurchNews from './MainChurchNews';
import MainInfoBox from './MainInfoBox'
import MainNotices from './MainNotices';
import MainOfficialNotes from './MainOfficialNotes';
import MainSubBanners from './MainSubBanners';

const MainContent = () => {

    return (
        <div id='container-wrap' className='container clr'>
            <section className='line01'>
                <div className='nm_cdnot'>
                    
                    <MainNotices />         {/* 공지사항 */}
                    <MainOfficialNotes />   {/* 합회공문 */}
                    <MainSubBanners />      {/* 서브 배너 */}

                </div>
                <p className='clear'></p>
            </section>
            <section className='line02 mt40'>
                <div className='nm_cdnot'>

                    <MainChurchNews />      {/* 교회합회소식 */}
                    <MainInfoBox />         {/* 합회문의 */}

                </div>
                <p className='clear'></p>
            </section>
        </div>
    );
};

export default MainContent;
