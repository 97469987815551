import React from 'react';
import { addDays, format, isThisMonth } from 'date-fns';
import { Icon } from '@iconify/react';
import styled from 'styled-components';

const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledText = styled.span`
    font-family: '기본체';
`;

const Last = styled.div`
    color: #777;
`;

const ActivityReportHeader = ({ currentMonth, prevMonth, nextMonth }) => {
    return (
        <Header>
            <div className="first">
                <StyledText>
                    {format(currentMonth, 'yyyy')}년 <span className="text month">{format(currentMonth, 'M')}월</span>
                </StyledText>
            </div>
            <Last>
                <Icon icon="bi:arrow-left-circle-fill" onClick={prevMonth} style={{ marginRight: 4 }} />
                {!isThisMonth(addDays(currentMonth, 10)) ? ( //
                    <Icon icon="bi:arrow-right-circle-fill" onClick={nextMonth} />
                ) : (
                    <Icon icon="bi:arrow-right-circle-fill" style={{ color: '#ccc' }} />
                )}
            </Last>
        </Header>
    );
};

export default ActivityReportHeader;
