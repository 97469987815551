import React, { useContext, useEffect, useState } from 'react';
import { addQuarters, format, getQuarter, startOfQuarter, subDays, subQuarters } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { CommonContext, ProgressContext, UserContext } from '../../contexts';
import { getTermReportData, saveTermReportData, submitTermReportData } from '../../data/mysql_term';

import TermReportTable from './screen/TermReportTable';
import TermReportBaseInfo from './screen/TermReportBaseInfo';

import toast from 'react-hot-toast';
import ArrowButtons from './components/ArrowButtons';

const TermReport = () => {
    const { spinner } = useContext(ProgressContext);

    const { categories } = useContext(CommonContext);
    const { user } = useContext(UserContext);
    const { pathname } = useLocation();

    const [currentTerm, setCurrentTerm] = useState(subQuarters(new Date(), 1));

    const [termReportInfo, setTermReportInfo] = useState({});
    const [termReportData, setTermReportData] = useState({});
    const [toBeDeletedTermReportData, setToBeDeletedTermReportData] = useState({});
    const [termReportPrevData, setTermReportPrevData] = useState({});

    const [isSendable, setIsSendable] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [errorMassage, setErrorMassage] = useState({ head: '', body: '', tail: '' });

    const timeZone = 'Asia/Seoul';
    const KoreaStartOfQuarter = Math.floor(startOfQuarter(currentTerm, { timeZone: timeZone }).getTime() / 1000);

    // 1. 마운트 될때, 실행된다. 보고서의 기본 정보를 가져온다.
    useEffect(() => {
        if (!toBeDeletedTermReportData === {} || !termReportData === {}) return;

        const init = async () => {
            let result = await getTermReportData({ userId: user.id, dt: KoreaStartOfQuarter });

            if (result) {
                setTermReportInfo(result.termReportInfo);
                setTermReportData(result.termReportData);
                setToBeDeletedTermReportData(result.toBeDeletedTermReportData);
                setTermReportPrevData(result.termReportPrevData);
            } else {
                // 이 경우 pastor_member_rel, church_pastor_rel의 테이블을 먼저 확인할 것.
                // 목회자 인증이 완료되지 않았거나, 목회자가 교회에 등록되지 않았을 확률이 높다.
                setErrorMassage({ head: '교회를 조회 할 수 없습니다', body: '* 등록된 교회를 조회하는 데 실패하였습니다', tail: '* 합회로 문의해 주시기 바랍니다' });
            }
        };
        init();
    }, [currentTerm]);

    /**
     * 레포트를 전송한다.
     */
    const _handleSendReport = async () => {
        try {
            spinner.start();

            const result = await submitTermReportData({ reportId: termReportInfo.id, termReportData, toBeDeletedTermReportData });

            setTermReportInfo((report) => ({
                ...report,
                is_submitted: 'Y',
            }));

            if (result) {
                setIsSendable(false);
                toast.success('레포트를 전송하였습니다.');
            } else {
                toast.error('레포트 전송에 실패하였습니다.');
            }
        } catch (e) {
            toast.error('레포트 전송에 실패하였습니다.');
            console.log(e);
        } finally {
            spinner.stop();
        }
    };

    /**
     * '다시 작성'버튼을 누르면 일 데이터를 초기화한다.
     */
    const _handleResetReport = async () => {
        let result = await getTermReportData({ userId: user.id, dt: KoreaStartOfQuarter });

        if (!result) return toast.error('초기화에 실패하였습니다.');

        setTermReportInfo(result.termReportInfo);
        setTermReportData(result.termReportData);
        setToBeDeletedTermReportData(result.toBeDeletedTermReportData);
        setTermReportPrevData(result.termReportPrevData);

        toast.success('초기화 되었습니다.');
    };

    /**
     * 기말 데이터를 저장한다.
     */
    const _handleSaveReport = async () => {
        let result;
        try {
            spinner.start();

            result = await saveTermReportData({
                reportId: termReportInfo.id, //
                termReportData,
                toBeDeletedTermReportData,
            });
            result && toast.success('저장 되었습니다.');

            return console.log('save: ', result);
        } catch (e) {
            console.log(e);
        } finally {
            spinner.stop();
        }
    };

    useEffect(() => {
        termReportInfo?.is_submitted === 'Y' ? setDisabled(true) : setDisabled(false);
    }, [termReportInfo]);

    return (
        <div className="content-area">
            <div className="site-content">
                <div>
                    <div className="title-inner">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>
                                <img src="/common/images/title.png" alt="" />
                                <h1>{categories.find((category) => category.categoryCode === pathname.split('/')[4])?.categoryName}</h1>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: 20 }}></div>
                    <div style={{ clear: 'both' }}></div>
                    <div className="title_subpage_solid_1"></div>
                    <div className="container-fluid">
                        <TermReportBaseInfo //
                            errorMassage={errorMassage}
                            termReportInfo={termReportInfo}
                            termReportData={termReportData}
                            isSendable={isSendable}
                            setIsSendable={setIsSendable}
                            onSendButtonClick={_handleSendReport}
                            onResetButtonClick={_handleResetReport}
                            onSaveButtonClick={_handleSaveReport}
                        />

                        <div className="row">
                            <div className="panel">
                                <div className="panel-heading">
                                    <h3 className="styled-between">
                                        <div>
                                            {format(currentTerm, 'yyyy')}년 제 {getQuarter(currentTerm)}기 보고
                                        </div>
                                        <ArrowButtons setCurrentTerm={setCurrentTerm} currentTerm={currentTerm} />
                                    </h3>
                                </div>
                                <TermReportTable //
                                    disabled={disabled}
                                    termReportPrevData={termReportPrevData}
                                    termReportInfo={termReportInfo}
                                    termReportData={termReportData}
                                    setTermReportData={setTermReportData}
                                    toBeDeletedTermReportData={toBeDeletedTermReportData}
                                    setToBeDeletedTermReportData={setToBeDeletedTermReportData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermReport;
