import React, { useState, useContext, useEffect, useRef } from 'react';
import { CompactPicker } from 'react-color';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { showDeleteConfirmBox } from '../../components/DeleteConfirmBox';
import { CommonContext, ProgressContext } from '../../contexts';
import { removeWhitespace } from '../../utils/common';
import { createNewSubscribeCalendar, deleteSubscribedCalendar, getAllCalendars, updateCalendarColor, updateCalendarName, updateCalendarPublicState } from '../../data/mysql';

const AdminCalendars = () => {
    const { categories } = useContext(CommonContext);
    const { spinner } = useContext(ProgressContext);
    const { pathname } = useLocation();

    const ref = useRef();

    const [calendars, setCalendars] = useState([]);
    const [calendarName, setCalendarName] = useState('');
    const [calendarId, setCalendarId] = useState('');
    const [calendarColor, setCalendarColor] = useState('');
    const [isSendable, setIsSendable] = useState(false);

    const [displayColorPicker, setdisplayColorPicker] = useState(false);

    useEffect(() => {
        ref.current.focus();

        const init = async () => {
            setCalendars(await getAllCalendars());
        };
        init();
    }, []);

    useEffect(() => {
        if (removeWhitespace(calendarName) && removeWhitespace(calendarId)) {
            setIsSendable(true);
        } else {
            setIsSendable(false);
        }
    }, [calendarName, calendarId]);

    /**
     * 구독할 캘린더를 추가한다.
     */
    const _handleAddCalendar = async () => {
        try {
            spinner.start();

            const result = await createNewSubscribeCalendar({ calendarName, calendarId, calendarColor });

            if (result?.status === 'success') {
                toast.success(result.message);

                setCalendarName('');
                setCalendarId('');
                setCalendarColor('');
            } else {
                toast.error(result.message);
            }

            setCalendars(await getAllCalendars());
        } catch (e) {
            console.log(e.message);
        } finally {
            spinner.stop();
        }
    };

    /**
     *
     * @param calendarId: 캘린더 아이디를 가지고 온다.
     */
    const _handleDeleteCalendar = async ({ calendarId }) => {
        try {
            spinner.start();

            const result = await deleteSubscribedCalendar({ calendarId });

            if (result?.status === 'success') {
                toast.success(result.message);

                setCalendars(await getAllCalendars());
            } else {
                toast.error(result.message);
            }
        } catch (e) {
            console.log(e.message);
        } finally {
            spinner.stop();
        }
    };

    /**
     * 공개 상태(Y, N)를 변경한다.
     * @param  calendarId: 캘린더 ID
     * @param  publicState: 공개 상태(Y, N)
     */
    const _handelPublicState = async ({ calendarId, publicState }) => {
        try {
            spinner.start();

            await updateCalendarPublicState({ calendarId, publicState: publicState === 'Y' ? 'N' : 'Y' });
            setCalendars(await getAllCalendars());
        } catch (e) {
            console.log(e.message);
        } finally {
            spinner.stop();
        }
    };

    /**
     * 캘린더 이름을 변경한다.
     * @param  calendarId: 캘린더 ID
     * @param  calendarName: 캘린더 이름
     */
    const _handleEditCalendarName = async ({ calendarId, calendarName }) => {
        try {
            spinner.start();

            await updateCalendarName({ calendarId, calendarName });
            setCalendars(await getAllCalendars());
        } catch (e) {
            console.log(e.message);
        } finally {
            spinner.stop();
        }
    };

    /**
     * 해당 캘린더의 컬러를 변경한다. by calendarId
     * @param calendarId: 캘린더 ID
     * @param color: 캘린더 컬러
     */
    const _handleChangeColor = async ({ calendarId, color }) => {
        try {
            spinner.start();
            await updateCalendarColor({ calendarId, color });
            setCalendars(await getAllCalendars());
        } catch (e) {
            console.log(e.message);
        } finally {
            spinner.stop();
        }
    };

    return (
        <div id="primary" className="content-area">
            {/* <!--s:#content --> */}
            <div id="content" className="site-content">
                <article>
                    <div>
                        <div className="title-inner">
                            <img src="/common/images/title.png" alt="" />
                            <h1>{categories.find((category) => category.categoryCode === pathname.split('/')[4])?.categoryName}</h1>
                        </div>
                        <div style={{ height: 20 }}></div>
                        <div style={{ clear: 'both' }}></div>
                        <div className="title_subpage_solid_1"></div>
                        <div style={{ height: 30 }}></div>

                        {/* 입력부 ------------------------------------------------------  */}
                        <div className="calendar">
                            {calendars.map((calendar, index) => {
                                return (
                                    <div key={index} className="subscribe-calendar-list">
                                        <div className="public">
                                            <button
                                                type="button"
                                                className={`btn state ${calendar.public === 'Y' ? 'btn-warning' : 'btn-gray'}`}
                                                onClick={() => _handelPublicState({ calendarId: calendar.id, publicState: calendar.public })}
                                            >
                                                {calendar.public === 'Y' ? '공개' : '비공개'}
                                            </button>
                                        </div>
                                        <div className="name">
                                            <input
                                                id={`edit_name_${index}`}
                                                defaultValue={calendar.name}
                                                onChange={(e) => {
                                                    let icon = document.getElementById(`edit_${index}`);

                                                    if (calendar.name !== e.target.value) {
                                                        icon.style.display = '';
                                                    } else {
                                                        icon.style.display = 'none';
                                                    }
                                                }}
                                            />
                                            <i
                                                id={`edit_${index}`}
                                                className="material-icons"
                                                style={{ fontSize: 13, marginLeft: 4, display: 'none', cursor: 'pointer' }}
                                                onClick={(e) => {
                                                    _handleEditCalendarName({ calendarId: calendar.id, calendarName: document.getElementById(`edit_name_${index}`).value });
                                                    e.target.style.display = 'none';
                                                }}
                                            >
                                                edit
                                            </i>
                                        </div>
                                        <div className="calendar-id">
                                            <input defaultValue={calendar.googleCalendarId} disabled />
                                        </div>
                                        <div className="color">
                                            <button
                                                type="button"
                                                className="btn"
                                                style={{ backgroundColor: calendar.color }}
                                                onClick={() => {
                                                    let div = document.getElementById(`colorPicker_${index}`);

                                                    if (div.style.display === 'none') {
                                                        div.style.display = 'block';
                                                    } else {
                                                        div.style.display = 'none';
                                                    }
                                                }}
                                            >
                                                &nbsp;
                                            </button>
                                            {
                                                <div id={`colorPicker_${index}`} style={{ position: 'absolute', zIndex: 3, left: -210, top: 35, display: 'none' }}>
                                                    <CompactPicker
                                                        triangle="top-right"
                                                        // onChange={}
                                                        onChangeComplete={async (color, event) => {
                                                            _handleChangeColor({ calendarId: calendar.id, color: color.hex });

                                                            // this.style.display = 'none';
                                                            document.getElementById(`colorPicker_${index}`).style.display = 'none';
                                                            // console.log(this);
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        <div className="functions">
                                            <i
                                                className="material-icons"
                                                style={{ fontSize: 18, color: '#AEAEAE', cursor: 'pointer' }}
                                                onClick={() => showDeleteConfirmBox(() => _handleDeleteCalendar({ calendarId: calendar.id }))}
                                            >
                                                delete
                                            </i>
                                        </div>
                                    </div>
                                );
                            })}
                            {/* 입력부 ------------------------------------------------------  */}
                            <div className="input-form">
                                <input
                                    type="text"
                                    ref={ref}
                                    className="name"
                                    value={calendarName}
                                    placeholder="[부서 이름]을 입력하세요"
                                    maxLength={32}
                                    onChange={(e) => setCalendarName(e.target.value)}
                                />
                                <input
                                    type="text"
                                    className="calendar-id"
                                    value={calendarId}
                                    placeholder="[구글 캘린더 ID]를 입력하세요"
                                    maxLength={128}
                                    onChange={(e) => setCalendarId(e.target.value)}
                                />
                                <div style={{ position: 'relative' }}>
                                    <button
                                        type="button"
                                        className="btn color"
                                        style={{ backgroundColor: calendarColor }}
                                        onClick={() => {
                                            setdisplayColorPicker(!displayColorPicker);
                                        }}
                                    />
                                    {displayColorPicker && (
                                        <div style={{ position: 'absolute', zIndex: 3, left: -190, top: 60, display: '' }}>
                                            <CompactPicker
                                                triangle="top-right"
                                                // onChange={}
                                                onChangeComplete={(color, event) => {
                                                    setCalendarColor(color.hex);
                                                    setdisplayColorPicker(!displayColorPicker);
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <button type="button" className="btn btn-black color" disabled={!isSendable} onClick={_handleAddCalendar}>
                                    등록
                                </button>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
            {/* <!--//e: #content --> */}
        </div>
    );
};

export default AdminCalendars;
