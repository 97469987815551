import React from 'react';

import TopBar from './TopBar';
import MainNavBar from './MainNavBar';

const Header = () => {
    return (
        <div className='wow fadeInDown'>
            <TopBar />          {/* 탑 메뉴: 로그인 | 회원가입 | 회원리스트 | 메인롤링사진 */}
            <MainNavBar />      {/* 메인 메뉴: 합회소개 | 합회보고 | 자료실 | 게시판 */}
        </div>
    );
};

export default Header;