import React from 'react';
import { format, getQuarter } from 'date-fns';
import styled from 'styled-components';
import ArrowButtons from '../../../termReport/components/ArrowButtons';

const StyledText = styled.span`
    font-family: '기본체';
`;

const Last = styled.div`
    color: #777;
`;

const ActivityTermReportHeader = ({ currentTerm, setCurrentTerm }) => {
    return (
        <div className="styled-between">
            <div className="first">
                <StyledText>
                    {format(currentTerm, 'yyyy')}년 제 {getQuarter(currentTerm)}기 보고
                </StyledText>
            </div>
            <Last>
                <ArrowButtons //
                    currentTerm={currentTerm}
                    setCurrentTerm={setCurrentTerm}
                />
            </Last>
        </div>
    );
};

export default ActivityTermReportHeader;
