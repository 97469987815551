import React from 'react';
import { Link } from 'react-router-dom';
import { DataTypes } from '../../data/Types';

const MainInfoBox = () => {
    return (
        <div className="nmcdn_col1 col-sm-4 wow fadeInRight" data-wow-delay="0.7s">
            <div className="nmcdn_card">
                <div className="question">
                    <p>영남합회 문의안내</p>
                    <p className="call">{`${DataTypes.WEBSITE_INFO_TEL}`}</p>
                    <p className="qu_text">
                        영남합회는 대구, 부산, 울산 등 3개의 광역시를 포함한 경남, 경북 지역의 선교를 책임진 합회로서 123개의 조직된 교회와 27개의 미조직된 예배소로 구성되어 있습니다.
                    </p>
                    <div>
                        <Link to="/page/info/27/intro">합회소개</Link>
                        <Link to="/page/info/34/path">오시는길</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainInfoBox;
