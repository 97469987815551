import React, { useEffect, useRef, useState } from 'react';

const AddRequest = ({ request, reportMxData, setReportMxData, disabled }) => {
    // const [disabled, setDisabled] = useState(false);

    const requestRef = useRef();

    // useEffect(() => {
    //     reportMxData.is_submitted === 'Y' ? setDisabled(true) : setDisabled(false);
    // }, [reportMxData.is_submitted]);

    const _handleAddRequest = ({ target }) => {
        setReportMxData((report) => ({
            ...report, //
            request: target.value,
        }));
        requestRef.current.focus();
    };

    return (
        <div className="row">
            <div className="panel">
                <div className="panel-heading">
                    <h3>통신란</h3>
                </div>
                <div className="panel-body">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <textarea
                            ref={requestRef}
                            className="styled-textarea" //
                            value={request}
                            placeholder={'여기에 입력하세요'}
                            onChange={_handleAddRequest}
                            disabled={disabled}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddRequest;
